import { Map } from 'immutable'
import { withdrawalHistory as withdrawalHistoryAPI } from 'lib/api'
import moment from 'moment-timezone'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  withdrawalHistory: [],
  popupFlag: {},
  isDialog: false,
  rejectMemo: '',
  confirm: undefined,
  searchStatus: 'ALL',
  // 기본 1주일 전까지 데이터 가져옴
  searchStartDate: moment()
    .subtract(6, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  searchEndDate: moment()
    .add(1, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  nextKey: undefined,
  page: 0,
  count: 20,
  rowsPerPage: 5
})

const handler = {
  OPEN_POPUP: {
    reducer: (state, action) => {
      const name = action.payload
      const popupFlag = state.get('popupFlag')
      return state.set(
        'popupFlag',
        Object.assign({}, popupFlag, { [name]: true })
      )
    }
  },
  CLOSE_POPUP: {
    reducer: (state, action) => {
      const name = action.payload
      const popupFlag = state.get('popupFlag')
      return state.set(
        'popupFlag',
        Object.assign({}, popupFlag, { [name]: false })
      )
    }
  },
  OPEN_DIALOG: {
    reducer: (state, action) => {
      const withdrawList = state.get('withdrawalHistory')
      const result = withdrawList.filter(
        withdraw => withdraw.withdrawId === action.payload
      )[0]
      return state
        .set('isDialog', true)
        .set('rejectMemo', result ? result.memo : '메모가 없습니다.')
    }
  },
  CLOSE_DIALOG: {
    reducer: state => {
      return state.set('isDialog', false)
    }
  },
  CONFIRM_SUBMIT: {
    reducer: (state, action) => {
      return state.set('confirm', action.payload)
    }
  },
  HANDLE_CHANAGE: {
    reducer: (state, action) => {
      return state.set('searchStatus', action.payload)
    }
  },
  ON_CHANGE_START_DATE: {
    reducer: (state, action) => {
      return state.set('searchStartDate', action.payload)
    }
  },
  ON_CHANGE_END_DATE: {
    reducer: (state, action) => {
      return state.set('searchEndDate', action.payload)
    }
  },
  ON_CHANGE_PAGE: {
    reducer: (state, action) => {
      return state.set('page', action.payload)
    }
  },
  ON_CHANGE_ROWS_PER_PAGE: {
    reducer: (state, action) => {
      return state.set('rowsPerPage', action.payload)
    }
  },
  GET_WITHDRAWAL_HISTORY: {
    payloadCreator: withdrawalHistoryAPI.getWithdrawalHistory,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { refresh } = action.payload
        const { data, nextKey } = action.payload.result
        const withdrawalHistory = state.get('withdrawalHistory')
        let newWithdrawalHistory = withdrawalHistory.concat(data)
        let newCount = withdrawalHistory.concat(data).length
        if (refresh) {
          newWithdrawalHistory = data
          newCount = data.length
        }
        const stateData = {
          withdrawalHistory: newWithdrawalHistory,
          nextKey,
          count: newCount
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
