import { Map, Set, List, fromJS } from 'immutable'
import { apps as api } from 'lib/api'
import _ from 'lodash'
import config from 'config'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const { messageType = {} } = config
const initialForm = Map({
  appId: '',
  accountId: '',
  clientId: '',
  clientSecret: '************************',
  redirectUri: 'http://',
  appName: '',
  scopes: Set([]),
  thumbnail: Map({}),
  screenshots: List([]),
  profit: Map(
    _.mapValues(messageType, () => {
      return 0
    })
  ),
  homepage: 'https://',
  email: '',
  categories: Set([]),
  intro: '',
  description: '',
  status: 'default'
})
const initialState = Map({
  detail: initialForm,
  isNew: false,
  isRecreated: false,
  isOpen: false,
  isModified: false,
  isConfirmPopupOpen: false,
  isError: false,
  message: '',
  messageVariant: '',
  validateResult: Map({}),
  result: '',
  page: 0,
  count: 0,
  rowsPerPage: 20,
  list: [],
  selected: [],
  appCategoryList: [
    { value: 'default', label: '-- 선택 --' },
    { value: 'ENTER', label: '엔터테인머트' },
    { value: 'BUSINESS', label: '비즈니스' },
    { value: 'ETC', label: '기타' }
  ],
  appStatusList: [
    { value: 'default', label: '-- 선택 --' },
    { value: 'ACTIVE', label: '활성화' },
    { value: 'BLOCKED', label: '스팸차단됨' }
  ],
  appStageList: [
    { value: 'default', label: '-- 선택 --' },
    { value: 'DEVELOP', label: '개발중' },
    { value: 'LIVE', label: '활성화' }
  ],
  conditionList: [
    {
      type: 'status',
      operator: 'eq',
      value: ''
    }
  ],
  categoryList: [
    {
      text: '계정 아이디 (accountId)',
      type: 'accountId',
      allowedOperators: ['eq']
    },
    {
      text: '앱 아이디 (appId)',
      type: 'appId',
      allowedOperators: ['eq']
    },
    {
      text: '앱 이름 (appName)',
      type: 'appName',
      allowedOperators: ['eq']
    },
    {
      text: '앱 상태 (status)',
      type: 'status',
      allowedOperators: ['eq'],
      enumList: [
        'ACTIVE',
        'INACTIVE',
        'DEVELOPING',
        'REJECTED',
        'DELETED',
        'BLOCKED'
      ]
    }
  ],
  operatorList: {
    na: { text: '해당없음' },
    eq: { text: '== 와 같음' },
    ne: { text: '!== 와 다름' },
    gte: { text: '>= 같거나 큰' },
    gt: { text: '> 큰' },
    lte: { text: '<= 같거나 작은' },
    lt: { text: '< 작은' }
  }
})

const handler = {
  RESET_DETAIL_FORM: {
    reducer: (state, action) => {
      return state
        .set('detail', initialForm)
        .set('isNew', false)
        .set('isRecreated', false)
        .set('isOpen', false)
        .set('isModified', false)
        .set('isConfirmPopupOpen', false)
        .set('isError', false)
    }
  },
  SET_CONFIRM_POPUP_CLOSE: {
    reducer: (state, action) => {
      return state.set('isConfirmPopupOpen', !state.get('isConfirmPopupOpen'))
    }
  },
  SET_MESSAGE: {
    reducer: (state, action) => {
      const { message = 'default message', type = 'success' } = action.payload
      return state.set('message', message).set('messageVariant', type)
    }
  },
  SET_ERROR: {
    reducer: (state, action) => {
      return state.set('isError', action.payload)
    }
  },
  SET_CLIENT_SECRET: {
    reducer: (state, action) => {
      return state
        .setIn(['detail', 'clientSecret'], action.payload)
        .set('isRecreated', true)
    }
  },
  SET_NEW: {
    reducer: (state, action) => {
      return state.set('isNew', true)
    }
  },
  SET_DETAIL: {
    reducer: (state, action) => {
      return state.set('detail', action.payload)
    }
  },
  DELETE_PERMISSION: {
    reducer: (state, action) => {
      const scope = action.payload
      const scopes = state.getIn(['detail', 'scopes'])
      const newScopes = scopes.delete(scope)
      return state.setIn(['detail', 'scopes'], newScopes)
    }
  },
  DELETE_IMAGE: {
    reducer: (state, action) => {
      const { target, file } = action.payload
      if (target === 'thumbnail') {
        return state
          .setIn(['detail', 'thumbnail'], fromJS({}))
          .set('isModified', true)
      }
      const screenshots = state.getIn(['detail', target])
      const index = _.findIndex(screenshots.toJS(), o => o.name === file.name)
      const newImages = screenshots.delete(index)
      return state.setIn(['detail', target], newImages).set('isModified', true)
    }
  },
  UPDATE_PROFIT: {
    reducer: (state, action) => {
      const { target, value } = action.payload
      return state
        .setIn(['detail', 'profit', target], parseFloat(value))
        .set('isModified', true)
    }
  },
  UPDATE_FORM: {
    reducer: (state, action) => {
      const { target, value } = action.payload
      if (target === 'scopes') {
        const scopes = state.getIn(['detail', 'scopes'])
        const newScopes = scopes.add(value)
        return state
          .setIn(['detail', 'scopes'], newScopes)
          .set('isModified', true)
      }
      if (target === 'categories') {
        const categories = value.filter(val => {
          if (val !== 'default') return val
          return false
        })
        return state
          .setIn(['detail', 'categories'], categories)
          .set('isModified', true)
      }
      return state.setIn(['detail', target], value).set('isModified', true)
    }
  },
  UPDATE_VALIDATE_RESULT: {
    reducer: (state, action) => {
      const { validateResult, target } = action.payload
      return state.setIn(['validateResult', target], validateResult)
    }
  },
  CLOSE_MESSAGE: {
    reducer: (state, action) => {
      return state.set('message', '')
    }
  },
  REMOVE_CONDITION: {
    reducer: (state, action) => {
      const index = action.payload
      const conditionList = state.get('conditionList')
      if (conditionList.length === 1) {
        conditionList[0].value = ''
        return state.set('conditionList', conditionList)
      }

      const newCondList = []
      _.filter(conditionList, (o, i) => {
        if (index !== i) newCondList.push(o)
      })
      return state.set('conditionList', newCondList)
    }
  },
  ADD_CONDITION: {
    reducer: (state, action) => {
      const conditionList = state.get('conditionList')
      const categoryList = state.get('categoryList')
      let newCond
      if (_.isEmpty(conditionList)) {
        const v = categoryList[0]
        return state.set('conditionList', {
          type: v.type,
          operator: v.allowedOperators[0],
          value: ''
        })
      }

      _.forOwn(categoryList, (v, k) => {
        if (newCond) return
        const newObj = _.find(conditionList, o => {
          return o.type === v.type
        })
        if (_.isUndefined(newObj)) {
          newCond = { type: v.type, operator: v.allowedOperators[0], value: '' }
        }
      })
      if (!newCond) return state.set('conditionList', conditionList)

      conditionList.push(newCond)
      return state.set('conditionList', conditionList)
    }
  },
  GET_APP_LIST: {
    payloadCreator: api.getAppList,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const { data } = action.payload
        const rowsPerPage = state.get('rowsPerPage')
        const page = state.get('page')
        const length = data.length
        const count =
          length !== rowsPerPage
            ? length + rowsPerPage * page
            : length + rowsPerPage * (page + 1)
        const stateData = { list: data, count: count }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_APP_INFO: {
    payloadCreator: api.getAppInfo,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const { data } = action.payload
        const detail = state.get('detail')
        const stateData = { detail }
        if (data.length) {
          const newDetail = fromJS(Object.assign({}, detail.toJS(), data[0]))
          stateData.detail = newDetail
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SAVE_PERMISSION: {
    payloadCreator: api.savePermission,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const newDetail = state.get('detail')
        const stateData = {
          detail: newDetail,
          isOpen: false
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  UPLOAD_IMAGE: {
    payloadCreator: api.uploadImage,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const {
          data: { imageName, imageUrl, originalName },
          target
        } = action.payload
        const stateData = { detail: {}, isModified: true }
        stateData.detail = Object.assign({}, state.get('detail').toJS())
        if (target === 'thumbnail') {
          stateData.detail.thumbnail = fromJS({
            name: imageName,
            url: imageUrl,
            originalName
          })
        } else {
          const data = { name: imageName, url: imageUrl, originalName }
          stateData.detail.screenshots.push(data)
        }
        stateData.detail = fromJS(stateData.detail)
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  UPDATE_FORM_DATA: {
    payloadCreator: api.updateFormData,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const newDetail = fromJS(action.payload.data)
        const stateData = {
          detail: newDetail,
          isOpen: false,
          isNew: false,
          isRecreated: false,
          isModified: false
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  UPDATE_STAGE: {
    payloadCreator: api.updateStage,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const newDetail = fromJS(action.payload.data)
        const stateData = {
          detail: newDetail,
          isConfirmPopupOpen: false
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SET_IS_MODIFIED: {
    reducer: state => {
      return state.set('isModified', true)
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
