export default function (message = '') {
  let size = 0
  const matches = message.match(/[^\x05]|.+/g) || []
  if (!matches) return size
  matches.forEach(str => {
    var charCode = str.charCodeAt(0)
    size += charCode <= 0x00007f ? 1 : 2
  })
  return Number(size)
}
