import { Map, List } from 'immutable'
import { profitHistory as api } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

export const initialState = Map({
  appList: List([]),
  dataList: List([]),
  rowData: List([]),
  rowsPerPage: 20,
  page: 0,
  count: 0,
  selectedApp: 'ALL',
  load: true,
  expectedProfit: 0
})

export const fetchDataSuccess = (state, action, options) => {
  const dataList = state.get('dataList').concat(action.payload.dataList)
  const rowsPerPage = state.get('rowsPerPage')
  const count =
    dataList.size + (dataList.size % rowsPerPage === 0 && dataList.size ? 1 : 0)
  if (action.payload.dataList.length !== rowsPerPage) { state = state.set('load', false) }
  const stateData = {
    dataList: dataList,
    count: count,
    expectedProfit: action.payload.expectedProfit
  }
  return onSuccess(state, action, { ...options, stateData })
}

export const getAppDataSuccess = (state, action, options) => {
  const stateData = {
    appList: action.payload.data.map(({ appId: value, appName: text }) => ({
      value,
      text
    }))
  }
  return onSuccess(state, action, { ...options, stateData })
}

export const setState = (state, action) => {
  Object.keys(action.payload).forEach(
    key => (state = state.set(key, action.payload[key]))
  )
  return state
}

export const getRowData = (state, action) => {
  const dataList = state.get('dataList')
  const page = state.get('page')
  const rowsPerPage = state.get('rowsPerPage')
  return state.set(
    'rowData',
    dataList.slice(page * rowsPerPage, (page + 1) * rowsPerPage)
  )
}

const handler = {
  SET_STATE: { reducer: setState },
  GET_ROW_DATA: { reducer: getRowData },
  FETCH_DATA: {
    payloadCreator: api.fetchData,
    reducer: {
      onFailure,
      onPending,
      onSuccess: fetchDataSuccess
    }
  },
  GET_APP_DATA: {
    payloadCreator: api.getAppData,
    reducer: {
      onFailure,
      onPending,
      onSuccess: getAppDataSuccess
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
