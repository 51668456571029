import axios from 'axios'
import qs from 'querystring'

export const login = params => {
  const uri = `/oauth2/v1/login`
  return axios.post(uri, params, { withCredentials: true })
}

export const getClientInfo = clientId => {
  const uri = `/oauth2/v1/clients/${clientId}`
  return axios.get(uri)
}

export const getMyAllowedClients = params => {
  const query = qs.stringify(params)
  const uri = `/oauth2/v1/connections/clients/me${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getScopeInfo = params => {
  const query = qs.stringify(params)
  const uri = `/oauth2/v1/scopes${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const recreateSecret = payload => {
  const { clientId } = payload
  const uri = `/oauth2/v1/clients/${clientId}/secret`
  return axios.put(uri)
}

export const deleteMyAllowedScopes = clientId => {
  const uri = `/oauth2/v1/authorize/decision`
  return axios.delete(uri, { data: { client_id: clientId } })
}
