import { Map } from 'immutable'
import { requestHandler } from 'lib'
import config from 'config'
import coolsmsMenu from 'lib/coolsmsMenu'
const { createRedux } = requestHandler
const initialState = Map({
  openIndex: [],
  loginBoxButtons: {
    login: {
      link: '/oauth2/login',
      local: 'auth-webpages'
    },
    signUp: {
      link: '/signup',
      local: 'auth-webpages'
    },
    resetPassword: {
      link: '/reset-password',
      local: 'auth-webpages'
    },
    logout: {
      link: config.apiHost + '/oauth2/v1/logout',
      local: ''
    },
    balance: {
      link: '/recharge',
      local: 'auth-webpages'
    },
    dashboard: {
      link: '/dashboard',
      local: 'auth-webpages'
    },
    start: {
      link: '/start',
      local: 'auth-webpages'
    },
    accounts: {
      link: '/accounts',
      local: 'auth-webpages'
    },
    accountInfo: {
      link: '/accounts/selected-account',
      local: 'auth-webpages'
    },
    profile: {
      link: '/profile',
      local: 'auth-webpages'
    }
  },
  menus: coolsmsMenu
})
const handler = {}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
