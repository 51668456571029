const axios = require('axios')

export const getAppList = async () => {
  const uri = `/appstore/v2/me/apps?dateSort=DESC`
  const { data } = await axios.get(uri)
  return data
}

export const createApp = async appName => {
  const url = `/appstore/v2/me/apps`
  const { data } = await axios({
    method: 'post',
    url,
    data: { appName }
  })
  return data
}
