
import _ from 'lodash'
import moment from 'moment-timezone'

export const parseCondition = conditionList => {
  const today = moment().format('Y-M-D')
  let options = {}
  _.forEach(conditionList, condition => {
    if (_.isEmpty(condition.value)) return
    switch (condition.type) {
      case 'recent':
        // 만약에 startDate 와 endDate 값이 있다면 무시함
        if (options.startDate || options.endDate) break
        options.startDate = moment(today).subtract(1, 'months').toISOString()
        options.endDate = moment(`${today} 23:59:59`).toISOString()
        break
      case 'accountId':
        options.accountId = condition.value
        break
      case 'corpNum':
        options.corpNum = condition.value
        break
      case 'corpName':
        options.corpName = condition.value
        break
      case 'stateCode':
        options.stateCode = condition.value
        break
      case 'ntsResult':
        options.ntsResult = condition.value
        break
      case 'startDate':
        // 이미 startDate 에 값이 있다면 recent 가 존재하므로 삭제처리
        if (options.startDate) delete options.endDate
        options.startDate = moment(condition.value).toISOString()
        break
      case 'endDate':
        // 이미 endDate 에 값이 있다면 recent 가 존재하므로 삭제처리
        if (options.endDate) delete options.startDate
        options.endDate = moment(condition.value).toISOString()
        break
      case 'dateSent':
        options.dateSent = condition.value
        break
      case 'userId':
        options.userId = condition.value
        break
      case 'countryId':
        options.countryId = condition.value
        break
      default:
    }
  })
  return options
}

/**
 * - 국세청 결과코드
 *
 * SUC001 // 성공 // 모든 검증 과정을 정상적으로 통과한 경우
 * SYN002 // 공급자(위수탁발행시 수탁자) 전자서명 오류 // 공급자의 전자세금계산서 전자서명이 유효하지 않는 경우
 * SYN003 // 승인번호 오류 // 승인번호가 국세청에 이미 등록되어 있는 경우
 * SYN004 // 전자세금계산서 스키마 오류 //
 *   - 유효하지 않은 전자세금계산서 구조를 가진 경우
 *     엘리먼트 중복, 필수 엘리먼트 미존재, 데이터 유형오류,
 *     코드오류, 승인번호 형식오류 등
 *   - 계산서의 종류 코드가 영세율일 경우
 * ERR001 // 공급자 사업자등록번호 오류 // 국세청 미등록 사업자
 * ERR002 // 공급받는자 사업자등록번호 오류 // 국세청 미등록 사업자 또는 미등록 주민등록번호인 경우
 * ERR003 // 수탁자 사업자등록번호 오류 // 수탁자 존재 시 국세청 미등록 사업자
 * ERR005 // 발행일시 오류 //
 *   - 발행일시가 유효하지 않는 경우
 *   - 발행일시가 전송일시 이후인 경우
 * ERR006 // 작성일자 오류 //
 *   - 작성일자가 유효하지 않은 경우
 *   - 작성일자가 발행일자 이후인 경우
 *   - 승인번호 내의 작성일자와 작성일자가 다른 경우
 * ERR007 // 공급가액, 세액 오류 //
 *   - 공급가액과 세액의 부호가 다른 경우
 *   - 수정세금계산서의 환입 또는 계약의 해제 시 금액이 정(+)의 값인 경우
 *   - 영세율 세금계산서일 때 세액이 “0”이 아닌 경우
 *   - 계산서일 때 세액이 “0”이 아닌 경우
 * ERR008 // 코드 유형 오류 // 세금계산서 종류, 공급받는자 사업자등록번호 구분 코드, 수정세금계산서일 때 수정 코드에 오류가 있는 경우
 * ERR009 // 폐업사업자 발행오류 // 공급자(위수탁발행시 수탁자)가 폐업신고일자 이후, 세금계산서를 발행한 경우
 * ERR010 // 국세청 등록번호 오류 // 발행시스템 승인번호의 국세청등록번호와 전송시스템 승인번호의 국세청등록번호가 다른 경우
 * ERR011 // 당초승인번호 오류 //
 *   - 수정전자세금계산서에 원본 승인번호가 존재하지 않거나, 원본 승인번호가 아닌 경우
 *   - 일반 전자세금계산서에 원본 승인번호가 존재하는 경우
 * ERR999 // 정의되지 않는 기타 오류 // 정의되지 않는 기타 오류
 *
 *
 * - 세금계산서 상태코드
 *
 * 100 // 임시저장 // 공급자(수탁자)가 정발행(위수탁) 세금계산서를 임시저장(Register)
 * 200 // 승인대기 //
 *   - 공급자(수탁자)가 임시저장[100] 상태의 (세금)계산서를 ‘발행예정(Send)’ 처리
 *   – 공급받는자의 승인(Accept)을 대기
 * 210 // 발행대기 //
 *   - 공급받는자가 승인대기[200] 상태의 (세금)계산서를 ‘승인(Accept)’ 처리
 *   – 공급자(수탁자)의 발행(Issue)을 대기
 * 300 310 // 발행완료  //
 *   - 300 : 공급자(수탁자)가 임시저장[100]상태의 (세금)계산서를 ‘발행(Issue)’ 처리
 *   - 310 : 공급자(수탁자)가 발행대기[210]상태의 (세금)계산서를 ‘발행(Issue)’ 처리
 * 301, 302, 303, 311, 312, 313 // 전송중 //
 *   - 국세청 전송전( _ _ 1), 전송대기( _ _ 2), 전송중( _ _ 3)으로 전송결과 수신하기 전 상태
 * 304, 314  // 전송성공 // 국세청에 전송이 정상적으로 완료
 * 305, 315  // 전송실패 // 국세청에 전송이 특정한 사유료 실패
 * 400 // 거부 // 공급받는자가 승인대기[200] 상태의 (세금)계산서를 ‘거부(Refuse)’ 처리
 * 500, 510 // 취소 //
 *   - 500 : 공급자(수탁자)가 승인대기[200]상태의 (세금)계산서를 ‘취소(cancelSend)’ 처리
 *   - 510 : 공급자(수탁자)가 발행대기[210]상태의 (세금)계산서를 ‘취소(cancelSend)’ 처리
 * 600, 610  // 발행취소 //
 *   - 600 : 공급자(수탁자)가 발행완료[300]상태의 (세금)계산서를 ‘발행취소(cancelIssue)’ 처리
 *   - 610 : 공급자(수탁자)가 발행완료[310]상태의 (세금)계산서를 ‘발행취소(cancelIssue)’ 처리
 *
 *
 * - 수정 사유코드
 *
 * 1 - 기재사항 착오정정
 * 2 - 공급가액 변동
 * 3 - 환입
 * 4 - 계약의 해지
 * 5 - 내국신용장 사후개설
 * 6 - 착오에 의한 이중발행
 */
