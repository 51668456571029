import { isFunction, isEmptyString } from 'lib/detectType'
import getCustomActionsByProps from 'lib/getCustomActionsByProps'

const clearContainerResponse = (props, options) => {
  // 팝업에서 띄운 컨테이너는 예외
  if (options?.ignorePopup !== false) {
    if (!isEmptyString(props?.popupKey)) return
  }
  const actions = getCustomActionsByProps(props)
  actions.forEach(actionName => {
    if (isFunction(props?.[actionName]?.clearCurrentContainerResponse)) {
      // response 청소
      props[actionName].clearCurrentContainerResponse()
    }
  })
}
export default clearContainerResponse
