const merge = require('lodash/merge')

let config = {
  commonRequestUrl: 'https://api.solapi.dev',
  apiHost: 'https://api.solapi.dev',
  pageHost: 'https://solapi.dev'
}

module.exports = config

if (process.env.NODE_ENV === 'production') {
  const production = require('./production')
  config = merge(config, production)
}

// if (process.env.NODE_ENV === 'development') {
//   const development = require('./development')
//   config = merge(config, development)
// }

// if (process.env.NODE_ENV === 'test') {
//   const test = require('./test')
//   config = merge(config, test)
// }

// if (process.env.REACT_APP_INTEGRATION === '1') {
//   const integration = require('./integration')
//   config = merge(config, integration)
// }
module.exports = config
