import qs from 'qs'
import axios from 'axios'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getCredentials = (accountId, options = {}) => {
  const { cache = false } = options
  return axios.get(`/credentials/v1/accounts/${accountId}`, { cache })
}

export const getCredentialsSecret = ({ accountId, apiKey }) => {
  const uri = `/credentials/v1/accounts/${accountId}/${apiKey}/secret`
  return axios.get(uri)
}

export const getSdkList = params => {
  const query = qs.stringify(params)
  return axios.get(`/credentials/v1/sdk${query ? '?' + query : ''}`)
}

export const createCredential = async payload => {
  const { accountId, ...data } = payload
  const uri = `/credentials/v1/accounts/${accountId}`
  return axios.post(uri, data)
}

export const findCredentials = payload => {
  const { accountId, ...params } = payload
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/credentials/v1/account-management/${accountId}${
    query ? '?' + query : ''
  }`
  return axios.get(uri)
}

export const updateCredential = payload => {
  const { accountId, ...params } = payload
  const uri = `/credentials/v1/account-management/${accountId}`
  return axios.put(uri, params)
}

export const getCredentialList = payload => {
  const { accountId } = payload
  return axios.get(`/credentials/v1/accounts/${accountId}`)
}

export const switchCredentialStatus = ({ accountId, apiKey }) => {
  const uri = `/credentials/v1/accounts/${accountId}/${apiKey}/status`
  return axios.post(uri, {})
}

export const resetCredentialSecret = ({ accountId, apiKey }) => {
  const uri = `/credentials/v1/accounts/${accountId}/${apiKey}/secret`
  return axios.post(uri, {})
}

export const deleteCredential = ({ accountId, apiKey }) => {
  const uri = `/credentials/v1/accounts/${accountId}/${apiKey}`
  return axios.delete(uri)
}

export const updateIPFilter = ({ accountId, apiKey, ipblocks }) => {
  const uri = `/credentials/v1/accounts/${accountId}/${apiKey}/ipfilter`
  return axios.put(uri, { ipfilter: { ipblocks } })
}
