import qs from 'querystring'
import omitBy from 'lodash/omitBy'
import mapValues from 'lodash/mapValues'
import isEmpty from 'lodash/isEmpty'
import isUndefined from 'lodash/isUndefined'
import groupBy from 'lodash/groupBy'
import { isObject, isArray } from 'lib/detectType'

export const formatSearchResult = (searchResult = {}) => {
  const { dateType, startDate, endDate, ...result } = searchResult
  const criteria = []
  const cond = []
  const value = [].concat(
    ...Object.values(
      mapValues(result, (o, crit) => {
        criteria.push(
          ...(isObject(o)
            ? [].concat(
                ...Object.keys(o).map(cond =>
                  isArray(o[cond]) ? o[cond].map(d => crit) : crit
                )
              )
            : [crit])
        )
        cond.push(
          ...(isObject(o)
            ? [].concat(
                ...Object.keys(o).map(cond =>
                  isArray(o[cond]) ? o[cond].map(d => cond) : cond
                )
              )
            : ['eq'])
        )
        return isObject(o) ? [].concat(...Object.values(o)) : [o]
      })
    )
  )
  return omitBy(
    {
      dateType,
      startDate,
      endDate,
      criteria: criteria.join(','),
      value: value.join(','),
      cond: cond.join(',')
    },
    isEmpty
  )
}

export const formatSearchQuery = queryString => {
  const query = queryString.replace(/^\?/, '')
  const {
    dateType,
    startDate,
    endDate,
    criteria = '',
    value = '',
    cond = ''
  } = qs.parse(query)
  const criteriaArray = criteria.split(',').filter(d => d)
  const values = value.split(',').filter(d => d)
  const conds = cond.split(',').filter(d => d)
  if (
    criteriaArray.length < 1 ||
    criteriaArray.length !== values.length ||
    criteriaArray.length !== conds.length
  ) {
    return omitBy({ dateType, startDate, endDate }, isUndefined)
  }
  const result = mapValues(groupBy(criteriaArray), data => {
    const length = data.filter(d => d).length
    if (length > 0) {
      const cond = conds.splice(0, length)
      const value = values.splice(0, length)
      return cond.map((key, i) => ({ [key]: value[i] }))
    }
  })
  return omitBy({ dateType, startDate, endDate, ...result }, isUndefined)
}

export default { formatSearchResult }
