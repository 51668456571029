/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
import { test as testAPI } from 'lib/api'

const initialState = Map({ a: 1 })
const handler = {
  // 요청 테스트
  SERVER_REQUEST: {
    options: {
      debounce: {
        wait: 1000,
        leading: true,
        trailing: false
      },
      reducerDebounce: {
        wait: 1000
      }
    },
    payloadCreator: testAPI.serverRequest,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action) => {
        const message = 'success!!'
        const options = { message }
        return onSuccess(state, action, options)
      }
    }
  }
}

handler.failedRequset = {
  options: {
    response: {
      loading: {
        display: 'linear' // (string) default: circular
      },
      message: {
        display: 'alert'
      },
      error: {
        display: 'alert' // (string) default: snackbar
      }
    }
  },
  payloadCreator: testAPI.failedRequset
}

handler.loadingRequest = {
  options: {
    response: {
      loading: {
        display: 'linear'
      }
    }
  },
  payloadCreator: testAPI.loadingRequest
}

handler.successRequset = {
  payloadCreator: testAPI.successRequset
}

handler.ignoreError = {
  options: {
    response: {
      error: {
        ignore: true // (array or boolean) default: false
      }
    }
  }
}

handler.okRequest = {
  payloadCreator: testAPI.okRequest
}

handler.asyncFunc = {
  payloadCreator: testAPI.asyncfunc
}

handler.realMfa = {
  options: {
    debounce: {
      wait: 500
    },
    response: {
      error: {
        // ignore: true // (array or boolean) default: false
      },
      message: {
        // display: 'alert',
        // ignore: true // (array or boolean) default: false
      },
      loading: {
        // ignore: true,
        // display: 'linear'
      }
    }
  },
  payloadCreator: testAPI.realMfa,
  reducer: {
    onFailure: (state, action, options) => {
      console.log(action)
      return onFailure(state, action, options)
    },
    onSuccess: (state, action, options) => {
      console.log('run!', action.meta)
      return onSuccess(state, action, {
        ...options,
        stateData: {
          test: 1235
        }
      })
    }
  }
}

handler.simpleMfa = {
  payloadCreator: testAPI.realMfa
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
