import { Map } from 'immutable'
import _ from 'lodash'
import findIndex from 'lodash/findIndex'

import uniqid from 'uniqid'
import { requestHandler } from 'lib'

const { createRedux } = requestHandler
const initialState = Map({
  data: {},
  messageQueue: []
})

function enqueue(state, action) {
  const { key, severity, duration } = action?.payload || {}
  const hash = key === 'public' || !key ? uniqid() : key
  const data = {
    hash,
    showCloseButton: true,
    hideClickAway: severity !== 'error',
    duration: duration || (severity === 'error' ? 12000 : 5500),
    ...action.payload
  }
  const messageQueue = state.get('messageQueue')
  const newMessageQueue = [...messageQueue]
  const existHashIndex = findIndex(newMessageQueue, messageData => {
    return messageData?.hash === data?.hash
  })
  if (existHashIndex !== -1) {
    newMessageQueue[existHashIndex] = data
    return state.set('messageQueue', newMessageQueue)
  }
  const existMessageIndex = findIndex(newMessageQueue, messageData => {
    return (
      messageData?.message === data?.message &&
      messageData?.severity === data?.severity
    )
  })
  if (existMessageIndex !== -1) {
    newMessageQueue[existMessageIndex] = data
    return state.set('messageQueue', newMessageQueue)
  }
  return state.set('messageQueue', newMessageQueue.concat(data))
}

const handler = {
  SET_DATA: {
    reducer: (state, action) => {
      const {
        key,
        open = true,
        message,
        variant,
        request = {},
        snackbarProps
      } = action.payload
      const data = state.get('data')
      const detailData = Object.assign(
        {},
        data[key] || {},
        _.omitBy(
          {
            open,
            message,
            variant,
            request,
            snackbarProps
          },
          _.isUndefined
        )
      )
      return state.set('data', Object.assign({}, data, { [key]: detailData }))
    }
  }
}

handler.new = { reducer: enqueue }

handler.enqueue = { reducer: enqueue }

handler.dequeue = {
  reducer: (state, action) => {
    const hash = action.payload?.hash
    const messageQueue = state.get('messageQueue')
    return state.set(
      'messageQueue',
      messageQueue.filter(messageData => {
        return messageData?.hash !== hash
      })
    )
  }
}

handler.updateMessage = {
  reducer: (state, action) => {
    const hash = action.payload?.hash
    const messageQueue = state.get('messageQueue')
    const qIndex = findIndex(messageQueue, messageData => {
      return messageData?.hash === hash
    })
    const newMessageQueue = [...messageQueue]
    newMessageQueue[qIndex] = { ...newMessageQueue[qIndex], ...action.payload }
    return state.set('messageQueue', newMessageQueue)
  }
}

handler.updateMessageQueue = {
  reducer: (state, action) => {
    return state.set('messageQueue', [...action.payload])
  }
}

handler.clear = {
  reducer: (state, action) => {
    const { key } = action.payload || {}
    if (key) {
      const data = state.get('data')
      return state.set('data', Object.assign({}, data, { [key]: {} }))
    }
    return state.set('data', {}).set('messageQueue', [])
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
