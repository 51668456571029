import axios from 'axios'
import qs from 'qs'

export const payByImport = params => {
  const uri = `/prepayment/v1/pay/import`
  return axios.post(uri, params)
}

export const payByKcp = params => {
  const uri = `/prepayment/v1/pay/kcp`
  return axios.post(uri, params)
}

export const getHistories = params => {
  const queryString = qs.stringify(params)
  const uri = `/prepayment/v1/payments${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}
