const axios = require('axios')
const qs = require('querystring')

export const getWithdrawalAdminHistory = searchParams => {
  const uri = `/cash/v1/admin/withdraw?${decodeURIComponent(
    qs.stringify(searchParams)
  )}`
  return axios.get(uri)
}

export const updateWithdraw = ({ withdrawId, status, memo }) => {
  const uri = `/cash/v1/admin/withdraw`
  const data = { withdrawId, status }
  if (memo) data.memo = memo
  return axios.put(uri, data)
}
