import { Map } from 'immutable'
import * as userAPI from 'lib/api/user'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
const initialState = Map({
  sendHashDebounceLoading: false
})
const handler = {
  SEND_HASH: {
    options: {
      debounce: {
        wait: 1500,
        leading: true,
        trailing: false
      }
    },
    payloadCreator: userAPI.sendResetPassword,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const { message } = action.meta
        const stateData = { message }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  EXISTENCE_EMAIL: {
    options: {
      debounce: {
        wait: 1500,
        leading: false,
        trailing: true,
        async: true
      },
      reducerDebounce: {
        wait: 500,
        leading: true,
        trailing: true,
        async: false
      }
    },
    payloadCreator: userAPI.existenceEmail,
    reducer: { onFailure, onPending, onSuccess }
  },
  RESET_PASSWORD: {
    options: {
      debounce: {
        wait: 1500,
        leading: true,
        trailing: false
      }
    },
    payloadCreator: userAPI.resetPassword,
    reducer: { onFailure, onPending, onSuccess }
  }
}
const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState,
  { moduleName: 'user' }
)
export const actions = reduxActions
export default reduxReducers
