/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'

import LoginPaper from 'components/molecules/LoginPaper'
import CommonFormPaper from 'components/molecules/CommonFormPaper'

const styles = theme => ({
  loginForm: theme.mixins.gutters({
    maxWidth: 320,
    borderRadius: 5,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 10px 30px 0 rgba(0, 0, 0, .2)'
  })
})

export const useProps = {}
const LoginForm = ({ classes, proxy, ...props }) => {
  const { formData = {}, formError = {} } = props
  const {
    message,
    isOnlyEnglish = true,
    username,
    password,
    captchaLoading = false
  } = formData
  return (
    <div className={classes.root}>
      <CommonFormPaper>
        <LoginPaper
          isOnlyEnglish={isOnlyEnglish}
          message={message}
          username={username}
          password={password}
          captchaLoading={captchaLoading}
          formError={formError}
          onKeyUp={props.onKeyUp}
          onSubmitLogin={props.onSubmitLogin}
          onChangeFormData={props.onChangeFormData}
          onChangePassword={props.onChangePassword}
        />
      </CommonFormPaper>
    </div>
  )
}

LoginForm.defaultProps = setDefaultProps(useProps)
LoginForm.propTypes = setPropTypes(useProps)
export default withStyles(styles)(LoginForm)
