import { Map } from 'immutable'
import * as deferredPaymentAPI from 'lib/api/deferredPayment'
import { createRedux } from 'lib/requestHandler'
const initialState = Map({})
const handler = {}

handler.get = {
  options: { response: { error: { ignore: [404] } } },
  payloadCreator: deferredPaymentAPI.get
}

handler.getStatement = {
  options: {
    response: {
      error: {
        ignore: true // (array or boolean) default: false
      }
    }
  },
  payloadCreator: deferredPaymentAPI.getStatement
}

handler.getContract = {
  options: { response: { error: { ignore: [404] } } },
  payloadCreator: deferredPaymentAPI.getContract
}

handler.getRecentUsage = {
  options: { response: { error: { ignore: [404] } } },
  payloadCreator: deferredPaymentAPI.getRecentUsage
}

handler.updateContract = {
  options: { debounce: { wait: 500 } },
  payloadCreator: deferredPaymentAPI.updateContract
}

handler.requestConfirmation = {
  options: { debounce: { wait: 500 } },
  payloadCreator: deferredPaymentAPI.requestConfirmation
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
