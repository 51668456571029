import { Map } from 'immutable'
import { requestHandler } from 'lib'
import * as kakaoAPI from 'lib/api/kakao'
const { createRedux } = requestHandler
const initialState = Map({
  treeData: [],
  contactsGroupChecked: {}
})

const handler = {}

handler.getChannels = {
  payloadCreator: kakaoAPI.getChannels
}

handler.getTemplates = {
  payloadCreator: kakaoAPI.getTemplates
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)

export const actions = reduxActions
export default reduxReducers
