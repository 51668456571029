import axios from 'axios'
import qs from 'querystring'

export const get = (payload, options = {}) => {
  const { cache = false } = options
  const uri = `/deferred-payment/v1/account-numbers`
  return axios.get(uri, { cache })
}

export const getStatement = params => {
  const queryString = qs.stringify(params)
  const uri = `/deferred-payment/v1/statements${
    queryString ? '?' + queryString : ''
  }`
  return axios.get(uri)
}

export const getContract = () => {
  const uri = `/deferred-payment/v1/contract`
  return axios.get(uri)
}

export const getRecentUsage = () => {
  const uri = `/deferred-payment/v1/contract/recent-usage`
  return axios.get(uri)
}

export const updateContract = payload => {
  const uri = `/deferred-payment/v1/contract`
  return axios.put(uri, payload)
}

export const requestConfirmation = () => {
  const uri = `/deferred-payment/v1/contract/request`
  return axios.post(uri, {})
}
