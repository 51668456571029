/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { signUp as SignUpAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({})

const handler = {
  SIGN_UP: {
    payloadCreator: SignUpAPI.signUp,
    reducer: {
      onFailure: (state, action) => {
        try {
          const stateData = {
            InvalidReCAPTCHA:
              action.payload.response.data.errorCode === 'InvalidReCAPTCHA'
          }
          return onFailure(state, action, { stateData })
        } catch (error) {
          return onFailure(state, action)
        }
      },
      onPending,
      onSuccess
    }
  },
  EXISTENCE_EMAIL: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: SignUpAPI.existenceEmail,
    reducer: {
      onFailure,
      onPending,
      onSuccess
    }
  }
}

handler.getTerms = {
  payloadCreator: SignUpAPI.getTerms
}

handler.getPrivacyStatement = {
  payloadCreator: SignUpAPI.getPrivacyStatement
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState,
  { moduleName: 'signUp' }
)
export const actions = reduxActions
export default reduxReducers
