import pickBy from 'lodash/pickBy'
const getCustomActionsByProps = props => {
  const common = [
    'PopupActions',
    'SnackbarActions',
    'FormActions',
    'ConfirmActions',
    'SearchActions',
    'TableActions',
    'UploadActions',
    'HelpActions'
    // 서버에 요청을 보내는 action 이므로 공용이어도 제외합니다.
    // 'SupportActions'
  ]
  const actions = pickBy(props, (action, key) => {
    if (common.indexOf(key) !== -1) return false
    return /^(.+)Actions?$/.test(key)
  })
  return Object.keys(actions)
}
export default getCustomActionsByProps
