import { Map, List } from 'immutable'
import { profitHistoryDetail as api } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

export const initialState = Map({
  dataList: List([]),
  rowData: List([]),
  rowsPerPage: 20,
  page: 0,
  count: 0,
  selectedApp: 'ALL',
  load: false
})

const handler = {
  SET_STATE: {
    reducer: (state, action) => {
      Object.keys(action.payload).forEach(
        key => (state = state.set(key, action.payload[key]))
      )
      return state
    }
  },
  GET_ROW_DATA: {
    reducer: (state, action) => {
      const dataList = state.get('dataList')
      const page = state.get('page')
      const rowsPerPage = state.get('rowsPerPage')
      return state.set(
        'rowData',
        dataList
          .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
          .map(data => {
            const { date, appName, accountId, sumProfit } = data
            return [
              `${accountId} / ${appName}`,
              date,
              `${sumProfit.toLocaleString()}원`
            ]
          })
      )
    }
  },
  FETCH_DATA: {
    payloadCreator: api.fetchData,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const dataList = state.get('dataList').concat(action.payload.dataList)
        const rowsPerPage = state.get('rowsPerPage')
        const count =
          dataList.size +
          (dataList.size % rowsPerPage === 0 && dataList.size ? 1 : 0)
        if (action.payload.dataList.length !== rowsPerPage) { state = state.set('load', false) }
        const stateData = {
          dataList: dataList,
          count: count
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
