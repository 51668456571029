import React from 'react'
const name = 'auth-webpages'

export default [
  {
    title: '주소록',
    path: '/contacts',
    exact: true,
    component: React.lazy(() => import('containers/ContactsContainer')),
    local: name,
    drawer: false
  },
  {
    title: '허용 IP주소',
    path: '/allowed-ipaddr',
    exact: true,
    component: React.lazy(() => import('components/pages/AllowedIPAddrPage')),
    local: name,
    drawer: true
  },
  {
    title: '캐시 충전',
    path: '/recharge',
    exact: true,
    component: React.lazy(() => import('containers/RechargeContainer')),
    local: name,
    drawer: true
  },
  {
    title: '잔액 소진 알림',
    path: '/lba',
    exact: true,
    component: React.lazy(() => import('components/pages/LowBalanceAlertPage')),
    local: name,
    drawer: true
  },
  {
    title: '잔액 자동 충전',
    path: '/recharge/auto',
    exact: true,
    component: React.lazy(() => import('components/pages/AutoRechargePage')),
    local: name,
    drawer: true
  }
]
