import jwt from 'jsonwebtoken'
import cookies from 'js-cookie'

export const get = () => {
  const CSAK = cookies.get('CSAK')
  return jwt.decode(CSAK)
}

export default {
  get
}
