import { Map } from 'immutable'
import * as authAPI from 'lib/api/auth'
import { requestHandler } from 'lib'
const { createRedux } = requestHandler
const initialState = Map({})
const handler = {}

handler.getHistory = {
  payloadCreator: authAPI.getHistory
}

handler.getSettings = {
  payloadCreator: authAPI.getSettings
}

handler.request = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: authAPI.request
}

handler.updateSettings = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: authAPI.updateSettings
}

handler.verifyHash = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: authAPI.verifyHash
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
