import isUndefined from 'lodash/isUndefined'
import isEmpty from 'lodash/isEmpty'
import isNull from 'lodash/isNull'
import omitBy from 'lodash/omitBy'
import uniq from 'lodash/uniq'
import sortBy from 'lodash/sortBy'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import sumBy from 'lodash/sumBy'
import flatten from 'lodash/flatten'
import mapValues from 'lodash/mapValues'

import uniqid from 'uniqid'

import senderIdFormat from 'lib/senderIdFormat'
import {
  isPhoneNumber,
  isEmptyString,
  isEmptyObject,
  isNumber,
  isArray,
  isObject
} from 'lib/detectType'
import config from 'config'

// 현재 입력중인 수신번호에서 번호 파싱
export const parseInputPhoneNumber = rawText => {
  const matched = isEmptyString(rawText)
    ? []
    : rawText.match(/([^\s]+)(.*)/) || []
  const [, phoneNumber, name] = matched
  const formatPhoneNumber = isPhoneNumber(
    String(phoneNumber).replace(/[^0-9\\+]/g, '')
  )
    ? String(phoneNumber).replace(/[^0-9\\+]/g, '')
    : phoneNumber
  return {
    phoneNumber: formatPhoneNumber,
    name: isEmptyString(name) ? name : name.trim()
  }
}

export const getExtraFieldFromText = (
  text = '',
  id = '',
  subText = '',
  options = {}
) => {
  if (options?.rcsFlag) {
    return uniq(
      [...String(text).matchAll('{{([a-zA-Z0-9가-힣_]+)}}', ['g', 'm'])].map(
        data => {
          return data[1]
        }
      )
    ).map(name => {
      return { key: id, extraFieldName: name, subText, options }
    })
  }
  const regExp =
    options?.checkInvalid === true
      ? /(([$|#])([\\[|{])(.+?)([}\]]))|(\{.+?\})/g
      : /#{(.+?)}/g
  return (String(text).match(regExp) || []).map((name, index) => {
    const result = {
      key: id,
      extraFieldName: name,
      subText,
      options
    }
    if (options?.checkInvalid === true) {
      const invalid = /#{.+?}/.test(name) !== true
      Object.assign(result, { invalid })
    }
    return result
  })
}

// 서버쪽 치환 순서
// text -> variables -> additionalBody -> emphasizeTitle -> button -> quickReplies -> header -> highlight.title -> item.list -> item.summary.description
export const calcExtraFields = (formData = {}, options = {}) => {
  const { checkInvalid = false } = options
  const {
    tabIndex = 0,
    text = '',
    templateInfo = {},
    templateContent = '',
    naverTemplateInfo = {},
    naverTemplateContent = '',
    selectedRcsTemplateInfo = {},
    ctaBtns = [],
    rcsBtns = [],
    rcsAdditionalFlag,
    additionalBody = []
  } = formData
  if (tabIndex === 5 && isObject(selectedRcsTemplateInfo?.originalData)) {
    return getRcsTplExtraFields(selectedRcsTemplateInfo, options)
  }
  const extraFields = []
  const {
    emphasizeTitle,
    buttons: ataBtns = [],
    quickReplies = [],
    header,
    highlight = {},
    item
  } = templateInfo
  const { buttons: nsaBtns = [] } = naverTemplateInfo
  // const keyName = ['문자', '알림톡', '친구톡', '네이버톡', '해외', 'RCS']
  extraFields.push(
    ...getExtraFieldFromText(
      tabIndex === 1
        ? templateContent
        : tabIndex === 3
        ? naverTemplateContent
        : tabIndex === 5 && selectedRcsTemplateInfo?.templateId
        ? ''
        : text,
      '본문',
      '',
      { rcsFlag: tabIndex === 5, checkInvalid }
    )
  )
  // rcs mms
  if (tabIndex === 5) {
    if (rcsAdditionalFlag && additionalBody.length > 0) {
      extraFields.push(
        ...getExtraFieldFromText(
          JSON.stringify(
            additionalBody.map(bodyData => {
              const newButtons = isArray(bodyData?.buttons)
                ? bodyData.buttons.map(btnData => {
                    return omit(btnData, ['buttonName'])
                  })
                : []
              return { ...bodyData, buttons: newButtons }
            })
          ),
          'RCS 슬라이드',
          '',
          { rcsFlag: true, checkInvalid }
        )
      )
    }
    if (rcsBtns.length > 0) {
      extraFields.push(
        ...getExtraFieldFromText(
          JSON.stringify(
            rcsBtns.map(btnData => {
              return omit(btnData, ['buttonName'])
            })
          ),
          'RCS 버튼',
          '',
          { rcsFlag: true, checkInvalid }
        )
      )
    }
  }
  // 알림톡 강조 표기 제목
  if (tabIndex === 1) {
    extraFields.push(
      ...getExtraFieldFromText(emphasizeTitle || '', '강조표기 제목', '', {
        checkInvalid
      })
    )
  }
  const buttons = []
    .concat(tabIndex === 2 ? ctaBtns : [])
    .concat(tabIndex === 1 ? ataBtns : [])
    .concat(tabIndex === 3 ? nsaBtns : [])
  buttons.forEach(button => {
    extraFields.push(
      ...getExtraFieldFromText(
        button?.linkMo,
        button?.buttonName,
        '모바일 웹링크 버튼',
        { checkInvalid }
      )
    )
    extraFields.push(
      ...getExtraFieldFromText(
        button?.linkPc,
        button?.buttonName,
        'PC 웹링크 버튼',
        { checkInvalid }
      )
    )
    extraFields.push(
      ...getExtraFieldFromText(
        button?.linkAnd,
        button?.buttonName,
        '안드로이드 링크 버튼',
        { checkInvalid }
      )
    )
    extraFields.push(
      ...getExtraFieldFromText(
        button?.linkIos,
        button?.buttonName,
        '아이폰 링크 버튼',
        { checkInvalid }
      )
    )
  })
  if (tabIndex === 1) {
    quickReplies.forEach(button => {
      extraFields.push(
        ...getExtraFieldFromText(
          button?.linkMo,
          button?.buttonName,
          '모바일 웹링크 바로연결버튼',
          { checkInvalid }
        )
      )
      extraFields.push(
        ...getExtraFieldFromText(
          button?.linkPc,
          button?.buttonName,
          'PC 웹링크 바로연결버튼',
          { checkInvalid }
        )
      )
      extraFields.push(
        ...getExtraFieldFromText(
          button?.linkAnd,
          button?.buttonName,
          '안드로이드 링크 바로연결버튼',
          { checkInvalid }
        )
      )
      extraFields.push(
        ...getExtraFieldFromText(
          button?.linkIos,
          button?.buttonName,
          '아이폰 링크 바로연결버튼',
          { checkInvalid }
        )
      )
    })
    if (!isEmptyString(header)) {
      extraFields.push(
        ...getExtraFieldFromText(header, '템플릿 헤더', '', {
          checkInvalid
        })
      )
    }
    if (!isEmptyString(highlight?.title)) {
      extraFields.push(
        ...getExtraFieldFromText(highlight?.title, '하이라이트 제목', '', {
          checkInvalid
        })
      )
    }
    if (isArray(item?.list) && item?.list) {
      item.list.forEach((data, index) => {
        if (!isEmptyString(data?.description)) {
          extraFields.push(
            ...getExtraFieldFromText(
              data?.description,
              data?.title,
              `${index + 1}번째 아이템 설명`,
              { checkInvalid }
            )
          )
        }
      })
    }
    if (!isEmptyString(item?.summary?.description)) {
      extraFields.push(
        ...getExtraFieldFromText(item?.summary?.description, '합계 값', '', {
          checkInvalid
        })
      )
    }
  }
  return extraFields
}

// 입력한 치환문구를 사용가능한 params로
// folders: [
//   {
//     folderId: ''
//     replacement: [ 3, 1, 2 ]
//     variables: { '변수명': '값1' }
//   }
// ],
// people: [
//   {
//     phoneNumber: '01000000001',
//     personName: '홍길동',
//     replacement: [ '메모1', '메모2', '메모 3' ]
//     variables: { '변수명': '값1' }
//   }
// ],
const getSortedReplacement = (
  variables,
  variableValues,
  folderId,
  folderUniqId
) => {
  return sortBy(
    flatten(
      Object.values(variables).map((varData, index) => {
        const varInfo = varData.map((data, index) => {
          const varName = data?.extraFieldName
          const priority = data?.varIndex
          const rawValue = variableValues?.[folderUniqId]?.[varName]?.[index]
          const isDefaultExtraField = rawValue?.isDefaultExtraField === true
          const isExtraField = !isEmptyString(folderId) && isNumber(rawValue)
          const value =
            isExtraField || !isDefaultExtraField ? rawValue : rawValue?.value
          const returnValue = isEmptyString(value) ? { value: '' } : value
          return {
            varName,
            priority,
            value: returnValue
          }
        })
        return varInfo
      })
    ),
    ['priority']
  )
}
const createRecipientParams = ({
  variables = {},
  variableValues = [],
  recipients = [],
  recipientGroups = []
}) => {
  const folders = []
  const replacement = []
  const groups = recipientGroups.map(data => {
    const isIndividual = data?.isIndividual === true
    const folderId = data?.folderId
    const folderUniqId = data?.id
    // 사용자 입력한 변수 값 정렬
    const sortedReplacement = getSortedReplacement(
      variables,
      variableValues,
      folderId,
      folderUniqId
    )
    const replacementValue = sortedReplacement.map(data => data?.value)
    if (!isIndividual && !isEmptyString(data?.folderId)) {
      folders.push({
        folderId: data?.folderId,
        replacement: replacementValue
      })
    }
    if (isIndividual && isEmptyString(data?.folderId)) {
      replacement.push(...replacementValue)
    }
    return {
      isIndividual,
      folderId: data?.folderId,
      replacement: replacementValue
    }
  })
  const people = recipients.map(recipient => {
    const isFolder = !isEmptyString(recipient?.folderId)
    const groupInfo = groups.find(
      groupData => groupData?.folderId === recipient?.folderId
    )
    const isIndividual = groupInfo?.isIndividual === true
    let folderReplacement = []
    if (isIndividual && isFolder && groupInfo?.replacement?.length > 0) {
      folderReplacement = groupInfo.replacement.map(value => {
        return isNumber(value)
          ? { value: recipient?.extraValues?.[value] }
          : value
      })
    }
    return {
      phoneNumber: isEmptyString(recipient?.phoneNumber)
        ? 'NULL'
        : recipient?.phoneNumber,
      personName: recipient?.personName,
      replacement: isIndividual && !isFolder ? replacement : folderReplacement
    }
  })
  return { folders, people }
}
const createRcsTplVarsParams = ({
  variables,
  variableValues,
  recipients,
  recipientGroups
}) => {
  const folders = []
  const formatedRecipientGroups = {}
  recipientGroups.forEach(data => {
    const isIndividual = data?.isIndividual === true
    const folderId = data?.folderId
    const folderUniqId = data?.id
    // 사용자 입력한 변수 값 정렬
    const formatedVariables = mapValues(variables, (v, key) => {
      const rawValue = variableValues?.[folderUniqId]?.[key]?.[0]
      const isExtraField = !isEmptyString(folderId) && isNumber(rawValue)
      const value = isExtraField ? rawValue : rawValue?.value
      return value
    })
    if (!isIndividual && !isEmptyString(folderId)) {
      folders.push({
        folderId,
        variables: formatedVariables
      })
    }
    formatedRecipientGroups[folderId || '__INDIVIDUAL_GROUP__'] = {
      isIndividual,
      variables: formatedVariables
    }
  })
  const people = recipients.map(recipient => {
    const groupInfo =
      formatedRecipientGroups?.[recipient?.folderId || '__INDIVIDUAL_GROUP__']
    const formatedVariables = mapValues(groupInfo?.variables, v => {
      return isNumber(v) ? recipient?.extraValues?.[v] : v
    })
    const phoneNumber = isEmptyString(recipient?.phoneNumber)
      ? recipient?.phoneNumber
      : recipient.phoneNumber.trim()
    return {
      phoneNumber: isEmptyString(phoneNumber) ? 'NULL' : phoneNumber,
      personName: recipient?.personName,
      variables: formatedVariables
    }
  })
  return { people, folders }
}

export const makePrepareParams = (formData = {}) => {
  const {
    tabIndex = 0,
    variables = [],
    variableValues = [],
    recipients = [],
    recipientGroups = [],
    subject,
    text,
    imageId,
    adChecked,
    businessName = '',
    allowDuplicates = false,
    enableEmbeddedFunctions = false,

    // kakao
    pfId,
    templateId,
    templateInfo = {},
    ctaBtns = [],

    // naver
    talkId,
    naverTemplateId,

    // rcs
    selectedBrandId,
    selectedRcsTemplateId,
    selectedRcsTemplateInfo = {},
    copyAllowed,
    rcsAdditionalFlag,
    additionalBody = [],
    additionalBodyType = 'M',
    rcsBtns = [],

    from,
    // kakao ad flag
    disableSms
  } = cloneDeep(formData)
  const isRcsTpl =
    tabIndex === 5 && isObject(selectedRcsTemplateInfo?.originalData)
  const replacement = isRcsTpl
    ? createRcsTplVarsParams({
        variables,
        variableValues,
        recipients,
        recipientGroups
      })
    : createRecipientParams({
        variables,
        variableValues,
        recipients,
        recipientGroups
      })
  if (selectedRcsTemplateId) {
    const buttons = selectedRcsTemplateInfo?.buttons || []
    rcsBtns.splice(0, rcsBtns.length)
    rcsBtns.push(...buttons)
  }
  const isCta = tabIndex === 2
  const isRcs = tabIndex === 5
  const isTemplate =
    tabIndex === 1 ||
    tabIndex === 3 ||
    (tabIndex === 5 && selectedRcsTemplateId)
  const kakaoOptions = omitBy(
    omitBy(
      {
        pfId,
        templateId: tabIndex !== 1 ? undefined : templateId,
        imageId: !isCta ? undefined : imageId,
        buttons: !isCta ? undefined : ctaBtns,
        adFlag: !isCta ? undefined : adChecked,
        disableSms
      },
      isUndefined
    ),
    isNull
  )
  const naverOptions = omitBy(
    omitBy(
      {
        talkId,
        templateId: naverTemplateId,
        disableSms
      },
      isUndefined
    ),
    isNull
  )
  const rcsOptions = omitBy(
    omitBy(
      {
        brandId: selectedBrandId,
        templateId: selectedRcsTemplateId,
        buttons: isEmptyString(selectedRcsTemplateId) ? rcsBtns : undefined,
        copyAllowed,
        commercialType: adChecked,
        mmsType:
          rcsAdditionalFlag === true && isEmptyString(selectedRcsTemplateId)
            ? additionalBodyType + (additionalBody?.length + 1)
            : undefined,
        additionalBody:
          rcsAdditionalFlag === true &&
          isArray(additionalBody) &&
          isEmptyString(selectedRcsTemplateId)
            ? sortBy(additionalBody, ['priority']).map(data => {
                const picks = ['title', 'description', 'buttons', 'imageId']
                return omitBy(
                  pick(Object.assign(data, { imageId: data?.fileId }), picks),
                  isUndefined
                )
              })
            : undefined,
        disableSms
      },
      isUndefined
    ),
    isNull
  )
  const prepareText =
    tabIndex === 1
      ? templateInfo.content
      : isCta || isRcs
      ? text
      : `${adChecked ? '(광고)' + businessName + `\n` : ''}${text || ''}${
          adChecked
            ? `\n무료수신거부 ${senderIdFormat(config.blocksender)}`
            : ''
        }`
  return {
    formData: {
      ...formData,
      adChecked,
      replacement
    },
    params: omitBy(
      {
        subject:
          (tabIndex === 0 || isRcs) && !isEmptyString(subject)
            ? subject
            : undefined,
        text: isTemplate ? undefined : prepareText,
        from: !isEmptyString(from) ? from : undefined,
        allowDuplicates,
        imageId: tabIndex === 0 || (isRcs && !isTemplate) ? imageId : undefined,
        kakaoOptions: tabIndex === 1 || isCta ? kakaoOptions : undefined,
        naverOptions: tabIndex === 3 ? naverOptions : undefined,
        rcsOptions: isRcs ? rcsOptions : undefined,
        enableEmbeddedFunctions,
        ...omitBy(replacement, isEmpty)
      },
      isUndefined
    )
  }
}

// 기존 그룹에 새로운 그룹목록 추가
export const addRecipientGroups = (originRecipientGroups, recipientGroups) => {
  let copyOriginGroups = cloneDeep(originRecipientGroups)
  recipientGroups.forEach(newRecipientGroup => {
    copyOriginGroups = addRecipientGroup(copyOriginGroups, newRecipientGroup)
  })
  return copyOriginGroups
}

// 기존 그룹에 새로운 그룹 추가
export const addRecipientGroup = (originRecipientGroups, newRecipientGroup) => {
  let copyOriginGroups = cloneDeep(originRecipientGroups)
  const folderId = newRecipientGroup?.folderId
  const isIndividual = newRecipientGroup?.isIndividual === true
  // 기존 그룹 인덱스 찾기 (개별추가인 경우 포함)
  const originGroupIndex = findIndex(originRecipientGroups, groupData => {
    return (
      groupData?.folderId === folderId ||
      // 개별추가
      (isIndividual === true &&
        groupData?.isIndividual === true &&
        isEmptyString(groupData?.folderId) &&
        isEmptyString(folderId))
    )
  })
  // 새로운 그룹을 추가하는 경우
  if (originGroupIndex === -1) {
    return copyOriginGroups.concat(newRecipientGroup)
  }
  // 기존 그룹이 존재하는 경우
  const originGroup = copyOriginGroups[originGroupIndex]
  // 기존 그룹과 새로운 그룹이 모두 개별추가된 경우
  const isIndividualAction =
    isIndividual === true && originGroup?.isIndividual === true
  copyOriginGroups[originGroupIndex] = {
    ...originGroup,
    ...newRecipientGroup,
    // 개별추가 + 기존 그룹 존재 시 아이디는 기존 그룹의 아이디 계승
    id: isIndividualAction ? originGroup?.id : newRecipientGroup?.id,
    isIndividual,
    count: isIndividualAction
      ? originGroup?.count + newRecipientGroup?.count
      : newRecipientGroup?.count
  }
  return copyOriginGroups
}

export const addRecipients = (originRecipients, recipients, folderId) => {
  if (!isArray(originRecipients)) {
    originRecipients = []
  }
  let newRecipients = cloneDeep(recipients)
  if (!isArray(newRecipients) || newRecipients.length === 0) {
    return originRecipients
  }
  return originRecipients.concat(
    newRecipients
      .filter(data => {
        return isObject(data) && !isEmptyObject(data)
      })
      .map(recipient => {
        return omitBy(
          {
            id: uniqid(),
            personId: recipient?.personId,
            personName: isEmptyString(recipient?.personName)
              ? ''
              : recipient?.personName, // required
            phoneNumber: isEmptyString(recipient?.phoneNumber)
              ? ''
              : recipient?.phoneNumber, // required
            extraValues: isArray(recipient?.extraValues)
              ? recipient?.extraValues
              : undefined, // optional
            folderId: isEmptyString(recipient?.folderId)
              ? folderId
              : recipient?.folderId // optional
          },
          isUndefined
        )
      })
  )
}

export const getNewRecipientGroup = (data = {}) => {
  const folderInfo = omitBy(
    pick(data, [
      'isIndividual',
      'isDisposable',
      'folderId',
      'folderName',
      'count',
      'peopleCount',
      'extraFields'
    ]),
    isUndefined
  )
  const { isIndividual, count, peopleCount } = folderInfo
  folderInfo.count =
    isNumber(count) && count > 0
      ? count
      : isIndividual !== true && isNumber(peopleCount)
      ? peopleCount
      : 0
  return {
    id: uniqid(),
    isIndividual: true,
    folderName: '개별 추가', // required
    count: 0, // required
    // folderId: '', // optional
    extraFields: [], // optional
    ...folderInfo
  }
}

// 배열 목록에서 전화번호 열을 자동으로 감지합니다.
export const autoDetectPhoneNumberColumn = rawData => {
  // 3개 이상인 경우 배열 유사도 60% 이상
  const minPoint = rawData.length > 3 ? 60 : 50
  const size = rawData.length
  const columnScores = []
  let maxCountOfColumns = 0
  // 반드시 2차원 배열이어야 함.
  rawData.forEach(columns => {
    maxCountOfColumns =
      columns.length > maxCountOfColumns ? columns.length : maxCountOfColumns
    columns.forEach((colData, columnIndex) => {
      const addScore = isPhoneNumber(String(colData).replace(/[^0-9\\+]+/g, ''))
        ? 1
        : 0
      columnScores[columnIndex] = isNumber(columnScores[columnIndex])
        ? columnScores[columnIndex] + addScore
        : addScore
    })
  })
  // column이 1개인 경우, 전화번호 1개라도 찾으면 수신번호에 사용
  if (maxCountOfColumns === 1) {
    return columnScores?.[0] > 0 ? [true] : [false]
  }
  return columnScores.map(score => {
    return (score / size) * 100 >= minPoint
  })
}

// 수신번호 목록의 folderIds
export const getFolderIdsByRecipients = recipients => {
  return uniq(recipients.map(data => data.folderId))
}

// folderId로 수신번호 필터링
export const getRecipientsByFolderId = (recipients, folderId) => {
  return recipients.filter(data => data?.folderId === folderId)
}
export const getRecipientsById = (recipients, id) => {
  return recipients.filter(data => data?.id === id)
}
export const getRecipientGroupByFolderId = (recipientGroups, folderId) => {
  return recipientGroups.find(data => data?.folderId === folderId) || {}
}
export const getFolderInfo = (folderList, folderId) => {
  const folderInfo = folderList.find(data => data?.folderId === folderId) || {}
  return folderInfo
}

// folderId로 수신자 목록 제외
export const removeRecipientsByFolderId = (recipients, folderId) => {
  if (isArray(folderId)) {
    return recipients.filter(data => folderId.indexOf(data?.folderId) === -1)
  }
  return recipients.filter(data => data?.folderId !== folderId)
}

// folderId로 수신자 그룹 제거
export const removeFolderByFolderId = (recipientGroups, folderId) => {
  return recipientGroups.filter(data => data?.folderId !== folderId)
}

export const getTotalRecipientsCount = recipientGroups => {
  const totalRecipientsCount = sumBy(recipientGroups, data => data?.count || 0)
  return totalRecipientsCount
}

// 존재하지 않는 수신자 명단 정리
export const filterByFolderIdRecipients = (newRecipientGroups, recipients) => {
  const existsFolderIds = newRecipientGroups.map(data => data?.folderId)
  return recipients.filter(data => {
    return existsFolderIds.indexOf(data?.folderId) !== -1
  })
}

// 수신자 업데이트
export const updateRecipient = (recipients, id, data) => {
  return recipients.map(recipient => {
    if (recipient?.id === id) return data
    return recipient
  })
}

// 그룹 업데이트
export const updateRecipientGroup = (recipientGroups, id, data) => {
  return recipientGroups.map(group => {
    if (group?.id === id) return data
    return group
  })
}

// 그룹 일부 수정
export const editRecipientGroup = (recipientGroups, id, data) => {
  return recipientGroups.map(group => {
    if (group?.id === id) {
      return { ...group, ...data }
    }
    return group
  })
}

export const getRcsTplExtraFields = (tplInfo, options) => {
  const { checkInvalid = false } = options
  const extraFields = []
  const originVars = tplInfo?.originalData?.params || []
  const contentTextVars = flatten(
    originVars
      .map(data => {
        const contentText = data?.inputText
        if (!isEmptyString(data?.inputText)) {
          return getExtraFieldFromText(contentText, '템플릿 본문', '', {
            rcsFlag: true,
            checkInvalid
          })
        }
      })
      .filter(d => d)
  )
  extraFields.push(...contentTextVars)
  extraFields.push(
    ...originVars
      .filter(data => {
        return isEmptyString(data?.inputText)
      })
      .map(data => {
        return {
          key: 'RCS 템플릿',
          extraFieldName: data?.param,
          subText: '',
          options: {}
        }
      })
  )
  return extraFields
}

export default {}
