import { Map } from 'immutable'
import * as storageAPI from 'lib/api/storage'
import { requestHandler } from 'lib'
const { createRedux } = requestHandler
const initialState = Map({
  recipients: {}
})

const handler = {}

handler.upload = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: storageAPI.upload
}

handler.getFileInfo = {
  payloadCreator: storageAPI.getFileInfo
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)

export const actions = reduxActions
export default reduxReducers
