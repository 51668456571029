export const toggleCamel = (str, lower = true) => {
  return lower
    ? (/_|^[A-Z]+$/g.test(str) ? str.toLowerCase() : str).replace(
      /(_([a-zA-Z]))/g,
      match => {
        return match.replace('_', '').toUpperCase()
      }
    )
    : str
      .replace(/([a-z])([A-Z])/g, (match, a, b) => {
        return a + '_' + b
      })
      .toUpperCase()
}

export default toggleCamel
