/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'

// lib
import bindStateToProps from 'lib/bindStateToProps'
import bindActionCreators from 'lib/bindActionCreators'
import isEqualProps from 'lib/isEqualProps'
import mapActionLog from 'lib/mapActionLog'
import proxyOverride from 'lib/proxyOverride'
import mapAsyncActions from 'lib/mapAsyncActions'
import { isArray, isFunction } from 'lib/detectType'

// redux modules (action)
// import { actions as rbcActions } from 'store/modules/rbc'

// common modules (action)
// import { actions as formActions } from 'store/modules/form'
// import { actions as tableActions } from 'store/modules/table'
// import { actions as searchActions } from 'store/modules/search'
// import { actions as uploadActions } from 'store/modules/upload'
// import { actions as popupActions } from 'store/modules/popup'
import { actions as snackbarActions } from 'store/modules/snackbar'
// import { actions as confirmActions } from 'store/modules/confirm'
// import { actions as helpActions } from 'store/modules/help'
// import { actions as supportActions } from 'store/modules/support'

// react components & containers
import SnackbarQueue from 'components/organisms/SnackbarQueue'

/**
 * 메시지 큐
 */
class SnackbarQueueContainer extends Component {
  constructor(props) {
    super(props)
    // 해당 컨테이너 내의 메서드에 자동으로 this 클래스를 바인딩 합니다.
    autoBind(this)
    // 해당 컨테이너 내의 메서드 실행 시 액션로그를 남깁니다.
    mapActionLog(this)
    // HOC 사용 시 현재 컨테이너에 proxy가 props에 존재하는 경우 proxy 메서드에 this 클래스의 메서드를 대입
    proxyOverride(this)
    // 2차 인증을 사용하는 리덕스 액션이 존재하는 경우 덮어씌워 await이 정상적으로 작동하게 합니다.
    mapAsyncActions(this.props, {
      // exceptActions: { AppsActions: true }
    })

    // how to use ?
    // const { SnackbarActions } = this.props
    // SnackbarActions.enqueue({
    //   // messasge key
    //   hash: '', // optional
    //   message: 'popup message!', // required
    //   autoHide: false, // optional
    //   hideClickAway: false, // optional (default: true, error = false)
    //   progressColor: '#fff',
    //   duration: 5500, // optional (default: 5500, error = 12000)
    //   severity: 'error', // optional (default: null, error / info / warning / success)
    //   buttons: [{ name: 'buttonName', onClick: (event, messageData) => {} }], // optional
    //   showCloseButton: false // optional (default: true)
    // })
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const { messageQueue: nextMessageQueue = [] } = nextProps
    const { messageQueue = [] } = this.props
    return !isEqualProps(nextMessageQueue, messageQueue)
  }

  // hoc 사용 시 해당 부분 삭제하세요.
  componentDidMount = () => {}

  onCompleteTimer = hash => {
    const { SnackbarActions } = this.props
    SnackbarActions.dequeue({ hash })
  }

  onClickCloseButton = hash => {
    const { SnackbarActions } = this.props
    SnackbarActions.dequeue({ hash })
  }

  onClickAway = () => {
    // const { SnackbarActions, messageQueue = [] } = this.props
    // if (messageQueue.length === 0) return
    // const newMessageQueue = messageQueue.filter((messageData, index) => {
    //   return (
    //     messageData?.autoHide === false ||
    //     messageData?.hideClickAway === false ||
    //     index === 0
    //   )
    // })
    // SnackbarActions.updateMessageQueue(newMessageQueue)
  }

  onMouseEnter = () => {
    const { SnackbarActions, messageQueue = [] } = this.props
    if (messageQueue.length === 0) return
    const newMessageQueue = messageQueue.map(messageData => {
      return { ...messageData, hidePause: true }
    })
    SnackbarActions.updateMessageQueue(newMessageQueue)
  }

  onMouseLeave = () => {
    const { SnackbarActions, messageQueue = [] } = this.props
    if (messageQueue.length === 0) return
    const newMessageQueue = messageQueue.map(messageData => {
      return { ...messageData, hidePause: false }
    })
    SnackbarActions.updateMessageQueue(newMessageQueue)
  }

  onClickButton = (e, index, data) => {
    if (isArray(data?.buttons) && isFunction(data?.buttons[index]?.onClick)) {
      data.buttons[index].onClick(e, data)
    }
  }

  render = () => {
    return <SnackbarQueue {...this.props} proxy={this} />
  }
}

export default withRouter(
  connect(
    bindStateToProps({
      snackbar: ['messageQueue']
    }),
    bindActionCreators({
      snackbarActions
      // formActions
    })
  )(SnackbarQueueContainer)
)
