import _ from 'lodash'
import { isObject, isFunction } from 'lib/detectType'
import toggleCamel from 'lib/toggleCamel'

const customBindActionCreator = (actionCreator, dispatch, moduleName) => {
  // container에서 actions을 사용하면 이곳에서 처리됩니다.
  return function () {
    // "Array 형태"를 실제 array object로 변경
    const args = [...arguments]
    const payload = args.length > 1 ? args.slice(0, args.length - 1) : args
    // 가장 마지막 인자는 무조건 옵션
    const options =
      args.length > 1 && isObject(args[args.length - 1])
        ? args[args.length - 1]
        : {}
    Object.assign(options, { executeBy: this?.executeBy })
    // requestHandler.js로 넘겨줍니다.
    return dispatch(
      actionCreator.apply(this, [
        ...payload,
        { ...options, actions: this, moduleName }
      ])
    )
  }
}

const firstUpper = str => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

const getModuleName = name => {
  return name.replace(/(.*)Actions$/, '$1')
}

const customActionCreators = (
  actionCreatorsObject,
  dispatch,
  componentName
) => {
  const boundActionCreators = {}
  _.map(actionCreatorsObject, (actionCreators, actionName) => {
    const mapActionCreators = {}
    const moduleName = getModuleName(actionName)
    _.map(actionCreators, (actionCreator, key) => {
      if (isFunction(actionCreator)) {
        mapActionCreators[key] = customBindActionCreator(
          actionCreator,
          dispatch,
          moduleName,
          componentName
        )
      }
    })
    const camelActionName = toggleCamel(actionName)
    const formatActionName = firstUpper(camelActionName)
    Object.assign(boundActionCreators, {
      [formatActionName]: mapActionCreators
    })
  })
  return boundActionCreators
}

export const bindActionCreators = (actionCreatorsObject, dispatch) => {
  if (dispatch) {
    return customActionCreators(actionCreatorsObject, dispatch)
  }
  return dispatch => {
    return customActionCreators(actionCreatorsObject, dispatch)
  }
}

export default bindActionCreators
