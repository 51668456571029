import validate from 'validate.js'
import schema, { liveSchema } from './validateSchema'
import _ from 'lodash'

validate.validators.validateIntro = function (value, options, key, attributes) {
  if (!value) return
  if (/^[가-힣a-zA-Z0-9\s]+$/.test(value)) return
  return '앱 소개는 한글, 영어, 숫자만 가능합니다.'
}

export const validateFormData = ({ target, value }) => {
  if (!value || value === '') return
  const validateData = { [target]: _.isArray(value) ? value[value.length - 1] : value }
  const result = validate(validateData, schema, { fullMessages: false })
  if (!result || !result[target]) return
  return result[target][0]
}

export const validateForm = (form) => {
  const result = validate(form, schema, { fullMessages: false })
  if (!result) return

  // eslint-disable-next-line
  let messages = []
  _.forOwn(result, (val, key) => {
    messages.push(`${_.values(val)}`)
  })
  return messages.join('\n')
}

export const validateBeforeLive = (form) => {
  const result = validate(form, liveSchema, { fullMessages: false })
  if (!result) return

  // eslint-disable-next-line
  let messages = []
  _.forOwn(result, (val, key) => {
    messages.push(val[0])
  })
  return messages.join('\n')
}
