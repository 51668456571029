import { isString } from 'lib/detectType'

export const updateFiles = params => {
  const { fileItems = [], readType = 'dataURL' } = params
  return Promise.all(
    [...fileItems]
      .filter(item => item.file.name)
      .map(fileItem => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          try {
            if (readType === 'array') {
              reader.readAsArrayBuffer(fileItem?.file)
            } else {
              reader.readAsDataURL(fileItem?.file)
            }
          } catch (error) {
            reject(error)
          }
          reader.onload = e => {
            const result = reader.result
            const fileData =
              isString(result) && result.replace
                ? result.replace(/^.*base64,/, '')
                : result
            resolve({ fileItem, result, reader, fileData })
          }
          reader.onerror = error => {
            reject(error)
          }
        })
      })
  )
}

export const addFile = params => {
  const { fileItem, readType = 'dataURL' } = params
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    try {
      if (readType === 'array' && fileItem?.file) {
        reader.readAsArrayBuffer(fileItem?.file)
      } else if (fileItem?.file) {
        reader.readAsDataURL(fileItem?.file)
      }
    } catch (error) {
      reject(error)
    }
    reader.onload = e => {
      const result = reader.result
      const fileData =
        isString(result) && result.replace
          ? result.replace(/^.*base64,/, '')
          : result
      resolve({ fileItem, result, reader, fileData })
    }
    reader.onerror = error => {
      reject(error)
    }
  })
}
