import axios from 'axios'
import qs from 'qs'

export const putTalk = (params = {}) => {
  const uri = `/naver/v1/talks`
  return axios.post(uri, params)
}

export const getAgent = (params = {}) => {
  const { talkId } = params
  const uri = `/naver/v1/talks/${talkId}`
  return axios.get(uri)
}

export const getAgents = (params = {}) => {
  const query = qs.stringify(params)
  const uri = `/naver/v1/talks${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getTemplate = (params = {}, options = {}) => {
  const { cache = false } = options
  const { templateId } = params
  const uri = `/naver/v1/templates/${templateId}`
  return axios.get(uri, { cache })
}

export const deleteAgent = params => {
  const { talkId } = params
  const uri = `/naver/v1/talks/${talkId}`
  return axios.delete(uri)
}

export const getTemplates = (params = {}) => {
  const query = qs.stringify(params)
  const uri = `/naver/v1/templates${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const setTemplate = params => {
  const { templateId, name, content, buttons } = params
  const uri = `/naver/v1/templates/${templateId}`
  return axios.put(uri, { name, content, buttons })
}

export const putTemplate = params => {
  const { talkId, name, content, buttons, service } = params
  const uri = `/naver/v1/templates/${service}`
  return axios.post(uri, { talkId, name, content, buttons })
}

export const templateInspection = params => {
  const { templateId, service } = params
  const uri = `/naver/v1/templates/${templateId}/${service}/inspection`
  return axios.put(uri, {})
}

export const putTemplateComment = params => {
  const { templateId, comment, service } = params
  const uri = `/naver/v1/templates/${templateId}/${service}/comment`
  return axios.post(uri, { comment })
}

export const deleteTemplate = params => {
  const { templateId } = params
  const uri = `/naver/v1/templates/${templateId}`
  return axios.delete(uri)
}

export const hideTemplate = payload => {
  const { templateId, isHidden } = payload
  const uri = `/naver/v1/templates/${templateId}/hide`
  return axios.put(uri, { isHidden })
}
