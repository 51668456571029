/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'

// lib
import bindStateToProps from 'lib/bindStateToProps'
import bindActionCreators from 'lib/bindActionCreators'
import mapActionLog from 'lib/mapActionLog'
import proxyOverride from 'lib/proxyOverride'
import mapAsyncActions from 'lib/mapAsyncActions'
import { isFunction } from 'lib/detectType'

// redux modules (action)
// import { actions as rbcActions } from 'store/modules/rbc'

// common modules (action)
// import { actions as formActions } from 'store/modules/form'
// import { actions as tableActions } from 'store/modules/table'
// import { actions as searchActions } from 'store/modules/search'
// import { actions as uploadActions } from 'store/modules/upload'
// import { actions as popupActions } from 'store/modules/popup'
// import { actions as snackbarActions } from 'store/modules/snackbar'
// import { actions as confirmActions } from 'store/modules/confirm'
// import { actions as helpActions } from 'store/modules/help'
// import { actions as supportActions } from 'store/modules/support'

// react components & containers
import Progress from 'components/organisms/Progress'

/**
 * (작성된 설명이 없습니다.)
 */
class ProgressContainer extends Component {
  constructor(props) {
    super(props)
    // 해당 컨테이너 내의 메서드에 자동으로 this 클래스를 바인딩 합니다.
    autoBind(this)
    // 해당 컨테이너 내의 메서드 실행 시 액션로그를 남깁니다.
    mapActionLog(this)
    // HOC 사용 시 현재 컨테이너에 proxy가 props에 존재하는 경우 proxy 메서드에 this 클래스의 메서드를 대입
    proxyOverride(this)
    // 2차 인증을 사용하는 리덕스 액션이 존재하는 경우 덮어씌워 await이 정상적으로 작동하게 합니다.
    mapAsyncActions(this.props, {
      // exceptActions: { AppsActions: true }
    })
    this.state = {
      value: 0,
      duration: this.props.duration || 6000,
      interval: this.props.interval || 100
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      nextState?.value !== this.state.value ||
      nextProps?.pause !== this.props.pause ||
      nextProps.resetFlag !== this.props.resetFlag
    )
  }

  // hoc 사용 시 해당 부분 삭제하세요.
  componentDidMount = () => {
    const { interval } = this.state
    this.interval = setInterval(() => {
      const { value, duration } = this.state
      const step = (interval / duration) * 100
      const newValue = value + step
      const { pause } = this.props
      if (pause === true) {
        return this.setState({ value })
      }
      this.setState({ value: newValue >= 100 ? 100 : newValue })
    }, interval)
  }

  componentDidUpdate = nextProps => {
    if (nextProps?.resetFlag !== this.props.resetFlag) {
      this.resetValue()
    }
    if (this.state.value >= 100) {
      this.onComplete()
      clearInterval(this.interval)
    }
  }

  componentWillUnmount = () => {
    clearInterval(this.interval)
  }

  onComplete = () => {
    const { onComplete } = this.props
    if (isFunction(onComplete)) {
      onComplete()
    }
  }

  resetValue = () => {
    return this.setState({ value: 0 })
  }

  render = () => {
    // hoc 사용 시 proxy 삭제하세요.
    return (
      <Progress
        type={this.props.type}
        pause={this.props.pause}
        progressProps={this.props.progressProps}
        value={this.state.value}
      />
    )
  }
}

export default withRouter(
  connect(
    bindStateToProps({
      // form: [{ myData: 'data.formId' }]
    }),
    bindActionCreators({
      // formActions
    })
  )(ProgressContainer)
)
