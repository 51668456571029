import { Map } from 'immutable'
import moment from 'moment-timezone'
import crypto from 'crypto'
import cryptoRandomString from 'crypto-random-string'
import { createRedux } from 'lib/requestHandler'
const initialState = Map({
  apiKey: '',
  apiSecret: '',
  signature: '',
  header: '',
  isUseSignature: false
})

const handler = {
  ON_CHANGE_API_KEY: {
    reducer: (state, action) => {
      return state.set('apiKey', action.payload)
    }
  },
  ON_CHANGE_API_SECRET: {
    reducer: (state, action) => {
      return state.set('apiSecret', action.payload)
    }
  },
  CREATE_SIGNATURE: {
    reducer: (state, action) => {
      const now = moment(new Date()).format('YYYY-MM-DD HH:mm:ss')
      const salt = cryptoRandomString({ length: 64 })
      const apiKey = state.get('apiKey')
      const apiSecret = state.get('apiSecret')
      const signatureKey = crypto.createHmac('sha256', apiSecret)
      const signature = signatureKey.update(now + salt).digest('hex')
      return state
        .set('signature', signature)
        .set(
          'header',
          `Authorization: HMAC-SHA256 apiKey=${apiKey}, date=${now}, salt=${salt}, signature=${signature}`
        )
        .set('isUseSignature', false)
    }
  },
  USE_SIGNATURE: {
    reducer: (state, action) => {
      return state.set('isUseSignature', true)
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
