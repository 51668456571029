import { Map } from 'immutable'
import { client as api } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  clientSecret: '',
  scopes: [],
  selectedScopes: [],
  message: ''
})

const setInitScope = scopes => {
  if (!scopes.includes('users:read')) scopes.push('users:read')
  return scopes
}

const handler = {
  GET_CLIENT: {
    payloadCreator: api.getClient,
    reducer: {
      onFailure,
      onPending,
      onSuccess
    }
  },
  RECREATE_SECRET: {
    payloadCreator: api.recreateSecret,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const { clientSecret } = action.payload.data
        const stateData = { clientSecret }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_APP_SCOPES: {
    payloadCreator: api.getAppScopes,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const selectedScopes = action.payload.selectedScopes
        const scopes = action.payload.data
        const stateData = {
          scopes: scopes,
          selectedScopes: setInitScope(selectedScopes)
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  MULTICHIP_REMOVE_SELECTED_ITEM: {
    reducer: (state, action) => {
      const item = action.payload
      const selectedScopes = state.get('selectedScopes')
      if (item === 'users:read') {
        return state.set('selectedScopes', selectedScopes)
      }
      return state.set(
        'selectedScopes',
        selectedScopes.filter(i => i !== item)
      )
    }
  },
  MULTICHIP_ADD_SELECTED_ITEM: {
    reducer: (state, action) => {
      const item = action.payload
      const selectedScopes = state.get('selectedScopes')
      return state.set('selectedScopes', [...selectedScopes, item])
    }
  },
  MULTICHIP_ADD_ALL_SELECTED_ITEM: {
    reducer: state => {
      const scopes = state.get('scopes')
      const selectedScopes = scopes.map(obj => obj.name)
      return state.set('selectedScopes', selectedScopes)
    }
  },
  MULTICHIP_REMOVE_ALL_SELECTED_ITEM: {
    reducer: state => {
      return state.set('selectedScopes', ['users:read'])
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
