import { Map } from 'immutable'
import { oauth as oauthAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  prgoress: {},
  clientInfo: {},
  scopeList: [],
  error: null
})

const handler = {
  GET_CLIENT_INFO: {
    payloadCreator: oauthAPI.getClientInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { clientInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_SCOPE_INFO: {
    payloadCreator: oauthAPI.getScopeInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { scopeList: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
