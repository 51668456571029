/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  defaultConfirmKey: 'public',
  confirmData: {}
})

const awaitFunction = payload => {
  const { confirmFlag } = payload
  if (confirmFlag === true || process.env.NODE_ENV === 'test') {
    return Promise.resolve()
  }
  return new Promise(() => {})
}
const handler = {}

handler.show = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: awaitFunction,
  reducer: {
    onFailure,
    onPending: (state, action) => {
      const confirmData = state.get('confirmData')
      const defaultConfirmKey = state.get('defaultConfirmKey')
      const {
        key = defaultConfirmKey,
        title,
        message,
        onConfirm,
        onCancel,
        severity,
        popupMessage,
        options = {},
        dialogProps = {}
      } = action.meta.payload
      const newConfirmData = Object.assign({}, confirmData[key] || {}, {
        open: true,
        title,
        message,
        onConfirm,
        onCancel,
        severity,
        popupMessage,
        options,
        dialogProps
      })
      const stateData = {
        confirmData: Object.assign({}, confirmData, {
          [key]: newConfirmData
        })
      }
      return onPending(state, action, { stateData, message })
    },
    onSuccess: (state, action) => {
      const confirmData = state.get('confirmData')
      const defaultConfirmKey = state.get('defaultConfirmKey')
      const { key = defaultConfirmKey } = action.meta.payload
      const newConfirmData = Object.assign({}, confirmData[key] || {}, {
        open: false,
        confirmFlag: false
      })
      const stateData = {
        confirmData: Object.assign({}, confirmData, {
          [key]: newConfirmData
        })
      }
      return onSuccess(state, action, { stateData })
    }
  }
}

handler.confirm = {
  reducer: (state, action) => {
    const confirmData = state.get('confirmData')
    const defaultConfirmKey = state.get('defaultConfirmKey')
    const { key = defaultConfirmKey } = action.payload
    const newConfirmData = Object.assign({}, confirmData[key] || {}, {
      open: false,
      confirmFlag: true
    })
    return state.set(
      'confirmData',
      Object.assign({}, confirmData, {
        [key]: newConfirmData
      })
    )
  }
}

handler.clear = {
  reducer: (state, action) => {
    const defaultConfirmKey = state.get('defaultConfirmKey')
    const { key = defaultConfirmKey } = action.payload
    const confirmData = state.get('confirmData')
    const newConfirmData = Object.assign({}, { open: false })
    return state.set(
      'confirmData',
      Object.assign({}, confirmData, { [key]: newConfirmData })
    )
  }
}

handler.cancel = {
  reducer: (state, action) => {
    const confirmData = state.get('confirmData')
    const defaultConfirmKey = state.get('defaultConfirmKey')
    const { key = defaultConfirmKey } = action.payload
    const newConfirmData = Object.assign({}, confirmData[key] || {}, {
      open: false,
      confirmFlag: false
    })
    return state.set(
      'confirmData',
      Object.assign({}, confirmData, {
        [key]: newConfirmData
      })
    )
  }
}

handler.setConfirmFlag = {
  reducer: (state, action) => {
    const confirmData = state.get('confirmData')
    const defaultConfirmKey = state.get('defaultConfirmKey')
    const { key = defaultConfirmKey, confirmFlag = false } = action.payload
    const newConfirmData = Object.assign({}, confirmData[key] || {}, {
      confirmFlag
    })
    return state.set(
      'confirmData',
      Object.assign({}, confirmData, {
        [key]: newConfirmData
      })
    )
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
