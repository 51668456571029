import config from 'config'
let menu = [
  {
    headerTitle: '메시지전송',
    list: [
      {
        name: '메시지 보내기',
        icon: 'flight_takeoff',
        link: '/purplebook',
        local: 'auth-webpages'
      },
      {
        name: '간단 메시지 보내기',
        icon: 'flight_takeoff',
        link: '/purplebook/guide',
        local: 'auth-webpages'
      },
      {
        name: '전송결과',
        icon: 'flight_takeoff',
        link: '/message-log',
        local: 'auth-webpages'
      },
      {
        name: '사용통계',
        icon: 'flight_takeoff',
        link: '/stat',
        local: 'auth-webpages'
      },
      {
        name: '관리자 전송결과',
        link: '/admin/message-log'
      }
    ]
  },
  {
    headerTitle: '카카오/네이버/RCS',
    list: [
      {
        name: '카카오 채널 연동',
        icon: 'flight_takeoff',
        link: '/kakao/plus-friends',
        local: 'auth-webpages'
      },
      {
        name: '알림톡 템플릿 관리',
        icon: 'flight_takeoff',
        link: '/kakao/templates',
        local: 'auth-webpages'
      },
      {
        name: '네이버톡톡 연동',
        icon: 'flight_takeoff',
        link: '/naver/agents',
        local: 'auth-webpages'
      },
      {
        name: '네이버톡톡 템플릿 관리',
        icon: 'flight_takeoff',
        link: '/naver/templates',
        local: 'auth-webpages'
      },
      {
        name: 'RCS 브랜드 연동',
        icon: 'flight_takeoff',
        link: '/rcs/brands',
        local: 'auth-webpages'
      },
      {
        name: 'RCS 템플릿 관리',
        icon: 'flight_takeoff',
        link: '/rcs/templates',
        local: 'auth-webpages'
      },
      { name: '카카오채널 그룹 관리', link: '/admin/kakao/groups' },
      { name: '카카오채널 관리', link: '/admin/kakao/plus-friends' },
      {
        name: '카카오 템플릿 관리',
        link: '/admin/kakao/templates'
      },
      {
        name: '네이버 에이전트 관리',
        icon: 'flight_takeoff',
        link: '/admin/naver/agents',
        local: 'auth-webpages'
      },
      {
        name: '네이버 템플릿 관리',
        icon: 'flight_takeoff',
        link: '/admin/naver/templates',
        local: 'auth-webpages'
      }
    ]
  },
  {
    headerTitle: '요금충전',
    list: [
      {
        name: '요금안내/충전',
        icon: 'flight_takeoff',
        link: '/recharge',
        local: 'auth-webpages'
      },
      // {
      //   name: '후불요금제',
      //   icon: 'flight_takeoff',
      //   link: '/recharge',
      //   local: 'coolsms-layout'
      // },
      {
        name: '충전내역',
        icon: 'flight_takeoff',
        link: '/balance/history',
        local: 'auth-webpages'
      },
      {
        name: '잔액소진알림',
        icon: 'flight_takeoff',
        link: '/lba',
        local: 'auth-webpages'
      },
      {
        name: '잔액자동충전',
        icon: 'flight_takeoff',
        link: '/recharge/auto',
        local: 'auth-webpages'
      },
      {
        name: '세금계산서',
        icon: 'flight_takeoff',
        link: '/taxinvoice',
        local: 'auth-webpages'
      },
      {
        name: '후불 요금',
        icon: 'flight_takeoff',
        link: '/deferred-payment',
        local: 'auth-webpages'
      },
      {
        name: '후불서비스 신청',
        icon: 'flight_takeoff',
        link: '/deferred-payment/request',
        local: 'auth-webpages'
      },
      {
        name: config.pageTitle.balanceAdmin,
        link: '/admin/balance'
      },
      {
        name: '사용자 충전 내역',
        link: '/admin/balance/history'
      },
      {
        name: config.pageTitle.withdrawalHistory,
        link: '/admin/withdrawal/history'
      },
      {
        name: '후불사용내역',
        link: '/admin/deferred-payment'
      },
      {
        name: config.pageTitle.taxAdmin,
        link: '/admin/taxinvoice'
      },
      {
        name: '후불사용자 목록',
        link: '/admin/deferred-payment/list'
      }
    ]
  },
  {
    headerTitle: '환경설정',
    list: [
      {
        name: '발신번호 관리',
        icon: 'flight_takeoff',
        link: '/senderids',
        local: 'auth-webpages'
      },
      {
        name: '일일발송량 관리',
        icon: 'flight_takeoff',
        link: '/quota',
        local: 'auth-webpages'
      },
      {
        name: '수신거부 관리',
        icon: 'flight_takeoff',
        link: '/blocklist',
        local: 'auth-webpages'
      },
      {
        name: '첨부파일 관리',
        icon: 'flight_takeoff',
        link: '/storage',
        local: 'auth-webpages'
      },
      {
        name: '단축 URL 관리',
        icon: 'flight_takeoff',
        link: '/short/campaigns',
        local: 'auth-webpages'
      },
      {
        name: config.pageTitle.senderIdAdminList,
        link: '/admin/senderids'
      },
      {
        name: config.pageTitle.storageListPage,
        link: '/admin/storage'
      },
      {
        name: 'Quota 관리',
        link: '/admin/quota'
      },
      {
        name: 'Quota 조정 추정치',
        link: '/admin/quota/estimate/:accountId'
      }
    ]
  },
  {
    headerTitle: '개발 / 연동',
    list: [
      {
        name: 'API Key 관리',
        icon: 'flight_takeoff',
        link: '/credentials',
        local: 'auth-webpages'
      },
      {
        name: 'SDK 다운로드',
        icon: 'flight_takeoff',
        link: '/sdk',
        local: 'auth-webpages'
      },
      {
        name: 'Webhook 관리',
        icon: 'flight_takeoff',
        link: '/webhooks',
        local: 'auth-webpages'
      },
      {
        name: '내 앱 관리',
        icon: 'flight_takeoff',
        link: '/me/apps',
        local: 'auth-webpages'
      },
      {
        name: '수익 내역',
        icon: 'flight_takeoff',
        link: '/me/apps/settle',
        local: 'auth-webpages'
      },
      {
        name: 'API 개발문서',
        icon: 'flight_takeoff',
        link: 'https://docs.coolsms.co.kr/',
        local: 'auth-webpages',
        newTab: true
      },
      {
        name: '단축 URL',
        icon: 'flight_takeoff',
        link: '/short/campaigns',
        local: 'auth-webpages'
      },
      {
        name: 'Webhooks 관리',
        link: '/admin/webhooks'
      },
      {
        name: config.pageTitle.appsAdmin,
        link: '/admin/apps'
      },
      {
        name: config.pageTitle.profitHistory,
        link: '/admin/apps/settle'
      }
    ]
  },
  {
    headerTitle: '어플리케이션',
    list: [
      {
        name: '앱스토어',
        icon: 'flight_takeoff',
        link: `/apps`,
        local: 'auth-webpages',
        newTab: true
      }
    ]
  },
  {
    headerTitle: '고객지원',
    list: [
      {
        name: '공지사항',
        icon: 'help',
        link: `${config.supportPageHost}/hc/ko/community/topics/${config.zendeskNotice}`,
        newTab: true
      },
      // {
      //   name: '1:1 문의',
      //   icon: 'help',
      //   link: `${config.apiHost}/users/v1/zendesk/request`,
      //   newTab: true
      // },
      // {
      //   name: '문의 내역',
      //   icon: 'view_list',
      //   link: `${config.zendeskApiHost}/hc/ko/requests`,
      //   newTab: true
      // },
      {
        name: '자주 묻는 질문',
        icon: 'question_answer',
        link: `${config.apiHost}/users/v1/zendesk/login?return_to=/hc/ko`,
        newTab: true
      },
      {
        name: 'Feature 관리',
        link: '/admin/Feature'
      }
    ]
  },
  {
    headerTitle: '관리자메뉴',
    list: [
      {
        name: '관리자 대시보드',
        link: '/admin/dashboard'
      },
      {
        name: '사용자 계정 관리',
        link: '/admin/userinfo'
      },
      {
        name: config.pageTitle.messageRoutes,
        link: '/admin/routes'
      },
      {
        name: config.pageTitle.businessAdmin,
        icon: 'help',
        link: '/admin/companies'
      },
      {
        name: '사용자 단가 설정',
        link: '/admin/pricing'
      },
      {
        name: config.pageTitle.whiteListPage,
        link: '/admin/white'
      },
      {
        name: '계정 인증 관리',
        link: '/admin/verifications'
      },
      {
        name: config.pageTitle.scheduler,
        link: '/admin/scheduler'
      },
      {
        name: config.pageTitle.accountListPage,
        link: '/admin/accounts'
      },
      {
        name: config.pageTitle.messagesMonitoringPage,
        link: '/admin/messages/monitoring'
      },
      {
        name: config.pageTitle.memberListPage,
        link: '/admin/members'
      },
      {
        name: config.pageTitle.userLogListPage,
        link: '/admin/user-logs'
      },
      {
        name: config.pageTitle.requestLogListPage,
        link: '/admin/request-logs'
      },
      {
        name: '블랙리스트 관리',
        link: '/admin/blocks'
      },
      {
        name: '마케터 목록',
        link: '/admin/marketer'
      },
      {
        name: '회계 레코드 목록',
        link: '/admin/accounting/items'
      },
      {
        name: '회계 레코드 입력',
        link: '/admin/accounting/items/new'
      },
      {
        name: '회계 리포트',
        link: '/admin/accounting/report'
      },
      {
        name: '상담 내역',
        link: '/admin/inquiries'
      }
    ]
  }
]

export default menu
