import isEqual from 'react-fast-compare'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import omit from 'lodash/omit'
import omitBy from 'lodash/omitBy'
import uniq from 'lodash/uniq'
// import { diff } from 'deep-object-diff'
import { isString, isObject, isArray, isEmptyString } from 'lib/detectType'

const getResponseData = (props, forceUpdateResponseKey = []) => {
  return pickBy(props, obj => {
    return (
      obj?.actionName &&
      obj?.actionId &&
      ['getMyAccountInfo']
        .filter(key => {
          return forceUpdateResponseKey.indexOf(key) === -1
        })
        .indexOf(obj?.actionName) === -1
    )
  })
}

// 순서 매우 중요
export const isEqualProps = (nextProps, props, useProps, options = {}) => {
  const {
    // 반드시 비교할 상태
    forceUpdateProps = [],
    forceUpdateResponseKey = []
  } = options
  if (isString(useProps)) {
    const propsFilter = useProps
      .replace(/ |\t/gm, '')
      .split(/,|\n/)
      .filter(d => d)
    const newProps = pick(props, propsFilter)
    const newNextProps = pick(nextProps, propsFilter)
    return isEqual(newProps, newNextProps)
  }
  if (isObject(useProps)) {
    const newProps = pick(props, Object.keys(useProps))
    const newNextProps = pick(nextProps, Object.keys(useProps))
    return isEqual(newProps, newNextProps)
  }
  if (isArray(useProps)) {
    const newProps = pick(props, useProps)
    const newNextProps = pick(nextProps, useProps)
    return isEqual(newProps, newNextProps)
  }
  const responseData = getResponseData(props, forceUpdateResponseKey)
  const nextResponseData = getResponseData(nextProps, forceUpdateResponseKey)
  // 비교 제외 대상 (변경되어도 관여하지 않음)
  const omitList = uniq([
    'classes',
    'proxy',
    'location',
    'history',
    'match',
    'ChannelIO',
    'highlight',
    // 'Splitted',
    // 'children',
    // 'popupWidth',
    // 'popupHeight',
    // authLoading true일때 다음 loading 상태가 true인경우 검사에서 제외
    nextProps?.authLoading === true ? 'authLoading' : '',
    nextProps?.authLoading === true ? 'auth' : '',
    ...Object.keys(responseData),
    ...Object.keys(nextResponseData)
  ])
    .filter(omitKey => !isEmptyString(omitKey))
    .filter(omitKey => {
      return forceUpdateProps.indexOf(omitKey) === -1
    })
  const omitFilter = (value, key) => {
    return omitList.indexOf(key) !== -1 || /^[A-Z](.+)Actions$/.test(key)
  }
  const newProps = omitBy(props, omitFilter)
  const newNextProps = omitBy(nextProps, omitFilter)
  // responseData 제외한 값중 다른 값이 존재하면 리렌더링
  if (!isEqual(newProps, newNextProps)) {
    return false
  }
  // exceptLoading true일때 다음 loading 상태가 true인경우 검사에서 제외
  const { exceptLoading = true } = options
  const nextLoadingTrueKeys = Object.keys(
    pickBy(nextResponseData, obj => {
      return obj?.loading === true
    })
  )
  const omitTrueLoadingResponse = omit(
    responseData,
    exceptLoading ? nextLoadingTrueKeys : []
  )
  const omitTrueLoadingNextResponse = omit(
    nextResponseData,
    exceptLoading ? nextLoadingTrueKeys : []
  )
  const isEqualResponse = isEqual(
    omitTrueLoadingResponse,
    omitTrueLoadingNextResponse
  )
  if (!isEqualResponse) return false
  return true
}

export default isEqualProps
