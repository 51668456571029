/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import _ from 'lodash'
import { createRedux } from 'lib/requestHandler'
import { isObject } from 'lib/detectType'

const initialState = Map({
  defaultPopupKey: 'public',
  size: {},
  popupData: {}
})

const handler = {}

handler.clear = {
  reducer: (state, action) => {
    const { key } = action.payload || {}
    if (key) {
      const popupData = state.get('popupData')
      return state.set('popupData', Object.assign({}, popupData, { [key]: {} }))
    }
    return state.set('popupData', {})
  }
}

handler.new = {
  reducer: (state, action) => {
    const { key = 'public', ...payload } = action.payload
    const { actions = [] } = payload
    // 이미 '닫기'로 추정되는 버튼이 존재하는 경우 닫기 버튼 기본값 사용하지 않음
    const existsCloseButton = isObject(
      actions.find(data => data?.title === '닫기')
    )
    const existsSupportButton = isObject(
      actions.find(data => data?.title === '문의')
    )
    const popupData = state.get('popupData')
    const detailData = _.omitBy(
      {
        key,
        openFlag: true,
        padding: true,
        supportButton: !existsSupportButton,
        closeButton: !existsCloseButton,
        ...payload
      },
      _.isUndefined
    )
    return state.set(
      'popupData',
      Object.assign({}, popupData, { [key]: detailData })
    )
  }
}

handler.toggle = {
  reducer: (state, action) => {
    const { key, ...payload } = action.payload
    const popupData = state.get('popupData')
    const { openFlag = false } = popupData[key] || {}
    const open = !openFlag
    const detailData = _.omitBy({ ...payload, openFlag: open }, _.isUndefined)
    return state.set(
      'popupData',
      Object.assign({}, popupData, { [key]: detailData })
    )
  }
}

handler.setPopupData = {
  reducer: (state, action) => {
    const defaultPopupKey = state.get('defaultPopupKey')
    const { key = defaultPopupKey, ...payload } = action.payload
    const popupData = state.get('popupData')
    const detailData = Object.assign(
      {},
      popupData[key] || {},
      _.omitBy({ ...payload }, _.isUndefined)
    )
    return state.set(
      'popupData',
      Object.assign({}, popupData, { [key]: detailData })
    )
  }
}

handler.setSize = {
  reducer: (state, actino) => {
    const { key, width, height } = actino.payload
    const size = state.get('size')
    const newSize = size[key] || {}
    newSize.width = width
    newSize.height = height
    return state.set('size', Object.assign({}, size, { [key]: newSize }))
  }
}

handler.close = {
  reducer: (state, action) => {
    const key = action?.payload?.key || 'public'
    const popupData = state.get('popupData')
    const data = popupData?.[key] || {}
    const detailData = _.omitBy({ ...data, openFlag: false }, _.isUndefined)
    return state.set(
      'popupData',
      Object.assign({}, popupData, { [key]: detailData })
    )
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
