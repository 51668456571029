/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { Component } from 'react'
import { bindStateToProps, bindActionCreators } from 'lib'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import ConfirmForm from 'components/molecules/ConfirmForm'
import { actions as confirmActions } from 'store/modules/confirm'

import { isFunction } from 'lib/detectType'

/**
 * Confirm dialog
 * @class
 */
class ConfirmContainer extends Component {
  constructor(props) {
    super(props)
    autoBind(this)
    this.lastActiveElement = null
  }

  shouldComponentUpdate(nextProps) {
    const { confirmKey, confirmData = {} } = this.props
    const { confirmData: nextConfirmData = {} } = nextProps
    const { open, message: msg } = confirmData[confirmKey] || {}
    const { open: nextOpen, message: nextMsg } =
      nextConfirmData[confirmKey] || {}
    return open !== nextOpen || msg !== nextMsg
  }

  componentDidUpdate = prevProps => {
    const { confirmKey, confirmData } = this.props
    const { confirmData: prevConfirmData } = prevProps
    const openFlag = confirmData?.[confirmKey]?.open
    const prevOpenFlag = prevConfirmData?.[confirmKey]?.open
    const isOpen = openFlag === true && prevOpenFlag !== true
    const isClose = openFlag === false && prevOpenFlag !== false
    // 컨펌이 열리는 경우 기존 포커스 해제 -> 닫은 후 다시 포커스
    // enter keyup event 중복 방지
    if (isOpen) {
      this.lastActiveElement = document.activeElement
      if (this.lastActiveElement !== null) {
        this.lastActiveElement.blur()
      }
    }
    if (isClose) {
      if (this.lastActiveElement !== null) {
        setTimeout(() => {
          this.lastActiveElement.focus()
        }, 200)
      }
    }
  }

  onConfirm = event => {
    event.preventDefault()
    event.stopPropagation()
    const { ConfirmActions, confirmKey, confirmData = {} } = this.props
    const { onConfirm } = confirmData[confirmKey] || {}
    ConfirmActions.confirm({ key: confirmKey })
    if (isFunction(onConfirm)) {
      return onConfirm()
    }
  }

  onClose = (e, reason) => {
    this.onCancel(e)
  }

  onCancel = event => {
    event.preventDefault()
    event.stopPropagation()
    const { ConfirmActions, confirmKey, confirmData = {} } = this.props
    const { onCancel } = confirmData[confirmKey] || {}
    ConfirmActions.cancel({ key: confirmKey })
    if (isFunction(onCancel)) {
      return onCancel()
    }
  }

  render() {
    const props = Object.assign({}, this.props, this.state)
    const key = props.confirmKey || props.defaultConfirmKey || 'public'
    const confirmProps = props.confirmData[key] || {}
    return <ConfirmForm {...props} proxy={this} {...confirmProps} />
  }
}

export default connect(
  bindStateToProps({
    confirm: true
  }),
  bindActionCreators({
    confirmActions
  })
)(ConfirmContainer)
