const axios = require('axios')
const qs = require('qs')

export const getClient = clientId =>
  axios.get(
    `/oauth2/v1/clients?${qs.stringify({
      client_id: clientId
    })}`
  )
export const recreateSecret = clientId =>
  axios.put(`/oauth2/v1/clients/${clientId}/secret`, null)
export const getAppScopes = async selectedScopes => {
  const result = await axios.get(`/oauth2/v1/scopes?type=APP`)
  return Object.assign(result, { selectedScopes })
}
