/**
 * @author EdenCha <eden@nurigo.net>
 */
import qs from 'query-string'
import url from 'url-parse'
import moment from 'moment-timezone'
import { Map } from 'immutable'
import { notification as noticeAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  block: false,
  deniedKeys: [],
  searchParams: {
    startDate: moment().subtract(1, 'months').startOf('day').toISOString(),
    endDate: moment().endOf('day').toISOString()
  },
  page: 0,
  rowsPerPage: 10,
  nextKey: null,
  notifications: [],
  keys: []
})

const handler = {
  GET_NOTICE_HISTORY: {
    payloadCreator: noticeAPI.getNoticeHistory,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const query = url(action.payload.config.url).query
        const params = qs.parse(query)
        const rowsPerPage = state.get('rowsPerPage')
        const payload = action.payload.data
        const { data, nextKey, block, keys, deniedKeys } = payload
        const stateData = {
          page: 0,
          nextKey: nextKey,
          rowsPerPage: Number(params.limit || rowsPerPage),
          searchParams: params,
          keys: keys,
          deniedKeys: deniedKeys,
          block: block,
          notifications: data
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  ADD_NOTICE_HISTORY: {
    payloadCreator: noticeAPI.getNoticeHistory,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const notifications = state.get('notifications')
        const page = state.get('page')
        const payload = action.payload.data
        const { data: newData, nextKey, block, keys, deniedKeys } = payload
        const stateData = {
          page: page + 1,
          nextKey: nextKey,
          keys: keys,
          deniedKeys: deniedKeys,
          block: block,
          notifications: [].concat(notifications, newData)
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SET_NOTIFICATION_BLOCK: {
    payloadCreator: noticeAPI.setNotificationBlock,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const payload = action.payload.data
        const { block, deniedKeys } = payload
        const stateData = {
          block: block,
          deniedKeys: deniedKeys
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SET_NOTIFICATION_DENIAL: {
    payloadCreator: noticeAPI.setNotificationDenial,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const payload = action.payload.data
        const { block, deniedKeys } = payload
        const stateData = {
          block: block,
          deniedKeys: deniedKeys
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SET_NOTICE_LIST_PAGE: {
    reducer: (state, action) => state.set('page', Number(action.payload))
  },
  SET_SEARCH_PARAMS: {
    reducer: (state, action) => state.set('searchParams', action.payload)
  }
}

handler.getNotifications = {
  payloadCreator: noticeAPI.getNotifications
}

handler.confirmNotification = {
  payloadCreator: noticeAPI.confirmNotification
}

handler.getNoticeDenial = {
  payloadCreator: noticeAPI.getNoticeDenial
}

handler.setNoticeDenial = {
  payloadCreator: noticeAPI.setNoticeDenial
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
