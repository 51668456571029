import axios from 'axios'
import axiosCancel from 'axios-cancel'
import { Map } from 'immutable'
import { iam as iamAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
const initialState = Map({})
axiosCancel(axios, {
  debug: false // default
})
const handler = {
  SEARCH_BUSINESS_INFO: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: iamAPI.searchBusinessInfo,
    reducer: {
      onFailure: (state, action, options) => {
        if (axios.isCancel(action.payload)) {
          return onPending(state, action, options)
        }
        return onFailure(state, action, options)
      },
      onPending,
      onSuccess
    }
  },
  PUT_BUSINESS_INFO: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: iamAPI.putBusinessInfo,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { businessInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

handler.getBlockGroups = {
  payloadCreator: iamAPI.getBlockGroups
}

handler.getBlockNumbers = {
  payloadCreator: iamAPI.getBlockNumbers
}

handler.putBlockGroup = {
  payloadCreator: iamAPI.putBlockGroup
}

handler.addBlockNumbers = {
  payloadCreator: iamAPI.addBlockNumbers
}

handler.deleteBlockNumber = {
  payloadCreator: iamAPI.deleteBlockNumber
}

handler.deleteBlockGroup = {
  payloadCreator: iamAPI.deleteBlockGroup
}

handler.getBusinessInfo = {
  options: { response: { error: { ignore: [404] } } },
  payloadCreator: iamAPI.getBusinessInfo
}

handler.getRequestedBusinessInfo = {
  options: { response: { error: { ignore: [404] } } },
  payloadCreator: iamAPI.getRequestedBusinessInfo
}

handler.addBusinessMember = {
  options: { debounce: { wait: 500 } },
  payloadCreator: iamAPI.addBusinessMember
}

handler.createMemberPerson = {
  options: { debounce: { wait: 500 } },
  payloadCreator: iamAPI.createMemberPerson
}

handler.updateMemberPerson = {
  options: { debounce: { wait: 500 } },
  payloadCreator: iamAPI.updateMemberPerson
}

handler.getMemberPerson = {
  options: { response: { error: { ignore: [404] } } },
  payloadCreator: iamAPI.getMemberPerson
}

handler.getBlacks = {
  payloadCreator: iamAPI.getBlacks
}

handler.getBlockListByReceivcer = {
  payloadCreator: iamAPI.getBlockListByReceivcer
}

handler.deleteBlockListByReceivcer = {
  options: { debounce: { wait: 500 } },
  payloadCreator: iamAPI.deleteBlockListByReceivcer
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
