import React from 'react'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import withStyles from '@material-ui/core/styles/withStyles'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'

import config from 'config'
import { isObject, isEmptyString } from 'lib/detectType'
import getComponentId from 'lib/getComponentId'

import CommonButton from 'containers/CommonButtonContainer'
import FormHeaderLogo from 'components/molecules/FormHeaderLogo'

const styles = theme => ({
  buttonWrap: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3)
  },
  loginButtonWrap: {
    position: 'relative'
  },
  botCheckText: {
    position: 'absolute',
    width: '100%',
    top: '50%',
    transform: 'translateY(-50%)'
  },
  signUpTextArea: theme.mixins.gutters({
    textAlign: 'center'
  }),
  loginFormLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none'
  },
  passwordField: {
    '& input': {
      '-webkit-ime-mode': 'inactive',
      '-moz-ime-mode': 'inactive',
      '-ms-ime-mode': 'inactive',
      'ime-mode': 'inactive'
    }
  }
})

const LoginPaper = props => {
  const {
    classes,
    isOnlyEnglish = true,
    message,
    username,
    password,
    captchaLoading = false,
    formError,
    onKeyUp,
    onSubmitLogin,
    onChangeFormData,
    onChangePassword
  } = props
  const ahid = jwt.decode(cookies.get('AHID'))
  const hasHomepageUrl =
    isObject(ahid) && !isEmptyString(ahid?.homepage) && ahid?.homepage
  const appName = isObject(ahid) && ahid?.appName
  const isMysite = hasHomepageUrl && !isEmptyString(appName)
  return (
    <>
      <FormHeaderLogo title={`${config?.serviceName} 로그인`} />
      <TextField
        variant="outlined"
        id="username"
        name="username"
        label={config.isCoolsms ? '이메일 또는 아이디' : '이메일'}
        placeholder={
          config.isCoolsms
            ? '이메일 혹은 아이디를 입력하세요.'
            : '이메일을 입력하세요.'
        }
        autoFocus
        fullWidth
        required
        value={username || ''}
        margin="normal"
        onKeyUp={onKeyUp}
        error={formError.username}
        helperText={formError.username}
        onChange={e => onChangeFormData({ username: e.target.value })}
      />
      <TextField
        variant="outlined"
        id="password"
        name="password"
        type="password"
        label="비밀번호"
        placeholder="비밀번호"
        className={classes.passwordField}
        fullWidth
        required
        margin="normal"
        helperText={
          (!isOnlyEnglish && '한글로 입력되고 있습니다.') || formError.password
        }
        value={password || ''}
        error={!isOnlyEnglish || formError.password}
        onKeyUp={onKeyUp}
        onChange={e => onChangePassword(e.target.value)}
      />
      <div className={classes.buttonWrap}>
        <Grid container spacing={1}>
          {message && (
            <Grid item xs={12}>
              <Typography variant="body2" color="error">
                {message}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <div
              className={classes.loginButtonWrap}
              id={getComponentId('loginButton')}
            >
              <CommonButton
                variant="contained"
                size="large"
                color="primary"
                fullWidth
                wait={1000}
                clickLoading
                disabled={captchaLoading}
                onClick={() => {
                  onSubmitLogin()
                }}
              >
                로그인
              </CommonButton>
              <Box
                className={classes.botCheckText}
                style={{ display: captchaLoading ? 'block' : 'none' }}
              >
                <Typography
                  variant="caption"
                  color="textSecondary"
                  align="center"
                  display="block"
                >
                  로봇 여부를 검사하고있습니다.
                </Typography>
                <Typography
                  variant="caption"
                  color="textSecondary"
                  align="center"
                  display="block"
                >
                  응답이 없는 경우 <span style={{}}>이곳</span>을 클릭하여
                  페이지를 새로고침 하세요.
                </Typography>
              </Box>
            </div>
          </Grid>
          {isMysite && (
            <Grid item xs={12}>
              <CommonButton
                variant="outlined"
                size="small"
                color="primary"
                newTabIcon
                fullWidth
                confirm={`현재 사이트는 솔라피 주식회사에서 제공하는 메시징 서비스 입니다.\n\n"${appName}"에서 설정한 외부 홈페이지로 이동할까요?`}
                onClick={() => {
                  window.open(hasHomepageUrl, '_blank')
                }}
              >
                {appName} 홈페이지
              </CommonButton>
            </Grid>
          )}
        </Grid>
      </div>
      <div className={classes.signUpTextArea}>
        <Grid className={classes.formBtnArea} container spacing={1}>
          <Grid item xs={12}>
            <Typography color="primary" align="center" variant="body2">
              <Link
                to={config.pages.resetPassword}
                className={classes.loginFormLink}
              >
                비밀번호를 잊었습니까?
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography
              className={classNames(classes.signUpText, 'signUpText')}
              variant="body2"
            >
              첫 방문이라면?{' '}
              <Typography color="primary" variant="body2" display="inline">
                <Link
                  to={config.pages.signUp}
                  className={classes.loginFormLink}
                >
                  회원가입
                </Link>
              </Typography>{' '}
              또는{' '}
              <Typography color="primary" variant="body2" display="inline">
                <Link to={config.pages.trial} className={classes.loginFormLink}>
                  체험하기
                </Link>
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default withStyles(styles)(LoginPaper)
