/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { verifyMemberEmail as verifyMemberEmailAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  verified: false
})

const handler = {
  GET_MEMBER_INFO: {
    payloadCreator: verifyMemberEmailAPI.getMemberInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const memberInfo = action.payload.data
        const verified = memberInfo.status === 'ACTIVE'
        const stateData = { memberInfo, verified }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  AUTHENTICATE_EMAIL: {
    payloadCreator: verifyMemberEmailAPI.authenticateEmail,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { verified: true }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
