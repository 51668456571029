import { unsubscribe as unsubscribeAPI } from 'lib/api'
import { Map } from 'immutable'
import moment from 'moment-timezone'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  unsubscribeList: [
    /*
    dateCreated: "2019-09-20T03:33:52.000Z"
    handleKey: "IM01BL190902032610241usuYnn8RpVGIM01BL190902032610241usuYnn8RpAB"
    recipientNumber: "01029951047"
    senderNumber: "01025389903"
    type: "DENIAL"
     */
  ],
  phoneNumber: '',
  isEmptyList: false
})

const handler = {
  getUnsubscribeList: {
    payloadCreator: unsubscribeAPI.getUnsubscribeList,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { blackList } = action.payload.data
        blackList.forEach(black => {
          black.dateCreated = moment(black.dateCreated).format(
            'YYYY-MM-DD HH:mm:ss'
          )
        })
        const stateData = {
          unsubscribeList: blackList,
          isEmptyList: !blackList.length
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  deleteUnsubscribe: {
    payloadCreator: unsubscribeAPI.deleteUnsubscribe,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const oldUnsubscribeList = state.get('unsubscribeList')
        const deletedResult = action.payload.data
        deletedResult.forEach(deletedData => {
          const { handleKey } = deletedData
          const removeTargetIndex = oldUnsubscribeList.findIndex(
            unsubscribe => unsubscribe.handleKey === handleKey
          )
          // 삭제한 HandleKey 제거
          oldUnsubscribeList.splice(removeTargetIndex, 1)
        })
        const stateData = {
          unsubscribeList: [].concat(oldUnsubscribeList)
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  setPhoneNumber: {
    reducer: (state, action) => {
      return state.set('phoneNumber', action.payload)
    }
  },
  clearUnsubscribeList: {
    reducer: state => {
      return state
        .set('phoneNumber', '')
        .set('unsubscribeList', [])
        .set('isEmptyList', false)
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
