import axios from 'axios'
import qs from 'querystring'
import { omitBy, isUndefined } from 'lodash'

export const getUnitPriceInfo = () => {
  const uri = `/pricing/v1/messaging/countries?limit=300`
  return axios.get(uri)
}

export const getPricing = (params = {}) => {
  const { countryId } = params
  const queryString = qs.stringify(omitBy({ countryId }, isUndefined))
  const uri = `/pricing/v1/messaging${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const getDefaultMessagePriceList = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/pricing/v1/messaging/default${
    queryString ? '?' + queryString : ''
  }`
  return axios.get(uri)
}

export const getMessagePrice = payload => {
  const queryString = qs.stringify(payload)
  const uri = `/pricing/v1/messaging${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}
