/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
import reactStringReplace from 'react-string-replace'
// import omitBy from 'lodash/omitBy'

// material UI
// import Paper from '@material-ui/core/Paper'
// import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'

// my components
import SimpleLink from 'components/atoms/SimpleLink'

// my containers
// import UserContainerName from 'containers/UserContainerName'

// libraries
// import config from 'config'
import { isString, isEmptyString } from 'lib/detectType'
// import isEqualProps from 'lib/isEqualProps'

const styles = theme => ({
  root: {}
})
export const useProps = {}
/**
 * 텍스트에서 url을 링크로 파싱합니다.
 */
const ReplaceUrlToLink = React.memo(({ classes, ...props }) => {
  const { children, LinkProps = {} } = props
  if (!isString(children) || isEmptyString(children)) return children
  const replaceString = reactStringReplace(
    children,
    /(https?:\/\/\S+)/g,
    (match, i) => {
      return (
        <SimpleLink {...LinkProps} to={match} key={match + i}>
          {match}
        </SimpleLink>
      )
    }
  )
  return <>{replaceString}</>
})

ReplaceUrlToLink.defaultProps = setDefaultProps(useProps)
ReplaceUrlToLink.propTypes = setPropTypes(useProps)
export default withStyles(styles)(ReplaceUrlToLink)
