import { Map } from 'immutable'
import { naver as naverAPI } from 'lib/api'
import { createRedux } from 'lib/requestHandler'
const initialState = Map({
  statusProgress: ['PENDING', 'INSPECTING', 'REJECTED', 'APPROVED'],
  stepDesc: [
    '연동되지 않았습니다.',
    '아직 템플릿을 등록하지 않았습니다.',
    '템플릿을 검수 요청하세요. 1~3 영업일이 소요됩니다.',
    '검수 요청을 보냈습니다. 검수 결과 도착까지 1~3 영업일이 소요됩니다.',
    '템플릿 등록이 완료되었습니다.'
  ],
  serviceList: {
    biz: {
      color: '#00aab1'
    }
  }
})
const handler = {}
handler.putTalk = {
  options: { debounce: { wait: 500 } },
  payloadCreator: naverAPI.putTalk
}
handler.getAgent = {
  payloadCreator: naverAPI.getAgent
}
handler.getAgents = {
  payloadCreator: naverAPI.getAgents
}
handler.deleteAgent = {
  options: { debounce: { wait: 500 } },
  payloadCreator: naverAPI.deleteAgent
}
handler.getTemplate = {
  payloadCreator: naverAPI.getTemplate
}
handler.getTemplates = {
  payloadCreator: naverAPI.getTemplates
}
handler.setTemplate = {
  options: { debounce: { wait: 1000 } },
  payloadCreator: naverAPI.setTemplate
}
handler.putTemplate = {
  options: { debounce: { wait: 1000 } },
  payloadCreator: naverAPI.putTemplate
}
handler.templateInspection = {
  options: { debounce: { wait: 1000 } },
  payloadCreator: naverAPI.templateInspection
}
handler.putTemplateComment = {
  options: { debounce: { wait: 1000 } },
  payloadCreator: naverAPI.putTemplateComment
}
handler.deleteTemplate = {
  options: { debounce: { wait: 1000 } },
  payloadCreator: naverAPI.deleteTemplate
}
handler.hideTemplate = {
  payloadCreator: naverAPI.hideTemplate
}
const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
