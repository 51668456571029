import axios from 'axios'

export const uploadDocuments = params => {
  const uri = `/senderid/v1/documents`
  return axios.post(uri, params)
}

export const getDocuments = params => {
  const { category } = params
  const uri = `/senderid/v1/documents?category=${category}`
  return axios.get(uri)
}

export const submit = params => {
  const uri = `/senderid/v1/papers/limitation`
  return axios.post(uri, params)
}
