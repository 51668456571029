/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
import urlParse from 'url-parse'
import { Link } from 'react-router-dom'
// import omitBy from 'lodash/omitBy'

// material UI
// import Grid from '@material-ui/core/Grid'
// import Paper from '@material-ui/core/Paper'
// import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'
import { RxOpenInNewWindow as RxOpenInNewWindowIcon } from 'react-icons/rx'
import { BiLink as BiLinkIcon } from 'react-icons/bi'

// my components
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'

// libraries
// import config from 'config'
import { isString, isEmptyString } from 'lib/detectType'
import isEqualProps from 'lib/isEqualProps'

const styles = theme => ({
  root: {},
  wrapSpan: {
    display: 'inline'
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      color: '#5b05ff',
      textDecoration: 'underline'
    },
    '&:active': {
      color: theme.palette.secondary.main
    }
  },
  newTabIcon: {
    fontSize: '1em',
    verticalAlign: 'middle',
    marginLeft: 4
  }
})
export const useProps = {}
/**
 * 링크 컴포넌트
 */
const Comp = props => {
  const { classes, children, openInNew } = props
  return (
    <>
      <span>{children}</span>
      <span>
        {openInNew ? (
          <RxOpenInNewWindowIcon className={classes.newTabIcon} />
        ) : (
          <BiLinkIcon className={classes.newTabIcon} />
        )}
      </span>
    </>
  )
}
const SimpleLink = React.memo(
  ({ classes, proxy, ...props }) => {
    const {
      children,
      to,
      target,
      title: propTitle,
      onClick = () => {},
      ...others
    } = props
    if (!isString(to) || isEmptyString(to)) {
      return children
    }
    const targetUrl = urlParse(to)
    const title =
      isString(propTitle) && !isEmptyString(propTitle)
        ? propTitle
        : isString(children)
        ? children
        : null
    const openInNew = target === '_blank'
    const isSameHostName = window.location.hostname === targetUrl?.hostname
    const anchorProps =
      target === '_blank'
        ? {
            without: true,
            rel: 'noopener noreferrer'
          }
        : {}
    if (!isSameHostName) {
      return (
        <span className={classes.wrapSpan}>
          <a
            href={targetUrl.href}
            target={target}
            onClick={onClick}
            className={classes.link}
            title={title}
            {...anchorProps}
          >
            <Comp classes={classes} openInNew={openInNew}>
              {children}
            </Comp>
          </a>
        </span>
      )
    }
    return (
      <span className={classes.wrapSpan}>
        <Link
          {...others}
          to={`${targetUrl?.pathname}${targetUrl?.query}${targetUrl?.hash}`}
          target={target}
          onClick={onClick}
          className={classes.link}
          title={title}
        >
          <Comp classes={classes} openInNew={openInNew}>
            {children}
          </Comp>
        </Link>
      </span>
    )
  },
  (prevProps, nextProps) => {
    return isEqualProps(nextProps, prevProps)
  }
)

SimpleLink.defaultProps = setDefaultProps(useProps)
SimpleLink.propTypes = setPropTypes(useProps)
export default withStyles(styles)(SimpleLink)
