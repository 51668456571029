import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import 'filepond-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'
import App from 'components/App'

if (process.env.NODE_ENV === 'production') {
  // import 'core-js/es6/set'
  // import 'core-js/es6/map'
  // import 'raf/polyfill'
  require('console.history')
}

document.body.style.margin = 0
document.body.style.padding = 0
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
