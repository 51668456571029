import axios from 'axios'
import { cacheAdapterEnhancer } from 'axios-extensions'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import uniqid from 'uniqid'
import config from 'config'
import { isFunction, isString } from 'lib/detectType'

function getApiHost() {
  const appHomeData = jwt.decode(cookies.get('AHID')) || {}
  const apiHost =
    process.env.NODE_ENV === 'development'
      ? config?.apiHost
      : appHomeData?.apiHost || config?.apiHost
  return apiHost
}

function getAuthorization() {
  return `Bearer ${cookies.get('CSAK')}`
}

function setAxiosHeaders() {
  const apiHost = getApiHost()
  axios.defaults.baseURL = apiHost
  axios.defaults.headers.common['Authorization'] = getAuthorization()
  // axios.defaults.headers.common['Cache-Control'] = 'no-cache'
  // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
}

function setAxiosDefault(payload = {}) {
  // const CancelToken = axios.CancelToken
  setAxiosHeaders()
  const { showSnackbar } = payload
  // 기본적으로 cache 사용하지 않음
  axios.defaults.adapter = cacheAdapterEnhancer(axios.defaults.adapter, {
    enabledByDefault: false
  })

  // 캐시사용여부 확인, 멤버권한여부 확인, 매 요청에 헤더 재설정
  axios.interceptors.request.use(
    function (config) {
      // const auth = isObject(jwt.decode(cookies.get('CSAK')))
      config.headers = {
        ...config.headers,
        common: {
          ...config.headers.common,
          Authorization: getAuthorization()
        }
      }
      // Do something before request is sent
      return {
        ...config
        // 비회원용 요청이 있을 수 있으므로 현재는 사용하지 않음
        // cancelToken: new CancelToken(cancel => {
        // 권한 없을 경우 바로 요청 취소
        // if (!auth) {
        //   cancel('토큰이 올바르지 않습니다. 다시 로그인해주세요.')
        // }
        // })
      }
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error)
    }
  )

  axios.interceptors.response.use(
    function (response) {
      // 응답 데이터를 가공
      // ...
      const { data, request = {} } = response
      const { responseURL } = request
      if (isString(data) && /\/oauth2\/login/.test(responseURL)) {
        return Promise.reject({
          config: { ...response?.config, cache: false },
          response: {
            ...response,
            uniqKey: uniqid(),
            status: 401,
            data: {
              errorCode: 'Unauthorized',
              errorMessage: '로그인이 필요합니다.',
              permissionDenied: true
            }
          }
        })
      }
      return {
        uniqKey: uniqid(),
        ...response
      }
    },
    // 요청 오류 발생 시
    function (error) {
      const { response = {} } = error
      const { status, data = {} } = response
      const { errorCode, errorMessage, mfa } = data
      const url =
        config.pages.login +
        '?returnUrl=' +
        encodeURIComponent(window.location.href)
      let message
      if (
        errorCode === 'TokenExpired' &&
        status === 401 &&
        isFunction(showSnackbar)
      ) {
        message = '토큰이 만료되었습니다. 다시 로그인해주세요.'
      } else if (
        status === 401 &&
        !mfa &&
        isFunction(showSnackbar) &&
        /아이디|비밀번호/.test(errorMessage) !== true
      ) {
        message = '토큰이 올바르지 않거나 만료되었습니다. 다시 로그인해주세요.'
      }
      if (message) {
        showSnackbar(message, '로그인', url, false)
      }
      return Promise.reject(error)
    }
  )
}

export default setAxiosDefault
