import { Map } from 'immutable'

import { requestHandler } from 'lib'

const { createRedux } = requestHandler
const initialState = Map({
  // 해당 값은 그대로 purplebook formData로 사용되며, 마찬가지로 그대로 복사함
  presetFormKeys: [
    'tabIndex',
    'subject',
    'from',
    'pfId',
    'templateId',
    'talkId',
    'naverTemplateId',
    'kakaoImageLink',
    'ctaBtns',
    'rcsBtns',
    'rcsAdditionalFlag',
    'additionalBody',
    'adChecked',
    'disableSms',
    'copyAllowed',
    'businessName',
    'uploadChecked',
    'scheduledDate'
  ]
})

const handler = {}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)

export const actions = reduxActions
export default reduxReducers
