import axios from 'axios'
import qs from 'querystring'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getEstimateQuota = (payload, options = {}) => {
  const { cache = false } = options
  return axios.get(`/quota/v1/estimate`, { cache })
}

export const setUserQuota = params => axios.put(`/quota/v1/me`, params)

export const uploadImage = params => axios.post(`/storage/v1/files`, params)

export const requestToIncreaseSystemQuota = params =>
  axios.post(`/quota/v1/me/system`, params)

export const getRequestedQuotas = params =>
  axios.get(`/quota/v1/me/system?${decodeURIComponent(qs.stringify(params))}`)

export const updateQuota = (payload = {}) => {
  const { autoAdjustment, quota } = payload
  const uri = `/quota/v1/me`
  return axios.put(uri, omitBy({ autoAdjustment, quota }, isUndefined))
}
