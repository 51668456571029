import { Map } from 'immutable'
import { createRedux } from 'lib/requestHandler'
import {
  isObject,
  isFunction,
  isNumber,
  isArray,
  isEmptyString
} from 'lib/detectType'
import uniqid from 'uniqid'
import _ from 'lodash'
import uniq from 'lodash/uniq'
import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'

const initialState = Map({
  hash: {},
  data: {},
  columns: {},
  options: {},
  checked: {},
  updateFlag: {},
  initFlag: {},
  focusRow: {},
  groupByContainers: {}
})

const handler = {
  INIT: {
    reducer: (state, action) => {
      const {
        key,
        options = {},
        columns = {},
        data: payload = [],
        originData: originTableData
      } = action.payload
      const originData = state.get('originData')
      const data = state.get('data')
      const originInitFlag = state.get('initFlag')
      const originUpdateFlag = state.get('updateFlag')
      const originColumns = state.get('columns')
      const originOptions = state.get('options')
      const originChecked = state.get('checked')
      const originHash = state.get('hash')
      const originFocusRow = state.get('focusRow')
      const newHash = uniqid()
      const { formatDataCallback, checkbox: { checked: checkedFunc } = {} } =
        options
      const params = isFunction(formatDataCallback)
        ? formatDataCallback(_.cloneDeep(payload))
        : payload
      const defaultOptions = {
        page: 0,
        rowsPerPage: 20,
        // 추가로 필요한 데이터가 있는지 여부
        existsMoreDataFlag: true
      }
      const checked = Object.assign(
        {},
        ...(isFunction(checkedFunc)
          ? params
              .slice(0, options.rowsPerPage || defaultOptions.rowsPerPage)
              .map((item, index) => {
                return checkedFunc(item, index) ? { [index]: item } : false
              })
              .filter(d => d)
          : [])
      )
      const columnKeys = Object.keys(columns)
      // 초기화 된 컨테이너 추가
      const executeBy = action?.meta?.containerOptions?.executeBy
      const groupByContainers = {}
      const originGroupByContainers = state.get('groupByContainers')
      const tableDataKeys = !isEmptyString(executeBy)
        ? uniq((originGroupByContainers?.[executeBy] || []).concat(key))
        : []
      Object.assign(groupByContainers, originGroupByContainers, {
        [executeBy]: tableDataKeys
      })
      const resultOriginData = (originTableData || payload).map(data => {
        return { ...data, uniqRowId: uniqid() }
      })
      const resultData = params.map((data, index) => {
        return {
          ..._.pick(data, columnKeys),
          uniqRowId: resultOriginData[index]?.uniqRowId
        }
      })
      return state
        .set('initFlag', Object.assign({}, originInitFlag, { [key]: true }))
        .set('hash', Object.assign({}, originHash, { [key]: newHash }))
        .set('updateFlag', Object.assign({}, originUpdateFlag, { [key]: true }))
        .set(
          'originData',
          Object.assign({}, originData, { [key]: resultOriginData })
        )
        .set('checked', Object.assign({}, originChecked, { [key]: checked }))
        .set(
          'data',
          Object.assign({}, data, {
            [key]: resultData
          })
        )
        .set('columns', Object.assign({}, originColumns, { [key]: columns }))
        .set(
          'options',
          Object.assign({}, originOptions, {
            [key]: Object.assign(defaultOptions, options)
          })
        )
        .set('focusRow', _.omit(originFocusRow, [key]))
        .set('groupByContainers', groupByContainers)
    }
  },
  SET_OPTIONS: {
    reducer: (state, action) => {
      const { key, options = {} } = action.payload
      const originOptions = state.get('options')
      return state.set(
        'options',
        Object.assign({}, originOptions, {
          [key]: Object.assign({}, originOptions[key] || {}, options)
        })
      )
    }
  },
  SET: {
    reducer: (state, action) => {
      const { key, updateFlag = false, data: payloadData = [] } = action.payload
      // 새롭게 렌더링 해야하는 경우 updateFlag 를 true로 사용합니다.
      const originUpdateFlag = state.get('updateFlag')
      const originData = state.get('originData')
      const originOptions = state.get('options')
      const data = state.get('data')
      const originColumns = state.get('columns')
      const originChecked = state.get('checked')
      const columnKeys = Object.keys(originColumns[key] || {})
      const { formatDataCallback } = originOptions[key] || {}
      const params = isFunction(formatDataCallback)
        ? formatDataCallback(_.cloneDeep(payloadData))
        : payloadData
      const resultOriginData = payloadData.map(data => ({
        ...data,
        uniqRowIds: uniqid()
      }))
      const resultData = params.map(obj => {
        return { ..._.pick(obj, columnKeys), uniqRowId: uniqid() }
      })
      return state
        .set(
          'updateFlag',
          Object.assign({}, originUpdateFlag, { [key]: updateFlag })
        )
        .set(
          'originData',
          Object.assign({}, originData, { [key]: resultOriginData })
        )
        .set('data', Object.assign({}, data, { [key]: resultData }))
        .set('checked', Object.assign({}, originChecked, { [key]: {} }))
    }
  },
  ADD: {
    reducer: (state, action) => {
      const {
        key,
        limit,
        data: payload = [],
        originData: originTableData,
        hash
      } = action.payload
      const originHash = state.get('hash')
      const currentHash = originHash[key]
      // 만약 작업 진행 도중 init을 다시 하는경우 새로운 데이터를 테이블에 붙여넣지 않습니다.
      if (hash !== undefined && hash !== currentHash) return state
      const originData = state.get('originData')
      const data = state.get('data')
      const originColumns = state.get('columns')
      const originOptions = state.get('options')
      const { formatDataCallback } = originOptions[key] || {}
      const params = isFunction(formatDataCallback)
        ? formatDataCallback(_.cloneDeep(payload))
        : payload
      let existsMoreDataFlag = true
      // 추가되어진 데이터 수가 요청한 기대 데이터 수보다 적은경우 더 이상 서버에 데이터가 없다고 간주함
      if (isNumber(limit) && limit > params.length) existsMoreDataFlag = false
      const columnKeys = Object.keys(originColumns[key] || {})
      const uniqRowIds = new Array(params.length).fill('').map(() => uniqid())
      const resultOriginData = [].concat(
        originData[key],
        (originTableData || payload).map((data, index) => {
          return { ...data, uniqRowId: uniqRowIds[index] }
        })
      )
      const resultData = [].concat(
        data[key],
        params.map((data, index) => {
          return {
            ..._.pick(data, columnKeys),
            uniqRowId: uniqRowIds[index]
          }
        })
      )
      return state
        .set(
          'originData',
          Object.assign({}, originData, {
            [key]: resultOriginData
          })
        )
        .set(
          'data',
          Object.assign({}, data, {
            [key]: resultData
          })
        )
        .set(
          'options',
          Object.assign({}, originOptions, {
            [key]: Object.assign({}, originOptions[key] || {}, {
              existsMoreDataFlag
            })
          })
        )
    }
  },
  TOGGLE_CHECKBOX_VISIBILITY: {
    reducer: (state, action) => {
      const { key } = action.payload
      const originOptions = state.get('options')
      const originChecked = state.get('checked')
      const options = Object.assign({}, originOptions[key] || {})
      const { checkbox: { visibility = true } = {} } = options
      const _newOptions = _.merge({}, options, {
        checkbox: { visibility: !visibility }
      })
      return state
        .set(
          'checked',
          Object.assign({}, originChecked, {
            [key]: {}
          })
        )
        .set(
          'options',
          Object.assign({}, originOptions, {
            [key]: _newOptions
          })
        )
    }
  },
  CHECK_ALL: {
    reducer: (state, action) => {
      const { key, checked: flag, data = [] } = action.payload
      const originOptions = state.get('options')
      const originChecked = state.get('checked')
      const options = originOptions[key] || {}
      const { rowsPerPage, checkbox: { disabled: disabledFunc } = {} } = options
      const checked = _.omitBy(
        Object.assign(
          {},
          ...Array.apply(null, { length: rowsPerPage }).map((d, index) => {
            const disabled =
              flag &&
              isFunction(disabledFunc) &&
              disabledFunc(data[index], index)
            return {
              [index]: flag && !disabled ? data[index] : false
            }
          })
        ),
        d => {
          return !d
        }
      )
      return state.set(
        'checked',
        Object.assign({}, originChecked, {
          [key]: checked
        })
      )
    }
  },
  TOGGLE_CHECK: {
    reducer: (state, action) => {
      const { key, index, data } = action.payload
      const originChecked = state.get('checked')
      const originOptions = state.get('options')
      const checked = originChecked[key] || {}
      const options = originOptions[key] || {}
      const { checkbox: { disabled } = {} } = options
      const disabledFlag = isFunction(disabled) && disabled(data, index)
      const checkFlag = disabledFlag ? false : checked[index] ? false : data
      const _new = _.omitBy(
        Object.assign({}, checked, { [index]: checkFlag }),
        d => {
          return !d
        }
      )
      return state.set(
        'checked',
        Object.assign({}, originChecked, {
          [key]: _new
        })
      )
    }
  }
}

handler.uncheck = {
  reducer: (state, action) => {
    const { key = [], checkedIndex = [] } = action.payload
    const keys = isArray(key) ? key : [key]
    const originChecked = state.get('checked') || {}
    const newChecked = {}
    if (checkedIndex.length > 0) {
      Object.assign(
        newChecked,
        _.omitBy(originChecked[key], (data, index) => {
          return checkedIndex.indexOf(index) !== -1
        })
      )
    }
    return state.set(
      'checked',
      Object.assign({}, originChecked, ...keys.map(k => ({ [k]: newChecked })))
    )
  }
}

handler.checkAllToggle = {
  reducer: (state, action) => {
    const { key, data: currentPageData = {} } = action.payload
    const originOptions = state.get('options')
    const options = originOptions[key] || {}
    const {
      page = 0,
      rowsPerPage = 20,
      checkbox: { disabled: disabledFunc } = {}
    } = options
    const originChecked = state.get('checked')
    const checked = _.cloneDeep(originChecked[key]) || {}
    const newChecked = Object.assign(
      {},
      ...Object.keys(currentPageData).map(index => {
        if (isFunction(disabledFunc) && disabledFunc(currentPageData, index)) {
          return null
        }
        const absIndex = page * rowsPerPage + Number(index)
        return { [absIndex]: currentPageData[index] }
      })
    )
    const inter = _.intersection(Object.keys(checked), Object.keys(newChecked))
    const flag = inter.length !== Object.keys(currentPageData).length
    return state.set(
      'checked',
      Object.assign({}, originChecked, {
        [key]: _.omit(Object.assign({}, checked, newChecked), flag ? [] : inter)
      })
    )
  }
}

handler.rowFocus = {
  reducer: (state, action) => {
    const { key, index: absIndex, findIndexCallback } = action.payload
    const focusRow = state.get('focusRow')
    if (isNumber(absIndex) && absIndex >= 0) {
      return state.set(
        'focusRow',
        Object.assign({}, focusRow, { [key]: absIndex })
      )
    }
    if (isFunction(findIndexCallback)) {
      const originData = state.get('originData')
      const rowIndex = findIndex(originData?.[key] || [], findIndexCallback)
      if (rowIndex === -1) return state
      return state.set(
        'focusRow',
        Object.assign({}, focusRow, { [key]: rowIndex })
      )
    }
    return state
  }
}

handler.remove = {
  reducer: (state, action) => {
    const { key, findIndexCallback } = action.payload
    const data = state.get('data')
    const originData = state.get('originData')
    const newData = _.cloneDeep(data[key] || [])
    const newOriginData = _.cloneDeep(originData[key] || [])
    const index = _.findIndex(newOriginData, findIndexCallback)
    if (index === -1) return state
    newData.splice(index, 1)
    newOriginData.splice(index, 1)
    return state
      .set('data', Object.assign({}, data, { [key]: newData }))
      .set(
        'originData',
        Object.assign({}, originData, { [key]: newOriginData })
      )
  }
}

handler.replace = {
  reducer: (state, action) => {
    const {
      key,
      findIndexCallback,
      data: payloadData,
      originData: payloadOriginData
    } = action.payload
    const data = state.get('data') || {}
    const originData = state.get('originData') || {}
    const originOptions = state.get('options') || {}
    const { formatDataCallback } = originOptions[key] || {}
    const params = isFunction(formatDataCallback)
      ? formatDataCallback([_.cloneDeep(payloadData)])[0]
      : payloadData
    const newData = _.cloneDeep(data[key] || [])
    const newOriginData = _.cloneDeep(originData[key] || [])
    const index = _.findIndex(newOriginData, findIndexCallback)
    if (index === -1) return state
    const uniqRowId = newOriginData[index]?.uniqRowId
    newData[index] = { ...params, uniqRowId }
    newOriginData[index] = { ...(payloadOriginData || payloadData), uniqRowId }
    return state
      .set('data', Object.assign({}, data, { [key]: newData }))
      .set(
        'originData',
        Object.assign({}, originData, { [key]: newOriginData })
      )
  }
}

// 특정 행 데이터의 일부만 변경
handler.replaceItem = {
  reducer: (state, action) => {
    const { key = 'public', index, item } = action.payload
    if ((!isNumber(index) && !isFunction(index)) || !isObject(item)) {
      return state
    }

    const originRawData = state.get('originData') || {}
    if (!isArray(originRawData[key])) return state
    const rowIndex = isFunction(index)
      ? _.findIndex(originRawData[key], index)
      : index
    const originRawItem = originRawData[key][rowIndex]

    if (rowIndex === -1 || !isObject(originRawItem)) return state
    const newRawItem = { ...originRawItem, ...item }

    const options = state.get('options') || {}
    const formatDataCallback = options[key]?.formatDataCallback

    const data = state.get('data') || {}
    const newData = _.cloneDeep(data[key] || [])
    const newRawData = _.cloneDeep(originRawData[key] || [])
    const uniqRowId = newData?.[rowIndex]?.uniqRowId
    newData[rowIndex] = {
      ...(isFunction(formatDataCallback)
        ? formatDataCallback([_.cloneDeep(newRawItem)])[0]
        : newRawItem),
      uniqRowId
    }
    newRawData[rowIndex] = { ...newRawItem, uniqRowId }
    return state
      .set('data', Object.assign({}, data, { [key]: newData }))
      .set(
        'originData',
        Object.assign({}, originRawData, { [key]: newRawData })
      )
  }
}

const pushAction =
  (actionType = 'push') =>
  (state, action) => {
    const { key, data = [] } = action.payload
    const originData = state.get('data')
    const originRawData = state.get('originData')
    const options = state.get('options') || {}
    const { formatDataCallback } = options[key] || {}
    const newData = (
      isFunction(formatDataCallback)
        ? formatDataCallback(
            isArray(data) ? _.cloneDeep(data) : [_.cloneDeep(data)]
          )
        : isArray(data)
        ? _.cloneDeep(data)
        : [_.cloneDeep(data)]
    ).map(data => ({ ...data, uniqRowId: uniqid() }))
    const resultOriginData =
      actionType === 'push'
        ? originRawData[key].concat(newData)
        : newData.concat(originRawData[key])
    const resultData =
      actionType === 'push'
        ? originData[key].concat(newData)
        : newData.concat(originData[key])
    return state
      .set(
        'originData',
        Object.assign({}, originRawData, {
          [key]: resultOriginData
        })
      )
      .set(
        'data',
        Object.assign({}, originData, {
          [key]: resultData
        })
      )
  }

// 단순 행 추가
handler.push = { reducer: pushAction() }
handler.unshift = { reducer: pushAction('unshift') }

// 데이터 청소
handler.clear = {
  reducer: (state, action) => {
    const key = action.payload?.key
    const cleanDataFields = [
      'initFlag',
      'hash',
      'updateFlag',
      'originData',
      'checked',
      'data',
      'columns',
      'options',
      'focusRow'
    ]
    if (isEmptyString(key)) {
      const newState = Object.assign(
        {},
        ...cleanDataFields.map(key => ({ [key]: {} }))
      )
      return state.merge(newState)
    }
    const keys = isArray(key) ? key : [key]
    const newState = {}
    keys.forEach(key => {
      Object.assign(
        newState,
        ...cleanDataFields.map(fieldName => {
          const dataObj = cloneDeep(state.get(fieldName) || {})
          delete dataObj?.[key]
          return { [fieldName]: dataObj || {} }
        })
      )
    })
    return state.merge(newState)
  }
}
handler.clearByCurrentContainer = {
  reducer: (state, action) => {
    const executeBy = action?.meta?.containerOptions?.executeBy
    if (isEmptyString(executeBy)) return state
    const originGroupByContainers = state.get('groupByContainers')
    const keys = originGroupByContainers?.[executeBy] || []
    const cleanDataFields = [
      'initFlag',
      'hash',
      'updateFlag',
      'originData',
      'checked',
      'data',
      'columns',
      'options',
      'focusRow'
    ]
    const newState = {}
    keys.forEach(key => {
      Object.assign(
        newState,
        ...cleanDataFields.map(fieldName => {
          const dataObj = cloneDeep(state.get(fieldName) || {})
          delete dataObj?.[key]
          return { [fieldName]: dataObj || {} }
        })
      )
    })
    return state.merge(newState)
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
