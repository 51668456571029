import { Map } from 'immutable'
import { withdrawalAdminHistory as withdrawalHistoryAdminAPI } from 'lib/api'
import moment from 'moment-timezone'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  withdrawalAdminHistory: [],
  isRejectDialog: false,
  isCompleteDialog: false,
  isMemoDialog: false,
  rejectMemo: '',
  accountId: '',
  withdrawId: '',
  searchStatus: 'ALL',
  // 기본 1주일 전까지 데이터 가져옴
  searchStartDate: moment()
    .subtract(6, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  searchEndDate: moment()
    .add(1, 'days')
    .format('YYYY-MM-DD HH:mm:ss'),
  nextKey: undefined,
  page: 0,
  count: 20,
  rowsPerPage: 20
})

const handler = {
  OPEN_REJECT_DIALOG: {
    reducer: (state, action) => {
      const { withdrawId, accountId } = action.payload
      return state
        .set('isRejectDialog', true)
        .set('accountId', accountId)
        .set('withdrawId', withdrawId)
    }
  },
  CLOSE_REJECT_DIALOG: {
    reducer: (state, action) => {
      return state.set('isRejectDialog', false).set('rejectMemo', '')
    }
  },
  OPEN_COMPLETE_DIALOG: {
    reducer: (state, action) => {
      const { withdrawId, accountId } = action.payload
      return state
        .set('isCompleteDialog', true)
        .set('accountId', accountId)
        .set('withdrawId', withdrawId)
    }
  },
  CLOSE_COMPLETE_DIALOG: {
    reducer: (state, action) => {
      return state.set('isCompleteDialog', false)
    }
  },
  OPEN_MEMO_DIALOG: {
    reducer: (state, action) => {
      const withdrawalAdminHistory = state.get('withdrawalAdminHistory')
      const result = withdrawalAdminHistory.filter(
        withdraw => withdraw.withdrawId === action.payload
      )[0]
      return state
        .set('isMemoDialog', true)
        .set('rejectMemo', result ? result.memo : '메모가 없습니다.')
    }
  },
  CLOSE_MEMO_DIALOG: {
    reducer: (state, action) => {
      return state.set('isMemoDialog', false)
    }
  },
  CHANGE_REJECT_MEMO: {
    reducer: (state, action) => {
      return state.set('rejectMemo', action.payload)
    }
  },
  HANDLE_CHANGE: {
    reducer: (state, action) => {
      return state.set('searchStatus', action.payload)
    }
  },
  ON_CHANGE_START_DATE: {
    reducer: (state, action) => {
      return state.set('searchStartDate', action.payload)
    }
  },
  ON_CHANGE_END_DATE: {
    reducer: (state, action) => {
      return state.set('searchEndDate', action.payload)
    }
  },
  ON_CHANGE_PAGE: {
    reducer: (state, action) => {
      return state.set('page', action.payload)
    }
  },
  ON_CHANGE_ROWS_PER_PAGE: {
    reducer: (state, action) => {
      return state.set('rowsPerPage', action.payload)
    }
  },
  CLEAR_LIST: {
    reducer: (state, action) => {
      return state
        .set('withdrawalAdminHistory', [])
        .set('nextKey', undefined)
        .set('page', 0)
    }
  },
  GET_WITHDRAWAL_ADMIN_HISTORY: {
    payloadCreator: withdrawalHistoryAdminAPI.getWithdrawalAdminHistory,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { data, nextKey } = action.payload.data
        const withdrawalAdminHistory = state.get('withdrawalAdminHistory')
        const newWithdrawalAdminHistory = withdrawalAdminHistory.concat(data)
        const stateData = {
          withdrawalAdminHistory: newWithdrawalAdminHistory,
          nextKey,
          count: newWithdrawalAdminHistory.length
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  UPDATE_WITHDRAW: {
    payloadCreator: withdrawalHistoryAdminAPI.updateWithdraw,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { status } = action.payload
        if (status === 200) alert('출금 처리가 완료되었습니다.')
        const stateData = {
          isCompleteDialog: false,
          isRejectDialog: false
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
