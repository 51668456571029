import axios from 'axios'

// 현재 axios 라이브러리에 302 redirect response를 받지 못하는 버그가 있습니다.
// 추후 이슈가 해결 되는대로 수정되어야 합니다.
// 참조 : https://github.com/axios/axios/issues/1153
axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (error.response && error.response.data && error.response.data.location) {
      window.location = error.response.data.location
    } else {
      return Promise.reject(error)
    }
  }
)

// get client info by client id
export const getClientInfo = ({ clientId }) =>
  axios.get(`/oauth2/v1/clients/${clientId}`)

// get scope detail info by scope name
export const getScopeInfo = ({ scope }) =>
  axios.get(`/oauth2/v1/scopes?scope=${scope}`)
