import qs from 'querystring'
import get from 'lodash/get'
import merge from 'lodash/merge'
import groupBy from 'lodash/groupBy'
import mapValues from 'lodash/mapValues'
import { isArray, isString, isEmptyString, isEmptyObject } from 'lib/detectType'

const createResult = listFilter => {
  const criterias = []
  const values = []
  const conditions = []
  const filteredList = listFilter.filter(data => {
    const value = !isEmptyString(data?.value) ? data?.value : ''
    return isString(value) ? value.trim() : value
  })
  if (filteredList.length < 1) return {}
  filteredList.forEach(data => {
    let criteria = data?.key
    if (isArray(data?.value)) {
      criteria = [].concat(...data.value)
      values.push(...data?.value)
      conditions.push(...data?.cond)
      criterias.push(...criteria.fill(data?.key))
    } else {
      values.push(data?.value)
      conditions.push(data?.cond)
      criterias.push(criteria)
    }
  })
  return {
    criteria: criterias,
    value: values,
    cond: conditions
  }
}

const setValueToResultObj = (result, criteria, value, cond) => {
  const target = get(result, [criteria, cond])
  if (isArray(target)) {
    result[criteria][cond].push(value)
  } else {
    merge(result, { [criteria]: { [cond]: [value] } })
  }
  return result
}

// 일반적인 api params
const toParams = listFilter => {
  const tmp = {}
  listFilter
    .filter(data => {
      const value = !isEmptyString(data?.value) ? data?.value : ''
      return isString(value) ? value.trim() : value
    })
    .forEach(data => {
      let criteria = data?.key
      if (isArray(data?.value)) {
        data.value.forEach((value, index) => {
          const cond = data?.cond?.[index]
          setValueToResultObj(tmp, criteria, value, cond)
        })
      } else {
        setValueToResultObj(tmp, criteria, data?.value, data?.cond)
      }
    })
  const result = mapValues(tmp, data => {
    return mapValues(data, v => {
      return v.join(',')
    })
  })
  return result
}

// equal only
const toSimpleParams = listFilter => {
  return Object.assign(
    {},
    ...listFilter
      .filter(data => {
        return !isEmptyString(data?.value)
      })
      .map(data => {
        return { [data?.key]: data?.value }
      })
  )
}

// criteria 사용 시 (예: 메시지 목록)
const toResult = listFilter => {
  const result = createResult(listFilter)
  if (isEmptyObject(result)) return {}
  return {
    criteria: result.criteria.join(','),
    value: result.value.join(','),
    cond: result.cond.join(',')
  }
}

const toQuery = listFilter => {
  const result = createResult(listFilter)
  if (isEmptyObject(result)) return ''
  return qs.stringify({
    criteria: result.criteria.join(','),
    value: result.value.join(','),
    cond: result.cond.join(',')
  })
}

// equal only
const toSimpleQuery = listFilter => {}

const queryToFilterValue = queryString => {
  if (!isString(queryString)) return {}
  const query = queryString.replace(/^\?/, '')
  const {
    startDate,
    endDate,
    criteria = '',
    value = '',
    cond = ''
  } = qs.parse(query)
  const criterias = criteria.split(',').filter(d => d)
  const values = value.split(',').filter(d => d)
  const conds = cond.split(',').filter(d => d)
  if (!isEmptyString(startDate) && !isEmptyString(endDate)) {
    criterias.push('dateCreated')
    values.push(startDate)
    conds.push('gte')
    criterias.push('dateCreated')
    values.push(endDate)
    conds.push('lte')
  }
  const result = mapValues(
    groupBy(
      criterias.map((criteria, index) => {
        return {
          criteria,
          value: values?.[index],
          cond: conds?.[index]
        }
      }),
      rawData => {
        return rawData?.criteria
      }
    ),
    value => {
      return {
        value: value.map(v => v?.value),
        cond: value.map(v => v?.cond)
      }
    }
  )
  return result
}

export {
  toParams,
  toSimpleParams,
  toResult,
  toQuery,
  toSimpleQuery,
  queryToFilterValue
}
