import cookie from 'js-cookie'
import jwt from 'jsonwebtoken'
export const getCurrentAccount = () => {
  const token = cookie.get('CSAK')
  const decodedInfo = jwt.decode(token)
  if (!decodedInfo) return null

  const { accountId } = decodedInfo
  return accountId
}
