/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
// import omitBy from 'lodash/omitBy'

// material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'

// my components
import withCompWidth from 'commonHoc/withCompWidth'
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'
// import CommonButton from 'containers/CommonButtonContainer'

// libraries
import config from 'config'
import { isObject, isEmptyString } from 'lib/detectType'
import isEqualProps from 'lib/isEqualProps'

import LogoImg from 'static/image/logo.png'
import CoolsmsLogoImg from 'static/image/coolsms.png'

const styles = theme => ({
  logoImg: {
    width: '100%',
    maxWidth: 230
  },
  mySiteLogoImg: {
    width: '100%',
    maxWidth: 200
  },
  emptyLogoBox: {
    width: '100%',
    maxWidth: 200,
    maxHeight: 200,
    borderRadius: 10
  },
  logoAnchor: {
    display: 'block'
  },
  sloganTitle: {
    color: '#7e7e7e',
    wordBreak: 'keep-all'
  },
  titleText: {
    wordBreak: 'keep-all',
    fontSize: '1.3em'
  }
})
export const useProps = {}
/**
 * 로그인, 회원가입, 비번찾기 등 공통 로고 사용부
 */
const FormHeaderLogo = React.memo(
  ({ classes, proxy, ...props }) => {
    const { title, slogan: propSlogan } = props
    const slogan = config?.isCoolsms
      ? propSlogan === false
        ? ''
        : '세상에서 가장 안정적이고 빠른 문자메시지'
      : propSlogan === false
      ? ''
      : '모든 고객이 통하는 마케팅 메시지'
    const ahid = jwt.decode(cookies.get('AHID'))
    const isMysite = isObject(ahid) && !isEmptyString(ahid?.logoUrl)
    return (
      <Box className={classes.root} mt={2} mb={3}>
        <Grid container justifyContent="center" spacing={1}>
          <Grid item xs="auto">
            <a href={config.homePath} className={classes.logoAnchor}>
              <img
                className={isMysite ? classes.mySiteLogoImg : classes.logoImg}
                src={
                  isMysite
                    ? ahid?.logoUrl
                    : config.isCoolsms
                    ? CoolsmsLogoImg
                    : LogoImg
                }
                alt="logo"
                title="logo"
              />
            </a>
          </Grid>
          {!isEmptyString(slogan) && !isMysite && (
            <Grid item xs={12}>
              <Typography
                className={classes.sloganTitle}
                align="center"
                color="textSecondary"
                variant="caption"
                display="block"
              >
                {slogan}
              </Typography>
            </Grid>
          )}
          {!isEmptyString(title) && (
            <Grid item xs={12}>
              <Typography
                className={classes.titleText}
                align="center"
                color="textSecondary"
              >
                <strong>{title}</strong>
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    return isEqualProps(nextProps, prevProps)
  }
)

FormHeaderLogo.defaultProps = setDefaultProps(useProps)
FormHeaderLogo.propTypes = setPropTypes(useProps)
export default withStyles(styles)(withCompWidth(FormHeaderLogo))
