/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
// import omitBy from 'lodash/omitBy'

// material UI
import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'
import { FcVlc as FcVlcIcon } from 'react-icons/fc'

// my components
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'
// import CommonButton from 'containers/CommonButtonContainer'

// libraries
// import config from 'config'
// import { isArray } from 'lib/detectType'

const styles = theme => ({
  root: {
    border: `1px solid ${theme.palette.error.main}`,
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer'
  },
  icon: {
    fontSize: '1.3em',
    verticalAlign: 'bottom',
    marginRight: 4
  }
})
export const useProps = {}
/**
 * 컴포넌트 내부 오류
 */
const Error = React.memo(
  ({ classes }) => {
    return (
      <Box
        p={1}
        className={classes.root}
        onClick={e => {
          e.preventDefault()
          if (
            window.confirm(
              '페이지를 새로고침 합니다. 같은 문제가 지속되면 고객센터에 문의해주세요!'
            )
          ) {
            window.location.reload()
          }
        }}
      >
        <Typography variant="body2" align="center">
          <FcVlcIcon className={classes.icon} />
          오류
        </Typography>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    return true
  }
)

Error.defaultProps = setDefaultProps(useProps)
Error.propTypes = setPropTypes(useProps)
export default withStyles(styles)(Error)
