import mapValues from 'lodash/mapValues'
import escapeStringRegexp from 'escape-string-regexp'

import { isEmptyString } from 'lib/detectType'

export const replaceUrlFromText = (text, matchedUrlArr) => {
  if (isEmptyString(text)) return ''
  matchedUrlArr.forEach(campaign => {
    const url = campaign?.url
    const regexp = new RegExp(escapeStringRegexp(url), 'g')
    const keyword = `__{{ShortURL:CampaignId=${campaign?.campaignId}&URLIndex=${campaign?.urlIndex}}}__`
    text = text.replace(regexp, keyword)
  })
  return text
}

export const replaceUrlFromVariables = (variableValues, matchedUrlArr) => {
  const newVariableValues = mapValues(variableValues, recipientGroup => {
    return mapValues(recipientGroup, variables => {
      return variables.map(variable => {
        return {
          value: replaceUrlFromText(variable?.value, matchedUrlArr)
        }
      })
    })
  })
  return newVariableValues
}
export default {
  replaceUrlFromText,
  replaceUrlFromVariables
}
