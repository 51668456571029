import classNames from 'classnames'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import sortBy from 'lodash/sortBy'
import last from 'lodash/last'
import isUndefined from 'lodash/isUndefined'
import nurigoTheme from 'themes/nurigo-material'
import { isEmptyObject, isObject } from 'lib/detectType'

export const getComponentByBreakpoint = (breakpoint, components) => {
  if (!isObject(components) || isEmptyObject(components)) {
    return null
  }
  if (!breakpoint) {
    return Object.values(components)[0]
  }
  const { values = {} } = nurigoTheme.breakpoints
  // 기본값
  const sortedDefaultBreakpoint = sortBy(
    Object.keys(values).map(key => {
      return { breakpoint: key, value: values[key] }
    }),
    ['value']
  ).map(data => {
    return data?.breakpoint
  })
  const sortedBreakpoint = sortBy(
    Object.keys(components).map(key => {
      return { breakpoint: key, value: values[key] }
    }),
    ['value']
  ).map(data => {
    return data?.breakpoint
  })
  const sortedBreakpointIndex = sortedBreakpoint.map(data => {
    return sortedDefaultBreakpoint.indexOf(data)
  })
  // 현재 컴포넌트의 Breakpoint Index
  const currentBreakpointIndex = sortedDefaultBreakpoint.indexOf(breakpoint)
  // 입력된 컴포넌트 중 최소
  const minIndex = sortedBreakpointIndex[0]
  const nearIndex =
    sortedBreakpoint.indexOf(breakpoint) === -1 ||
    isUndefined(components?.[breakpoint])
      ? last(
          sortedBreakpointIndex.filter(index => index < currentBreakpointIndex)
        )
      : sortedBreakpoint.indexOf(breakpoint)
  const componentBreakpoint = isUndefined(components[breakpoint])
    ? isUndefined(sortedDefaultBreakpoint[nearIndex])
      ? sortedDefaultBreakpoint[minIndex]
      : sortedDefaultBreakpoint[nearIndex]
    : sortedDefaultBreakpoint[currentBreakpointIndex]
  return isUndefined(components[componentBreakpoint])
    ? Object.values(components)[0]
    : components[componentBreakpoint]
}

export const makeStyleObject = (className, breakpoint, jssObject = {}) => {
  const { values = {} } = nurigoTheme.breakpoints
  const currentWidth = values[breakpoint] || 0
  return Object.assign(
    {},
    ...Object.keys(pickBy(values, value => value >= currentWidth)).map(key => ({
      [className + key]: jssObject
    }))
  )
}

export const getClassName = (classes, className, componentBreakpoint) => {
  const { values = {} } = nurigoTheme.breakpoints
  const currentWidth = values[componentBreakpoint] || 0
  return classNames(
    Object.values(
      pick(classes, [
        className,
        ...Object.keys(pickBy(values, value => value <= currentWidth)).map(
          key => className + key
        )
      ])
    )
  )
}

export default nurigoTheme.breakpoints
