import { Map } from 'immutable'
import * as api from 'lib/api/account'
import { getCurrentAccount } from 'lib/utils'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  switchPopup: false,
  selectedAccount: null,
  allAccountList: [],
  accountList: [],
  nextKey: undefined,
  count: 0,
  page: 0,
  rowsPerPage: 20
})

const handler = {
  ON_CHANGE_PAGE: {
    reducer: (state, action) => {
      return state.set('page', action.payload)
    }
  },
  ON_CHANGE_ROWS_PER_PAGE: {
    reducer: (state, action) => {
      return state.set('rowsPerPage', action.payload)
    }
  },
  SET_ACCOUNT_LIST: {
    reducer: (state, action) => {
      const page = state.get('page')
      const limit = state.get('rowsPerPage')
      const allAccountList = state.get('allAccountList')

      const firstItem = page * limit
      let lastItem = (page + 1) * limit
      if (lastItem > allAccountList.length) {
        lastItem = allAccountList.length
      }

      const accountList = allAccountList.slice(firstItem, lastItem)
      return state.set('accountList', accountList)
    }
  },
  GET_ACCOUNT_LIST: {
    payloadCreator: api.getAccountList,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const {
          data: { accountList, nextKey },
          loadNew
        } = action.payload
        if (loadNew) state = state.set('allAccountList', [])

        const allAccountList = state
          .get('allAccountList')
          .concat(accountList)
          .filter(d => d)
        const stateData = {
          allAccountList,
          nextKey,
          count: allAccountList.length
        }
        if (loadNew) stateData.page = 0
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  CHANGE_SELECTED_ACCOUNT: {
    payloadCreator: api.changeSelectedAccount,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { selectedAccount: getCurrentAccount() }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  TOGGLE_POPUP: {
    reducer: (state, action) => {
      return state
        .set('switchPopup', !state.get('switchPopup'))
        .set('popupAccountId', action.payload)
    }
  },
  GET_SELECTED_ACCOUNT: {
    reducer: (state, action) => {
      const selectedAccount = getCurrentAccount()
      return state.set('selectedAccount', selectedAccount)
    }
  },
  CREATE_ACCOUNT: {
    payloadCreator: api.createAccount,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { alertMessage: '계정이 성공적으로 생성되었습니다.' }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  ACTIVE_ACCOUNT: {
    payloadCreator: api.activeAccount,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = {
          alertMessage: '계정이 성공적으로 활성화 되었습니다.'
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
