import axios from 'axios'
import qs from 'qs'

export const registBrand = params => {
  const { businessNumber } = params
  return axios.post(`/rbc/v1/brands`, { businessNumber })
}

export const getBrandList = params => {
  const query = qs.stringify(params)
  return axios.get(`/rbc/v1/brands${query ? '?' + query : ''}`)
}

export const deleteBrand = params => {
  const { brandId } = params
  return axios.delete(`/rbc/v1/brands/${brandId}`)
}

export const getFormList = params => {
  const query = qs.stringify(params)
  return axios.get(`/rbc/v1/templates/forms${query ? '?' + query : ''}`)
}

export const getTplFormDetail = params => {
  const { templateFormId } = params
  return axios.get(`/rbc/v1/templates/forms/${templateFormId}`)
}

export const getTemplate = params => {
  const { templateId } = params
  return axios.get(`/rbc/v1/templates/${templateId}`)
}

export const getTemplateList = params => {
  const query = qs.stringify(params)
  return axios.get(`/rbc/v1/templates${query ? '?' + query : ''}`)
}

export const createTemplate = params => {
  return axios.post(`/rbc/v1/templates`, params)
}

export const updateTemplate = params => {
  const { templateId, ...reqBody } = params
  return axios.put(`/rbc/v1/templates/${templateId}`, reqBody)
}

export const deleteTemplate = params => {
  const { templateId } = params
  return axios.delete(`/rbc/v1/templates/${templateId}`)
}

export const syncTemplate = params => {
  const { brandId } = params
  return axios.put(`/rbc/v1/templates/sync`, { brandId })
}
