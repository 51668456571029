import { Map, fromJS } from 'immutable'
import { appsMe as api } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  selectType: 'NONE',
  searchText: '',
  detail: Map({}),
  appList: [],
  appSearchList: [],
  page: 0,
  rowsPerPage: 10,
  isProcessing: false,
  isOpenRegister: false,
  appName: '',
  message: '',
  messageVariant: '',
  registerError: false,
  tooltipTitle: ''
})

function getAppSearchList(appList, selectType, searchText) {
  return appList.filter(item => {
    if (selectType === 'NONE') {
      if (searchText === '') return item
      else return item.appName.includes(searchText)
    } else {
      if (searchText === '' && item.status === selectType) return item
      else { return item.appName.includes(searchText) && item.status === selectType }
    }
  })
}

const handler = {
  SET_PROCESSING: {
    reducer: (state, action) => {
      return state.set('isProcessing', true)
    }
  },
  CHANGE_SELECTOR: {
    reducer: (state, action) => {
      const selectType = action.payload
      const appSearchList = getAppSearchList(
        state.get('appList'),
        selectType,
        state.get('searchText')
      )
      return state
        .set('selectType', selectType)
        .set('appSearchList', appSearchList)
    }
  },
  CHANGE_SEARCH_TEXT: {
    reducer: (state, action) => {
      const searchText = action.payload.trim()
      const appSearchList = getAppSearchList(
        state.get('appList'),
        state.get('selectType'),
        searchText
      )
      return state
        .set('searchText', searchText)
        .set('appSearchList', appSearchList)
    }
  },
  ON_CHANGE_PAGE: {
    reducer: (state, action) => {
      return state.set('page', action.payload)
    }
  },
  ON_CHANGE_ROWS_PER_PAGE: {
    reducer: (state, action) => {
      return state.set('rowsPerPage', action.payload)
    }
  },
  OPEN_REGISTER_APP_MODAL: {
    reducer: state => {
      return state
        .set('isOpenRegister', true)
        .set('appName', '')
        .set('registerError', false)
    }
  },
  CLOSE_REGISTER_APP_MODAL: {
    reducer: state => {
      return state.set('isOpenRegister', false)
    }
  },
  ON_CHANGE_APP_NAME: {
    reducer: (state, action) => {
      return state.set('appName', action.payload)
    }
  },
  HANDLE_MESSAGE_CLOSE: {
    reducer: state => {
      return state.set('message', '')
    }
  },
  INVALID_APP_NAME: {
    reducer: state => {
      return state.set('registerError', true)
    }
  },
  GET_APP_LIST: {
    payloadCreator: api.getAppList,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = {
          appList: action.payload,
          appSearchList: action.payload
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  CREATE_APP: {
    payloadCreator: api.createApp,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = {
          detail: fromJS(action.payload),
          isOpenRegister: false,
          isProcessing: false
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
