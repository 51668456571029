import axios from 'axios'
import qs from 'qs'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const readOutgoingData = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/webhook/v1/outgoing${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const createOutgoingData = (params = {}) => {
  const uri = `/webhook/v1/outgoing`
  return axios.post(uri, omitBy(params, isUndefined))
}

export const updateOutgoingData = (params = {}) => {
  const { webhookId, ...body } = params
  const uri = `/webhook/v1/outgoing/${webhookId}`
  return axios.put(uri, omitBy(body, isUndefined))
}

export const deleteOutgoingData = (params = {}) => {
  const { webhookId } = params
  const uri = `/webhook/v1/outgoing/${webhookId}`
  return axios.delete(uri)
}

export const readEvents = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/webhook/v1/events${query ? '?' + query : ''}`
  return axios.get(uri)
}
