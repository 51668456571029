import { Map } from 'immutable'
import * as pricingAPI from 'lib/api/pricing'
import { requestHandler } from 'lib'
const { createRedux } = requestHandler
const initialState = Map({
  recipients: {}
})

const handler = {}

handler.getPricing = {
  payloadCreator: pricingAPI.getPricing
}

handler.getUnitPriceInfo = {
  payloadCreator: pricingAPI.getUnitPriceInfo
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)

export const actions = reduxActions
export default reduxReducers
