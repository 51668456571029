import Joi from 'joi-browser'
import Regex from './regex'

export const validate = payload => {
  const schema = Joi.object().keys({
    email: Joi.string()
      .email()
      .error(new Error('잘못된 이메일 형식입니다.')),
    password: Joi.string()
      .regex(Regex.PW_REGEX)
      .error(
        new Error(
          '비밀번호는 영문자, 숫자, 특수문자를 포함하는 6 ~ 16자 여야만 합니다.'
        )
      ),
    name: Joi.string()
      .regex(Regex.NM_REGEX)
      .error(new Error('이름은 2 ~ 20자 여야만 하며 한글 혹은 영어 대소문자만 가능합니다.')),
    status: Joi.string().valid('ACTIVE', 'INACTIVE', 'DELETED'),
    phoneNumber: Joi.string().regex(/^\d{9,11}$/),
    recheckPassword: Joi.string(),
    originPassword: Joi.string()
  })

  const { error } = Joi.validate(payload, schema)
  const errorMessage = error ? error.message : null
  return errorMessage
}
