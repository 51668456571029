import axios from 'axios'
import qs from 'qs'
import { omitBy, isEmpty, mapValues } from 'lodash'
import {
  isEmptyObject,
  isObject,
  isString,
  isEmptyString
} from 'lib/detectType'

export const getFolders = params => {
  const queryString = qs.stringify(params)
  const uri = `/contacts/v1/folders${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const getPeople = params => {
  const { folderId, startKey, limit, ...searchQuery } = params
  const searchQueryString = mapValues(
    omitBy(searchQuery, isEmpty),
    (value, key) => {
      return { like: value }
    }
  )
  const queryString = qs.stringify(
    Object.assign({ startKey, limit }, searchQueryString)
  )
  const uri = `/contacts/v1/folders/${folderId}/people${
    queryString ? '?' + queryString : ''
  }`
  return axios.get(uri)
}

export const createFolder = params => {
  const uri = '/contacts/v1/folders'
  return axios.post(uri, params)
}

export const updateFolder = params => {
  const uri = `/contacts/v1/folders/${params.folderId}`
  return axios.put(uri, params)
}

export const createPeople = params => {
  const { folderId, ...reqParams } = params
  const uri = `/contacts/v1/folders/${folderId}/people`
  return axios.post(uri, reqParams)
}

export const updatePeople = params => {
  const { folderId, ...reqParams } = params
  const uri = `/contacts/v1/folders/${folderId}/people`
  return axios.put(uri, reqParams)
}

export const deletePeople = params => {
  const { folderId, ...reqParams } = params
  const uri = `/contacts/v1/folders/${folderId}/people`
  return axios.delete(uri, { data: reqParams })
}

export const deleteFolder = params => {
  const { folders } = params
  const uri = `/contacts/v1/folders`
  return axios.delete(uri, { data: { folders } })
}

export const excelDownload = params => {
  const { folderId } = params
  const uri = `/contacts/v1/folders/${folderId}/excel`
  return axios.get(uri, { responseType: 'blob' })
}

export const prepare = params => {
  const uri = `/contacts/v1/prepare`
  return axios.post(uri, params)
}

export const getPrepare = params => {
  const { groupId } = params
  const uri = `/contacts/v1/prepare/${groupId}`
  return axios.get(uri)
}

export const stopPrepare = params => {
  const { groupId } = params
  const uri = `/contacts/v1/prepare/${groupId}/stop`
  return axios.put(uri, {})
}

export const createPreset = params => {
  const uri = `/contacts/v1/presets`
  return axios.post(uri, params)
}

// 임시 저장
export const getPresets = (
  params = { permanent: false, limit: 20 },
  options = {}
) => {
  const { cache = false } = options
  const queryString = qs.stringify(params)
  const uri = `/contacts/v1/presets${queryString ? '?' + queryString : ''}`
  return axios.get(uri, { cache })
}

export const getPermanentPresets = (
  params = { permanent: true, limit: 100 },
  options = {}
) => {
  const { cache = false } = options
  const queryString = qs.stringify(params)
  const uri = `/contacts/v1/presets${queryString ? '?' + queryString : ''}`
  return axios.get(uri, { cache })
}

export const updatePreset = params => {
  const { presetId, ...reqParams } = params
  const uri = `/contacts/v1/presets/${presetId}`
  return axios.put(uri, reqParams)
}

export const deletePreset = params => {
  const { presetId } = params
  const uri = `/contacts/v1/presets/${presetId}`
  return axios.delete(uri)
}

export const scheduleGroupMessage = params => {
  const { groupId, scheduledDate } = params
  const uri = `/contacts/v1/schedules/groups/${groupId}`
  return axios.post(uri, { scheduledDate })
}

export const cancelScheduledGroup = params => {
  const { groupId } = params
  const uri = `/contacts/v1/schedules/groups/${groupId}`
  return axios.delete(uri)
}

export const cancelScheduledMessage = params => {
  const { messageId } = params
  const uri = `/contacts/v1/schedules/messages/${messageId}`
  return axios.delete(uri)
}

export const getFolderByFolderId = async params => {
  const { targetRecipientGroup: folderId } = params
  if (isObject(folderId) && !isEmptyObject(folderId)) {
    return Promise.resolve()
  }
  if (!isString(folderId) || isEmptyString(folderId)) {
    return Promise.resolve()
  }
  // ignoreDisposable가 true인 경우 모든 폴더 조회
  const {
    data: { folders = [] }
  } = await getFolders({ folderId, ignoreDisposable: true })
  return Promise.resolve({ ...(folders?.[0] || {}), isIndividual: false })
}
