import axios from 'axios'

export const getBankCode = (params, options = {}) => {
  const { cache = false } = options
  const uri = '/exclusive-account/v1/daoupay/bankcode'
  return axios.get(uri, { cache })
}

export const getExclusiveAccount = (params, options = {}) => {
  const { cache = false } = options
  const uri = '/exclusive-account/v1/accounts'
  return axios.get(uri, { cache })
}

export const createExclusiveAccount = params => {
  const { bankCode } = params
  const uri = '/exclusive-account/v1/accounts'
  return axios.post(uri, { bankCode })
}
