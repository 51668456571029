/**
 * @author EdenCha
 * @email eden@nurigo.net
 * @description 엑셀 공용
 */
import { Map } from 'immutable'
import omit from 'lodash/omit'
import { createRedux } from 'lib/requestHandler'

const initialState = Map({
  workbook: {},
  worksheet: {},
  options: {}
})

const handler = {}

handler.init = {
  reducer: (state, action) => {
    const { key, workbook, worksheet, options, buffer } = action.payload
    const originOptions = state.get('options')
    const originWorkbook = state.get('workbook')
    const originWorksheet = state.get('worksheet')
    const originBuffer = state.get('buffer')
    return state
      .set('workbook', Object.assign({}, originWorkbook, { [key]: workbook }))
      .set(
        'worksheet',
        Object.assign({}, originWorksheet, { [key]: worksheet })
      )
      .set('options', Object.assign({}, originOptions, { [key]: options }))
      .set('buffer', Object.assign({}, originBuffer, { [key]: buffer }))
  }
}

handler.set = {
  reducer: (state, action) => {
    const { key, options = {} } = action.payload
    const originOptions = state.get('options')
    const myOptions = originOptions[key]
    const newOptions = Object.assign({}, myOptions, options)
    return state.set(
      'options',
      Object.assign({}, originOptions, { [key]: newOptions })
    )
  }
}

handler.setOption = {
  reducer: (state, action) => {
    const { key, options } = action.payload
    const originOptions = state.get('options')
    const myOptions = originOptions[key]
    const newOptions = Object.assign({}, myOptions, options)
    return state.set(
      'options',
      Object.assign({}, originOptions, { [key]: newOptions })
    )
  }
}

handler.setData = {
  reducer: (state, action) => {
    const { key, data = [] } = action.payload
    const originData = state.get('data')
    return state.set('data', Object.assign({}, originData, { [key]: data }))
  }
}

handler.setBuffer = {
  reducer: (state, action) => {
    const { key, buffer } = action.payload
    const originBuffer = state.get('buffer')
    return state.set(
      'buffer',
      Object.assign({}, originBuffer, { [key]: buffer })
    )
  }
}

handler.clear = {
  reducer: (state, action) => {
    const { key } = action.payload
    const originOptions = state.get('options')
    const originWorkbook = state.get('workbook')
    const originWorksheet = state.get('worksheet')
    const originData = state.get('data')
    const originRawData = state.get('rawData')
    return state
      .set('workbook', omit(originWorkbook, [key]))
      .set('worksheet', omit(originWorksheet, [key]))
      .set('data', omit(originData, [key]))
      .set('options', omit(originOptions, [key]))
      .set('rawData', omit(originRawData, [key]))
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
