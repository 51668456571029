import axios from 'axios'
import qs from 'qs'
import moment from 'moment'

export const getAppData = () => axios.get(`/appstore/v2/me/apps`)
export const fetchData = async ({
  offset,
  limit,
  startDate,
  endDate,
  selectedApp
}) => {
  const query = {
    offset,
    limit,
    startDate,
    endDate
  }
  if (selectedApp !== 'ALL') query.appId = selectedApp
  const url = `/appstore/v2/profits?${qs.stringify(query)}`
  const {
    data: { expectedProfit, completed }
  } = await axios.get(url)
  return {
    dataList: completed.map(({ dateCreated, appId, appName, profit }) => {
      return [
        moment(dateCreated).format('YYYY-MM-DD'),
        `${appName}(${appId})`,
        profit
      ]
    }),
    expectedProfit
  }
}
