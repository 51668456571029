import { diff } from 'deep-object-diff'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

import { isObject } from 'lib/detectType'

const checkExpiredToken = token => {
  const decodedToken = jwt.decode(token)
  if (!isObject(decodedToken)) return false
  const browserTime = new Date()
  const isExpired =
    Number(decodedToken?.exp) < Number(browserTime.getTime()) / 1000
  return isExpired
}
export default (trigger, ref) => {
  var checkCookie = (() => {
    var lastCookie = document.cookie
    var last = cookies.get()
    var prevExpired = false
    return () => {
      var currentCookie = document.cookie
      var current = cookies.get()
      const curExpired = checkExpiredToken(current?.CSAK)
      if (curExpired === true && prevExpired !== curExpired) {
        trigger({ tokenExpired: curExpired })
        prevExpired = curExpired
      }
      if (currentCookie !== lastCookie) {
        trigger({ current, diff: diff(last, current) })
        lastCookie = currentCookie
        last = current
      }
    }
  })()

  if (!ref) {
    ref = setInterval(checkCookie, 500) // run every 500 ms
  }
}
