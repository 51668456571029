import { Map } from 'immutable'
import * as affiliateAPI from 'lib/api/affiliate'
import { requestHandler } from 'lib'
const { createRedux } = requestHandler
const initialState = Map({})
const handler = {}

handler.getAffiliate = {
  payloadCreator: affiliateAPI.getAffiliate
}

handler.getRecommend = {
  payloadCreator: affiliateAPI.getRecommend
}

handler.insertAffiliate = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: affiliateAPI.insertAffiliate
}

handler.insertRecommend = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: affiliateAPI.insertRecommend
}

handler.deleteAffiliate = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: affiliateAPI.deleteAffiliate
}

handler.deleteRecommend = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: affiliateAPI.deleteRecommend
}

handler.getProfit = {
  options: { response: { error: { ignore: true } } },
  payloadCreator: affiliateAPI.getProfit
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
