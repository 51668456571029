import { quota as quotaAPI } from 'lib/api'
import { Map } from 'immutable'
import { createRedux } from 'lib/requestHandler'

const initialState = Map({})

const handler = {
  getEstimateQuota: {
    options: {
      response: {
        error: {
          ignore: [404, 403]
        }
      }
    },
    payloadCreator: quotaAPI.getEstimateQuota
  },
  getRequestedQuotas: {
    payloadCreator: quotaAPI.getRequestedQuotas
  },
  requestToIncreaseSystemQuota: {
    options: { debounce: { wait: 500 } },
    payloadCreator: quotaAPI.requestToIncreaseSystemQuota
  },
  uploadImage: {
    payloadCreator: quotaAPI.uploadImage
  },
  setUserQuota: {
    payloadCreator: quotaAPI.setUserQuota
  },
  updateQuota: {
    options: { debounce: { wait: 500 } },
    payloadCreator: quotaAPI.updateQuota
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
