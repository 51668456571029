import axios from 'axios'
import qs from 'qs'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const yourApiNameHere = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/research/v1/yourPathHear${query ? '?' + query : ''}`
  return axios.get(uri)
}
