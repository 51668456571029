import axios from 'axios'
import qs from 'querystring'
import { omitBy, isUndefined } from 'lodash'

export const getStatistics = searchParams => {
  const uri = `/messages/v4/statistics?${decodeURIComponent(
    qs.stringify(searchParams)
  )}`
  return axios.get(uri)
}

export const getGroupMessages = payload => {
  const { groupId, nextKey: startKey, limit, ...params } = payload
  const queryString = qs.stringify(
    omitBy({ ...params, startKey, limit }, isUndefined)
  )
  const uri = `/messages/v4/groups/${groupId}/messages/${
    queryString ? '?' + queryString : ''
  }`
  return axios.get(uri)
}

export const sendGroupMessage = params => {
  const { groupId } = params
  const uri = `/messages/v4/groups/${groupId}/send`
  return axios.post(uri, {})
}

export const sendSimpleMessage = params => {
  const uri = `/messages/v4/send`
  return axios.post(uri, params)
}
