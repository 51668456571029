import React, { useState, useEffect } from 'react'
import compose from 'recompose/compose'
import Lottie from 'react-lottie'

import withStyles from '@material-ui/core/styles/withStyles'
import withWidth from '@material-ui/core/withWidth'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { isElement, isString } from 'lib/detectType'

import info from 'static/lottie/confirm_info.json'
import warning from 'static/lottie/confirm_warning.json'
import error from 'static/lottie/confirm_error.json'
import success from 'static/lottie/confirm_success.json'

import {
  MdCancel as MdCancelIcon,
  MdCheckCircle as MdCheckCircleIcon
} from 'react-icons/md'
import getComponentId from 'lib/getComponentId'

const styles = theme => ({
  popup: {
    '&&': {
      width: 400,
      maxWidth: '100%',
      maxHeight: '90vh',
      margin: 16
    }
  },
  dialogTitle: {
    paddingBottom: 0
  },
  dialogContent: {
    padding: '1em 1em 1em 1em',
    wordBreak: 'break-all',
    whiteSpace: 'pre-line',
    '&&': {
      [theme.breakpoints.down('sm')]: {}
    }
  },
  confirmMessage: {
    wordBreak: 'break-all',
    whiteSpace: 'pre-line'
  },
  cancelButton: {
    color: '#f50057',
    border: '1px solid rgba(245, 0, 87, 0.5)'
  },
  confirmButton: {},
  buttonIcon: {
    fontSize: '1.2em',
    marginRight: 4
  },
  iconArea: {
    display: 'inline-block',
    width: 30,
    marginRight: theme.spacing(1),
    verticalAlign: 'middle'
  }
})

const ConfirmForm = props => {
  const { classes, ...others } = props
  const {
    proxy,
    open = false,
    title,
    message = '',
    options = {},
    severity,
    popupMessage,
    dialogProps
  } = others
  const {
    confirmBtn: {
      name: confirmBtnName = '확인',
      props: {
        color: confirmBtnColor = popupMessage ? 'default' : 'primary',
        ...confirmBtnProps
      } = {}
    } = {},
    cancelBtn: {
      name: cancelBtnName = '취소',
      props: { ...cancelBtnProps } = {}
    } = {}
  } = options
  const { onConfirm, onCancel, onClose } = proxy
  const [speed, setSpeed] = useState(0.1)
  useEffect(() => {
    setSpeed(0.1)
  }, [speed])
  const animationData = { info, warning, error, success }
  return (
    <Dialog
      classes={{ paper: classes.popup }}
      open={open}
      onClose={onClose}
      disableEnforceFocus
      style={{
        zIndex: 1500
      }}
      {...dialogProps}
    >
      {title && (
        <DialogTitle classes={{ root: classes.dialogTitle }}>
          {severity && animationData[severity] && (
            <span className={classes.iconArea}>
              <Lottie
                speed={speed}
                options={{
                  speed,
                  loop: 5,
                  autoplay: true,
                  animationData: animationData[severity],
                  rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice'
                  }
                }}
              />
            </span>
          )}
          {title}
        </DialogTitle>
      )}
      <DialogContent classes={{ root: classes.dialogContent }}>
        <Grid container>
          {!title && severity && animationData[severity] && (
            <Grid item xs="auto">
              <span className={classes.iconArea}>
                <Lottie
                  speed={speed}
                  options={{
                    speed,
                    loop: 5,
                    autoplay: true,
                    animationData: animationData[severity],
                    rendererSettings: {
                      preserveAspectRatio: 'xMidYMid slice'
                    }
                  }}
                />
              </span>
            </Grid>
          )}
          <Grid item xs>
            {isElement(message) && message}
            {isString(message) && (
              <Typography variant="body2" className={classes.confirmMessage}>
                {message}
              </Typography>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            {!popupMessage && (
              <Button
                data-compid={getComponentId('cancelButton')}
                variant="outlined"
                onClick={onCancel}
                autoFocus
                fullWidth
                color="default"
                {...cancelBtnProps}
              >
                <MdCancelIcon className={classes.buttonIcon} />
                {cancelBtnName}
              </Button>
            )}
          </Grid>
          <Grid item xs={6}>
            <Button
              data-compid={getComponentId('confirmButton')}
              variant="outlined"
              onClick={onConfirm}
              color={confirmBtnColor}
              autoFocus={popupMessage}
              fullWidth
              {...confirmBtnProps}
            >
              {!popupMessage && (
                <MdCheckCircleIcon className={classes.buttonIcon} />
              )}
              {confirmBtnName}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
export default compose(withStyles(styles), withWidth())(ConfirmForm)
