const axios = require('axios')
const qs = require('querystring')

export const getCompanyList = async (query = {}) => {
  const uri = `/cash/v1/admin/company?${qs.stringify(query)}`
  return (await axios.get(uri)).data.map(company => {
    const { accountId, name, businessNumber, owner } = company
    return {
      isChecked: false,
      value: [accountId, name, businessNumber, owner],
      hiddenValue: company
    }
  })
}

export const createCompanyData = async obj => {
  let uri = `/cash/v1/admin/company`
  const {
    accountId,
    businessNumber,
    businessType,
    businessItems,
    contacts,
    name,
    owner,
    address
  } = obj
  const data = {
    accountId,
    businessNumber,
    businessType,
    businessItems,
    name,
    owner,
    address,
    contacts
  }
  await axios.post(uri, data)
  return data
}
export const updateCompanyData = async obj => {
  let uri = `/cash/v1/admin/company`
  let {
    _id: companyId,
    businessNumber,
    businessType,
    businessItems,
    contacts,
    name,
    owner,
    address,
    row
  } = obj
  contacts = contacts.map(contact => {
    const { name, email, phone } = contact
    return { name, email, phone }
  })
  const data = {
    companyId,
    businessNumber,
    businessType,
    businessItems,
    name,
    owner,
    address,
    contacts
  }
  await axios.put(uri, data)
  return { row, data }
}
