/**
 * @author EdenCha
 * @email eden@nurigo.net
 * @description (작성된 설명이 없습니다.)
 */
import { Map } from 'immutable'
import { short as shortAPI } from 'lib/api'
import { createRedux } from 'lib/requestHandler'

const initialState = Map({
  //    your: 'initialized data'
})

const handler = {}

handler.createShortCampaign = {
  // // 모든 옵션은 선택사항
  // options: {
  //   requestName: '요청 이름 입력',
  //   debounce: {
  //     wait: 500,
  //     leading: true,
  //     trailing: false
  //   },
  //   response: {
  //     // 해당 메시지는 컨테이너에 설정이 존재하는 경우 표시 순서가 2순위로 밀려납니다.
  //     successMessage: '요청에 성공했습니다.',
  //     error: {
  //       ignore: [404, 403], // array or true
  //       helper: {
  //         cond: {
  //           status: 404,
  //           errorCode: 'ResourceNotFound'
  //         },
  //         message:
  //           '위 조건에 부합하는 오류 발생 시 메시지에 부가적인 도움말을 사용자에게 보여줄 수 있습니다.'
  //       }
  //     }
  //   }
  // },
  // options: { debounce: { wait: 500 } },
  payloadCreator: shortAPI.createShortCampaign
}

handler.updateShortCampaign = {
  options: { debounce: { wait: 500 } },
  payloadCreator: shortAPI.updateShortCampaign
}

handler.deleteShortCampaign = {
  options: { debounce: { wait: 500 } },
  payloadCreator: shortAPI.deleteShortCampaign
}

handler.getCampaignList = {
  payloadCreator: shortAPI.getCampaignList
}

handler.getStatByDaily = {
  payloadCreator: shortAPI.getStatByDaily
}

handler.getStatByHourly = {
  payloadCreator: shortAPI.getStatByHourly
}

handler.getClickCount = {
  payloadCreator: shortAPI.getClickCount
}

handler.getClicks = {
  payloadCreator: shortAPI.getClicks
}

// handler.apiNameHere = {
//   options: {},
//   reducer: (state, action, options) => {
//     return state.set('key', 'value')
//   }
// }

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
