/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { matchPath } from 'react-router'
import urlParse from 'url-parse'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
import { senderId as senderIdAPI } from 'lib/api'

const handler = {}
const initialState = Map({
  uploadDocuments: [],
  selectedDocuments: {},
  documents: {},
  authTabValue: 0,
  defaultLimit: 2,
  requestData: {},
  responseData: {},
  activeStep: 0,
  formData: {},
  formError: {},
  selectedReason: {},
  duplicateReason: ['여러 계정을 소유하고 있습니다.'],
  senderIdData: {}
})

Object.assign(handler, {
  CREATE_SENDER_ID: {
    payloadCreator: senderIdAPI.createSenderId,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { senderIdData: action.payload.data }
        const message = '발신번호 임시 등록 완료'
        return onSuccess(state, action, { ...options, stateData, message })
      }
    }
  }
})

Object.assign(handler, {
  SET_FORM_VALUE: {
    reducer: (state, action) => {
      const formData = state.get('formData')
      const payload = action.payload || {}
      return state.set('formData', Object.assign({}, formData, payload))
    }
  }
})

Object.assign(handler, {
  SET_REGISTRATION_DATA: {
    reducer: (state, action) => {
      const requestData = state.get('requestData')
      const payload = action.payload || {}
      return state.set('requestData', Object.assign({}, requestData, payload))
    }
  }
})

Object.assign(handler, {
  SET_REGISTRATION_ERROR: {
    reducer: (state, action) => {
      const formError = state.get('formError')
      const payload = action.payload || {}
      return state.set('formError', Object.assign({}, formError, payload))
    }
  }
})

Object.assign(handler, {
  SET_ACTIVE_STEP: {
    reducer: (state, action) => {
      const activeStep = action.payload || 0
      return state.set('activeStep', activeStep)
    }
  }
})

Object.assign(handler, {
  SET_DUPLICATE_REASON: {
    reducer: (state, action) => {
      const selectedReason = state.get('selectedReason')
      return state.set(
        'selectedReason',
        Object.assign({}, selectedReason, action.payload)
      )
    }
  }
})

Object.assign(handler, {
  GET_SENDER_ID_DATA: {
    payloadCreator: senderIdAPI.getSenderIdData,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { senderIdData: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
})

Object.assign(handler, {
  DUPLICATE_CONFIRM_SENDER_ID: {
    payloadCreator: senderIdAPI.duplicateConfirmSenderId,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const message = '중복 사유 제출 완료'
        const stateData = { senderIdData: action.payload.data }
        return onSuccess(state, action, { ...options, stateData, message })
      }
    }
  }
})

handler.authenticateSenderId = {
  payloadCreator: senderIdAPI.authenticateSenderId,
  reducer: {
    onSuccess: (state, action, options) => {
      const filterNumbers = state.get('filterNumbers')
      const { url } = action.payload.config
      const { pathname } = urlParse(url)
      const { params: { phoneNumber } = {} } = matchPath(
        pathname.replace(/\/api/, ''),
        {
          path: '/senderid/v1/numbers/:phoneNumber/authenticate',
          exact: true,
          strict: false
        }
      )
      const stateData = {
        senderIdData: action.payload.data,
        filterNumbers: filterNumbers.filter(number => number !== phoneNumber)
      }
      const message = '번호 인증 완료'
      return onSuccess(state, action, { ...options, stateData, message })
    }
  }
}

Object.assign(handler, {
  UPLOAD_DOCUMENTS: {
    payloadCreator: senderIdAPI.uploadDocuments,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const documents = state.get('documents')
        const stateData = {
          documents: Object.assign({}, documents, {
            data: [action.payload.data].concat(documents.data)
          })
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
})

Object.assign(handler, {
  GET_DOCUMENTS: {
    payloadCreator: senderIdAPI.getDocuments,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { documents: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
})

Object.assign(handler, {
  AUTH_DOCS_SENDER_ID: {
    payloadCreator: senderIdAPI.authDocsSenderId,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { senderIdData: action.payload.data }
        const message = '정상적으로 서류 인증을 제출했습니다.'
        return onSuccess(state, action, { ...options, stateData, message })
      }
    }
  }
})

Object.assign(handler, {
  DELETE_SENDER_ID: {
    payloadCreator: senderIdAPI.deleteSenderId,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { senderIdData: action.payload.data }
        const message = '정상적으로 발신번호를 삭제했습니다.'
        return onSuccess(state, action, { ...options, stateData, message })
      }
    }
  }
})

Object.assign(handler, {
  UPDATE_SENDER_ID: {
    payloadCreator: senderIdAPI.updateSenderId,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { senderIdData: action.payload.data }
        const message = '정상적으로 발신번호를 상태를 변경했습니다.'
        return onSuccess(state, action, { ...options, stateData, message })
      }
    }
  }
})

handler.getSenderIdList = {
  payloadCreator: senderIdAPI.getSenderIdList
}

handler.getDocumentInfo = {
  payloadCreator: senderIdAPI.getDocumentInfo
}

handler.activatedNumbers = {
  options: {
    saveLogFlag: true,
    debounce: { wait: 500 },
    response: { error: { ignore: [409] } }
  },
  payloadCreator: senderIdAPI.activatedNumbers
}

handler.createConsignmentSenderIds = {
  options: { debounce: { wait: 500 } },
  payloadCreator: senderIdAPI.createConsignmentSenderIds
}

handler.createLimitationSenderIds = {
  options: { debounce: { wait: 500 } },
  payloadCreator: senderIdAPI.createLimitationSenderIds
}

handler.checkPhoneNumber = {
  payloadCreator: senderIdAPI.checkPhoneNumber
}

handler.getActivatedSenderIds = {
  payloadCreator: senderIdAPI.getActivatedSenderIds
}

handler.getActiveNumbers = {
  payloadCreator: senderIdAPI.getActivatedSenderIds,
  reducer: {
    onSuccess: (state, action) => {
      return state.set('activeNumbers', action.payload.data)
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
