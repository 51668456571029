import TagManager from 'react-gtm-module'
import ReactGA4 from 'react-ga4'
import config from 'config'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'

const isProduction =
  process.env.NODE_ENV === 'production' && process.env.REACT_APP_SANDBOX !== '1'

const getTokenInfo = () => {
  const currentClienttoken = cookies.get('CSAK')
  const tokenInfo = jwt.decode(currentClienttoken) || {}
  return tokenInfo
}

if (!window.ga4Loaded && isProduction) {
  try {
    const tokenInfo = getTokenInfo()
    const tagManagerArgs = { gtmId: config?.gtmID }
    TagManager.initialize(tagManagerArgs)
    ReactGA4.initialize([
      {
        trackingId: config.mainTrackingID,
        gtagOptions: { user_id: tokenInfo?.memberId }
      }
    ])
    window.ga4Loaded = true
  } catch (error) {}
}

const reactGa4 = {
  gtag: function () {
    try {
      if (window.ga4Loaded !== true) return
      ReactGA4.gtag(...arguments)
    } catch (error) {}
  },
  event: function () {
    try {
      if (window.ga4Loaded !== true) return
      ReactGA4.event(...arguments)
    } catch (error) {}
  },
  push: data => {
    if (window.ga4Loaded !== true) return
    try {
      // const tokenInfo = getTokenInfo()
      // const memberId = tokenInfo?.memberId
      // const accountId = tokenInfo?.accountId
      // const userInfo = Object.assign(data?.user_info || {}, {
      //   serviceName: config.serviceName,
      //   memberId,
      //   accountId
      // })
      // Object.assign(data, { user_info: userInfo })
      window.dataLayer.push(data)
    } catch (error) {}
  }
}

export default reactGa4
