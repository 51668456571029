import mapValues from 'lodash/mapValues'
import omit from 'lodash/omit'
import { isObject } from 'lib/detectType'

const deepOmitByKeys = (obj, keys) => {
  return mapValues(omit(obj, keys), value => {
    if (isObject(value)) {
      return deepOmitByKeys(value, keys)
    }
    return value
  })
}
export default deepOmitByKeys
