import { Map } from 'immutable'
import cloneDeep from 'lodash/cloneDeep'

import { requestHandler } from 'lib'
import { onSuccess } from 'lib/requestHandler'
import * as contactsAPI from 'lib/api/contacts'
import {
  getFolderInfo,
  // getFolderIdsByRecipients,
  getNewRecipientGroup,
  addRecipientGroups,
  addRecipientGroup,
  addRecipients,
  removeRecipientsByFolderId,
  getTotalRecipientsCount,
  filterByFolderIdRecipients,
  getRecipientsByFolderId,
  removeFolderByFolderId,
  updateRecipientGroup,
  editRecipientGroup,
  updateRecipient
} from 'lib/contacts'
import { isEmptyString, isArray, isObject } from 'lib/detectType'

const { createRedux } = requestHandler
const initialState = Map({
  treeData: [],
  contactsGroupChecked: {},
  totalRecipientsCount: 0,
  recipientGroups: [],
  recipients: [],
  filteredRecipients: []
})

const handler = {}

handler.getFolders = {
  payloadCreator: contactsAPI.getFolders
}

handler.getPeople = {
  payloadCreator: contactsAPI.getPeople
}

handler.createFolder = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.createFolder
}

handler.updateFolder = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.updateFolder
}

handler.createPeople = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.createPeople
}

handler.updatePeople = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.updatePeople
}

handler.deletePeople = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.deletePeople
}

handler.deleteFolder = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.deleteFolder
}

handler.excelDownload = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.excelDownload
}

handler.prepare = {
  options: {
    saveLogFlag: true,
    debounce: { wait: 500 }
  },
  payloadCreator: contactsAPI.prepare
}

handler.getPrepare = {
  options: {
    response: { loading: { display: false } }
  },
  payloadCreator: contactsAPI.getPrepare
}

handler.stopPrepare = {
  payloadCreator: contactsAPI.stopPrepare
}

handler.createPreset = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.createPreset
}

handler.getPresets = {
  payloadCreator: contactsAPI.getPresets
}

handler.getPermanentPresets = {
  payloadCreator: contactsAPI.getPermanentPresets
}

handler.updatePreset = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.updatePreset
}

handler.deletePreset = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.deletePreset
}

handler.scheduleGroupMessage = {
  options: {
    saveLogFlag: true,
    debounce: { wait: 500 }
  },
  payloadCreator: contactsAPI.scheduleGroupMessage
}

handler.cancelScheduledGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.cancelScheduledGroup
}

handler.cancelScheduledMessage = {
  options: { debounce: { wait: 500 } },
  payloadCreator: contactsAPI.cancelScheduledMessage
}

// handler.addRecipients = {
//   reducer: (state, action) => {
//     const payload = action.payload || {}
//     const recipientGroups = cloneDeep(state.get('recipientGroups'))
//     const recipients = cloneDeep(state.get('recipients'))
//     const newRecipients = recipients.concat(addRecipients(payload))
//     console.log(newRecipients)
//     return state.set('recipients', newRecipients)
//   }
// }

/* 사용 예 */
// // 기존 그룹에 개별 추가
// ContactsActions.addRecipients({
//   recipients: this.appendList,
//   targetRecipientGroup: 'folderId'
// })
// // 새로운 그룹에 개별 추가
// ContactsActions.addRecipients({
//   recipients: this.appendList,
//   targetRecipientGroup: { data: 1 }
// })
// // 개별 그룹에 개별 추가
// ContactsActions.addRecipients({
//   recipients: this.appendList
// })
// // 그룹 추가
// ContactsActions.addRecipients({
//   targetRecipientGroup: 'folderId'
// })
// // 새로운 그룹 추가
// ContactsActions.addRecipients({
//   targetRecipientGroup: { data: 1 }
// })
handler.addRecipients = {
  payloadCreator: contactsAPI.getFolderByFolderId,
  reducer: {
    onSuccess: (state, action, options) => {
      const originRecipientGroups = cloneDeep(state.get('recipientGroups'))
      const originRecipients = cloneDeep(state.get('recipients'))
      const recipients = action?.meta?.payload?.recipients
      const targetRecipientGroup = action?.meta?.payload?.targetRecipientGroup
      // 개별 추가 여부
      const isIndividual = isArray(recipients)
      const recipientGroup = isObject(action.payload)
        ? action.payload
        : isObject(targetRecipientGroup)
        ? targetRecipientGroup
        : {}
      // 폴더 여부
      const folderId = recipientGroup?.folderId
      // 임시 폴더 여부
      const isDisposable = recipientGroup?.isDisposable === true
      // 신규 수신자 생성
      const newRecipients = isIndividual
        ? addRecipients(
            originRecipients,
            recipients.map(data => ({ ...data, folderId }))
          )
        : removeRecipientsByFolderId(originRecipients, folderId)
      // 그룹 추가
      const tmpNewGroup = getNewRecipientGroup({
        ...recipientGroup,
        isIndividual,
        isDisposable,
        count: isIndividual ? recipients.length : 0
      })
      const newRecipientGroups = addRecipientGroups(originRecipientGroups, [
        tmpNewGroup
      ])
      // if (isIndividual) {
      //   newRecipients.push(...addRecipients(originRecipients, recipients))
      // }
      // 카운트 변경
      const totalRecipientsCount = getTotalRecipientsCount(newRecipientGroups)
      // 폴더 덮어쓰기 경고
      // const overwriteFolderWarning =
      // 중복번호 경고
      // const duplicatedNumberWarning =
      // 유효하지 않은 번호 경고
      // const invalidPhoneNumberWarning =
      const stateData = {
        totalRecipientsCount,
        recipientGroups: newRecipientGroups,
        recipients: newRecipients
      }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

// 폴더 목록 수신목록에 추가
handler.addRecipientGroups = {
  reducer: (state, action) => {
    const payload = action.payload || []
    const originRecipientGroups = state.get('recipientGroups')
    const originRecipients = state.get('recipients')
    const folderIds = payload.map(data => data?.folderId)
    const newRecipients = removeRecipientsByFolderId(
      originRecipients,
      folderIds
    )
    const newRecipientGroups = addRecipientGroups(
      originRecipientGroups,
      payload.map(data =>
        getNewRecipientGroup({
          // 1회성 여부 기본값
          isDisposable: false,
          ...data,
          isIndividual: false
        })
      )
    )
    const totalRecipientsCount = getTotalRecipientsCount(newRecipientGroups)
    return state
      .set('recipients', newRecipients)
      .set('recipientGroups', newRecipientGroups)
      .set('totalRecipientsCount', totalRecipientsCount)
  }
}

handler.addRecipientGroup = {
  reducer: (state, action) => {
    const payload = action.payload || {}
    const recipientGroups = cloneDeep(state.get('recipientGroups'))
    const newRecipientGroups = addRecipientGroup(recipientGroups, payload)
    return state.set('recipientGroups', newRecipientGroups)
  }
}

// 수신자 그룹 일부 수정
handler.editRecipientGroup = {
  reducer: (state, action) => {
    const { id, data = {} } = action.payload || {}
    const recipientGroups = state.get('recipientGroups')
    const newRecipientGroups = editRecipientGroup(recipientGroups, id, data)
    const totalRecipientsCount = getTotalRecipientsCount(newRecipientGroups)
    return state
      .set('recipientGroups', newRecipientGroups)
      .set('totalRecipientsCount', totalRecipientsCount)
  }
}

handler.removeRecipientGroup = {
  reducer: (state, action) => {
    const groupId = action?.payload
    if (isEmptyString(groupId)) return state
    const recipientGroups = state.get('recipientGroups')
    const newRecipientGroups = recipientGroups.filter(
      data => data?.id !== groupId
    )
    const newRecipients = filterByFolderIdRecipients(
      newRecipientGroups,
      state.get('recipients')
    )
    const totalRecipientsCount = getTotalRecipientsCount(newRecipientGroups)
    const focusRecipientGroupId = state.get('focusRecipientGroupId')
    return state
      .set(
        'focusRecipientGroupId',
        focusRecipientGroupId === groupId ? undefined : focusRecipientGroupId
      )
      .set('recipientGroups', newRecipientGroups)
      .set('recipients', newRecipients)
      .set('totalRecipientsCount', totalRecipientsCount)
  }
}

handler.removeAllRecipients = {
  reducer: (state, action) => {
    const folderId = action?.payload
    const recipients = state.get('recipients')
    const newRecipients = isEmptyString(folderId)
      ? []
      : removeRecipientsByFolderId(recipients, folderId)
    const recipientGroups = state.get('recipientGroups')
    const newRecipientGroups = isEmptyString(folderId)
      ? []
      : removeFolderByFolderId(recipientGroups, folderId)
    const totalRecipientsCount = isEmptyString(folderId)
      ? 0
      : getTotalRecipientsCount(newRecipientGroups)
    return state
      .set('focusRecipientGroupId', undefined)
      .set('recipientGroups', newRecipientGroups)
      .set('recipients', newRecipients)
      .set('totalRecipientsCount', totalRecipientsCount)
  }
}

handler.removeRecipient = {
  reducer: (state, action) => {
    const id = action?.payload
    if (isEmptyString(id)) return state
    const recipients = state.get('recipients')
    const targetRecipient = recipients.find(data => data?.id === id)
    if (!isObject(targetRecipient)) return state
    const newRecipients = recipients.filter(data => data?.id !== id)
    const recipientGroupId = targetRecipient?.folderId
    const currentFolderRecipients = getRecipientsByFolderId(
      newRecipients,
      recipientGroupId
    )
    const recipientGroups = state.get('recipientGroups')
    const recipientGroup = getFolderInfo(recipientGroups, recipientGroupId)
    // 남은 수신자 없는경우 그룹 제거
    const isEmptyRecipientGroup = currentFolderRecipients.length === 0
    const newRecipientGroups = isEmptyRecipientGroup
      ? removeFolderByFolderId(recipientGroups, recipientGroupId)
      : updateRecipientGroup(recipientGroups, recipientGroup?.id, {
          ...recipientGroup,
          count: recipientGroup?.count - 1
        })
    const focusRecipientGroupId = state.get('focusRecipientGroupId')
    const totalRecipientsCount = getTotalRecipientsCount(newRecipientGroups)
    return state
      .set(
        'focusRecipientGroupId',
        isEmptyRecipientGroup && focusRecipientGroupId === recipientGroupId
          ? undefined
          : focusRecipientGroupId
      )
      .set('recipientGroups', newRecipientGroups)
      .set('recipients', newRecipients)
      .set('totalRecipientsCount', totalRecipientsCount)
  }
}

// 전체 수신자 제거
handler.cleanRecipients = {
  reducer: (state, action) => {
    const newRecipientGroups = []
    const newRecipients = filterByFolderIdRecipients(
      newRecipientGroups,
      state.get('recipients')
    )
    const totalRecipientsCount = getTotalRecipientsCount(newRecipientGroups)
    return state
      .set('focusRecipientGroupId', undefined)
      .set('recipientGroups', newRecipientGroups)
      .set('recipients', newRecipients)
      .set('totalRecipientsCount', totalRecipientsCount)
  }
}

handler.updateRecipient = {
  reducer: (state, action) => {
    const { id, data } = action?.payload
    const recipients = state.get('recipients')
    const newRecipients = updateRecipient(recipients, id, data)
    return state.set('recipients', newRecipients)
  }
}

// 수신자 목록 자세히보기 필터링에 사용됨
handler.setFocusRecipientGroupId = {
  reducer: (state, action) => {
    return state.set('focusRecipientGroupId', action?.payload)
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)

export const actions = reduxActions
export default reduxReducers
