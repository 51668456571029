import { Map } from 'immutable'
import { requestHandler } from 'lib'
import * as exclusiveAccountAPI from 'lib/api/exclusiveAccount'
const { createRedux } = requestHandler
const initialState = Map({
  treeData: [],
  contactsGroupChecked: {}
})

const handler = {}

handler.getBankCode = {
  options: { response: { error: { ignore: [404, 403] } } },
  payloadCreator: exclusiveAccountAPI.getBankCode
}

handler.getExclusiveAccount = {
  options: { response: { error: { ignore: [404, 403] } } },
  payloadCreator: exclusiveAccountAPI.getExclusiveAccount
}

handler.createExclusiveAccount = {
  payloadCreator: exclusiveAccountAPI.createExclusiveAccount
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)

export const actions = reduxActions
export default reduxReducers
