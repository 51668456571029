/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { balance as balanceAPI } from 'lib/api'
import qs from 'querystring'
import _ from 'lodash'
import urlParse from 'url-parse'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  balanceHistory: [],
  balanceInfo: {},
  paymentInfo: {},
  selectedChargePaymentIndex: 0,
  lowBalanceAlert: {},
  bankAcocuntInfo: {},
  remainingTryCount: null,
  popupFlag: {},
  popupMessageFlag: false,
  popupMessage: ''
})
const handler = {
  SET_POPUP_MESSAGE: {
    reducer: (state, action) => {
      const { flag, message } = action.payload
      return state.set('popupMessageFlag', flag).set('popupMessage', message)
    }
  },
  OPEN_POPUP: {
    reducer: (state, action) => {
      const name = action.payload
      const popupFlag = state.get('popupFlag')
      return state.set(
        'popupFlag',
        Object.assign({}, popupFlag, { [name]: true })
      )
    }
  },
  CLOSE_POPUP: {
    reducer: (state, action) => {
      const name = action.payload
      const popupFlag = Object.assign({}, state.get('popupFlag'))
      if (Object.prototype.toString.call(name) === '[object Array]') {
        Object.keys(popupFlag)
          .filter(d => name.indexOf(d) !== -1)
          .forEach(n => {
            popupFlag[n] = false
          })
      } else {
        popupFlag[name] = false
      }
      return state.set('popupFlag', popupFlag).set('alertMessage', '')
    }
  },
  CONFIRM_SUBMIT: {
    reducer: (state, action) => {
      return state.set('confirm', action.payload)
    }
  },
  CHARGE_BALANCE: {
    options: {
      debounce: {
        wait: 1000
      }
    },
    payloadCreator: balanceAPI.chargeBalance,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { config } = action.payload
        const { amount = 0 } = JSON.parse(config.data)
        const balanceInfo = state.get('balanceInfo')
        // 우선은 캐시 충전만 취급
        const stateData = {
          balanceInfo: Object.assign(balanceInfo, {
            balance: Number(balanceInfo.balance || 0) + Number(amount)
          }),
          alertMessage: ''
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SET_AUTO_RECHARGE: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: balanceAPI.setAutoRecharge,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { minimumCash, rechargeTo, paymentIds } = action.payload.data
        const balanceInfo = state.get('balanceInfo')
        const paymentInfo = state.get('paymentInfo')
        const { payments = [] } = paymentInfo
        paymentInfo.payments = [].concat(payments).map(payment => {
          const indexOf = paymentIds.indexOf(payment._id)
          payment.autoRecharge = indexOf === -1 ? 0 : indexOf + 1
          return payment
        })
        const stateData = {
          balanceInfo: Object.assign({}, balanceInfo, {
            minimumCash,
            rechargeTo
          }),
          paymentInfo: Object.assign({}, paymentInfo)
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_BALANCE_INFO: {
    payloadCreator: balanceAPI.getBalanceInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { balanceInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SET_LBA: {
    options: { debounce: { wait: 500 } },
    payloadCreator: balanceAPI.setLBA
  },
  SWITCH_LBA: {
    options: { debounce: { wait: 500 } },
    payloadCreator: balanceAPI.switchLBA
  },
  SWITCH_AUTO_RECHARGE: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: balanceAPI.switchAutoRecharge,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const balanceInfo = state.get('balanceInfo')
        const { autoRecharge } = action.payload.data
        balanceInfo.autoRecharge = autoRecharge
        const stateData = { balanceInfo: Object.assign({}, balanceInfo) }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_PAYMENT_INFO: {
    payloadCreator: balanceAPI.getPaymentInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { paymentInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  CREATE_PAYMENT: {
    options: {
      saveLogFlag: false,
      debounce: { wait: 500 }
    },
    payloadCreator: balanceAPI.createPayment,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const paymentInfo = state.get('paymentInfo')
        const { payments = [] } = paymentInfo
        const payment = action.payload.data
        payments.push({
          _id: payment.paymentId,
          description: payment.description,
          expDate: payment.expDate
        })
        const stateData = {
          paymentInfo: Object.assign({}, paymentInfo, { payments })
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  DELETE_PAYMENT: {
    payloadCreator: balanceAPI.deletePayment,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const paymentInfo = state.get('paymentInfo')
        const { payments = [] } = paymentInfo
        const { paymentId } = action.payload.data
        const result = payments.filter(payment => {
          return !(payment._id === paymentId || payment.paymentId === paymentId)
        })
        const stateData = {
          paymentInfo: Object.assign({}, paymentInfo, { payments: result })
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  CHANGE_CHARGE_PAYMENT: {
    reducer: (state, action) => {
      return state.set('selectedChargePaymentIndex', action.payload.index)
    }
  },
  GET_BANK_ACCOUNT: {
    payloadCreator: balanceAPI.getBankAccount,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { bankAccount: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  WITHDRAW: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: balanceAPI.withdraw,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { amount } = JSON.parse(action.payload.config.data)
        const balanceInfo = state.get('balanceInfo')
        const { balance = 0 } = balanceInfo
        const stateData = {
          balanceInfo: Object.assign({}, balanceInfo, {
            balance: balance - amount
          })
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  TRANSFER: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: balanceAPI.transfer,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { config } = action.payload
        const balanceInfo = state.get('balanceInfo')
        const data = JSON.parse(config.data)
        const balance = balanceInfo.balance - 500 - Number(data.amount)
        const stateData = {
          balanceInfo: Object.assign({}, balanceInfo, { balance })
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  RECEIPT: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: balanceAPI.receipt,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const file = new Blob([action.payload.data], {
          type: 'application/pdf'
        })
        const fileURL = URL.createObjectURL(file)
        window.open(fileURL)
        return onSuccess(state, action, options)
      }
    }
  },
  GET_BALANCE_HISTORY: {
    payloadCreator: balanceAPI.getBalanceHistory,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const url = urlParse(action.payload.config.url)
        const historyParams = qs.parse(url.query.replace(/^\?/, ''))
        const data = action.payload.data
        const { limit } = historyParams
        let historyHasNext = true
        if (limit && data.length < limit) {
          historyHasNext = false
        }
        const stateData = {
          historyParams,
          balanceHistory: data,
          historyHasNext
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  ADD_BALANCE_HISTORY: {
    payloadCreator: balanceAPI.getBalanceHistory,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const url = urlParse(action.payload.config.url)
        const historyParams = qs.parse(url.query.replace(/^\?/, ''))
        const data = action.payload.data
        const { limit } = historyParams
        let historyHasNext = true
        if (limit && data.length < limit) {
          historyHasNext = false
        }
        const balanceHistory = state.get('balanceHistory').concat(data)
        const stateData = {
          historyParams,
          balanceHistory: _.sortBy(balanceHistory, ['date']),
          historyHasNext
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

handler.requestPayment = {
  options: {
    debounce: {
      wait: 1000
    }
  },
  payloadCreator: balanceAPI.requestPayment
}

handler.issueTaxinvoice = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: balanceAPI.issueTaxinvoice
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
