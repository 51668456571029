/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
// import omitBy from 'lodash/omitBy'

// material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'

// my components
// import UserComponentName from 'components/molecules/UserComponentName'

// libraries
import { isFunction } from 'lib/detectType'

const styles = theme => ({
  root: {},
  supportBtn: {
    marginLeft: theme.spacing(1) / 2,
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    cursor: 'pointer'
  }
})
export const useProps = {}
/**
 * 회원 접근 권한 없는 컴포넌트 접근
 */
const AccessDenied = ({ classes, proxy, ...props }) => {
  const { role, componentRole, onClickSupport } = props
  return (
    <Grid container justify="center" alignItems="center">
      <Grid item>
        <Typography color="error" variant="caption">
          <ErrorOutlineIcon
            style={{
              verticalAlign: 'middle',
              fontSize: '1.4em',
              marginRight: 8
            }}
          />
          <Typography color="textSecondary" variant="caption">
            권한없음 (현재:{role})
            {isFunction(onClickSupport) && (
              <Typography
                variant="caption"
                className={classes?.supportBtn}
                onClick={() => onClickSupport(role, componentRole)}
              >
                [문의]
              </Typography>
            )}
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  )
}

AccessDenied.defaultProps = setDefaultProps(useProps)
AccessDenied.propTypes = setPropTypes(useProps)
export default withStyles(styles)(AccessDenied)
