import { Map } from 'immutable'
import * as api from 'lib/api/member'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
const initialState = Map({
  _id: '',
  dateCreated: '',
  dateUpdated: '',
  email: '',
  loginSessions: [],
  memberId: '',
  name: '',
  password: '',
  status: '',
  hasChanges: false,
  originalMemberInfo: {},
  validateResult: {},
  confirmKeys: ['updateConfirmContainer', 'leaveCoolsmsContainer'],
  mfaKeys: ['mfaUpdatePhoneNumberContainer'],
  popupKeys: ['phoneUpdatePopupContainer'],
  snackbarKeys: ['alarmSnackbar'],
  disableAll: false
})
const handler = {
  GET_MEMBER_INFO: {
    payloadCreator: api.getMemberInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = {
          ...action.payload.data,
          originalMemberInfo: action.payload.data
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  UPDATE_MEMBER_INFO: {
    payloadCreator: api.updateMemberInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = {
          ...action.payload.data,
          originalMemberInfo: action.payload.data
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  UPDATE_MEMBER_PASSWORD: {
    payloadCreator: api.updateMemberPassword,
    reducer: {
      onPending,
      onFailure,
      onSuccess
    }
  },
  UPDATE_MEMBER_EMAIL: {
    payloadCreator: api.updateMemberEmail,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = {
          ...action.payload.data,
          originalMemberInfo: action.payload.data
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  UPDATE_MEMBER_PHONE_NUMBER: {
    payloadCreator: api.updateMemberPhoneNumber,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = {
          ...action.payload.data,
          originalMemberInfo: action.payload.data
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SET_VALIDATE_RESULT: {
    reducer: (state, action) => {
      const validateResult = state.get('validateResult')
      return state.set(
        'validateResult',
        Object.assign(validateResult, action.payload)
      )
    }
  },
  LEAVE_COOLSMS: {
    payloadCreator: api.leaveCoolsms,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action) => {
        return onSuccess(state, action)
      }
    }
  }
}

handler.updateExtraPhoneNumber = {
  payloadCreator: api.updateExtraPhoneNumber
}

handler.deleteExtraPhoneNumber = {
  payloadCreator: api.deleteExtraPhoneNumber
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
