export default function(date) {
  const currentDate = new Date()
  const targetDate = new Date(date)
  const currentMiliSec = currentDate.getTime()
  const targetMiliSec = targetDate.getTime()
  let formatString = currentMiliSec - targetMiliSec < 0 ? `후` : `전`
  const diffMiliSec = Math.abs(currentMiliSec - targetMiliSec)
  const diffSec = diffMiliSec / 1000
  if (diffSec < 60) {
    return `방금`
  } else if (diffSec < 3600) {
    return `${Math.round(diffSec / 60)}분 ${formatString}`
  } else if (diffSec < 86400) {
    return `${Math.round(diffSec / 3600)}시간 ${formatString}`
  } else if (diffSec < 604800) {
    return `${Math.round(diffSec / 86400)}일 ${formatString}`
  } else if (diffSec < 2592000) {
    return `${Math.round(diffSec / 604800)}주 ${formatString}`
  } else {
    return `${Math.round(diffSec / 2592000)}개월 ${formatString}`
  }
}
