/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import deepEqual from 'fast-deep-equal/react'
import ReactGA from 'lib/ga'
import ComponentPopup from 'components/molecules/ComponentPopup'
import pickBy from 'lodash/pickBy'

import { actions as usersActions } from 'store/modules/users'
import { actions as popupActions } from 'store/modules/popup'
import { actions as confirmActions } from 'store/modules/confirm'
import { actions as channelTalkActions } from 'store/modules/channelTalk'

import mapActionLog from 'lib/mapActionLog'
import { bindStateToProps, bindActionCreators } from 'lib'
import { isObject, isFunction } from 'lib/detectType'

/**
 * Popup
 * @class
 */
class PopupContainer extends Component {
  constructor(props) {
    super(props)
    autoBind(this)
    mapActionLog(this)
  }

  componentDidMount() {
    const { popupKey, popupData } = this.props
    const { openFlag, title } = popupData[popupKey] || {}
    if (openFlag === true) {
      ReactGA.modalview(popupKey)
      ReactGA.modalview(title)
    }
  }

  componentWillUnmount() {
    const { PopupActions, popupKey } = this.props
    PopupActions.clear({ key: popupKey })
  }

  shouldComponentUpdate(nextProps) {
    const { popupKey, popupData = {} } = this.props
    const { popupData: nextPopupData = {} } = nextProps
    const { openFlag } = popupData[popupKey] || {}
    const { openFlag: nextOpenFlag } = nextPopupData[popupKey] || {}
    if (openFlag !== true && nextOpenFlag === true) {
      ReactGA.modalview(popupKey)
    }
    return (
      openFlag === true ||
      !deepEqual(popupData[popupKey], nextPopupData[popupKey])
    )
  }

  componentDidUpdate = prevProps => {
    const { ChannelTalkActions, popupKey, popupData = {} } = this.props
    const { popupData: prevPopupData = {} } = prevProps
    const { openFlag } = popupData[popupKey] || {}
    const { openFlag: prevOpenFlag } = prevPopupData[popupKey] || {}
    if (prevOpenFlag === true && openFlag !== true) {
      // 더 이상 열린 팝업이 없는 경우
      const isAllClosed =
        Object.keys(
          pickBy(popupData, data => {
            return data?.openFlag === true
          })
        ).length < 1
      if (isAllClosed) {
        ChannelTalkActions.showButton()
      }
      this.onExited()
    }
    if (prevOpenFlag !== true && openFlag === true) {
      ChannelTalkActions.hideButton()
      this.onOpen()
    }
  }

  onClose = () => {
    const {
      ConfirmActions,
      PopupActions,
      popupKey = this.props.defaultPopupKey,
      popupData = {}
    } = this.props
    const { closeConfirm } = popupData[popupKey] || {}
    if (isObject(closeConfirm)) {
      const {
        viewCallback,
        message = '정말로 창을 닫을까요?',
        ...confirmProps
      } = closeConfirm
      const viewFlag = isFunction(viewCallback) ? viewCallback() : true
      if (viewFlag) {
        return ConfirmActions.show({
          message,
          onConfirm: () => {
            PopupActions.close({ key: popupKey })
          },
          ...confirmProps
        })
      }
    }
    PopupActions.close({ key: popupKey })
  }

  onOpen = () => {
    const { popupKey = this.props.defaultPopupKey, popupData = {} } = this.props
    if (isFunction(popupData?.[popupKey]?.onOpen)) {
      popupData[popupKey].onOpen()
    }
  }

  onExited = () => {
    const {
      PopupActions,
      popupKey = this.props.defaultPopupKey,
      popupData = {}
    } = this.props
    if (isFunction(popupData?.[popupKey]?.onExited)) {
      popupData[popupKey].onExited()
    }
    PopupActions.clear({ key: popupKey })
  }

  onClickSupport = async () => {
    const {
      ChannelTalkActions,
      UsersActions,
      popupKey,
      popupData = {}
    } = this.props
    const title = popupData?.[popupKey]?.title
    const {
      data: { userChatId }
    } = await UsersActions.openChats()
    const {
      data: {
        message: { chatId }
      }
    } = await UsersActions.postMessage({
      userChatId,
      message: [
        `[${title}] 팝업 문의`,
        `location: ${window.location.href}`,
        `key: ${popupKey}`
      ].join('\n')
    })
    ChannelTalkActions.open({ chatId })
  }

  render() {
    const props = Object.assign({}, this.props, this.state)
    const popupKey = props.popupKey || props.defaultPopupKey
    const popupProps = props.popupData[popupKey] || {}
    if (!popupProps?.openFlag) return null
    return (
      <ComponentPopup
        {...props}
        {...popupProps}
        proxy={this}
        popupKey={popupKey}
      />
    )
  }
}

export default connect(
  bindStateToProps({
    channelTalk: ['isSupportLive'],
    popup: ['popupData']
  }),
  bindActionCreators({
    popupActions,
    confirmActions,
    channelTalkActions,
    usersActions
  })
)(PopupContainer)
