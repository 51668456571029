import { isEmptyString } from 'lib/detectType'

const parseErrorMessage = (action, options) => {
  const response = action?.payload?.response
  const status = response?.status
  const method = response?.config?.method
  const errorCode = response?.data?.errorCode
  const errorMessage = response?.data?.errorMessage
  const isScriptError = !status && !method
  const defaultMessage =
    status === 500 || status === 503
      ? '서버 오류. 관리자에게 문의하거나, 잠시후 재시도해주세요.'
      : status === 401 && errorCode === 'TokenExpired'
      ? '로그인 후 24시간이 지나 자동으로 로그아웃 되었습니다.'
      : status === 401 && errorCode === 'Unauthorized'
      ? '죄송합니다. 로그인이 필요합니다. 다시 로그인 해주세요.'
      : status === 401
      ? '죄송합니다. 권한이 없는 요청입니다. 로그인 후 재시도 해주세요.'
      : errorCode === 'Unauthorized'
      ? '죄송합니다. 권한이 없거나, 인증정보를 확인하던 중 문제가 발생했습니다.'
      : `죄송합니다. 요청중 문제가 발생했습니다. 잠시후 재시도해주세요.${
          status ? `\nSTATUS:${status}` : ''
        }`
  const newErrorMessage =
    options?.message ?? action?.meta?.message ?? errorMessage ?? defaultMessage
  const isValidationError =
    status === 400 &&
    errorCode === 'ValidationError' &&
    /\[.+\]/.test(newErrorMessage) === true &&
    /유효성 검사 실패/.test(newErrorMessage)
  if (isValidationError) {
    return parseValidationErrorMessage(response)
  }
  return {
    errorMessage: isScriptError ? action?.payload?.message : newErrorMessage,
    formatMessage: newErrorMessage
  }
}

const parseValidationErrorMessage = response => {
  const errorMessage = response?.data?.errorMessage
  const splitedMessage = errorMessage
    .replaceAll(']', '')
    .split('[')
    .filter(
      message => !/유효성 검사 실패/.test(message) && !isEmptyString(message)
    )
  return { errorMessage, formatMessage: splitedMessage.join('\n') }
}

export default parseErrorMessage
