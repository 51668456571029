const cookies = require('js-cookie')

const config = {
  gaTrackingID: 'UA-144132559-1',
  commonRequestUrl: 'https://api.solapi.com',
  apiHost: 'https://api.solapi.com',
  pageHost: 'https://solapi.com',
  consolePageHost: 'https://console.solapi.com',
  getRequestHeaders: () => {
    return { Authorization: `Bearer ${cookies.get('CSAK')}` }
  },
  kgMID: 'solapicom0',
  kgApiKey: '066bf50e2ba4e29f2568326c249888ae'
}

module.exports = config
