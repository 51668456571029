/* global ActiveXObject, XMLHttpRequest */
export default function getServerTime() {
  function srvTime() {
    let xmlHttp
    try {
      // FF, Opera, Safari, Chrome
      xmlHttp = new XMLHttpRequest()
    } catch (err1) {
      // IE
      try {
        xmlHttp = new ActiveXObject('Msxml2.XMLHTTP')
      } catch (err2) {
        try {
          xmlHttp = new ActiveXObject('Microsoft.XMLHTTP')
        } catch (err3) {
          return null
        }
      }
    }
    if (!xmlHttp) return null
    xmlHttp.open('HEAD', window.location.href.toString(), false)
    xmlHttp.setRequestHeader('Content-Type', 'text/html')
    xmlHttp.send('')
    return xmlHttp.getResponseHeader('Date')
  }
  let st = srvTime()
  if (!st) return new Date()
  return new Date(st)
}
