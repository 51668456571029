import { Map } from 'immutable'
import { kakao as kakaoAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
const initialState = Map({
  plusFriends: [],
  plusFriendInfo: {},
  templates: [],
  templateInfo: {},
  categories: [],
  statusProgress: ['PENDING', 'INSPECTING', 'REJECTED', 'APPROVED'],
  stepDesc: [
    '현재 연동되지 않았습니다.',
    '아직 해당 템플릿을 등록하지 않았습니다.',
    '카카오에 템플릿을 검수 요청하세요. 1~3 영업일이 소요됩니다.',
    '검수 요청을 보냈습니다. 검수 결과 도착까지 1~3 영업일이 소요됩니다.',
    '템플릿 등록이 완료되었습니다.'
  ],
  templateListPath: '/kakao/templates',
  plusFriendListPath: '/kakao/plus-friends',
  serviceList: {
    biz: {
      color: '#00aab1'
    },
    kakaoenterprise: {
      color: '#00aab1'
    }
  },
  kakaoTemplateSample: {
    categoryCode: '009005',
    emphasizeType: 'TEXT',
    securityFlag: false,
    emphasizeTitle: '소멸예정쿠폰',
    emphasizeSubtitle: '소멸전에 꼭 사용해주세요.',
    extra: '소멸된 쿠폰은 사용할 수 없습니다.',
    name: '쿠폰소멸안내1',
    content:
      '안녕하세요 #{회원명}님!\n\n회원님의 #{쿠폰명} 쿠폰이 곧 소멸예정입니다.(눈물)\n소멸전에 꼭 사용해주세요.\n\n소멸예정시각 : #{소멸시각}\n\n항상 저희 서비스를 이용해주셔서 감사합니다.\n\n아래 버튼을 클릭하여 소멸예정쿠폰을 조회할 수 있습니다.',
    buttons: [
      {
        buttonName: '채널 추가',
        buttonType: 'AC',
        linkAnd: null,
        linkIos: null,
        linkMo: null,
        linkPc: null
      },
      {
        buttonName: '소멸쿠폰조회',
        buttonType: 'WL',
        linkAnd: null,
        linkIos: null,
        linkMo: 'https://m.solapi.com/#{user_id}',
        linkPc: 'https://solapi.com/#{user_id}'
      }
    ]
  }
})
const handler = {
  GET_PLUS_FRIENDS: {
    payloadCreator: kakaoAPI.getPlusFriends,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const { friends } = action.payload.data
        const stateData = { plusFriends: friends }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_PLUS_FRIEND_INFO: {
    payloadCreator: kakaoAPI.getPlusFriendInfo,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const plusFriendInfo = action.payload.data
        const stateData = { plusFriendInfo }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_TEMPLATES: {
    payloadCreator: kakaoAPI.getTemplates,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const templates = action.payload.data
        const stateData = { templates }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_TEMPLATE_INFO: {
    payloadCreator: kakaoAPI.getTemplateInfo,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const templateInfo = action.payload.data
        const stateData = { templateInfo }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  PUT_TEMPLATE: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: kakaoAPI.putTemplate,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const templateInfo = action.payload.data
        const stateData = { templateInfo }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  SET_TEMPLATE: {
    options: {
      saveLogFlag: true,
      debounce: { wait: 500 }
    },
    payloadCreator: kakaoAPI.setTemplate,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const templateInfo = action.payload.data
        const stateData = { templateInfo }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  PUT_TEMPLATE_COMMENT: {
    options: {
      saveLogFlag: true,
      debounce: { wait: 500 }
    },
    payloadCreator: kakaoAPI.putTemplateComment,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const templateInfo = action.payload.data
        const stateData = { templateInfo }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  templateInspection: {
    options: {
      saveLogFlag: true,
      debounce: { wait: 500 }
    },
    payloadCreator: kakaoAPI.templateInspection
  },
  cancelTemplateInspection: {
    options: {
      saveLogFlag: true,
      debounce: { wait: 500 }
    },
    payloadCreator: kakaoAPI.cancelTemplateInspection
  },
  deleteTemplate: {
    options: {
      debounce: { wait: 500 }
    },
    payloadCreator: kakaoAPI.deleteTemplate
  },
  UPLOAD_KAKAO_IMAGE: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: kakaoAPI.uploadKakaoImage,
    reducer: {
      onFailure,
      onPending,
      onSuccess
    }
  },
  GET_KAKAO_IMAGES: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: kakaoAPI.getKakaoImages,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const kakaoImages = action.payload.data
        const stateData = { kakaoImages }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}
handler.requestToken = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: kakaoAPI.requestToken
}
handler.putPlusFriend = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: kakaoAPI.putPlusFriend
}
handler.deletePlusFriend = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.deletePlusFriend
}
handler.getCategories = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.getCategories,
  reducer: {
    onFailure,
    onPending,
    onSuccess: (state, action, options) => {
      const stateData = { categories: action?.payload?.data || [] }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}
handler.hideTemplate = {
  payloadCreator: kakaoAPI.hideTemplate
}
handler.sharePlusFriend = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.sharePlusFriend
}
handler.stopSharePlusFriend = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.stopSharePlusFriend
}
handler.getGroups = {
  payloadCreator: kakaoAPI.getGroups
}
handler.getGroupInfo = {
  payloadCreator: kakaoAPI.getGroupInfo
}
handler.createGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.createGroup
}
handler.deleteGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.deleteGroup
}
handler.insertPlusFriendToGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.insertPlusFriendToGroup
}
handler.deletePlusFriendToGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.deletePlusFriendToGroup
}
handler.getSendableTemplates = {
  payloadCreator: kakaoAPI.getSendableTemplates
}
handler.inviteChannelToGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.inviteChannelToGroup
}
handler.getInvitationReceiveList = {
  payloadCreator: kakaoAPI.getInvitationReceiveList
}
handler.getInvitationSendList = {
  payloadCreator: kakaoAPI.getInvitationSendList
}
handler.getInvitation = {
  payloadCreator: kakaoAPI.getInvitation
}
handler.getSearchIds = {
  payloadCreator: kakaoAPI.getSearchIds
}
handler.cancelInvitation = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.cancelInvitation
}
handler.acceptInvitation = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.acceptInvitation
}
handler.refuseInvitation = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.refuseInvitation
}
handler.requestChannelTokenToAddService = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.requestChannelTokenToAddService
}
handler.createChannelToAddService = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.createChannelToAddService
}
// v2
handler.getChannel = {
  payloadCreator: kakaoAPI.getChannel
}
// v2
handler.getChannelList = {
  payloadCreator: kakaoAPI.getChannelList
}
handler.getGroupList = {
  payloadCreator: kakaoAPI.getGroupList
}
handler.getChannelGroupList = {
  payloadCreator: kakaoAPI.getChannelGroupList
}
handler.exitGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.exitGroup
}
handler.updateGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.updateGroup
}
handler.deleteGroup = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.deleteGroup
}
handler.getGroupInfoV1 = {
  payloadCreator: kakaoAPI.getGroupInfoV1
}
// v2
handler.getTemplate = {
  payloadCreator: kakaoAPI.getTemplate
}
// v2
handler.updateTemplateName = {
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.updateTemplateName
}
// v2
handler.createTemplate = {
  saveLogFlag: true,
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.createTemplate
}
// v2
handler.updateTemplate = {
  saveLogFlag: true,
  options: { debounce: { wait: 500 } },
  payloadCreator: kakaoAPI.updateTemplate
}
// v2
handler.requestTemplateInspection = {
  options: {
    saveLogFlag: true,
    debounce: { wait: 500 }
  },
  payloadCreator: kakaoAPI.requestTemplateInspection
}
// v2
handler.getSenderKeys = {
  payloadCreator: kakaoAPI.getSenderKeys
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
