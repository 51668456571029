import { Map } from 'immutable'
import { requestHandler } from 'lib'
import { isArray } from 'lib/detectType'
const { createRedux } = requestHandler
const initialState = Map({
  description: {}
})

const handler = {
  INIT: {
    reducer: (state, action) => {
      const payload = action.payload
      const originDescription = state.get('description') || {}
      if (isArray(payload)) {
        const description = Object.assign(
          {},
          originDescription,
          ...payload.map(data => {
            const { key, description, tooltip, title } = data || {}
            return { [key]: { title, description, tooltip } }
          })
        )
        return state.set('description', description)
      }
      const { key, description, tooltip, title } = payload || {}
      return state.set('description', {
        [key]: { title, description, tooltip }
      })
    }
  }
}
const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
