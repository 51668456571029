import axios from 'axios'
import config from 'config'

export const successRequset = () => {
  return Promise.resolve()
}

export const failedRequset = () => {
  return Promise.reject()
}

export const realMfa = () => {
  console.log('config.getRequestHeaders()', config.getRequestHeaders())
  // return axios.put(
  //   'https://api.solapi.net/users/v1/member/password',
  //   {
  //     currentPassword: "asdf123!",
  //     password: "asdf1234!",
  //     passwordConfirmation: "asdf1234!"
  //   },
  //   {
  //     headers: config.getRequestHeaders()
  //   }
  // )

  return axios.get(
    'https://api.solapi.net/iam/v1/black/optouts',
    {
      headers: config.getRequestHeaders()
    }
    // headers: Object.assign(config.getRequestHeaders(), {
    //   'x-mfa-data': JSON.stringify({
    //     authType: 'KR-ARS',
    //     extras: {
    //       phoneNumber: '01029951047'
    //     }
    //   })
    // })
  )
}

export const loadingRequest = () => {
  return new Promise(resolve => {
    setTimeout(() => {
      console.log(1234)
      resolve()
    }, 3000)
  })
  // return new Promise(() => {})
}

export const okRequest = () => {
  return Promise.resolve()
}

export const serverRequest = async (params, headers) => {
  return Promise.reject()
  // const { phoneNumber, mfa = {} } = params
  // const uri = `/senderid/v1/numbers/${phoneNumber}/authenticate`
  // const mfaParams = _.omitBy(
  //   { mfa },
  //   data => !data || Object.keys(data).length === 0
  // )
  // return axios.put(uri, { ...params, ...mfaParams }, { headers })

  // const result = {}
  // const transactionId = Date.now()
  // if (mfa.token) {
  //   if (mfa.token === '123123') {
  //     return Promise.resolve()
  //   }
  //   const error = {
  //     response: {
  //       status: 401,
  //       data: {
  //         errorCode: 'Unauthorized',
  //         errorMessage: '잘못된 토큰',
  //         mfa: {
  //           transactionId,
  //           authType: mfa.authType,
  //           extras: ['phoneNumber'],
  //           allowedAuthTypes: ['SMS', 'ARS', 'KR-ARS', 'HASH']
  //         }
  //       }
  //     }
  //   }
  //   return Promise.reject(error)
  // }
  // if (mfa.authType) {
  //   const error = {
  //     response: {
  //       status: 401,
  //       data: {
  //         errorCode: 'Unauthorized',
  //         errorMessage: '2차 인증이 필요합니다. ' + transactionId,
  //         mfa: {
  //           transactionId,
  //           authType: mfa.authType,
  //           extras: ['phoneNumber'],
  //           allowedAuthTypes: ['SMS', 'ARS', 'KR-ARS', 'HASH']
  //         }
  //       }
  //     }
  //   }
  //   return Promise.reject(error)
  // }
  // const error = {
  //   response: {
  //     status: 401,
  //     data: {
  //       errorCode: 'Unauthorized',
  //       errorMessage: '2차 인증이 필요합니다.',
  //       mfa: {
  //         extras: ['phoneNumber'],
  //         allowedAuthTypes: ['SMS', 'ARS', 'KR-ARS', 'HASH', 'EMAIL-OTP']
  //       }
  //     }
  //   }
  // }
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     return reject(error)
  //     // return Date.now() % 2 ? resolve(result) : reject(error)
  //   }, 200)
  // })

  /*
  return new Promise((resolve, reject) => {
    const result = {
      data: {
        list: [1,2,3]
      }
    }
    const error = {
      allowedAuthTypes: ['ars'],
      extras: ['phoneNumber']
    }
    setTimeout(() => {
      const { transactionId, token, responseData = {} } = params
      if (!transactionId) {
        return reject(error)
      }
      const { validTokenNumber } = responseData
      if (String(token) !== String(validTokenNumber)) {
        return reject({ ...error, transactionId })
      }
      return resolve({ data: result })
    }, 1000)
  })
  */
}

function a() {
  return new Promise((resolve, reject) => {
    resolve(1)
  })
}
export const asyncfunc = async () => {
  const d = await a()
  console.log(d)
}
