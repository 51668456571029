import config from 'config'

let menu = [
  {
    headerTitle: '메시지전송',
    list: [
      {
        name: '메시지 보내기',
        icon: 'flight_takeoff',
        link: '/purplebook',
        local: 'auth-webpages'
      },
      {
        name: '간단 메시지 보내기',
        icon: 'flight_takeoff',
        link: '/purplebook/guide',
        local: 'auth-webpages'
      },
      {
        name: '전송결과',
        icon: 'flight_takeoff',
        link: '/message-log',
        local: 'auth-webpages'
      },
      {
        name: '사용통계',
        icon: 'flight_takeoff',
        link: '/stat',
        local: 'auth-webpages'
      }
    ]
  },
  {
    headerTitle: '카카오/네이버/RCS',
    list: [
      {
        name: '카카오 채널 연동',
        icon: 'flight_takeoff',
        link: '/kakao/plus-friends',
        local: 'auth-webpages'
      },
      {
        name: '알림톡 템플릿 관리',
        icon: 'flight_takeoff',
        link: '/kakao/templates',
        local: 'auth-webpages'
      },
      {
        name: '네이버톡톡 연동',
        icon: 'flight_takeoff',
        link: '/naver/agents',
        local: 'auth-webpages'
      },
      {
        name: '네이버톡톡 템플릿 관리',
        icon: 'flight_takeoff',
        link: '/naver/templates',
        local: 'auth-webpages'
      },
      {
        name: 'RCS 브랜드 연동',
        icon: 'flight_takeoff',
        link: '/rcs/brands',
        local: 'auth-webpages'
      },
      {
        name: 'RCS 템플릿 관리',
        icon: 'flight_takeoff',
        link: '/rcs/templates',
        local: 'auth-webpages'
      }
    ]
  },
  {
    headerTitle: '요금충전',
    list: [
      {
        name: '요금안내/충전',
        icon: 'flight_takeoff',
        link: '/recharge',
        local: 'auth-webpages'
      },
      // {
      //   name: '후불요금제',
      //   icon: 'flight_takeoff',
      //   link: '/recharge',
      //   local: 'coolsms-layout'
      // },
      {
        name: '충전내역',
        icon: 'flight_takeoff',
        link: '/balance/history',
        local: 'auth-webpages'
      },
      {
        name: '잔액소진알림',
        icon: 'flight_takeoff',
        link: '/lba',
        local: 'auth-webpages'
      },
      {
        name: '잔액자동충전',
        icon: 'flight_takeoff',
        link: '/recharge/auto',
        local: 'auth-webpages'
      },
      {
        name: '세금계산서',
        icon: 'flight_takeoff',
        link: '/taxinvoice',
        local: 'auth-webpages'
      },
      {
        name: '후불 요금',
        icon: 'flight_takeoff',
        link: '/deferred-payment',
        local: 'auth-webpages'
      },
      {
        name: '후불서비스 신청',
        icon: 'flight_takeoff',
        link: '/deferred-payment/request',
        local: 'auth-webpages'
      }
    ]
  },
  {
    headerTitle: '환경설정',
    list: [
      {
        name: '발신번호 관리',
        icon: 'flight_takeoff',
        link: '/senderids',
        local: 'auth-webpages'
      },
      {
        name: '일일발송량 관리',
        icon: 'flight_takeoff',
        link: '/quota',
        local: 'auth-webpages'
      },
      {
        name: '수신거부 관리',
        icon: 'flight_takeoff',
        link: '/blocklist',
        local: 'auth-webpages'
      },
      {
        name: '첨부파일 관리',
        icon: 'flight_takeoff',
        link: '/storage',
        local: 'auth-webpages'
      },
      {
        name: '단축 URL 관리',
        icon: 'flight_takeoff',
        link: '/short/campaigns',
        local: 'auth-webpages'
      }
    ]
  },
  {
    headerTitle: '개발 / 연동',
    list: [
      {
        name: 'API Key 관리',
        icon: 'flight_takeoff',
        link: '/credentials',
        local: 'auth-webpages'
      },
      {
        name: 'SDK 다운로드',
        icon: 'flight_takeoff',
        link: '/sdk',
        local: 'auth-webpages'
      },
      {
        name: 'Webhook 관리',
        icon: 'flight_takeoff',
        link: '/webhooks',
        local: 'auth-webpages'
      },
      {
        name: '내 앱 관리',
        icon: 'flight_takeoff',
        link: '/me/apps',
        local: 'auth-webpages'
      },
      {
        name: '수익 내역',
        icon: 'flight_takeoff',
        link: '/me/apps/settle',
        local: 'auth-webpages'
      },
      {
        name: 'API 개발문서',
        icon: 'flight_takeoff',
        link: 'https://docs.coolsms.co.kr/',
        local: 'auth-webpages',
        newTab: true
      },
      {
        name: '단축 URL',
        icon: 'flight_takeoff',
        link: '/short/campaigns',
        local: 'auth-webpages'
      }
    ]
  },
  {
    headerTitle: '어플리케이션',
    list: [
      {
        name: '앱스토어',
        icon: 'flight_takeoff',
        link: `${config.pageHost}/apps`,
        local: 'auth-webpages',
        newTab: true
      }
    ]
  },
  {
    headerTitle: '고객지원',
    list: [
      {
        name: '공지사항',
        icon: 'help',
        link: `${config.supportPageHost}/hc/ko/community/topics/${config.zendeskNotice}`,
        newTab: true
      },
      // {
      //   name: '1:1 문의',
      //   icon: 'help',
      //   link: `${config.apiHost}/users/v1/zendesk/request`,
      //   newTab: true
      // },
      // {
      //   name: '문의 내역',
      //   icon: 'view_list',
      //   link: `${config.zendeskApiHost}/hc/ko/requests`,
      //   newTab: true
      // },
      {
        name: '자주 묻는 질문',
        icon: 'question_answer',
        link: `${config.apiHost}/users/v1/zendesk/login?return_to=/hc/ko`,
        newTab: true
      }
    ]
  }
]

if (process.env.REACT_APP_ADMIN === '1') {
  const adminMenu = require('lib/coolsmsAdminMenu')
  menu = adminMenu.default
}

export default menu
