import axios from 'axios'

export const acceptInvitation = async invitationId => {
  return axios.post(`/users/v1/invitations/${invitationId}`)
}

export const createInviteMember = async data => {
  const { invitationId, ...params } = data
  return axios.post(`/users/v1/signup/invitations/${invitationId}`, params, {
    withCredentials: true
  })
}

export const getInvitationInfo = async invitationId =>
  axios.get(`/users/v1/invitations/${invitationId}`)
