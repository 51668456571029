import axios from 'axios'

export const getAccountInfo = async condition =>
  axios.get(`/users/v1/accounts/${condition.accountId}`)

export const deleteAccount = payload => {
  const { accountId } = payload
  return axios.delete(`/users/v1/accounts/${accountId}`, {
    withCredentials: true
  })
}

export const getMemberInfo = async accountId =>
  axios.get(`/users/v1/accounts/${accountId}/me`)
