import React from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Provider } from 'react-redux'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import cookies from 'js-cookie'
import jwt from 'jsonwebtoken'
import store from 'store'
import routes from 'routes'
import LayoutContainer from 'containers/LayoutContainer'
import Fallback from 'components/atoms/Fallback'
import NotFoundPage from 'components/pages/NotFoundPage'

const RouteWithSubRoutes = props => {
  const {
    path,
    exact,
    publicPage,
    adminPage,
    component: RouteComponent,
    ...others
  } = props
  return (
    <Route
      path={path}
      render={routeProps => {
        const token = jwt.decode(cookies.get('CSAK'))
        if (adminPage === true && !token?.isAdmin) {
          return <NotFoundPage adminPage />
        }
        return <RouteComponent exact {...others} {...routeProps} />
      }}
    />
  )
}

const App = () => {
  return (
    <Provider store={store}>
      <React.Suspense fallback={<Fallback />}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <BrowserRouter basename="">
            <LayoutContainer>
              <Switch>
                {routes.map((route, i) => {
                  return <RouteWithSubRoutes key={i} {...route} />
                })}
                <Route path="*" exact={true} component={NotFoundPage} />
              </Switch>
            </LayoutContainer>
          </BrowserRouter>
        </MuiPickersUtilsProvider>
      </React.Suspense>
    </Provider>
  )
}

export default App
