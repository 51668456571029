import { Map } from 'immutable'
import cloneDeep from 'lodash/cloneDeep'
import { users as usersAPI } from 'lib/api'
import { requestHandler } from 'lib'
const { createRedux, onSuccess } = requestHandler

const initialState = Map({
  localPreferences: JSON.parse(localStorage.getItem('localPreferences') || '{}')
})

const handler = {}

handler.getAccountInfo = {
  options: { response: { error: { ignore: [401] } } },
  payloadCreator: usersAPI.getAccountData
}

handler.getAccounts = {
  payloadCreator: usersAPI.getAccounts
}

handler.deleteAccount = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.deleteAccount
}

handler.leaveAccount = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.leaveAccount
}

handler.getMemberInfo = {
  options: { response: { error: { ignore: [401] } } },
  payloadCreator: usersAPI.getMemberInfo
}

handler.getMemberList = {
  payloadCreator: usersAPI.getMemberList
}

handler.getMysiteMembers = {
  options: { response: { error: { ignore: [404] } } },
  payloadCreator: usersAPI.getMysiteMembers
}

handler.getMysiteAccounts = {
  options: {
    response: {
      error: {
        ignore: [404]
      }
    }
  },
  payloadCreator: usersAPI.getMysiteAccounts
}

handler.createMysiteAccountExtends = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.createMysiteAccountExtends
}

handler.deleteMysiteAccountExtends = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.deleteMysiteAccountExtends
}

handler.editMemberInfo = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.editMemberInfo
}

handler.activeDeviceAuth = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.activeDeviceAuth
}

handler.getDeviceInfo = {
  payloadCreator: usersAPI.getDeviceInfo
}

handler.deleteDeviceInfo = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.deleteDeviceInfo
}

handler.insertDeviceInfo = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.insertDeviceInfo
}

handler.updateMember = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.updateMember
}

handler.updatePhoneNumber = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.updatePhoneNumber
}

handler.getMyAccountInfo = {
  payloadCreator: usersAPI.getMyAccountInfo
}

handler.updatePassword = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.updatePassword
}

handler.deleteMember = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.deleteMember
}

handler.leaveService = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.leaveService
}

handler.deletePhoneNumber = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.deletePhoneNumber
}

handler.trialSignUp = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.trialSignUp
}

handler.convertTrial = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.convertTrial
}

handler.getMyPreferences = {
  options: { response: { error: { ignore: true } } },
  payloadCreator: usersAPI.getMyPreferences,
  reducer: {
    onSuccess: (state, action, options) => {
      const stateData = { preferences: action.payload.data }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

handler.updateMyPreferences = {
  options: { response: { error: { ignore: true } } },
  payloadCreator: usersAPI.updateMyPreferences,
  reducer: {
    onSuccess: (state, action, options) => {
      const stateData = { preferences: action.payload.data }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

handler.setLocalPreferences = {
  reducer: (state, action, options) => {
    try {
      const { key, value } = action.payload
      let localPreferences = JSON.parse(
        localStorage.getItem('localPreferences') || '{}'
      )
      Object.assign(localPreferences, { [key]: value })
      localStorage.setItem('localPreferences', JSON.stringify(localPreferences))
      const newLocalPreferences = cloneDeep(localPreferences)
      return state.set('localPreferences', newLocalPreferences)
    } catch (error) {
      localStorage.setItem('localPreferences', '{}')
      return state.set('localPreferences', {})
    }
  }
}

handler.activateMember = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.activateMember
}

handler.inviteMember = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.inviteMember
}

handler.getInvitationList = {
  payloadCreator: usersAPI.getInvitationList
}

handler.cancelInvitation = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.cancelInvitation
}

handler.updateAccount = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.updateAccount
}

handler.updateAccountMember = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.updateAccountMember
}

handler.updateEmail = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.updateEmail
}

// 채널톡 채팅방 생성
handler.openChats = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.openChats
}

// 채팅방에 메시지 보내기
handler.postMessage = {
  options: { debounce: { wait: 500 } },
  payloadCreator: usersAPI.postMessage
}

handler.existenceEmail = {
  payloadCreator: usersAPI.existenceEmail
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
