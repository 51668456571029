import axios from 'axios'

// 멤버 정보 가져오기
export const getMemberInfo = () => axios.get(`/users/v1/member`)

// 멤버 정보 업데이트
export const updateMemberInfo = payload =>
  axios.put(`/users/v1/member`, payload)

// 비밀번호 변경
export const updateMemberPassword = payload =>
  axios.put(`/users/v1/member/password`, payload, {
    withCredentials: true
  })

// 이메일 변경
export const updateMemberEmail = payload =>
  axios.put(`/users/v1/member/email`, payload)

// 전화번호 변경
export const updateMemberPhoneNumber = payload =>
  axios.put(`/users/v1/member/phone-number`, payload)

// 회원 탈퇴
export const leaveCoolsms = payload =>
  axios.delete(`/users/v1/member`, {
    withCredentials: true
  })

export const updateExtraPhoneNumber = payload => {
  const uri = `/users/v1/member/phone-number/extra`
  return axios.put(uri, payload)
}

export const deleteExtraPhoneNumber = payload => {
  const uri = `/users/v1/member/phone-number/extra`
  return axios.delete(uri, { data: payload })
}
