/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import pickBy from 'lodash/pickBy'
import debounce from 'lodash/debounce'

// lib
// import config from 'config'
import bindStateToProps from 'lib/bindStateToProps'
import bindActionCreators from 'lib/bindActionCreators'
import isEqualProps from 'lib/isEqualProps'
// import mapActionLog from 'lib/mapActionLog'
import proxyOverride from 'lib/proxyOverride'
import mapAsyncActions from 'lib/mapAsyncActions'
import {
  isFunction,
  isString,
  isArray,
  isNumber,
  isObject,
  isEmptyString
} from 'lib/detectType'
import ReactGA4 from 'lib/ga4'

// redux modules (action)
// import { actions as rbcActions } from 'store/modules/rbc'

// common modules (action)
// import { actions as formActions } from 'store/modules/form'
// import { actions as tableActions } from 'store/modules/table'
// import { actions as searchActions } from 'store/modules/search'
// import { actions as uploadActions } from 'store/modules/upload'
// import { actions as popupActions } from 'store/modules/popup'
// import { actions as snackbarActions } from 'store/modules/snackbar'
import { actions as confirmActions } from 'store/modules/confirm'
// import { actions as helpActions } from 'store/modules/help'
// import { actions as supportActions } from 'store/modules/support'
// import { actions as excelActions } from 'store/modules/excel'

// react components & containers
import CommonButton from 'components/organisms/CommonButton'
import Button from '@material-ui/core/Button'

/**
 * 공용 버튼 컨테이너
 */
class CommonButtonContainer extends Component {
  constructor(props) {
    super(props)
    // 해당 컨테이너 내의 메서드에 자동으로 this 클래스를 바인딩 합니다.
    autoBind(this)
    // 해당 컨테이너 내의 메서드 실행 시 액션로그를 남깁니다.
    // mapActionLog(this)
    // HOC 사용 시 현재 컨테이너에 proxy가 props에 존재하는 경우 proxy 메서드에 this 클래스의 메서드를 대입
    proxyOverride(this)
    // 2차 인증을 사용하는 리덕스 액션이 존재하는 경우 덮어씌워 await이 정상적으로 작동하게 하고, 액션에 출처 컨테이너를 기록합니다.
    mapAsyncActions(this.props, {
      // exceptActions: { AppsActions: true }
    })
    this.wait = 0
    this.state = { loading: false, buttonShake: false }
    this.timerInterval = null
    this.shakeInterval = null
    this.allowedCustomProps = [
      // google ga action name
      'label',
      'primaryColor',
      'contrastText',
      'badgeContent',
      'tooltipTitle',
      'wait',
      'clickLoading',
      'clickPrepareAction',
      // confirm="real?"
      // confirm={{ message: 'hello?' }}
      'confirm',
      'error',
      // onClickDisabled={() => {
      //   console.log('disable click!')
      // }}
      'onClickDisabled',
      'loading',
      'progressValue',
      'newTabIcon',
      'className'
    ]
    this.allowedButtonProps = [
      'id',
      'children',
      'classes',
      'color',
      'component',
      'disabled',
      'disableElevation',
      'disableFocusRipple',
      'disableRipple',
      'fullWidth',
      'href',
      'target',
      'size',
      'startIcon',
      'variant',
      'onClick',
      'onMouseEnter'
    ]
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqualProps(
        nextProps,
        this.props,
        this.allowedButtonProps.concat(this.allowedCustomProps)
      ) || !isEqualProps(nextState, this.state, ['loading', 'buttonShake'])
    )
  }

  // hoc 사용 시 해당 부분 삭제하세요.
  componentDidMount = () => {
    this.wait = isNumber(this.props.wait) ? this.props.wait : this.wait
  }

  componentDidUpdate = (prevProps, prevState) => {
    const { error = false } = this.props
    const { error: prevError = false } = prevProps
    const { buttonShake = false } = this.state
    const { buttonShake: prevButtonShake = false } = prevState
    if (prevError === false && error === true && prevButtonShake === false) {
      this.setState({ buttonShake: true })
    }
    if (buttonShake === true) {
      this.shakeInterval = setTimeout(() => {
        this.setState({ buttonShake: false })
      }, 500)
    }
  }

  componentDidCatch = () => {
    this.setState({ hasError: true })
  }

  onClick = async e => {
    const { clickPrepareAction } = this.props
    if (isFunction(clickPrepareAction)) {
      clickPrepareAction(e)
    }
    if (isNumber(this.wait) && this.wait > 0) {
      const clickDebounce = await this.onClickDebounce(e, this.wait)
      if (isFunction(clickDebounce)) {
        clickDebounce()
      }
      return
    }
    await this.buttonConfirm()
    try {
      this.buttonLoading(this.wait)
      this.setGA()
    } catch (error) {}
    const { onClick } = this.props
    if (!isFunction(onClick)) return
    return onClick(e)
  }

  onClickDisabled = async e => {
    const { clickPrepareAction } = this.props
    if (isFunction(clickPrepareAction)) {
      clickPrepareAction(e)
    }
    if (isNumber(this.wait) && this.wait > 0) {
      const clickDebounce = await this.onClickDisabledDebounce(e, this.wait)
      if (isFunction(clickDebounce)) {
        clickDebounce()
      }
    }
    const { onClickDisabled } = this.props
    try {
      this.buttonLoading(this.wait)
      this.setGA('DisableButtonClickAction', true)
    } catch (error) {}
    return onClickDisabled(e)
  }

  onClickDebounce = async (e, wait) => {
    const { onClick } = this.props
    if (!isFunction(onClick)) return
    await this.buttonConfirm()
    if (isFunction(this.clickDebounce)) return this.clickDebounce
    this.clickDebounce = debounce(
      () => {
        try {
          this.buttonLoading(wait)
          this.setGA()
        } catch (error) {}
        return onClick(e)
      },
      wait,
      { leading: true, trailing: false, maxWait: 500 }
    )
    return this.clickDebounce
  }

  onMouseEnter = event => {
    const { onMouseEnter, error } = this.props
    const { buttonShake } = this.state
    if (error === true && buttonShake !== true) {
      this.setState({ buttonShake: true })
    }
    if (isFunction(onMouseEnter)) {
      onMouseEnter()
    }
  }

  getButtonName = () => {
    const { children } = this.props
    const buttonName = isArray(children)
      ? children
          .filter(data => isString(data))
          .map(name => name.replace(/[^0-9가-힣a-zA-Z\s]/g, ''))
          .join('|')
      : 'UNKOWN'
    return buttonName
  }

  setGA = (category = 'ButtonClickAction', isDisabled = false) => {
    const { auth, label } = this.props
    const action = isEmptyString(label) ? this.getButtonName() : label
    ReactGA4.event('click_common_button', {
      action,
      isDisabled,
      location: window.location.href,
      accountId: auth?.accountId
    })
  }

  onClickDisabledDebounce = async (event, wait) => {
    const { onClickDisabled } = this.props
    if (!isFunction(onClickDisabled)) return
    await this.buttonConfirm()
    if (isFunction(this.clickDisabledDebounce)) {
      return this.clickDisabledDebounce
    }
    this.clickDisabledDebounce = debounce(
      () => {
        try {
          this.buttonLoading(wait)
          this.setGA('DisableButtonClickAction', true)
        } catch (error) {}
        return onClickDisabled(event)
      },
      wait,
      { leading: true, trailing: false, maxWait: 500 }
    )
    return this.clickDisabledDebounce
  }

  buttonConfirm = () => {
    const { ConfirmActions, confirm, children } = this.props
    if (isString(confirm)) {
      return new Promise((resolve, reject) => {
        return ConfirmActions.show({
          title: children,
          message: confirm,
          onConfirm: resolve,
          onCancel: reject
        })
      })
    }
    if (isObject(confirm)) {
      return new Promise((resolve, reject) => {
        return ConfirmActions.show({
          onConfirm: resolve,
          onCancel: reject,
          ...confirm
        })
      })
    }
    return Promise.resolve()
  }

  buttonLoading = wait => {
    const { clickLoading } = this.props
    if (clickLoading === true) {
      clearInterval(this.timerInterval)
      this.setState({ loading: true })
      this.timerInterval = setTimeout(() => {
        this.setState({ loading: false })
      }, wait)
    }
  }

  render = () => {
    // hoc 사용 시 proxy 삭제하세요.
    // return <PurplebookForeignForm {...this.props} proxy={this} />
    const buttonProps = pickBy(this.props, (value, key) => {
      return this.allowedButtonProps.indexOf(key) !== -1 || isFunction(value)
    })
    if (this.state.hasError === true) {
      return <Button {...this.props} />
    }
    return (
      <CommonButton
        className={this.props.className}
        primaryColor={this.props.primaryColor}
        contrastText={this.props.contrastText}
        progressValue={this.props.progressValue}
        loading={
          this.props.loading === undefined
            ? this.state.loading
            : this.props.loading
        }
        newTabIcon={this.props.newTabIcon}
        badgeContent={this.props.badgeContent}
        tooltipTitle={this.props.tooltipTitle}
        buttonShake={this.state.buttonShake}
        buttonError={this.props.error}
        buttonProps={buttonProps}
        onMouseEnter={this.onMouseEnter}
        onClick={this.onClick}
        onClickDisabled={
          isFunction(this.props.onClickDisabled) ? this.onClickDisabled : false
        }
      >
        {this.props.children}
      </CommonButton>
    )
  }
}

export default connect(
  bindStateToProps({
    consoleLayout: ['auth']
    // form: [{ myData: 'data.formId' }]
  }),
  bindActionCreators({
    confirmActions
    // formActions
  })
)(CommonButtonContainer)
