import uniqId from 'uniqid'
import mapValues from 'lodash/mapValues'
import pick from 'lodash/pick'
import omitBy from 'lodash/omitBy'
import uniqBy from 'lodash/uniqBy'
import findIndex from 'lodash/findIndex'
import Joi from 'joi-browser'
import { isArray, isObject, isFunction } from 'lib/detectType'

export const isValidResult = (result, data, options, condInfo) => {
  const { error } = Joi.validate(
    result,
    Joi.object(
      mapValues(data, (value, key) => {
        if (value.simpleField || options.simpleForm) {
          return Joi.any()
        }
        if (value.allowedConditions) {
          return Joi.object(
            Object.assign(
              {},
              ...value.allowedConditions.map(cond => ({
                [cond]: Joi.string()
              }))
            )
          )
        }
        return Joi.object(
          mapValues(condInfo, () => {
            return Joi.string()
          })
        )
      })
    )
  )
  return error === null
}

export const getResult = (originItems, condInfo = {}, data = {}) => {
  const items = [].concat(originItems).filter(item => {
    const { type } = data[item.criteria]
    return item.value !== '' && type !== 'rangeDate'
  })
  const result = {}
  items.forEach(item => {
    const { criteria, condition, value } = item
    const { type } = data[criteria]
    if (condition && isObject(result[criteria])) {
      if (result[criteria][condition]) {
        result[criteria][condition] = isArray(result[criteria][condition])
          ? result[criteria][condition].concat(value)
          : [result[criteria][condition], value]
        return
      }
      Object.assign(
        result[criteria],
        condition ? { [condition || Object.keys(condInfo)[0]]: value } : value
      )
      return
    }
    if (type === 'datePicker') {
      result[criteria] = { gte: value[0], lte: value[1] }
      return
    }
    result[criteria] = condition
      ? { [condition || Object.keys(condInfo)[0]]: value }
      : value
  })
  return result
}

export const setItems = (
  data = {},
  value = {},
  options = {},
  condInfo = {}
) => {
  const { simpleForm } = options
  const itemsData = Object.keys(data).map(criteria => {
    // 검색 기준 옵션
    const {
      type,
      allowedConditions,
      simpleField = false,
      simpleFormOperator,
      simpleFormDateRange = true,
      defaultValue
    } = data[criteria] || {}
    const simpleFormFlag = simpleForm || simpleField
    const dateRangeFlag =
      simpleFormDateRange &&
      simpleFormFlag &&
      (type === 'date' || type === 'datetime')
    // 사용 가능한 검색 조건 모음
    const allowed = dateRangeFlag
      ? ['gte', 'lte']
      : simpleFormFlag
      ? simpleFormOperator
        ? [simpleFormOperator]
        : [undefined, 'eq', 'like']
      : isArray(allowedConditions)
      ? allowedConditions
      : Object.keys(condInfo)
    // 기본 값 설정을 위해 넘겨져온 파라미터 (주로 url에서 가져오게 됨)
    // 검색 조건 초기값이 존재하는 경우 검증
    const queryConditions = (
      isObject(value[criteria])
        ? Object.keys(value[criteria])
        : isArray(value[criteria])
        ? value[criteria].map(d => Object.keys(d)[0])
        : []
    ).filter(condition => allowed.indexOf(condition) !== -1)
    // 기본 검색 값
    let queryValue =
      queryConditions.length > 0
        ? isObject(value[criteria])
          ? pick(value[criteria], queryConditions)
          : isArray(value[criteria])
          ? value[criteria].map(d => Object.values(d)[0])
          : value[criteria]
        : value[criteria]
    if (type === 'rangeDate') {
      const { mapKeys } = data[criteria]
      if (isObject(mapKeys) && Object.keys(mapKeys).length === 2) {
        const [startDateKey, endDateKey] = Object.keys(mapKeys)
        const { valueFormatFromItem: startFormat } = mapKeys[startDateKey]
        const { valueFormatFromItem: endFormat } = mapKeys[endDateKey]
        if (value[startDateKey] && value[endDateKey]) {
          queryValue = [
            isFunction(startFormat)
              ? startFormat(value[startDateKey])
              : value[startDateKey],
            isFunction(endFormat)
              ? endFormat(value[endDateKey])
              : value[endDateKey]
          ].join(',')
        }
      }
    }
    const condition = dateRangeFlag
      ? allowed
      : queryConditions.length > 0
      ? queryConditions
      : allowed[0]
    return [].concat(
      ...(isArray(condition) ? condition : [condition]).map((cond, index) => {
        const value = isObject(queryValue)
          ? queryValue[cond] ||
            (dateRangeFlag && isObject(defaultValue)
              ? index
                ? defaultValue.end
                : defaultValue.start
              : defaultValue) ||
            ''
          : isArray(queryValue)
          ? queryValue[index]
          : queryValue ||
            (dateRangeFlag && isObject(defaultValue)
              ? index
                ? defaultValue.end
                : defaultValue.start
              : defaultValue) ||
            ''
        return [].concat(
          ...(isArray(value)
            ? simpleFormFlag
              ? [value[0]]
              : value
            : [value]
          ).map(value => ({
            hash: uniqId(),
            criteria,
            value,
            condition: cond
          }))
        )
      })
    )
  })
  const items = []
    .concat(...itemsData)
    .filter((item, index) => {
      const { criteria } = item
      const { simpleField = false } = data[criteria]
      const simpleFormFlag = simpleForm || simpleField
      // simple field를 제외한 item의 첫번째 index를 가져옵니다. (기본출력을 위해)
      const notSimpleFieldFirstIndex = findIndex(itemsData, arr => {
        const [o = {}] = arr
        return data[o.criteria].simpleField !== true
      })
      const notSimpleFieldValue = omitBy(
        value,
        (v, criteria) =>
          isObject(data[criteria]) && data[criteria].simpleField === true
      )
      return simpleFormFlag
        ? true
        : // simple field를 제외하고 검색값이 없는 경우
        Object.keys(notSimpleFieldValue).length === 0
        ? (notSimpleFieldFirstIndex === -1 ? 0 : notSimpleFieldFirstIndex) ===
          index
        : Object.keys(value).indexOf(criteria) !== -1
    })
    .slice(0, 10)
  // 조건과 연산자가 같은 경우 제거
  return uniqBy(items, o => {
    const { criteria, condition } = o
    return { criteria, condition }
  })
}

export default {
  isValidResult,
  getResult,
  setItems
}
