/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import autoBind from 'auto-bind'
import { withRouter } from 'react-router-dom'
import pick from 'lodash/pick'
import cloneDeep from 'lodash/cloneDeep'

// lib
// import config from 'config'
import bindStateToProps from 'lib/bindStateToProps'
import bindActionCreators from 'lib/bindActionCreators'
import isEqualProps from 'lib/isEqualProps'
import mapActionLog from 'lib/mapActionLog'
import proxyOverride from 'lib/proxyOverride'
import mapAsyncActions from 'lib/mapAsyncActions'
import clearContainerResponse from 'lib/clearContainerResponse'
import {
  isNumber,
  isString,
  isArray,
  isEmptyObject,
  isFunction,
  isObject
} from 'lib/detectType'
import getComponentId from 'lib/getComponentId'

// redux modules (action)
import { actions as consoleLayoutActions } from 'store/modules/consoleLayout'

// common modules (action)
// import { actions as formActions } from 'store/modules/form'
// import { actions as tableActions } from 'store/modules/table'
// import { actions as searchActions } from 'store/modules/search'
// import { actions as uploadActions } from 'store/modules/upload'
// import { actions as popupActions } from 'store/modules/popup'
// import { actions as snackbarActions } from 'store/modules/snackbar'
import { actions as confirmActions } from 'store/modules/confirm'
// import { actions as helpActions } from 'store/modules/help'
// import { actions as supportActions } from 'store/modules/support'
// import { actions as excelActions } from 'store/modules/excel'

// react components & containers
import ComponentGuidePointer from 'components/organisms/ComponentGuidePointer'

/**
 * 각종 컴포넌트 가이드 또는 도움말, 하이라이트 표기 시 사용되는 컨테이너
 */
// const containerKeyName = 'ComponentGuideContainerCustomKey'
class ComponentGuideContainer extends Component {
  constructor(props) {
    super(props)
    // 해당 컨테이너 내의 메서드에 자동으로 this 클래스를 바인딩 합니다.
    autoBind(this)
    // 해당 컨테이너 내의 메서드 실행 시 액션로그를 남깁니다.
    mapActionLog(this)
    // HOC 사용 시 현재 컨테이너에 proxy가 props에 존재하는 경우 proxy 메서드에 this 클래스의 메서드를 대입
    proxyOverride(this)
    // 2차 인증을 사용하는 리덕스 액션이 존재하는 경우 덮어씌워 await이 정상적으로 작동하게 하고, 액션에 출처 컨테이너를 기록합니다.
    mapAsyncActions(this.props, {
      // exceptActions: { AppsActions: true }
    })
    this.state = { elementsRect: {}, guidePriority: [], currentGuideIndex: -1 }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    const isUpdateHighlight =
      nextProps.highlight?.length > 0 &&
      !isEqualProps(nextProps, this.props, ['highlight'])
    const isUpdateGuide =
      !isEmptyObject(nextProps.componentGuide) &&
      !isEqualProps(nextProps, this.props, ['componentGuide'])
    return (
      isUpdateGuide ||
      isUpdateHighlight ||
      !isEqualProps(nextState, this.state, [
        'elementsRect',
        'currentGuideIndex'
      ])
    )
  }

  // hoc 사용 시 해당 부분 삭제하세요.
  componentDidMount = () => {
    // this.initForm()
  }

  componentDidUpdate = async prevProps => {
    const {
      ConsoleLayoutActions,
      componentGuide = {},
      highlight = []
    } = this.props
    if (
      !isEqualProps(this.props, prevProps, ['componentGuide']) &&
      !isEmptyObject(componentGuide)
    ) {
      this.initComponentGuide()
      return ConsoleLayoutActions.setState({ componentGuide: {} })
    }
    if (
      !isEqualProps(this.props, prevProps, ['highlight']) &&
      highlight?.length > 0
    ) {
      const { elementsRect } = await this.setHighlight(highlight, true)
      this.setState({ elementsRect })
      ConsoleLayoutActions.setState({ highlight: [] })
    }
  }

  // 정리 필수
  componentWillUnmount = () => {
    // const { FormActions } = this.props
    // const { RbcActions } = this.props
    // FormActions.clear({ key: containerKeyName })
    // 현재 컨테이너에서 사용된 특정 action의 response 제거
    // RbcActions.clearCurrentContainerResponse()
    // 현재 컨테이너에서 사용된 모든 response 제거
    this.onClear()
    clearContainerResponse(this.props)
  }

  waitElementLoad = async (
    callback,
    notFoundCallback,
    ms = 1000,
    maxCount = 5
  ) => {
    let tryCount = 0
    while (true) {
      if (tryCount >= maxCount) {
        notFoundCallback()
        break
      }
      try {
        await callback()
        break
      } catch (error) {
        tryCount += 1
        await this.wait(ms)
        continue
      }
    }
    return Promise.resolve()
  }

  wait = ms => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve()
      }, ms)
    })
  }

  initComponentGuide = async () => {
    const { componentGuide = {} } = this.props
    const queue = componentGuide?.queue || []
    const { elementsRect, guidePriority } = await this.setHighlight(
      queue,
      false
    )
    this.setState({
      rawComponentGuide: cloneDeep(componentGuide),
      guidePriority,
      elementsRect,
      currentGuideIndex: 0
    })
  }

  setHighlight = async (highlight = [], isError = true) => {
    if (!isArray(highlight)) return {}
    const guidePriority = []
    const elementsRect = {}
    await Promise.all(
      highlight.map(async data => {
        const componentName = isString(data) ? data : Object.keys(data)?.[0]
        const message = isString(data) ? '' : Object.values(data)?.[0]?.message
        const componentId = getComponentId(componentName)
        guidePriority.push(componentId)
        return this.setHighlightInfo(
          componentId,
          componentName,
          message,
          isError
        )
      })
    ).then(res => {
      Object.assign(elementsRect, ...res)
    })
    return { guidePriority, elementsRect }
  }

  setHighlightInfo = async (
    componentId,
    componentName,
    message = '',
    isError = false
  ) => {
    const info = {
      componentName,
      message,
      visible: true
    }
    if (isError === true) {
      Object.assign(info, {
        isError: true,
        timer: setTimeout(() => {
          this.clearTimeout(componentId)
          this.onRemove(componentId)
        }, 20000)
      })
    }
    await this.waitElementLoad(
      () => {
        const el = document.getElementById(componentId)
        const els = document.querySelectorAll(`[data-compid="${componentId}"]`)
        if (el) {
          this.addElementInfo(info, el)
          return Promise.resolve()
        }
        if (els?.length > 0) {
          els.forEach(el => {
            return this.addElementInfo(info, el)
          })
          return Promise.resolve()
        }
        return Promise.reject()
      },
      () => {
        Object.assign(info, { isNotFoundComponent: true })
      },
      // 에러 하이라이트의 경우 컴포넌트 찾기 지연 해제
      isError ? 500 : 1000,
      isError ? 1 : 5
    )
    return { [componentId]: info }
  }

  addElementInfo = (info, el) => {
    const zIndex = this.getZIndex(el)
    const clientRect = el.getBoundingClientRect()
    return Object.assign(info, {
      zIndex: isNumber(Number(zIndex)) ? Number(zIndex) : 1300,
      ...pick(clientRect, ['width', 'height', 'left', 'top'])
    })
  }

  getZIndex = el => {
    if (!el) return
    const zIndex = window.getComputedStyle(el).zIndex
    if (zIndex === 'auto') {
      return this.getZIndex(el.parentElement)
    }
    return zIndex
  }

  clearTimeout = componentId => {
    const { elementsRect = {} } = this.state
    clearTimeout(elementsRect?.[componentId]?.timer)
  }

  onRemove = async componentId => {
    const { elementsRect = {} } = this.state
    const newElementsRect = {
      ...elementsRect,
      [componentId]: { ...elementsRect[componentId], visible: false }
    }
    this.setState({ elementsRect: newElementsRect })
  }

  onClear = () => {
    const { ConsoleLayoutActions } = this.props
    this.setState({
      elementsRect: {},
      guidePriority: [],
      currentGuideIndex: -1
    })
    ConsoleLayoutActions.setState({ highlight: [], componentGuide: {} })
  }

  onClickNextGuide = async () => {
    const {
      guidePriority = [],
      currentGuideIndex,
      rawComponentGuide
    } = this.state
    const maxLength = guidePriority?.length
    if (currentGuideIndex + 1 >= maxLength) {
      this.onClear()
    } else {
      const queue = rawComponentGuide?.queue || []
      const { elementsRect } = await this.setHighlight(queue, false)
      this.setState({ elementsRect, currentGuideIndex: currentGuideIndex + 1 })
    }
  }

  onClickPrevGuide = async () => {
    const { currentGuideIndex, rawComponentGuide } = this.state
    if (currentGuideIndex < 0) {
      return
    }
    const queue = rawComponentGuide?.queue || []
    const { elementsRect } = await this.setHighlight(queue, false)
    this.setState({ elementsRect, currentGuideIndex: currentGuideIndex - 1 })
  }

  onClickFinishGuide = (skipFlag, callback) => {
    const { ConfirmActions } = this.props
    const { rawComponentGuide } = this.state
    const lastCallback = () => {
      if (isFunction(callback)) {
        callback()
      }
      if (isFunction(rawComponentGuide?.onComplete)) {
        rawComponentGuide.onComplete()
      }
    }
    if (skipFlag === true) {
      ConfirmActions.show({
        title: '가이드 마치기',
        severity: 'info',
        message: '사용자 화면 안내를 종료할까요? 나중에 다시 확인할 수 있어요.',
        onConfirm: () => {
          lastCallback()
          setTimeout(() => {
            this.onClear()
          }, 400)
        }
      })
    } else {
      lastCallback()
      setTimeout(() => {
        this.onClear()
      }, 400)
    }
  }

  onClickEventBlocker = () => {
    const { guidePriority = [], currentGuideIndex } = this.state
    const maxLength = guidePriority?.length
    const isLast = currentGuideIndex + 1 >= maxLength
    if (isLast) {
      this.onClickFinishGuide(true)
    }
  }

  render = () => {
    const { guidePriority, elementsRect = {}, currentGuideIndex } = this.state
    if (!isObject(elementsRect) || isEmptyObject(elementsRect)) {
      return null
    }
    return (
      <ComponentGuidePointer
        guidePriority={guidePriority}
        elementsRect={elementsRect}
        currentGuideIndex={currentGuideIndex}
        onRemove={this.onRemove}
        onClickNextGuide={this.onClickNextGuide}
        onClickPrevGuide={this.onClickPrevGuide}
        onClickFinishGuide={this.onClickFinishGuide}
        onClickEventBlocker={this.onClickEventBlocker}
      />
    )
  }
}

export default withRouter(
  connect(
    bindStateToProps({
      consoleLayout: ['highlight', 'componentGuide']
    }),
    bindActionCreators({
      confirmActions,
      consoleLayoutActions
    })
  )(ComponentGuideContainer)
)
