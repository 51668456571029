/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { limitation as limitationAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const handler = {}
const initialState = Map({
  defaultLimit: 2,
  limitUploadDocuments: [],
  limitSelectedDocs: [],
  limitDocs: {},
  responseData: {}
})

Object.assign(handler, {
  UPLOAD_DOCUMENTS: {
    payloadCreator: limitationAPI.uploadDocuments,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const limitDocs = state.get('limitDocs')
        const message = '업로드 완료'
        const stateData = {
          limitDocs: Object.assign({}, limitDocs, {
            data: [action.payload.data].concat(limitDocs.data)
          })
        }
        return onSuccess(state, action, { ...options, stateData, message })
      }
    }
  }
})

Object.assign(handler, {
  GET_DOCUMENTS: {
    payloadCreator: limitationAPI.getDocuments,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { limitDocs: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
})

Object.assign(handler, {
  SUBMIT: {
    payloadCreator: limitationAPI.submit,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { senderIdData: action.payload.data }
        const message = '정상적으로 발신번호 개수 제한 해제 요청했습니다.'
        return onSuccess(state, action, { ...options, stateData, message })
      }
    }
  }
})

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
