import axios from 'axios'
import qs from 'querystring'
import pick from 'lodash/pick'

export const getAppList = params => {
  const query = params
    ? `?${qs.stringify(
        pick(params, [
          'offset',
          'limit',
          'accountId',
          'appId',
          'appName',
          'categories',
          'type'
        ])
      )}`
    : ''
  return axios.get(`/appstore/v2/apps${query}`)
}

export const getAppData = appId => {
  const query = `?${qs.stringify({ appId })}`
  return axios.get(`/appstore/v2/apps${query}`)
}

export const addAppList = payload => {
  const query = `?${qs.stringify(payload)}`
  return axios.get(`/appstore/v2/apps${query}`)
}

export const getProfitHistory = payload => {
  const url = `/appstore/v2/profits?${qs.stringify(payload)}`
  return axios.get(url)
}

export const getProfitHistoryDetail = payload => {
  const url = `/appstore/v2/profits/daily/detail?${qs.stringify(payload)}`
  return axios.get(url)
}

export const getApps = payload => {
  const query = qs.stringify(payload)
  const url = `/appstore/v2/me/apps${query ? '?' + query : ''}`
  return axios.get(url)
}

export const createApp = params => {
  const url = `/appstore/v2/me/apps`
  return axios.post(url, params)
}

export const updateApp = params => {
  const { appId, ...requestParams } = params
  const url = `/appstore/v2/me/apps/${appId}`
  return axios.put(url, requestParams)
}

export const deleteApp = params => {
  const { appId } = params
  const url = `/appstore/v2/me/apps/${appId}`
  return axios.delete(url)
}

export const uploadThumbnail = params => {
  const { appId, ...requestParams } = params
  const url = `/appstore/v2/me/apps/${appId}/images/thumbnail`
  return axios.post(url, requestParams)
}

export const createAppDomain = payload => {
  const { appId, ...params } = payload
  const url = `/appstore/v2/me/apps/${appId}/apphome`
  return axios.post(url, params)
}

export const updateAppDomain = payload => {
  const { appId, ...params } = payload
  const url = `/appstore/v2/me/apps/${appId}/apphome`
  return axios.put(url, params)
}

export const createSubAccount = payload => {
  const { appId, accountId } = payload
  const url = `/appstore/v2/me/apps/${appId}/subaccounts`
  return axios.post(url, { accountId })
}

export const getSubAccounts = payload => {
  const { appId, ...params } = payload
  const query = qs.stringify(params)
  const url = `/appstore/v2/me/apps/${appId}/subaccounts${
    query ? '?' + query : ''
  }`
  return axios.get(url)
}

export const deleteSubAccount = payload => {
  const { appId, accountId } = payload
  const url = `/appstore/v2/me/apps/${appId}/subaccounts`
  return axios.delete(url, { data: { accountId } })
}

export const getMysiteUserProfit = payload => {
  const { appId, ...params } = payload
  const query = qs.stringify(params)
  const url = `/appstore/v2/me/apps/${appId}/apphome/profit${
    query ? '?' + query : ''
  }`
  return axios.get(url)
}

export const createMysiteUserProfit = payload => {
  const { appId, ...params } = payload
  const url = `/appstore/v2/me/apps/${appId}/apphome/profit`
  return axios.post(url, params)
}

export const updateMysiteUserProfit = payload => {
  const { appId, ...params } = payload
  const url = `/appstore/v2/me/apps/${appId}/apphome/profit`
  return axios.put(url, params)
}

export const deleteMysiteUserProfit = payload => {
  const { appId, accountId } = payload
  const url = `/appstore/v2/me/apps/${appId}/apphome/profit`
  return axios.delete(url, { data: { accountId } })
}

export const createContentImage = payload => {
  const { appId, image, name } = payload
  const uri = `/appstore/v2/me/apps/${appId}/images/screenshots`
  return axios.post(uri, { image, name })
}

export const createThumbnail = payload => {
  const { appId, image, name } = payload
  const uri = `/appstore/v2/me/apps/${appId}/images/thumbnail`
  return axios.post(uri, { image, name })
}

export const updateStage = payload => {
  const { appId } = payload
  const uri = `/appstore/v2/me/apps/${appId}/stage`
  return axios.put(uri)
}

export const updateSubAccount = payload => {
  const { appId, accountId, ...params } = payload
  const uri = `/appstore/v2/me/apps/${appId}/subaccounts/${accountId}`
  return axios.put(uri, params)
}

export const deleteSubAccountCustomFields = payload => {
  const { appId, ...data } = payload
  const uri = `/appstore/v2/me/apps/${appId}/subaccounts/customfields`
  return axios.delete(uri, { data })
}

export const rechargeSubAccount = payload => {
  const { appId, subAccountId, ...params } = payload
  const uri = `/appstore/v2/me/apps/${appId}/subaccounts/${subAccountId}/balance`
  return axios.post(uri, params)
}
