const removeWhiteSpace = text => {
  return (
    String(text)
      .replace(/[\ud7a4-\uffff]/gmu, '')
      // 엔티티 줄바꿈 문자 제거
      .replace(/\u00A0/g, ' ')
  )
}

export default removeWhiteSpace
