import axios from 'axios'
import qs from 'qs'

export const getHistory = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/auth/v1/tokens${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const getSettings = (params = {}) => {
  const queryString = qs.stringify(params)
  const uri = `/auth/v1/settings${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const request = (params = {}) => {
  const uri = `/auth/v1/request`
  return axios.post(uri, params)
}

export const updateSettings = (params = {}) => {
  const uri = `/auth/v1/settings`
  return axios.put(uri, params)
}

export const verifyHash = (params = {}) => {
  const uri = `/auth/v1/hash/verify`
  return axios.post(uri, params)
}
