/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { message as messageAPI } from 'lib/api'
import _ from 'lodash'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  order: 'asc',
  orderBy: 'date',
  statistics: {},
  chartPeriod: []
})
const checkTypes = checkTarget => {
  const types = {
    total: 0,
    sms: 0,
    lms: 0,
    mms: 0,
    ata: 0,
    cta: 0,
    cti: 0
  }
  const target = ['successed', 'failed', 'total']
  const targetType = Object.prototype.toString.call(checkTarget)
  if (targetType === '[object Object]') {
    if (checkTarget.monthPeriod) checkTypes(checkTarget.monthPeriod)
    if (checkTarget.dayPeriod) {
      checkTarget.dayPeriod.forEach(day => {
        checkTypes(day)
      })
    }
    target.forEach(t => {
      checkTarget[t] = { ...types, ...checkTarget[t] }
    })
  } else if (targetType === '[object Array]') {
    checkTarget.forEach(t => checkTypes(t))
  }
}
const handler = {
  PERIOD_SORT: {
    reducer: (state, action) => {
      const statistics = state.get('statistics')
      const { type, order, property } = action.payload
      if (statistics[type] === undefined) return
      statistics[type] = _.sortBy(statistics[type], [property])
      if (order === 'desc') statistics[type].reverse()
      return state
        .set('statistics', Object.assign({}, statistics))
        .set('order', order)
        .set('orderBy', property)
    }
  },
  GET_STATISTICS: {
    payloadCreator: messageAPI.getStatistics,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const statistics = Object.assign({}, action.payload.data)
        const { dayPeriod = [], monthPeriod = [] } = statistics
        statistics.dayPeriod = _.sortBy(dayPeriod, ['date'])
        statistics.monthPeriod = _.sortBy(monthPeriod, ['date'])
        checkTypes(statistics)
        const stateData = {
          statistics,
          chartPeriod: [].concat(...statistics.dayPeriod)
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
