import axios from 'axios'
import qs from 'qs'

export const fetchData = async ({
  offset,
  limit,
  startDate,
  endDate,
  selectedApp
}) => {
  const query = {
    offset,
    limit,
    startDate,
    endDate
  }
  if (selectedApp !== 'ALL') query.appId = selectedApp
  const { data: dataList } = await axios.get(
    `/appstore/v2/profits/daily/detail?${qs.stringify(query)}`
  )
  return {
    dataList
  }
}
