/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
// import omitBy from 'lodash/omitBy'

// material UI
// import Grid from '@material-ui/core/Grid'
// import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'

// my components
import withCompWidth from 'commonHoc/withCompWidth'
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'
// import CommonButton from 'containers/CommonButtonContainer'

// libraries
// import config from 'config'
// import { isArray } from 'lib/detectType'
import isEqualProps from 'lib/isEqualProps'

const styles = theme => ({
  root: {
    maxWidth: 320,
    borderRadius: 10,
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 10px 20px 0 rgba(0, 0, 0, .15)'
  }
})
export const useProps = {}
/**
 * (작성된 설명이 없습니다.)
 */
const CommonFormPaper = React.memo(
  ({ classes, proxy, ...props }) => {
    const { children } = props
    return (
      <Box className={classes.root} p={3} pt={6} pb={6}>
        {children}
      </Box>
    )
  },
  (prevProps, nextProps) => {
    return isEqualProps(nextProps, prevProps)
  }
)

CommonFormPaper.defaultProps = setDefaultProps(useProps)
CommonFormPaper.propTypes = setPropTypes(useProps)
export default withStyles(styles)(withCompWidth(CommonFormPaper))
