import { Map } from 'immutable'
import { accountManagement as accountManagementAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  accountInfo: {},
  confirmInfo: {
    isConfirm: false,
    title: '제목',
    text: '내용',
    submitText: '확인 텍스트',
    loading: false,
    submitAction: undefined
  },
  snackBarInfo: {
    open: false,
    variant: 'success',
    message: ''
  },
  memberInfo: {
    role: ''
  },
  errorMessage: ''
})
const handler = {
  GET_ACCOUNT_INFO: {
    payloadCreator: accountManagementAPI.getAccountInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { accountInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  OPEN_CONFIRM_DIALOG: {
    reducer: (state, action) => {
      const confirmInfo = state.get('confirmInfo')
      action.payload.isConfirm = true
      return state.set(
        'confirmInfo',
        Object.assign({}, confirmInfo, action.payload)
      )
    }
  },
  CLOSE_CONFIRM_DIALOG: {
    reducer: (state, action) => {
      const confirmInfo = state.get('confirmInfo')
      confirmInfo.isConfirm = false
      return state.set('confirmInfo', Object.assign({}, confirmInfo))
    }
  },
  CLOSE_SNACK_BAR: {
    reducer: (state, action) => {
      const snackBarInfo = state.get('snackBarInfo')
      snackBarInfo.open = false
      return state.set('snackBarInfo', Object.assign({}, snackBarInfo))
    }
  },
  START_LOADING: {
    reducer: (state, action) => {
      const confirmInfo = state.get('confirmInfo')
      confirmInfo.loading = true
      return state.set('confirmInfo', Object.assign({}, confirmInfo))
    }
  },
  END_LOADING: {
    reducer: (state, action) => {
      const confirmInfo = state.get('confirmInfo')
      confirmInfo.loading = false
      return state.set(
        'confirmInfo',
        Object.assign({}, state.get('confirmInfo'), confirmInfo)
      )
    }
  },
  DELETE_ACCOUNT: {
    payloadCreator: accountManagementAPI.deleteAccount,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const snackBarInfo = state.get('snackBarInfo')
        snackBarInfo.open = true
        snackBarInfo.variant = 'success'
        snackBarInfo.message = '성공적으로 계정이 삭제되었습니다.'
        const stateData = { snackBarInfo: Object.assign({}, snackBarInfo) }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  OPEN_SNACK_BAR: {
    reducer: (state, action) => {
      return state.set('snackBarInfo', action.payload)
    }
  },
  GET_MEMBER_INFO: {
    payloadCreator: accountManagementAPI.getMemberInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { memberInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
