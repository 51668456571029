import crypto from 'crypto-js'

const isDev = process.env.NODE_ENV !== 'production'
export default function getComponentId(componentName) {
  const componentId = crypto.SHA256(componentName).toString()
  return (
    componentId +
    (isDev ? `_${componentName.replace(/[^0-9a-zA-Z가-힣]/g, '')}` : '')
  )
}
