import axios from 'axios'
import _ from 'lodash'
import omit from 'lodash/omit'
import qs from 'qs'
import { isEmptyString } from 'lib/detectType'

export const getPlusFriends = (params = {}) => {
  const searchParams = _.omitBy(params, _.isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v1/plus-friends${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getChannels = params => {
  const queryString = qs.stringify(params)
  const uri = `/kakao/v1/plus-friends${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const getPlusFriendInfo = params => {
  const { pfId } = params
  const uri = `/kakao/v1/plus-friends/${pfId}`
  return axios.get(uri)
}

export const getTemplates = (params = {}, options = {}) => {
  const { cache = false } = options
  const searchParams = _.omitBy({ limit: 50, ...params }, _.isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v1/templates${query ? '?' + query : ''}`
  return axios.get(uri, { cache })
}

export const getTemplateInfo = (params, options = {}) => {
  const { cache = false } = options
  const { templateId } = params
  const uri = `/kakao/v1/templates/${templateId}`
  return axios.get(uri, { cache })
}

export const getTemplate = payload => {
  const { templateId } = payload
  const uri = `/kakao/v2/templates/${templateId}`
  return axios.get(uri)
}

export const setTemplate = payload => {
  const { service, ...params } = payload
  const uri = `/kakao/v1/templates`
  return axios.post(uri, params)
}

export const putTemplate = payload => {
  const { templateId, service, pfId, pfGroupId, ...params } = payload
  const uri = `/kakao/v1/templates/${templateId}`
  return axios.put(uri, params)
}

export const putTemplateComment = params => {
  const { templateId, comment } = params
  const uri = `/kakao/v1/templates/${templateId}/comment`
  return axios.post(uri, { comment })
}

export const templateInspection = payload => {
  const { templateId, service, ...params } = payload
  const uri = `/kakao/v1/templates/${templateId}/inspection`
  return axios.put(uri, params)
}

export const deleteTemplate = params => {
  const { templateId } = params
  const uri = `/kakao/v2/templates/${templateId}`
  return axios.delete(uri)
}

export const uploadKakaoImage = params => {
  const uri = `/kakao/v1/images`
  return axios.post(uri, params)
}

export const getKakaoImages = params => {
  const searchParams = _.omitBy({ limit: 25, ...params }, _.isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v1/images${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const requestToken = params => {
  const uri = `/kakao/v1/plus-friends/token`
  return axios.post(uri, omit(params, ['service']))
}

export const putPlusFriend = params => {
  const uri = `/kakao/v1/plus-friends`
  return axios.post(uri, omit(params, ['service']))
}

export const deletePlusFriend = params => {
  const { pfId } = params
  const uri = `/kakao/v1/plus-friends/${pfId}`
  return axios.delete(uri)
}

export const getCategories = () => {
  const uri = `/kakao/v1/categories`
  return axios.get(uri)
}

export const hideTemplate = payload => {
  const { templateId, isHidden } = payload
  const uri = `/kakao/v1/templates/${templateId}/hide`
  return axios.put(uri, { isHidden })
}

export const sharePlusFriend = payload => {
  const { pfId, accountId } = payload
  const uri = `/kakao/v1/plus-friends/share/${pfId}`
  return axios.post(uri, { accountId })
}

export const stopSharePlusFriend = payload => {
  const { pfId, accountId } = payload
  const uri = `/kakao/v1/plus-friends/share/${pfId}/${accountId}`
  return axios.delete(uri)
}

export const getGroups = payload => {
  const searchParams = _.omitBy(payload, _.isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v1/pf-groups${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getGroupInfo = payload => {
  const channelGroupId = isEmptyString(payload?.channelGroupId)
    ? payload?.pfGroupId
    : payload?.channelGroupId
  const uri = `/kakao/v2/channel-groups/${channelGroupId}`
  return axios.get(uri)
}

export const getGroupInfoV1 = payload => {
  const { pfGroupId } = payload
  const uri = `/kakao/v1/pf-groups/${pfGroupId}`
  return axios.get(uri)
}

export const createGroup = payload => {
  const { name, channelIds, reasonCreated } = payload
  const uri = `/kakao/v2/channel-groups`
  return axios.post(uri, { name, channelIds, reasonCreated })
}

export const insertPlusFriendToGroup = payload => {
  const { pfGroupId, pfId } = payload
  const uri = `/kakao/v1/pf-groups/${pfGroupId}/plus-friends`
  return axios.post(uri, { pfId })
}

export const deletePlusFriendToGroup = payload => {
  const { pfGroupId, pfId } = payload
  const uri = `/kakao/v1/pf-groups/${pfGroupId}/plus-friends/${pfId}`
  return axios.delete(uri)
}

export const getSendableTemplates = payload => {
  const searchParams = _.omitBy(payload, _.isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v1/templates/sendable${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const inviteChannelToGroup = payload => {
  const { channelGroupId, channelId } = payload
  const uri = `/kakao/v2/channel-groups/invitations/${channelGroupId}`
  return axios.post(uri, { channelId })
}

export const cancelInvitation = payload => {
  const { invitationId } = payload
  const uri = `/kakao/v2/channel-groups/invitations/${invitationId}`
  return axios.delete(uri)
}

export const acceptInvitation = payload => {
  const { invitationId } = payload
  const uri = `/kakao/v2/channel-groups/invitations/${invitationId}/accept`
  return axios.post(uri, {})
}

export const refuseInvitation = payload => {
  const { invitationId } = payload
  const uri = `/kakao/v2/channel-groups/invitations/${invitationId}/refuse`
  return axios.post(uri, {})
}

export const getInvitationReceiveList = (payload = {}) => {
  const { channelGroupId, channelId, status, limit } = payload
  const searchParams = _.omitBy(
    {
      channelGroupId,
      channelId,
      status,
      targetType: 'FROM',
      limit
    },
    _.isUndefined
  )
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v2/channel-groups/invitations${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getInvitationSendList = (payload = {}) => {
  const { channelGroupId, channelId, status, limit } = payload
  const searchParams = _.omitBy(
    {
      channelGroupId,
      channelId,
      status,
      targetType: 'TO',
      limit
    },
    _.isUndefined
  )
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v2/channel-groups/invitations${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getInvitation = payload => {
  const { invitationId } = payload
  const uri = `/kakao/v2/channel-groups/invitations/${invitationId}`
  return axios.get(uri)
}

export const getSearchIds = payload => {
  const { channelIds = [] } = payload
  const searchParams = _.omitBy({ channelIds }, _.isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v2/searchid${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const requestChannelTokenToAddService = payload => {
  const { channelId, channelGroupId, phoneNumber } = payload
  const uri = `/kakao/v2/channels/token/${channelId}`
  return axios.post(uri, { channelGroupId, phoneNumber })
}

export const createChannelToAddService = payload => {
  const { channelId, token } = payload
  const uri = `/kakao/v2/channels/${channelId}/service`
  return axios.post(uri, { token })
}

export const getChannel = payload => {
  const { channelId } = payload
  const uri = `/kakao/v2/channels/${channelId}`
  return axios.get(uri)
}

export const getChannelList = payload => {
  const query = qs.stringify(payload)
  const uri = `/kakao/v2/channels${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getGroupList = payload => {
  const query = qs.stringify(payload)
  const uri = `/kakao/v2/channel-groups${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getChannelGroupList = payload => {
  const { channelIds = [], status } = payload
  const searchParams = _.omitBy({ channelIds, status }, _.isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/kakao/v2/channel-groups${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const exitGroup = payload => {
  const { channelGroupId, channelId } = payload
  const uri = `/kakao/v2/channel-groups/${channelGroupId}/channels/${channelId}/exit`
  return axios.delete(uri)
}

export const updateGroup = payload => {
  const { channelGroupId, ...params } = payload
  const uri = `/kakao/v2/channel-groups/${channelGroupId}`
  return axios.put(uri, params)
}

export const deleteGroup = payload => {
  const { channelGroupId } = payload
  const uri = `/kakao/v2/channel-groups/${channelGroupId}`
  return axios.delete(uri)
}

export const updateTemplateName = payload => {
  const { templateId, name } = payload
  const uri = `/kakao/v2/templates/${templateId}/name`
  return axios.put(uri, { name })
}

// v2
export const createTemplate = payload => {
  const uri = `/kakao/v2/templates`
  return axios.post(uri, payload)
}

// v2
export const updateTemplate = payload => {
  const { templateId, ...params } = payload
  const uri = `/kakao/v2/templates/${templateId}`
  return axios.put(uri, params)
}

// v2
export const requestTemplateInspection = payload => {
  const { templateId, ...params } = payload
  const uri = `/kakao/v2/templates/${templateId}/inspection`
  return axios.put(uri, params)
}

// v2
export const cancelTemplateInspection = payload => {
  const { templateId } = payload
  const uri = `/kakao/v2/templates/${templateId}/inspection/cancel`
  return axios.put(uri)
}

// v2
export const getSenderKeys = payload => {
  const { channelId } = payload
  const uri = `/kakao/v2/channels/${channelId}/sender-key`
  return axios.get(uri)
}
