import cookies from 'js-cookie'

import getCookieDomain from 'lib/getCookieDomain'
import { isObject } from 'lib/detectType'

const logoutChannelIO = () => {
  try {
    const localStorageList = Object.keys(localStorage)
    localStorageList.forEach(key => {
      const isChannelTalkData = /^Channel\..+/.test(key)
      if (isChannelTalkData === true) {
        localStorage.removeItem(key)
      }
    })
  } catch (error) {}
  const cookieList = cookies.get()
  if (isObject(cookieList)) {
    const keys = Object.keys(cookieList)
    const channelTalkCookies = keys.filter(name => {
      return /^ch-.+/.test(name)
    })
    channelTalkCookies.forEach(name => {
      cookies.remove(name, { path: '/', domain: getCookieDomain() })
    })
  }
}

export default logoutChannelIO
