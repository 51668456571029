export default [
  '김',
  '이',
  '박',
  '최',
  '정',
  '강',
  '조',
  '윤',
  '장',
  '임',
  '한',
  '오',
  '서',
  '신',
  '권',
  '황',
  '안',
  '송',
  '류',
  '전',
  '홍',
  '고',
  '문',
  '양',
  '손',
  '배',
  '백',
  '허',
  '유',
  '남',
  '심',
  '노',
  '하',
  '곽',
  '성',
  '차',
  '주',
  '우',
  '구',
  '라',
  '민',
  '진',
  '지',
  '엄',
  '채',
  '원',
  '천',
  '방',
  '공',
  '현',
  '함',
  '변',
  '염',
  '여',
  '추',
  '도',
  '소',
  '석',
  '선',
  '설',
  '마',
  '길',
  '연',
  '위',
  '표',
  '명',
  '기',
  '반',
  '왕',
  '금',
  '옥',
  '육',
  '인',
  '맹',
  '제',
  '모',
  '탁',
  '국',
  '어',
  '은',
  '편',
  '용',
  '예',
  '경',
  '봉',
  '사',
  '부',
  '가',
  '복',
  '태',
  '목',
  '형',
  '계',
  '피',
  '두',
  '감',
  '음',
  '빈',
  '동',
  '온',
  '호',
  '범',
  '좌',
  '팽',
  '승',
  '간',
  '상',
  '시',
  '갈',
  '단',
  '견',
  '당',
  '화',
  '창',
  '옹',
  '순',
  '빙',
  '종',
  '풍',
  '대',
  '엽',
  '궁',
  '아',
  '평',
  '독',
  '내',
  '랑',
  '판',
  '로',
  '매',
  '초',
  '궉',
  '묵',
  '근',
  '점',
  '탄',
  '해',
  '만',
  '필',
  '돈',
  '운',
  '곡',
  '야',
  '섭',
  '자',
  '포',
  '후',
  '담',
  '뢰',
  '학',
  '수',
  '나',
  '요',
  '총',
  '삼',
  '애',
  '묘',
  '교',
  '미',
  '비',
  '관',
  '무',
  '영',
  '등',
  '란',
  '산',
  '증',
  '난',
  '망',
  '다',
  '번',
  '보',
  '완',
  '탕'
]
