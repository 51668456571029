import { Map } from 'immutable'
import _ from 'lodash'
import qs from 'qs'
import uniqid from 'uniqid'
import { getResult, setItems } from 'lib/getSearchResult'
import { createRedux } from 'lib/requestHandler'
import { isArray, isObject, isEmptyString } from 'lib/detectType'
import cloneDeep from 'lodash/cloneDeep'

const initialState = Map({
  hash: {},
  data: {},
  items: {},
  result: {},
  query: {},
  options: {},
  simpleForm: {},
  originDefaultValue: {},
  condInfo: {
    eq: '와(과) 같음 (=)',
    ne: '와(과) 같지 않음 (!=)',
    gte: '보다 크거나 같음 (>=)',
    gt: '보다 큼 (>)',
    lte: '보다 작거나 같음 (<=)',
    lt: '보다 작음 (<)',
    like: '문자를 포함'
  }
})

const handler = {
  INIT: {
    reducer: (state, action) => {
      const {
        key,
        options = {},
        data = {},
        value,
        schema = {}
      } = action.payload
      const originHash = state.get('hash')
      const originData = state.get('data')
      const originItems = state.get('items')
      const originOptions = state.get('options')
      const originResult = state.get('result')
      const originQuery = state.get('query')
      const originSchema = state.get('schema')
      const originDefaultValue = state.get('defaultValue')
      const condInfo = state.get('condInfo')
      const defaultValue = _.omitBy(
        _.mapValues(data, o => {
          return o.defaultValue
        }),
        _.isUndefined
      )
      const initValue = Object.assign(
        {},
        defaultValue,
        isObject(value)
          ? value
          : qs.parse(window.location.search.replace(/\?/, ''))
      )
      // const searchValue = isValidResult(initValue, data, options, condInfo)
      //   ? initValue
      //   : {}
      const items = setItems(data, initValue, options, condInfo)
      const result = getResult(items, condInfo, data)
      const query = qs.stringify(result, { arrayFormat: 'index' })
      const hash = uniqid()
      return state
        .set('hash', Object.assign({}, originHash, { [key]: hash }))
        .set('options', Object.assign({}, originOptions, { [key]: options }))
        .set('data', Object.assign({}, originData, { [key]: data }))
        .set('items', Object.assign({}, originItems, { [key]: items }))
        .set('result', Object.assign({}, originResult, { [key]: result }))
        .set('query', Object.assign({}, originQuery, { [key]: query }))
        .set('schema', Object.assign({}, originSchema, { [key]: schema }))
        .set(
          'defaultValue',
          Object.assign({}, originDefaultValue, { [key]: defaultValue })
        )
    }
  },
  SET_ITEMS: {
    reducer: (state, action) => {
      const { key, value } = action.payload
      const originData = state.get('data')
      const originOptions = state.get('options')
      const originItems = state.get('items')
      const condInfo = state.get('condInfo')
      const data = originData[key]
      const options = originOptions[key]
      const items = setItems(data, value, options, condInfo)
      return state.set(
        'items',
        Object.assign({}, originItems, { [key]: items })
      )
    }
  },
  ADD_ITEM: {
    reducer: (state, action) => {
      const { key } = action.payload
      const originData = state.get('data')
      const originItems = state.get('items')
      const condInfo = state.get('condInfo')
      const data = originData[key]
      const criteria = Object.keys(
        _.pickBy(data, value => value.simpleField !== true)
      )[0]
      const value = (data[criteria] || {}).defaultValue || ''
      const { allowedConditions } = data[criteria] || {}
      const items = [].concat(originItems[key])
      items.push({
        hash: uniqid(),
        criteria,
        value,
        condition: isArray(allowedConditions)
          ? allowedConditions[0]
          : Object.keys(condInfo)[0]
      })
      return state.set(
        'items',
        Object.assign({}, originItems, { [key]: items })
      )
    }
  },
  DELETE_ITEM: {
    reducer: (state, action) => {
      const { key, index } = action.payload
      const originItems = state.get('items')
      const items = [].concat(originItems[key])
      items.splice(index, 1)
      return state.set(
        'items',
        Object.assign({}, originItems, { [key]: items })
      )
    }
  },
  SET_CRITERIA: {
    reducer: (state, action) => {
      const { key, index, value: criteria } = action.payload
      const originData = state.get('data')
      const originItems = state.get('items')
      const condInfo = state.get('condInfo')
      const data = originData[key]
      const items = [].concat(originItems[key])
      const value = data[criteria].defaultValue || ''
      const { allowedConditions } = data[criteria] || {}
      items[index] = Object.assign({}, items[index], {
        criteria,
        value,
        condition: isArray(allowedConditions)
          ? allowedConditions[0]
          : Object.keys(condInfo)[0]
      })
      return state.set(
        'items',
        Object.assign({}, originItems, { [key]: items })
      )
    }
  },
  SET_VALUE: {
    reducer: (state, action) => {
      const { key, index, value } = action.payload
      const originItems = state.get('items')
      const items = [].concat(originItems[key])
      items[index] = Object.assign({}, items[index], {
        value
      })
      return state.set(
        'items',
        Object.assign({}, originItems, { [key]: items })
      )
    }
  },
  SET_CONDITION: {
    reducer: (state, action) => {
      const { key, index, value: condition } = action.payload
      const originItems = state.get('items')
      const items = [].concat(originItems[key])
      items[index] = Object.assign({}, items[index], {
        condition
      })
      return state.set(
        'items',
        Object.assign({}, originItems, { [key]: items })
      )
    }
  },
  CANCEL: {
    reducer: (state, action) => {
      const { key } = action.payload
      const submitFlag = state.get('submitFlag')
      const originData = state.get('data')
      const originItems = state.get('items')
      const originOptions = state.get('options')
      const originResult = state.get('result')
      const originQuery = state.get('query')
      const originSchema = state.get('schema')
      const originDefaultValue = state.get('defaultValue')
      const data = originData[key]
      const options = originOptions[key]
      const value = originDefaultValue[key]
      const schema = originSchema[key]
      const condInfo = state.get('condInfo')
      const items = setItems(data, value, options, condInfo)
      const result = getResult(items, condInfo, data)
      const query = qs.stringify(result, { arrayFormat: 'index' })
      return state
        .set('options', Object.assign({}, originOptions, { [key]: options }))
        .set('data', Object.assign({}, originData, { [key]: data }))
        .set('items', Object.assign({}, originItems, { [key]: items }))
        .set('result', Object.assign({}, originResult, { [key]: result }))
        .set('query', Object.assign({}, originQuery, { [key]: query }))
        .set('schema', Object.assign({}, originSchema, { [key]: schema }))
        .set('searchFlag', Object.assign({}, submitFlag, { [key]: false }))
    }
  },
  SUBMIT: {
    reducer: (state, action) => {
      const { key } = action.payload
      const submitFlag = state.get('submitFlag')
      const originResult = state.get('result')
      const originQuery = state.get('query')
      const originItems = state.get('items')
      const originData = state.get('data')
      const condInfo = state.get('condInfo')
      const result = getResult(originItems[key], condInfo, originData[key])
      const query = qs.stringify(result, { arrayFormat: 'indices' })
      return state
        .set('searchFlag', Object.assign({}, submitFlag, { [key]: true }))
        .set('result', Object.assign({}, originResult, { [key]: result }))
        .set('query', Object.assign({}, originQuery, { [key]: query }))
    }
  }
}
// 데이터 청소
handler.clear = {
  reducer: (state, action) => {
    const key = action.payload?.key
    if (isEmptyString(key)) return state
    const keys = isArray(key) ? key : [key]
    const cleanDataFields = [
      'hash',
      'data',
      'items',
      'result',
      'query',
      'options',
      'simpleForm',
      'originDefaultValue'
    ]
    const newState = {}
    keys.forEach(key => {
      Object.assign(
        newState,
        ...cleanDataFields.map(fieldName => {
          const dataObj = cloneDeep(state.get(fieldName) || {})
          delete dataObj?.[key]
          return { [fieldName]: dataObj || {} }
        })
      )
    })
    return state.merge(newState)
  }
}
const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
