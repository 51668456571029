import axios from 'axios'
import axiosCancel from 'axios-cancel'
import qs from 'qs'

axiosCancel(axios, {
  debug: false // default
})

export const searchBusinessInfo = params => {
  axios.cancel('searchBusinessInfo')
  const queryString = qs.stringify(params)
  const uri = `/iam/v1/verifications/companies?${queryString}`
  return axios.get(uri, { requestId: 'searchBusinessInfo' })
}

export const putBusinessInfo = params => {
  const { accountId, ...requestParams } = params
  const uri = `/iam/v1/verifications/accounts/business/request`
  return axios.post(uri, requestParams)
}

export const getBlockGroups = payload => {
  const queryString = qs.stringify(payload)
  const uri = `/iam/v1/block/groups${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const getBlockNumbers = payload => {
  const queryString = qs.stringify(payload)
  const uri = `/iam/v1/block/numbers${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}

export const putBlockGroup = payload => {
  const uri = `/iam/v1/block/groups`
  return axios.post(uri, payload)
}

export const addBlockNumbers = payload => {
  const uri = `/iam/v1/block/numbers/bulk`
  return axios.post(uri, payload)
}

export const deleteBlockNumber = payload => {
  const { blockGroupId, blockNumberId } = payload
  const uri = `/iam/v1/block/groups/${blockGroupId}/numbers/${blockNumberId}`
  return axios.delete(uri)
}

export const deleteBlockGroup = payload => {
  const { blockGroupId } = payload
  const uri = `/iam/v1/block/groups/${blockGroupId}`
  return axios.delete(uri)
}

export const getBusinessInfo = () => {
  const uri = `/iam/v1/verifications/accounts/business`
  return axios.get(uri)
}

export const getRequestedBusinessInfo = () => {
  const uri = `/iam/v1/verifications/accounts/business/request`
  return axios.get(uri)
}

export const addBusinessMember = payload => {
  const { employment } = payload
  const uri = `/iam/v1/verifications/accounts/business/member`
  return axios.post(uri, { employment })
}

export const createMemberPerson = () => {
  const uri = `/iam/v1/verifications/members/person`
  return axios.post(uri, {})
}

export const updateMemberPerson = () => {
  const uri = `/iam/v1/verifications/members/person`
  return axios.put(uri, {})
}

export const getMemberPerson = () => {
  const uri = `/iam/v1/verifications/members/person`
  return axios.get(uri)
}

export const getBlacks = payload => {
  const query = qs.stringify(payload)
  const uri = `/iam/v1/black${query ? '?' + query : ''}`
  return axios.get(uri)
}

// 080 수신거부리스트 수신번호 기준
export const getBlockListByReceivcer = ({ phoneNumber, limit = 30 }) => {
  const uri = `/iam/v1/black/optouts/${phoneNumber}?limit=${limit}`
  return axios.get(uri)
}

export const deleteBlockListByReceivcer = ({ phoneNumber, handleKey }) => {
  const uri = `/iam/v1/black/optouts/${phoneNumber}`
  return axios.delete(uri, { data: { handleKeys: [handleKey] } })
}
