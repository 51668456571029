import axios from 'axios'

export const invitation = params => {
  const { email, role } = params
  const uri = `/users/v1/invitations`
  return axios.post(uri, { email, role })
}

export const getSenderIdInfo = params => {
  const uri = `/senderid/v1/numbers`
  return axios.get(uri)
}

export const getCredentials = (params = {}, options = {}) => {
  const { cache = false } = options
  const { accountId } = params
  return axios.get(`/credentials/v1/accounts/${accountId}`, { cache })
}

export const getCredentialsSecret = params => {
  const { apiKey, accountId } = params
  const uri = `/credentials/v1/accounts/${accountId}/${apiKey}/secret`
  return axios.get(uri)
}
