import _ from 'lodash'
import { isArray, isObject } from 'lib/detectType'

const customBindStateToProps = (state, paramObject) => {
  const reducers = Object.keys(paramObject)
  return Object.assign(
    {},
    ...reducers.map(reducerName => {
      const stateNames = paramObject[reducerName]
      if (stateNames === true) {
        const stateKeys = state[reducerName].keySeq().toArray()
        return Object.assign(
          {},
          ...stateKeys.map(key => {
            const stateData = state[reducerName].get(key)
            return { [key]: stateData }
          })
        )
      }
      if (isArray(stateNames)) {
        const keySeq = state[reducerName].keySeq().toArray()
        const saveState = Object.assign(
          {},
          ...[].concat(
            ...stateNames.map(key => {
              if (isObject(key)) {
                return Object.keys(key).map(aliasStateName => {
                  const statePath = key[aliasStateName].split('.')
                  const stateData = state[reducerName].get(statePath.shift())
                  if (isObject(stateData) && statePath.length > 0) {
                    return { [aliasStateName]: _.get(stateData, statePath) }
                  }
                  return { [aliasStateName]: stateData }
                })
              }
              if (keySeq.indexOf(key) === -1) return {}
              const keys = key.split('.')
              const stateData = state[reducerName].getIn(keys)
              return { [key]: stateData }
            })
          )
        )
        return saveState
      }
      return {}
    })
  )
}

export const bindStateToProps = (state, paramObject) => {
  if (!paramObject) {
    paramObject = state
    return state => {
      return customBindStateToProps(state, paramObject)
    }
  }
  return customBindStateToProps(state, paramObject)
}

export default bindStateToProps
