import axios from 'axios'
import qs from 'qs'

export const createFeature = payload =>
  axios.post(`/feature/v1/features`, payload)

export const getFeatures = payload => {
  const queryString = qs.stringify(payload)
  return axios.get(
    `/feature/v1/features${queryString ? '?' + queryString : ''}`
  )
}

export const getFeature = featureId =>
  axios.get(`/feature/v1/features/${featureId}`)

export const voteFeature = payload => {
  const { featureId, vote = 1 } = payload
  return axios.post(`/feature/v1/features/${featureId}/vote`, { vote })
}

export const addComment = payload => {
  const { featureId, comment } = payload
  return axios.post(`/feature/v1/features/${featureId}/comments`, { comment })
}

export const updateComment = payload => {
  const { featureId, commentId, comment } = payload
  return axios.put(`/feature/v1/features/${featureId}/comments/${commentId}`, {
    comment
  })
}

export const updateFeature = payload => {
  const { featureId, title, content } = payload
  return axios.put(`/feature/v1/features/${featureId}`, {
    title,
    content
  })
}

export const deleteFeature = featureId => {
  return axios.delete(`/feature/v1/features/${featureId}`)
}

export const deleteComment = payload => {
  const { featureId, commentId } = payload
  return axios.delete(`/feature/v1/features/${featureId}/comments/${commentId}`)
}
