import { Map } from 'immutable'
import { requestHandler } from 'lib'
import * as messageAPI from 'lib/api/message'
const { createRedux } = requestHandler
const initialState = Map({})

const handler = {}

handler.getGroupMessages = {
  payloadCreator: messageAPI.getGroupMessages
}

handler.sendGroupMessage = {
  options: {
    saveLogFlag: true,
    debounce: { wait: 500 }
  },
  payloadCreator: messageAPI.sendGroupMessage
}

handler.sendSimpleMessage = {
  options: {
    saveLogFlag: true,
    debounce: { wait: 500 }
  },
  payloadCreator: messageAPI.sendSimpleMessage
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)

export const actions = reduxActions
export default reduxReducers
