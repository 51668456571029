/**
 * @author EdenCha
 * @email eden@nurigo.net
 * @description (작성된 설명이 없습니다.)
 */
import { Map } from 'immutable'
import { createRedux, onSuccess } from 'lib/requestHandler'
import moment from 'moment-timezone'

import ReactGA from 'lib/ga'
import config from 'config'
import { isString, isEmptyString } from 'lib/detectType'

const initialState = Map({
  bootOption: {
    visibleChannelButton: true
  },
  ChannelIO: window.ChannelIO,
  option: {},
  user: {},
  isSupportLive: () => {
    const supportStartTime = moment()
      .tz('Asia/seoul')
      .startOf('date')
      .set('hour', config?.supportStartTime)
    const supportEndTime = moment()
      .tz('Asia/seoul')
      .startOf('date')
      .set('hour', config?.supportEndTime)
    const currentTime = moment(new Date()).tz('Asia/seoul')
    const dayOfWeek = currentTime.format('E')
    const isBreakTime =
      currentTime.isAfter(
        moment().tz('Asia/seoul').startOf('date').set('hour', 12)
      ) &&
      currentTime.isBefore(
        moment().tz('Asia/seoul').startOf('date').set('hour', 14)
      )
    return (
      currentTime.isAfter(supportStartTime) &&
      currentTime.isBefore(supportEndTime) &&
      dayOfWeek !== 6 &&
      dayOfWeek !== 7 &&
      !isBreakTime
    )
  }
})

const handler = {}

handler.boot = {
  options: {
    response: {
      error: {
        ignore: true // (array or boolean) default: false
      }
    }
  },
  payloadCreator: (option = {}, c, d) => {
    return new Promise((resolve, reject) => {
      if (!window.ChannelIO) return reject()
      window.ChannelIO('boot', option, (error, user) => {
        if (error) return reject(error)
        return resolve({
          option,
          user,
          ChannelIO: window.ChannelIO
        })
      })
    })
  },
  reducer: {
    onSuccess: (state, action, options) => {
      const user = action?.payload?.user || {}
      const option = action?.payload?.option || {}
      const visibleChannelButton =
        action?.meta?.containerOptions?.visibleChannelButton
      const bootOption = {
        visibleChannelButton:
          visibleChannelButton === undefined ? true : visibleChannelButton
      }
      const stateData = {
        user,
        option,
        bootOption,
        ChannelIO: window.ChannelIO,
        ChannelIOInitialized: true
      }
      const accountId = user?.profile?.accountId
      window.ChannelIO('onShowMessenger', () => {
        if (accountId) {
          ReactGA.event({
            category: 'ChannelTalk',
            action: 'showMessenger',
            label: accountId
          })
        }
      })
      window.ChannelIO('onChatCreated', () => {
        if (accountId) {
          ReactGA.event({
            category: 'ChannelTalk',
            action: 'chatCreated',
            label: accountId
          })
        }
      })
      if (visibleChannelButton === false) {
        window.ChannelIO('hideChannelButton')
      }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

handler.showButton = {
  payloadCreator: (payload, options) => {
    if (!window.ChannelIO) return Promise.reject()
    return Promise.resolve()
  },
  reducer: {
    onSuccess: (state, action, options) => {
      const bootOption = state.get('bootOption')
      const visibleChannelButton = bootOption?.visibleChannelButton
      if (visibleChannelButton !== false) {
        window.ChannelIO('showChannelButton')
      }
      const stateData = {}
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

handler.hideButton = {
  payloadCreator: (payload, options) => {
    if (!window.ChannelIO) return Promise.reject()
    return Promise.resolve()
  },
  reducer: {
    onSuccess: (state, action, options) => {
      window.ChannelIO('hideChannelButton')
      const stateData = {}
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

handler.open = {
  payloadCreator: (payload, options) => {
    if (!window.ChannelIO) return Promise.reject()
    const latestExecuteBy = options?.executeBy
    const updateUserData = payload?.user || {}
    const params = {
      profile: {
        latestExecuteBy,
        ...updateUserData
      }
    }
    return new Promise((resolve, reject) => {
      window.ChannelIO('updateUser', params, (error, user) => {
        if (error) return reject(error)
        resolve(user)
      })
    })
  },
  reducer: {
    onSuccess: (state, action, options) => {
      const payload = action?.meta?.payload
      const title = payload?.title
      const chatId = payload?.chatId
      const defaultMessage = isEmptyString(chatId)
        ? [
            title ? `${title} 관련 문의가 있습니다.` : '',
            payload?.message || '',
            isString(payload?.message) ? `--------------` : ''
          ]
            .filter(d => d)
            .concat('')
            .join('\n')
        : ''
      window.ChannelIO('openChat', chatId, defaultMessage)
      const stateData = { user: action.payload }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

handler.openSupportBot = {
  payloadCreator: (payload, options) => {
    if (!window.ChannelIO) return Promise.reject()
    const latestExecuteBy = options?.executeBy
    const updateUserData = payload?.user || {}
    const params = {
      profile: {
        latestExecuteBy,
        ...updateUserData
      }
    }
    return new Promise((resolve, reject) => {
      window.ChannelIO('updateUser', params, (error, user) => {
        if (error) return reject(error)
        resolve(user)
      })
    })
  },
  reducer: {
    onSuccess: (state, action, options) => {
      const payload = action?.meta?.payload
      const botId = payload?.botId
      const defaultMessage = isEmptyString(payload?.message)
        ? ''
        : payload?.message
      window.ChannelIO('openSupportBot', botId, defaultMessage)
      const stateData = { user: action.payload }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
