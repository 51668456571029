/**
 * @author EdenCha <eden@nurigo.net>
 */
import React, { useRef, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
// import makeStyles from '@material-ui/core/styles/makeStyles'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

// material UI
import Tooltip from '@material-ui/core/Tooltip'
import Zoom from '@material-ui/core/Zoom'
import Fade from '@material-ui/core/Fade'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Popper from '@material-ui/core/Popper'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'

// my components
import withCompWidth from 'commonHoc/withCompWidth'
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'
import CommonButton from 'containers/CommonButtonContainer'

// libraries
// import config from 'config'
import { isEmptyString } from 'lib/detectType'
import isEqualProps from 'lib/isEqualProps'

const styles = theme => ({
  pointerWrap: {
    position: 'absolute',
    // backgroundColor: '#000',
    // backgroundColor: `${theme.palette.background.default}99`,
    padding: theme.spacing(1),
    boxSizing: 'border-box',
    // color: theme.palette.error.main,
    borderRadius: 10,
    fontWeight: 'bolder',
    // 마우스 이벤트 무시
    // pointerEvents: 'none',
    border: `1px dotted ${theme.palette.primary.main}`
  },
  isGuideWrap: {
    '&:before': {
      position: 'absolute',
      content: '""',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
      borderRadius: 10,
      boxShadow: '0 0 0 5000px #0000002b'
    }
  },
  isErrorWrap: {
    animationName: '$highlight',
    animationDuration: '4s',
    animationTimingFunction: 'ease-out',
    animationIterationCount: 'infinite',
    border: `2px solid ${theme.palette.error.main}`,
    boxShadow: `0 0 15px ${theme.palette.error.main}`
  },
  eventBlocker: {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: 0,
    left: 0,
    zIndex: 1499
  },
  tooltip: {
    fontSize: '0.9rem'
  },
  tutorialPopper: {
    zIndex: 1500
  },
  tutorialPopperPaper: {
    maxWidth: '95%',
    boxSizing: 'border-box',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.primary.main}`,
    padding: theme.spacing(2)
  },
  tooltipPopper: {},
  '@keyframes highlight': {
    '0%': {
      opacity: 0
    },
    '20%': {
      opacity: 1
    },
    '80%': {
      opacity: 1
    },
    '100%': {
      opacity: 0
    }
  }
})
const Pointer = React.memo(
  props => {
    const {
      classes,
      isError,
      componentId,
      wrapStyle,
      message,
      zIndex,
      visible: defaultVisible,
      onRemove,
      onReadyComponent
    } = props
    const domRef = useRef(null)
    const [visible, setVisible] = useState(defaultVisible)
    const showTooltip = isError === true && !isEmptyString(message) && visible
    useEffect(() => {
      setVisible(defaultVisible)
    }, [defaultVisible])
    return (
      <Fade
        in={visible}
        timeout={visible ? 600 : 300}
        onExited={() => {
          onRemove(componentId)
        }}
      >
        <div
          className={classNames(classes.pointerWrap, {
            [classes.isErrorWrap]: isError,
            [classes.isGuideWrap]: !isError
          })}
          ref={ref => {
            domRef.current = ref
            onReadyComponent(domRef.current)
          }}
          style={wrapStyle}
          onClick={() => {
            if (isError) {
              setVisible(false)
            }
          }}
          onMouseEnter={() => {
            if (isError) {
              setVisible(false)
            }
          }}
        >
          <Tooltip
            open={showTooltip}
            title={message}
            TransitionComponent={Zoom}
            placement="bottom-start"
            arrow
            PopperProps={{ style: { zIndex } }}
            classes={{
              tooltip: classes.tooltip,
              popper: classes.tooltipPopper
            }}
          >
            <div style={{ width: '100%', height: '100%' }} />
          </Tooltip>
        </div>
      </Fade>
    )
  },
  (prevProps, nextProps) => {
    return isEqualProps(nextProps, prevProps, [
      'componentId',
      'isError',
      'visible',
      'message'
    ])
  }
)
const GuidePopper = React.memo(props => {
  const {
    classes,
    anchorEl,
    setAnchorEl,
    index,
    guidePriority,
    elementsRect,
    onClickNext,
    onClickPrev,
    onClickFinish
  } = props
  const open = Boolean(anchorEl)
  return (
    <>
      <div />
      <Popper
        className={classes.tutorialPopper}
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={350}>
            <TutorialHelpPaper
              classes={classes}
              index={index}
              guidePriority={guidePriority}
              elementsRect={elementsRect}
              onClickNext={() => {
                onClickNext()
              }}
              onClickPrev={onClickPrev}
              onClickLast={isSkip => {
                onClickFinish(isSkip, () => {
                  setAnchorEl(null)
                })
              }}
            />
          </Fade>
        )}
      </Popper>
    </>
  )
})
const TutorialHelpPaper = React.memo(props => {
  const {
    classes,
    index,
    guidePriority = [],
    elementsRect = {},
    onClickNext,
    onClickPrev,
    onClickLast
  } = props
  const currentKey = guidePriority?.[index]
  const currentData = elementsRect?.[currentKey] || {}
  const values = Object.values(elementsRect) || []
  const max = values.length
  const isLastStep = index + 1 >= max
  return (
    <Box>
      <Paper className={classes.tutorialPopperPaper} elevation={2}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="body2">
              <Typography
                variant="caption"
                color="primary"
                style={{ marginRight: 4 }}
              >
                <strong>Step {index + 1}.</strong>
              </Typography>
              {currentData?.message}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              wrap="nowrap"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Grid container wrap="nowrap" spacing={1}>
                  <Grid item>
                    <CommonButton
                      variant="outlined"
                      color="primary"
                      size="small"
                      disabled={index < 1}
                      onClick={() => {
                        onClickPrev()
                      }}
                    >
                      이전
                    </CommonButton>
                  </Grid>
                  <Grid item>
                    <CommonButton
                      variant="contained"
                      disableElevation={!isLastStep}
                      color="primary"
                      size="small"
                      onClick={() => {
                        if (isLastStep) {
                          const skipFlag = false
                          onClickLast(skipFlag)
                          setTimeout(() => {
                            onClickNext()
                          }, 500)
                        } else {
                          onClickNext()
                        }
                      }}
                    >
                      {isLastStep ? '마치기' : '다음'}
                    </CommonButton>
                  </Grid>
                </Grid>
              </Grid>
              {!isLastStep && (
                <Grid item>
                  <CommonButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      const skipFlag = true
                      onClickLast(skipFlag)
                    }}
                  >
                    끝내기
                  </CommonButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Box>
  )
})
export const useProps = {}
/**
 * 컴포넌트 가이드 또는 도움말 표현 시 사용되는 컴포넌트
 */
const ComponentGuidePointer = React.memo(
  ({ classes, proxy, ...props }) => {
    const {
      currentGuideIndex,
      guidePriority = [],
      elementsRect = {},
      onRemove,
      onClickNextGuide,
      onClickPrevGuide,
      onClickFinishGuide,
      onClickEventBlocker
    } = props
    const [popperAnchorEl, setPopperAnchor] = useState(null)
    const elementKeys = Object.keys(elementsRect)
    return (
      <>
        {currentGuideIndex > -1 && (
          <GuidePopper
            classes={classes}
            anchorEl={popperAnchorEl}
            index={currentGuideIndex}
            guidePriority={guidePriority}
            elementsRect={elementsRect}
            setAnchorEl={setPopperAnchor}
            onClickNext={onClickNextGuide}
            onClickPrev={onClickPrevGuide}
            onClickFinish={onClickFinishGuide}
          />
        )}
        {elementKeys.length > 0 &&
          (currentGuideIndex > -1
            ? [elementKeys?.[currentGuideIndex]]
            : elementKeys
          ).map(componentId => {
            const data = elementsRect[componentId]
            if (data?.isNotFoundComponent === true) return null
            const isError = data?.isError === true
            const isVisible = data?.visible === true
            const message = data?.message
            const padding = isError ? 4 : 8
            const styles = {
              zIndex: data?.zIndex,
              top: 0,
              left: 0,
              transform: `translate3d(${
                data?.left + window.scrollX - padding
              }px, ${data?.top + window.scrollY - padding}px, 0px)`,
              willChange: data?.visible ? 'transform' : 'auto',
              width: data?.width + padding * 2,
              height: data?.height + padding * 2
            }
            const mainElement =
              data?.zIndex > 1200 || !isError
                ? document.getElementsByTagName('body')?.[0]
                : document.getElementsByTagName('main')?.[0]
            if (!mainElement) return null
            return createPortal(
              <>
                {!isError && isVisible && (
                  <div
                    className={classes.eventBlocker}
                    onClick={onClickEventBlocker}
                  />
                )}
                <Pointer
                  classes={classes}
                  isError={isError}
                  componentId={componentId}
                  wrapStyle={styles}
                  message={message}
                  visible={isVisible === true}
                  zIndex={data?.zIndex}
                  onRemove={onRemove}
                  onReadyComponent={el => {
                    if (!isError) {
                      setPopperAnchor(el)
                    }
                  }}
                />
              </>,
              mainElement
            )
          })}
      </>
    )
  },
  (prevProps, nextProps) => {
    return isEqualProps(nextProps, prevProps, [
      'elementsRect',
      'currentGuideIndex'
    ])
  }
)

ComponentGuidePointer.defaultProps = setDefaultProps(useProps)
ComponentGuidePointer.propTypes = setPropTypes(useProps)
export default withStyles(styles)(withCompWidth(ComponentGuidePointer))
