import axios from 'axios'

export const existenceEmail = async email => {
  const uri = `/users/v1/existence/email/${email}`
  return axios.get(uri)
}

export const sendResetPassword = email => {
  const uri = `/users/v1/member/password/reset`
  return axios.post(uri, { email })
}

export const resetPassword = params => {
  const { hashId, password, passwordConfirmation } = params
  const uri = `/users/v1/member/password/reset/${hashId}`
  return axios.put(uri, { password, passwordConfirmation })
}
