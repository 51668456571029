/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import qs from 'querystring'
import urlParse from 'url-parse'
import { appstore as api } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  appListLoading: true,
  appListSearchParams: {},
  appList: [],
  appData: {},
  appDetailFlag: false,
  appListHasNext: true
})

const handler = {
  SET_STATE: {
    reducer: (state, action) => state.merge(action.payload)
  },
  GET_APP_LIST: {
    payloadCreator: api.getAppList,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const url = urlParse(action.payload.config.url)
        const params = qs.parse(url.query.replace(/^\?/, ''))
        const data = action.payload.data
        const stateData = {
          appListSearchParams: params,
          appListHasNext: true,
          appList: data,
          appListLoading: false
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_APP_DATA: {
    payloadCreator: api.getAppData,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = {
          appData: action.payload.data[0],
          appListLoading: false
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  ADD_APP_LIST: {
    payloadCreator: api.addAppList,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const appList = state.get('appList')
        const data = action.payload.data
        const stateData = {
          appListHasNext: data.length > 0,
          appList: appList.concat(data),
          appListLoading: false
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

handler.createApp = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.createApp
}

handler.updateApp = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.updateApp
}

handler.uploadThumbnail = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.uploadThumbnail
}

handler.getApps = {
  options: {
    response: {
      error: {
        ignore: [404] // (array or boolean) default: false
      }
    }
  },
  payloadCreator: api.getApps
}

handler.getProfitHistory = {
  options: {
    response: {
      error: {
        ignore: [404] // (array or boolean) default: false
      }
    }
  },
  payloadCreator: api.getProfitHistory
}

handler.getProfitHistoryDetail = {
  options: {
    response: {
      error: {
        ignore: [404] // (array or boolean) default: false
      }
    }
  },
  payloadCreator: api.getProfitHistoryDetail
}

handler.createAppDomain = {
  payloadCreator: api.createAppDomain
}

handler.updateAppDomain = {
  payloadCreator: api.updateAppDomain
}

handler.createSubAccount = {
  options: {
    response: {
      error: {
        ignore: [409]
      }
    }
  },
  payloadCreator: api.createSubAccount
}

handler.deleteApp = {
  payloadCreator: api.deleteApp
}

handler.getSubAccounts = {
  payloadCreator: api.getSubAccounts
}

handler.deleteSubAccount = {
  payloadCreator: api.deleteSubAccount
}

handler.getMysiteUserProfit = {
  payloadCreator: api.getMysiteUserProfit
}

handler.getMysiteUserProfit = {
  payloadCreator: api.getMysiteUserProfit
}

handler.createMysiteUserProfit = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.createMysiteUserProfit
}

handler.updateMysiteUserProfit = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.updateMysiteUserProfit
}

handler.deleteMysiteUserProfit = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.deleteMysiteUserProfit
}

handler.createThumbnail = {
  payloadCreator: api.createThumbnail
}

handler.createContentImage = {
  payloadCreator: api.createContentImage
}

handler.updateStage = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.updateStage
}

handler.updateSubAccount = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.updateSubAccount
}

handler.rechargeSubAccount = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.rechargeSubAccount
}

handler.deleteSubAccountCustomFields = {
  options: { debounce: { wait: 500 } },
  payloadCreator: api.deleteSubAccountCustomFields
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
