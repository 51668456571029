/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
// import omitBy from 'lodash/omitBy'

// material UI
import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
// import Button from '@material-ui/core/Button'
// import TextField from '@material-ui/core/TextField'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'
import { FcVlc as FcVlcIcon } from 'react-icons/fc'

// my components
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'
// import CommonButton from 'containers/CommonButtonContainer'

// libraries
// import config from 'config'
// import { isArray } from 'lib/detectType'

const styles = theme => ({
  root: {
    border: '1px solid #5780ff',
    backgroundColor: theme.palette.background.default,
    cursor: 'pointer'
  },
  icon: {
    fontSize: '1.3em',
    verticalAlign: 'sub',
    marginRight: 4
  }
})
export const useProps = {}
/**
 * 컴포넌트 로드 오류
 */
const ComponentLoadError = React.memo(
  ({ classes }) => {
    return (
      <Box
        p={1}
        className={classes.root}
        onClick={e => {
          e.preventDefault()
          if (
            window.confirm(
              '업데이트를 위해 페이지를 새로고침 합니다. 같은 문제가 지속되면 캐시 삭제 후 재시도 해주세요.'
            )
          ) {
            window.location.reload()
          }
        }}
      >
        <Typography
          variant="caption"
          align="center"
          color="textPrimary"
          display="block"
        >
          <FcVlcIcon className={classes.icon} />
          업데이트 필요
        </Typography>
      </Box>
    )
  },
  (prevProps, nextProps) => {
    return true
  }
)

ComponentLoadError.defaultProps = setDefaultProps(useProps)
ComponentLoadError.propTypes = setPropTypes(useProps)
export default withStyles(styles)(ComponentLoadError)
