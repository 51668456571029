import axios from 'axios'
import config from 'config'

// 계정 관련
export const getZendeskData = () => {
  const uri = `${config.zendeskApiHost}/api/v2/community/topics/${config.zendeskNotice}/posts.json`
  return axios.get(uri, { headers: { Authorization: null } })
}

export const getAccountInfo = params => {
  const { accountId } = config.getTokenData() || {}
  const uri = `/users/v1/accounts/${accountId}`
  return axios.get(uri)
}

// 발신번호 관련
export const getSenderIds = async () => {
  const uri = `/senderid/v1/numbers`
  const { data } = (await axios.get(uri)) || {}
  return data
}

// 오늘 발송 통계 가져오기
export const getTodayUseMessage = async () => {
  const todayStart = new Date()
  todayStart.setUTCHours(0)
  todayStart.setUTCMinutes(0)
  todayStart.setUTCSeconds(0)
  todayStart.setUTCMilliseconds(0)
  const todayEnd = new Date()
  todayEnd.setUTCHours(23)
  todayEnd.setUTCMinutes(59)
  todayEnd.setUTCSeconds(59)
  todayEnd.setUTCMilliseconds(999)
  const uri = `/messages/v4/statistics?startDate=${todayStart.toISOString()}&endDate=${todayEnd.toISOString()}`
  const { data } = (await axios.get(uri)) || {}
  return data
}

// 중요 알림 가져오기
export const getImportantNotice = () => {
  const uri = `/notification/v1/history?status=UNREAD&important=true&limit=20`
  return axios.get(uri)
}
