import { Map } from 'immutable'
import { storage as storageAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  storageList: [],
  startKey: '',
  nextKey: ''
})

const handler = {}
handler.GET_STORAGE_LIST = {
  payloadCreator: storageAPI.getStorageList,
  reducer: {
    onPending,
    onFailure,
    onSuccess: (state, action, options) => {
      const { fileList, nextKey, startKey } = action.payload.data
      const stateData = {
        storageList: fileList,
        startKey,
        nextKey
      }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}
handler.UPLOAD = {
  payloadCreator: storageAPI.upload
}
handler.BATCH_UPLOAD = {
  payloadCreator: storageAPI.batchUpload
}
handler.getFileInfo = {
  payloadCreator: storageAPI.getFileInfo
}
handler.deleteFile = {
  payloadCreator: storageAPI.deleteFile
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
