import { rbc as rbcAPI } from 'lib/api'
import { Map } from 'immutable'
import moment from 'moment-timezone'
import { createRedux } from 'lib/requestHandler'
import { isPhoneNumber, isUrl, isNumber } from 'lib/detectType'

const initialState = Map({
  defaultCell: {
    text: '{{변수}}',
    textAlignment: 'textStart',
    textColor: '#000000',
    textSize: '16dp',
    textStyle: 'normal'
  },
  rcsButtonType: {
    WL: {
      title: '웹링크',
      addon: {
        title: 'URL 주소',
        name: 'link',
        fields: [
          {
            title: 'URL 주소',
            name: 'link',
            value: '',
            require: true,
            maxLength: 300,
            validate: value => isUrl(value),
            helperText:
              '버튼 클릭 시 해당 주소로 이동합니다. https://를 포함한 URL을 입력하세요.'
          }
        ]
      }
    },
    DL: {
      title: '전화걸기',
      addon: {
        title: '전화번호',
        name: 'phone',
        fields: [
          {
            title: '전화번호',
            name: 'phone',
            value: '',
            require: true,
            maxLength: 15,
            format: value => String(value).replace(/[^0-9]/g, ''),
            validate: value => isPhoneNumber(value),
            helperText: '전화번호는 숫자만 입력합니다.'
          }
        ]
      }
    },
    CL: {
      title: '복사하기',
      addon: {
        title: '클립보드내용',
        name: 'text',
        fields: [
          {
            title: '복사내용',
            name: 'text',
            value: '',
            require: true,
            maxLength: 1000,
            helperText:
              '단말기 클립보드에 복사할 내용을 입력하세요. 최대 1000자'
          }
        ]
      }
    },
    ML: {
      title: '지도 좌표 공유',
      addon: {
        title: '지도정보',
        name: 'geoInfo',
        fields: [
          {
            title: '위도',
            name: 'latitude',
            value: '',
            require: true,
            maxLength: 20,
            format: value => String(value).replace(/[^0-9\\.]/g, ''),
            validate: value => isNumber(value),
            helperText: '위도는 숫자만 입력하세요. (소수점 입력 가능)'
          },
          {
            title: '경도',
            name: 'longitude',
            value: '',
            require: true,
            maxLength: 20,
            format: value => String(value).replace(/[^0-9\\.]/g, ''),
            validate: value => isNumber(value),
            helperText: '경도는 숫자만 입력하세요. (소수점 입력 가능)'
          },
          {
            title: '위치명',
            name: 'label',
            value: '',
            require: true,
            maxLength: 100,
            helperText: '최대 100자'
          },
          {
            title: 'URL',
            name: 'link',
            value: '',
            require: true,
            maxLength: 300,
            validate: value => isUrl(value),
            helperText: 'https://를 포함한 URL을 입력하세요.'
          }
        ]
      }
    },
    MQ: {
      title: '지도 쿼리 공유',
      addon: {
        title: '지도정보',
        name: 'geoInfo',
        fields: [
          {
            title: 'URL',
            name: 'link',
            value: '',
            require: true,
            maxLength: 300,
            validate: value => isUrl(value),
            helperText: 'https://를 포함한 URL을 입력하세요.'
          },
          {
            title: '위치명',
            name: 'query',
            value: '',
            require: true,
            maxLength: 100,
            helperText: '주소 뒤에 붙는 쿼리값을 입력하세요.'
          }
        ]
      }
    },
    MR: { title: '현재 위치 공유' },
    CA: {
      title: '캘린더 등록 (일정 보내기)',
      addon: {
        title: '일정',
        name: 'calendar',
        fields: [
          {
            title: '일정 제목',
            name: 'title',
            value: '',
            require: true,
            maxLength: 100,
            helperText: '최대 100자'
          },
          {
            title: '시작일',
            name: 'startTime',
            value: '',
            require: true,
            maxLength: 50,
            format: value => String(value).replace(/[^0-9]/g, ''),
            validate: value => {
              return (
                String(value).length === 8 &&
                moment(value, 'YYYYMMDD').toISOString() !== null
              )
            },
            helperText: 'YYYYMMDD 형식으로 입력합니다. 예시) 20200418'
          },
          {
            title: '종료일',
            name: 'endTime',
            value: '',
            require: true,
            maxLength: 50,
            format: value => String(value).replace(/[^0-9]/g, ''),
            validate: value => {
              return (
                String(value).length === 8 &&
                moment(value, 'YYYYMMDD').toISOString() !== null
              )
            },
            helperText: 'YYYYMMDD 형식으로 입력합니다. 예시) 20200418'
          },
          {
            title: '일정 내용',
            name: 'text',
            value: '',
            require: true,
            maxLength: 1000,
            helperText: '최대 1000자'
          }
        ]
      }
    },
    MS: {
      title: '메시지전송',
      addon: {
        title: '전송 내용',
        name: 'sendMessage',
        fields: [
          {
            title: '전화번호',
            name: 'phone',
            value: '',
            require: true,
            maxLength: 15,
            format: value => String(value).replace(/[^0-9]/g, ''),
            validate: value => isPhoneNumber(value),
            helperText: '전화번호는 숫자만 입력합니다.'
          },
          {
            title: '전송 내용',
            name: 'text',
            value: '',
            require: true,
            maxLength: 1000,
            helperText: '최대 1000자'
          }
        ]
      }
    }
  }
})

const handler = {
  registBrand: {
    options: {
      debounce: { wait: 500 },
      response: {
        error: {
          helper: {
            cond: {
              status: 404,
              errorCode: 'ResourceNotFound'
            },
            message:
              '아직 RCS Biz Center(RBC)에 등록되지 않았거나 솔라피가 대행사 등록이 되지 않은 상태입니다.'
          }
        }
      }
    },
    payloadCreator: rbcAPI.registBrand
  },
  deleteBrand: {
    options: { debounce: { wait: 500 } },
    payloadCreator: rbcAPI.deleteBrand
  },
  getBrandList: {
    payloadCreator: rbcAPI.getBrandList
  },
  getFormList: {
    payloadCreator: rbcAPI.getFormList
  },
  getTplFormDetail: {
    payloadCreator: rbcAPI.getTplFormDetail
  },
  getTemplate: {
    payloadCreator: rbcAPI.getTemplate
  },
  getTemplateList: {
    payloadCreator: rbcAPI.getTemplateList
  },
  createTemplate: {
    options: { debounce: { wait: 500 } },
    payloadCreator: rbcAPI.createTemplate
  },
  updateTemplate: {
    options: { debounce: { wait: 500 } },
    payloadCreator: rbcAPI.updateTemplate
  },
  deleteTemplate: {
    options: { debounce: { wait: 500 } },
    payloadCreator: rbcAPI.deleteTemplate
  },
  syncTemplate: {
    payloadCreator: rbcAPI.syncTemplate
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
