import axios from 'axios'
import qs from 'qs'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const createShortCampaign = (params = {}) => {
  // const searchParams = omitBy(params, isUndefined)
  // const query = qs.stringify(searchParams)
  // const uri = `/short/v1/yourPathHear${query ? '?' + query : ''}`
  const uri = `/short/v1/campaigns`
  return axios.post(uri, params)
}

export const updateShortCampaign = (params = {}) => {
  const { campaignId, ...reqParams } = params
  const uri = `/short/v1/campaigns/${campaignId}`
  return axios.put(uri, reqParams)
}

export const deleteShortCampaign = (params = {}) => {
  const { campaignId } = params
  const uri = `/short/v1/campaigns/${campaignId}`
  return axios.delete(uri)
}

export const getCampaignList = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/short/v1/campaigns${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getStatByDaily = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/short/v1/stats/daily${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getStatByHourly = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/short/v1/stats/hourly${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getClickCount = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/short/v1/clicks/count${query ? '?' + query : ''}`
  return axios.get(uri)
}

export const getClicks = (params = {}) => {
  const searchParams = omitBy(params, isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/short/v1/clicks${query ? '?' + query : ''}`
  return axios.get(uri)
}
