import { Map } from 'immutable'
import { black as blackAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
const initialState = Map({
  blackList: {}
})

const handler = {
  GET_BLACK_LIST: {
    payloadCreator: blackAPI.getBlackList,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const blackList = action.payload.data
        const stateData = { blackList }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
