/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { support as supportAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const handler = {}
const initialState = Map({
  description: '문의사항이 있으신가요?',
  subject: '',
  message: '',
  email: '',
  customObject: {}
})
/*
  attachments: [{
    filename: '',
    data: ''
  }],
*/

handler.request = {
  payloadCreator: supportAPI.requestFeedback,
  reducer: {
    onFailure,
    onPending,
    onSuccess: (state, action) => {
      const message = '정상적으로 문의가 등록되었습니다.'
      const stateData = { message: '', email: '' }
      return onSuccess(state, action, { message, stateData })
    }
  }
}
handler.setSupportData = {
  reducer: (state, action) => {
    const { email, message, description, ...params } = action.payload
    return state.merge(
      state,
      Map({
        ...params,
        email,
        message,
        description
      })
    )
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
