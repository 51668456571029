const axios = require('axios')
const qs = require('querystring')

export const getGroups = (payload, option = {}) => {
  const { cache = false } = option
  const queryString = qs.stringify(payload)
  const uri = `/messages/v4/groups${queryString ? '?' + queryString : ''}`
  return axios.get(uri, { cache })
}

export const getMessageGroupList = searchParams => {
  const uri = `/messages/v4/groups?${decodeURIComponent(
    qs.stringify(searchParams)
  )}`
  return axios.get(uri)
}

export const getMessages = (payload, option = {}) => {
  const { cache = false } = option
  const queryString = qs.stringify(payload)
  const uri = `/messages/v4/list${queryString ? '?' + queryString : ''}`
  return axios.get(uri, { cache })
}

export const getMessageList = searchParams => {
  const uri = `/messages/v4/list?${decodeURIComponent(
    qs.stringify(searchParams)
  )}`
  return axios.get(uri)
}

export const resendGroup = groupId => {
  const uri = `/messages/v4/groups/${groupId}/resend`
  return axios.post(uri, {})
}

export const messageExcelDownload = payload => {
  const params = qs.stringify(payload)
  const uri = `/messages/v4/list/excel${params ? '?' + params : ''}`
  return axios.get(uri, { responseType: 'blob' })
}

export const getGroupInfo = groupId => {
  const uri = `/messages/v4/groups/${groupId}`
  return axios.get(uri)
}
