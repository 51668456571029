import axios from 'axios'

export const signUp = params => {
  const { email, password, confirmPassword, captcha, marketerAccountId } =
    params
  const uri = `/users/v1/signup`
  return axios.post(
    uri,
    {
      email,
      password,
      passwordConfirmation: confirmPassword,
      captcha,
      marketerAccountId
    },
    { withCredentials: true }
  )
}

export const existenceEmail = email => {
  const uri = `/users/v1/existence/email/${email}`
  return axios.get(uri)
}

export const getTerms = () => {
  const url = `https://solapi.com/ghost/api/content/pages/slug/terms-of-service?key=52fb5fbd3b32e97f5c4437e2a1`
  return axios.get(url)
}

export const getPrivacyStatement = () => {
  const url = `https://solapi.com/ghost/api/content/pages/slug/privacy?key=52fb5fbd3b32e97f5c4437e2a1`
  return axios.get(url)
}
