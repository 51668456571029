import cloneDeep from 'lodash/cloneDeep'
import merge from 'lodash/merge'

import getCustomActionsByProps from 'lib/getCustomActionsByProps'
import { isArray, isObject, isFunction, isEmptyObject } from 'lib/detectType'

// use examp
// mapAsyncActions(this.props, { exceptActions: { AppsActions: true } })
// mapAsyncActions(this.props, { exceptActions: { AppsActions: ['setDetail', 'setNew'] } })

const mapMfaMethod =
  (action, actionName, methodName) =>
  // container action
  (params, actionOptions = {}) => {
    return new Promise(async (resolve, reject) => {
      try {
        const data = await action[actionName][methodName](
          params,
          merge({ mfa: { then: resolve, catch: reject } }, actionOptions)
        )
        return resolve(data)
      } catch (error) {
        const { response = {} } = error
        const { status, data = {} } = response
        const { errorCode } = data
        // 첫 요청 2차인증의 경우
        if (
          status === 401 &&
          errorCode === 'Unauthorized' &&
          isObject(data?.mfa) &&
          !isEmptyObject(data?.mfa)
        ) {
          return
        }
        return reject(error)
      }
    })
  }

const mapAsyncActions = (props, options = {}) => {
  const { exceptActions = {} } = options
  const exceptActionsArr = Object.keys(exceptActions).filter(key => {
    return exceptActions[key] === true
  })
  const actions = getCustomActionsByProps(props)
    .filter(actionName => {
      return (
        exceptActionsArr.indexOf(actionName) === -1 &&
        isObject(props?.[actionName])
      )
    })
    .map(actionName => {
      props[actionName].name = actionName
      props[actionName].executeBy = props?.componentName
      return { [actionName]: props[actionName] }
    })
  cloneDeep(actions).forEach(action => {
    const actionName = Object.keys(action)?.[0]
    Object.keys(action?.[actionName]).forEach(methodName => {
      if (
        methodName === 'setState' ||
        methodName === 'clearResponse' ||
        methodName === 'clearCurrentContainerResponse' ||
        methodName === 'clearCurrentLocationResponse' ||
        methodName === 'clearResponseWithoutGet' ||
        !isFunction(props?.[actionName]?.[methodName]) ||
        exceptActions?.[actionName] === true ||
        (isArray(exceptActions?.[actionName]) &&
          exceptActions[actionName].indexOf(methodName) !== -1)
      ) {
        return
      }
      props[actionName][methodName] = mapMfaMethod(
        action,
        actionName,
        methodName
      )
    })
  })
}

export default mapAsyncActions
