/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import _ from 'lodash'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
import * as MfaAPI from 'lib/api/mfa'
import { isArray } from 'lib/detectType'
import config from 'config'

const handler = {}
const defaultMfaKey = 'public'
const defaultMfaData = {
  transactionId: undefined,
  authType: undefined,
  allowedAuthTypes: [],
  allowedExtras: [],
  extras: {},
  mfaToken: undefined,
  callback: undefined,
  done: undefined
}

const initialState = Map({
  defaultMfaKey,
  mfa: {}
})

// 초기화
Object.assign(handler, {
  INIT: {
    reducer: (state, action) => {
      const { mfaKey } = action.payload
      const mfa = state.get('mfa') || {}
      return state.set(
        'mfa',
        Object.assign({}, mfa, {
          [mfaKey]: _.cloneDeep(defaultMfaData)
        })
      )
    }
  }
})

// 인증방식 선택
Object.assign(handler, {
  SET_CURRENT_AUTH_TYPE: {
    reducer: (state, action) => {
      const { mfaKey, authType } = action.payload
      const mfa = state.get('mfa')
      const mfaData = mfa[mfaKey]
      if (mfaData && mfaData.authType === authType) return state
      const detailData = Object.assign({}, mfaData || {}, {
        authType,
        transactionId: undefined,
        mfaToken: undefined,
        message: '인증 방식을 변경했습니다. 다시 요청해주세요.'
      })
      return state.set('mfa', Object.assign({}, mfa, { [mfaKey]: detailData }))
    }
  }
})

// mfa 필요 여부 검사
Object.assign(handler, {
  MFA_CHECK: {
    reducer: (state, action) => {
      const defaultMfaKey = state.get('defaultMfaKey')
      const {
        mfaKey = defaultMfaKey,
        error,
        extras = {},
        mfaToken = '',
        callback
      } = action.payload
      if (typeof callback !== 'function') return state

      const { response = {} } = error
      const { data = {}, status: statusCode } = response
      const { errorMessage, errorCode, mfa: responseMfa } = data
      if (!responseMfa || statusCode !== 401 || errorCode !== 'Unauthorized') {
        return state
      }

      const {
        transactionId,
        allowedAuthTypes = {},
        extras: allowedExtras = []
      } = responseMfa
      const allowedAuthTypesArray = Object.keys(allowedAuthTypes)
      const allowedAuthTypesLength = allowedAuthTypesArray.length
      if (!allowedAuthTypesLength) return state

      const storage = window.localStorage
      const lastAuthType = storage.lastAuthTypes
      const mfa = state.get('mfa')
      const { authType: stateAuthType } = mfa[mfaKey] || {}
      const authType =
        stateAuthType ||
        (lastAuthType && allowedAuthTypesArray.indexOf(lastAuthType) !== -1
          ? lastAuthType
          : allowedAuthTypesArray[0])
      const message = errorMessage || '인증 요청 했습니다.'
      const detailData = Object.assign(
        {},
        {
          transactionId,
          authType,
          allowedAuthTypes,
          allowedExtras,
          extras: _.pick(extras, allowedExtras),
          message,
          callback,
          mfaToken,
          done: false
        }
      )
      return state.set('mfa', Object.assign({}, mfa, { [mfaKey]: detailData }))
    }
  }
})

// 토큰 입력
Object.assign(handler, {
  SET_MFA_TOKEN: {
    reducer: (state, action) => {
      const { mfaKey, mfaToken } = action.payload
      const mfa = state.get('mfa')
      const detailData = Object.assign({}, mfa[mfaKey] || {}, { mfaToken })
      return state.set('mfa', Object.assign({}, mfa, { [mfaKey]: detailData }))
    }
  }
})

// 인증 완료
handler.done = {
  reducer: (state, action) => {
    const mfa = state.get('mfa')
    const { mfaKey = defaultMfaKey, message } = action.payload || {}
    const done = true
    return state.set(
      'mfa',
      Object.assign({}, mfa, { [mfaKey]: { done, message, mfaToken: '' } })
    )
  }
}

// 해시 인증 검사
Object.assign(handler, {
  VERIFY_HASH: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: MfaAPI.verifyHash,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action) => {
        const stateData = { verifyHeshResult: action.payload.data }
        return onSuccess(state, action, { stateData })
      }
    }
  }
})

// 2차 인증 초기화 (종료)
handler.clear = {
  reducer: (state, action) => {
    const { mfaKey } = action.payload || {}
    if (!mfaKey) return state.set('mfa', {})
    const mfa = _.omit(
      _.cloneDeep(state.get('mfa')),
      isArray(mfaKey) ? mfaKey : [mfaKey]
    )
    return state.set('mfa', mfa)
  }
}

// get txn
Object.assign(handler, {
  GET_TXN: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: MfaAPI.getTxn,
    reducer: {
      onFailure,
      onPending,
      onSuccess
    }
  }
})

handler.setMfa = {
  reducer: (state, action) => {
    const { key, mfa } = action.payload
    const oriMfa = state.get('mfa')
    const newMfa = {}
    Object.assign(newMfa, oriMfa, { [key]: mfa })
    return state.set('mfa', newMfa)
  }
}

handler.setExtras = {
  reducer: (state, action) => {
    const { key, data = {} } = action.payload
    const oriMfa = state.get('mfa')
    const mfa = _.cloneDeep(oriMfa[key])
    if (!mfa) return state
    if (!mfa.options.extras) {
      mfa.options.extras = {}
    }
    mfa.message = '인증번호를 요청해주세요.'
    Object.assign(mfa.options.extras, data)
    mfa.options.extras = _.omitBy(mfa.options.extras, data => {
      return !data
    })
    return state.set(
      'mfa',
      Object.assign({}, oriMfa, {
        [key]: _.omit(mfa, ['transactionId', 'mfaToken'])
      })
    )
  }
}

handler.show = {
  reducer: (state, action) => {
    const oriMfa = state.get('mfa')
    const {
      key,
      mfa = {},
      responseData = {},
      requestConfig = {},
      actions,
      action: moduleAction
    } = action.payload
    const containerOptions = mfa?.options
    const containerOrderPriority = containerOptions?.orderPriority || []
    const orderPriority =
      isArray(containerOrderPriority) && containerOrderPriority.length > 0
        ? containerOrderPriority
        : config.defaultAuthTypeOrderPriority
    const oriAuthType = oriMfa[key] && oriMfa[key].authType
    // 기본 우선순위 설정 (컨테이너에서 넘어온 옵션 순)
    const allowedAuthTypesArray = _.sortBy(
      Object.keys(mfa?.allowedAuthTypes || {}),
      (authType, index) => {
        const p = orderPriority.indexOf(authType)
        return p === -1 ? orderPriority.length + index : p
      }
    )
    const priority = allowedAuthTypesArray.map((type, index) => {
      if (type === 'PASSWORD') {
        // 2차 비밀번호 재시도 횟수가 남지않았거나 2차 비밀번호 미설정한 멤버의 경우 2차 비번 표시 우선순위 미룸
        const { remainingRetryCount, available } =
          mfa.allowedAuthTypes[type] || {}
        if (remainingRetryCount === 0 || available !== true) {
          return { [type]: allowedAuthTypesArray.length + index }
        }
      }
      return { [type]: index }
    })
    const sortedAllowedAuthTypes = _.sortBy(priority, p => {
      const key = Object.keys(p)[0]
      return p[key]
    }).map(data => {
      return Object.keys(data)[0]
    })
    const mfaData = {
      [key]: Object.assign({}, mfa, {
        authType: oriAuthType ?? mfa.authType ?? sortedAllowedAuthTypes?.[0],
        sortedAllowedAuthTypes: sortedAllowedAuthTypes,
        responseData,
        requestConfig,
        actions,
        action: moduleAction,
        done: false
      })
    }
    return state.set('mfa', Object.assign({}, oriMfa, mfaData))
  }
}

handler.createPassword = {
  options: {
    requestName: '2차 비밀번호 생성'
  },
  payloadCreator: MfaAPI.createPassword
}

handler.updatePassword = {
  options: {
    requestName: '2차 비밀번호 변경 / 재시도 횟수 초기화'
  },
  payloadCreator: MfaAPI.updatePassword
}

handler.checkForPasswordUsage = {
  options: {
    requestName: '2차 비밀번호 사용여부 확인'
  },
  payloadCreator: MfaAPI.checkForPasswordUsage
}

handler.deletePassword = {
  options: {
    requestName: '2차 비밀번호 삭제'
  },
  payloadCreator: MfaAPI.deletePassword
}

handler.getMemberHash = {
  options: {
    response: {
      error: {
        ignore: true // (array or boolean) default: false
      }
    }
  },
  payloadCreator: MfaAPI.getMemberHash
}

handler.tempCert = {
  options: { debounce: { wait: 500 } },
  payloadCreator: MfaAPI.tempCert
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
