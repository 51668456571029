/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'

// material UI
import LinearProgress from '@material-ui/core/LinearProgress'
import CircularProgress from '@material-ui/core/CircularProgress'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'

// my components
// import UserComponentName from 'components/molecules/UserComponentName'

// libraries
// import config from 'config'
// import { isArray } from 'lib/detectType'

const styles = theme => ({
  root: {},
  colorPrimary: { color: '#ffffff90' },
  colorPrimaryPause: { color: '#ffffff40' }
})
export const useProps = {}
/**
 * (작성된 설명이 없습니다.)
 */
const Progress = ({ classes, proxy, ...props }) => {
  const { type, value, pause, progressProps = {} } = props
  if (type === 'circular') {
    return (
      <CircularProgress
        classes={{
          colorPrimary: classNames(classes.colorPrimary, {
            [classes.colorPrimaryPause]: pause
          })
        }}
        variant="determinate"
        value={value}
        {...progressProps}
      />
    )
  }
  return (
    <LinearProgress
      classes={{
        colorPrimary: classNames(classes.colorPrimary, {
          [classes.colorPrimaryPause]: pause
        })
      }}
      variant="determinate"
      value={value}
      {...progressProps}
    />
  )
}

Progress.defaultProps = setDefaultProps(useProps)
Progress.propTypes = setPropTypes(useProps)
export default withStyles(styles)(Progress)
