import axios from 'axios'

export const getAccountList = async payload => {
  const { params = {}, options = {} } = payload
  if (options.loadNew) params.startKey = null
  if (params.limit) params.limit++
  const result = await axios.get(`/users/v1/accounts`, {
    params
  })
  return Object.assign({}, result, options)
}

export const createAccount = async () => axios.post(`/users/v1/accounts`, {})

export const changeSelectedAccount = async accountId =>
  axios.post(
    `/users/v1/accounts/${accountId}/switch`,
    {},
    { withCredentials: true }
  )

export const activeAccount = payload => {
  const { accountId, mfa } = payload
  return axios.put(`/users/v1/accounts/${accountId}/activate`, { mfa })
}
