import { Map } from 'immutable'
import { pender } from 'redux-pender'
import {
  createAction as reduxCreateActions,
  handleActions as reduxHandleActions
} from 'redux-actions'

import axios from 'axios'
import uniqid from 'uniqid'
import omitBy from 'lodash/omitBy'
import omit from 'lodash/omit'
import isUndefined from 'lodash/isUndefined'

import {
  isNumber,
  isFunction,
  isObject,
  isArray,
  isEmptyString
} from 'lib/detectType'
import toggleCamel from 'lib/toggleCamel'
import { saveTiming } from 'lib/requestLog'
import parseErrorMessage from 'lib/parseErrorMessage'

const asyncDebounce = (func, wait, options = {}) => {
  let timeout
  return function () {
    const context = this
    const args = arguments
    const { leading, trailing, async } = options
    const later = () => {
      timeout = null
      if (trailing) {
        return func.apply(context, args)
      }
    }
    const callNow = leading && !timeout
    clearTimeout(timeout)
    if (callNow) {
      return async
        ? Promise.resolve(func.apply(context, args))
        : func.apply(context, args)
    }
    if (async) {
      return new Promise((resolve, reject) => {
        timeout = setTimeout(() => {
          resolve(later())
        }, wait)
      })
    }
    timeout = setTimeout(later, wait)
  }
}

export const createAction = (
  type, // fullActionName
  payloadCreator,
  metaCreator,
  options = {}
) => {
  const {
    debounce: { wait = 0, leading = false, trailing = true, async = true } = {}
  } = options
  const isUseDebounce = isNumber(wait) && wait > 0
  const actionName = toggleCamel(type.split('/')[1])
  return reduxCreateActions(
    type,
    // options 에서 debounce옵션을 활성화 한 경우
    isUseDebounce
      ? asyncDebounce(
          customPayloadCreator(payloadCreator, { actionName }),
          wait,
          { leading, trailing, async }
        )
      : customPayloadCreator(payloadCreator, { actionName }),
    // meta creator
    customMetaCreator(metaCreator, options)
  )
}

function customPayloadCreator(payloadCreator, options = {}) {
  const { actionName } = options
  // 이곳의 payload는 lib/bindActionCreators.js 에서 생성
  return (...args) => {
    // 사용자가 파라미터를 1개 이상 보내온 경우
    const isFullArgs = isArray(args) && args.length > 1
    const payload = isFullArgs ? args.slice(0, args.length - 1) : []
    const isMultiPayload = payload.length > 1
    // payloadCreator(API)쪽으로 보낼 option
    const containerOptions = isObject(args[args.length - 1])
      ? args[args.length - 1]
      : {}

    // api 요청이나 중간에 별도의 payload를 변경하는 작업이 없을 경우
    // 이곳은 payload를 한 가지만 사용가능
    if (!isFunction(payloadCreator)) return payload[0]

    // 2차 인증 여부 확인
    const { config: mfaRequestConfig, mfaRequest } = payload[0] || {}
    const isMfa = mfaRequestConfig && mfaRequest === true

    // 캐시 사용 시
    // const callstack = String(new Error().stack).split('\n')
    // const fromInitFunction =
    //   callstack.filter(data => {
    //     return /componentDidMount/.test(data)
    //   }).length > 0
    Object.assign(containerOptions, {
      // cache: !isBoolean(containerOptions?.cache)
      //   ? Boolean(fromInitFunction) && !isMfa
      //   : containerOptions?.cache
      cache: false
    })
    const asyncFunction = async () => {
      const startTime = Date.now()
      try {
        // backend에 request를 하는 실질적인 부분입니다.
        const result = await (isMfa
          ? axios.request(mfaRequestConfig)
          : isMultiPayload
          ? payloadCreator(...payload, containerOptions)
          : payloadCreator(payload[0], containerOptions))
        return result
      } catch (error) {
        throw error
      } finally {
        const endTime = Date.now()
        const timing = endTime - startTime
        saveTiming({
          category: 'StoreAction',
          variable: actionName,
          value: timing
        })
      }
    }
    return asyncFunction()
  }
}

const customMetaCreator = (metaCreator, metaCreatorOptions = {}) => {
  const { moduleName } = metaCreatorOptions
  // options => 사용자가 container에서 보내온 옵션
  // 해당 부분의 파라미터는 lib/bindActionCreators.js 에서 넘어옴
  return (...args) => {
    const isFullArgs = isArray(args) && args.length > 1
    const payload = isFullArgs ? args.slice(0, args.length - 1) : []
    // payloadCreator(API)쪽으로 보낼 option
    const containerOptions = args[args.length - 1]
    const {
      // 기본적으로 container에서 stateData를 조정할 수 있음
      stateData = {},
      // 2차 인증에 관련된 옵션 설정
      mfa = {},
      moduleName: bindModuleName
    } = containerOptions
    if (isFunction(metaCreator)) {
      return metaCreator(...payload, containerOptions)
    }
    return omitBy(
      {
        actionId: uniqid(),
        payload: payload[0],
        fullPayload: payload,
        mfa,
        stateData,
        containerOptions,
        moduleName: bindModuleName || moduleName || containerOptions?.moduleName
      },
      isUndefined
    )
  }
}

const defaultHandler = {
  SET_STATE: {
    reducer: (state, action) => {
      return state.merge(Map(Object.assign({}, action.payload || {})))
    }
  },
  CLEAR_RESPONSE: {
    reducer: (state, action) => {
      const key = action.payload?.key
      const responseData = state.get('responseData')
      if (!key) return state.set('responseData', {})
      return state.set(
        'responseData',
        omit(responseData, isArray(key) ? key : [key])
      )
    }
  },
  CLEAR_CURRENT_CONTAINER_RESPONSE: {
    reducer: (state, action) => {
      const responseData = state.get('responseData')
      const containerOptions = action?.meta?.containerOptions
      const executeBy = containerOptions?.executeBy
      if (isEmptyString(executeBy)) return state
      const newResponseData = omitBy(responseData, value => {
        return executeBy === value?.containerOptions?.executeBy
      })
      return state.set('responseData', newResponseData)
    }
  },
  CLEAR_CURRENT_LOCATION_RESPONSE: {
    reducer: (state, action) => {
      const responseData = state.get('responseData')
      const currentLocation = window.location.href
      const newResponseData = omitBy(responseData, value => {
        return currentLocation === value?.location
      })
      return state.set('responseData', newResponseData)
    }
  },
  CLEAR_RESPONSE_WITHOUT_GET: {
    reducer: (state, action) => {
      const responseData = state.get('responseData')
      const newResponseData = omitBy(responseData, value => {
        return value?.method !== 'GET'
      })
      return state.set('responseData', newResponseData)
    }
  }
}

export const createRedux = (paramHandler, initialState, options = {}) => {
  let { moduleName } = options
  moduleName =
    moduleName || `${Date.now()}${Math.floor(Math.random() * 100000000)}`
  const handler = Object.assign({}, defaultHandler, paramHandler)
  const actions = Object.keys(handler)
  const exportActionObject = Object.assign(
    {},
    ...actions.map(actionName => {
      const fullActionName = `${moduleName}/${toggleCamel(actionName, false)}`
      const {
        payloadCreator,
        metaCreator,
        options: actionOptions = {}
      } = handler[actionName]
      const exportNames = toggleCamel(actionName)
      return {
        [exportNames]: createAction(
          fullActionName,
          payloadCreator,
          metaCreator,
          { ...options, ...actionOptions }
        )
      }
    })
  )
  const reducers = Object.assign(
    {},
    ...actions.map(actionName => {
      const fullActionName = `${moduleName}/${toggleCamel(actionName, false)}`
      const { reducer, options: actionOptions = {} } = handler[actionName]
      const {
        saveLogFlag,
        response: {
          error: {
            display: errorDisplay = 'snackbar',
            ignore: errorIgnore = false,
            helper = {}
          } = {},
          loading: {
            display: loadingDisplay = 'circular',
            ignore: loadingIgnore = false
          } = {},
          message: {
            display: messageDisplay = 'snackbar',
            ignore: messageIgnore = false
          } = {}
        } = {},
        reducerDebounce: {
          wait = 0,
          leading = true,
          trailing = true,
          async = false
        } = {}
      } = actionOptions
      if (!reducer || isObject(reducer)) {
        const newReducer = Object.assign(
          {},
          { onFailure, onPending, onSuccess },
          reducer || {}
        )
        return pender({
          type: fullActionName,
          ...Object.assign(
            {},
            ...Object.keys(newReducer).map(fnName => {
              const reducerHandler = (state, action) => {
                return newReducer[fnName](state, action, {
                  actionName: toggleCamel(actionName, true),
                  actions: exportActionObject,
                  reducer: newReducer,
                  saveLogFlag,
                  actionOptions,
                  response: {
                    error: {
                      display: errorDisplay,
                      ignore: errorIgnore,
                      helper
                    },
                    loading: { display: loadingDisplay, ignore: loadingIgnore },
                    message: { display: messageDisplay, ignore: messageIgnore }
                  }
                })
              }
              return {
                [fnName]: wait
                  ? asyncDebounce(reducerHandler, wait, {
                      leading,
                      trailing,
                      async
                    })
                  : reducerHandler
              }
            })
          )
        })
      }
      return {
        [fullActionName]: wait
          ? asyncDebounce(reducer, wait, {
              leading,
              trailing,
              async
            })
          : reducer
      }
    })
  )
  return {
    actions: exportActionObject,
    reducers: reduxHandleActions(reducers, initialState)
  }
}

export const onFailure = (state, action, options = {}) => {
  const {
    moduleName,
    containerOptions = {},
    actionId,
    mfa: mfaOptions,
    payload
  } = action.meta
  const {
    actionName,
    stateData: moduleStateData = {},
    saveLogFlag: moduleSaveLogFlag,
    response: responseOptions = {},
    actionOptions = {}
  } = options
  const {
    key,
    actions,
    stateData = moduleStateData,
    saveLogFlag = moduleSaveLogFlag
  } = containerOptions
  const { response = {} } = action.payload || {}
  const { status, data = {}, config: requestConfig = {} } = response
  const { mfa } = data
  if (mfa && mfaOptions) {
    if (!containerOptions?.mfa) containerOptions.mfa = mfaOptions
    Object.assign(mfa, { options: mfaOptions })
  }
  const isCancel = axios.isCancel(action?.payload)
  const { formatMessage, errorMessage } =
    parseErrorMessage(action, options) || {}
  const newMessage = isCancel
    ? action?.payload?.message || '서버 요청이 취소되었습니다.'
    : formatMessage
  const pendingResponseData = state.get('responseData')?.[key || actionName]
  const timing =
    pendingResponseData?.loading === true
      ? `${
          (Date.now() -
            new Date(pendingResponseData?.dateRequested).getTime()) /
          1000
        }s`
      : pendingResponseData?.timing
  const newResponseData = {
    packageVersion: process.env.REACT_APP_VERSION,
    actionId,
    key,
    actionName,
    location: window.location.href,
    status,
    loading: false,
    error: true,
    payload,
    headers: requestConfig.headers,
    url: requestConfig.url,
    requestData: requestConfig.data,
    method: requestConfig.method,
    code: response?.data?.errorCode,
    // 사용자에게 보여지는 메시지
    message: newMessage,
    // 내부 메시지
    errorMessage,
    moduleName,
    mfa,
    saveLogFlag,
    actionOptions,
    containerOptions: omit(containerOptions, ['actions']),
    responseOptions,
    isCancel,
    cache: requestConfig?.cache,
    permissionDenied: data?.permissionDenied,
    timing
  }
  const responseData = Object.assign({}, state.get('responseData'), {
    [key || actionName]: newResponseData
  })
  const newActions = Object.assign({}, state.get('actions'), {
    [key || actionName]: actions
  })
  const newRequestConfig = Object.assign({}, state.get('requestConfig'), {
    [key || actionName]: requestConfig
  })
  return state.merge(
    Map(
      Object.assign({}, stateData, {
        responseData,
        requestConfig: newRequestConfig,
        actions: newActions
      })
    )
  )
}

export const onPending = (state, action, options = {}) => {
  const {
    moduleName,
    actionId,
    payload = {},
    containerOptions = {}
  } = action.meta
  const {
    actionName,
    message,
    stateData = {},
    response: responseOptions = {},
    saveLogFlag,
    actionOptions = {}
  } = options
  const { key } = containerOptions
  const { mfaRequest = false } = payload || {}
  const newResponseData = {
    packageVersion: process.env.REACT_APP_VERSION,
    key,
    actionName,
    moduleName,
    loading: true,
    error: false,
    mfaRequest,
    message,
    responseOptions,
    location: window.location.href,
    actionId,
    saveLogFlag,
    actionOptions,
    dateRequested: new Date().toISOString()
  }
  const responseData = Object.assign({}, state.get('responseData'), {
    [key || actionName]: newResponseData
  })
  return state.merge(Map(Object.assign({}, stateData, { responseData })))
}

export const onSuccess = (state, action, options = {}) => {
  const {
    payload,
    moduleName,
    actionId,
    containerOptions = {}
  } = action.meta || {}
  const { key, stateData: metaStateData = {} } = containerOptions
  const { status, data, config: requestConfig = {} } = action.payload || {}
  const {
    actionName,
    stateData = metaStateData,
    saveLogFlag,
    response: responseOptions = {},
    actionOptions = {}
  } = options
  const message =
    options?.message ||
    containerOptions?.successMessage ||
    actionOptions?.response?.successMessage
  const pendingResponseData = state.get('responseData')?.[key || actionName]
  const timing =
    pendingResponseData?.loading === true
      ? `${
          (Date.now() -
            new Date(pendingResponseData?.dateRequested).getTime()) /
          1000
        }s`
      : pendingResponseData?.timing
  const newResponseData = {
    packageVersion: process.env.REACT_APP_VERSION,
    moduleName,
    key,
    actionName,
    status,
    loading: false,
    error: false,
    message,
    data,
    headers: requestConfig.headers,
    url: requestConfig.url,
    requestData: requestConfig.data,
    method: requestConfig.method,
    payload,
    location: window.location.href,
    actionId,
    saveLogFlag,
    actionOptions,
    containerOptions: omit(containerOptions, ['actions']),
    responseOptions,
    timing
  }
  const responseData = Object.assign({}, state.get('responseData'), {
    [key || actionName]: newResponseData
  })
  const newRequestConfig = Object.assign({}, state.get('requestConfig'), {
    [key || actionName]: requestConfig
  })
  return state.merge(
    Map(
      Object.assign({}, stateData, {
        responseData,
        requestConfig: newRequestConfig
      })
    )
  )
}

export default {
  onSuccess,
  onPending,
  onFailure,
  createAction,
  createRedux
}
