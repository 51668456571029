const axios = require('axios')
const qs = require('querystring')

export const getBalance = params => {
  const uri = `/cash/v1/balance`
  return axios.get(uri)
}

export const getLBAInfo = () => {
  const uri = `/cash/v1/balance/alert`
  return axios.get(uri)
}

export const switchLBA = params => {
  const uri = `/cash/v1/balance/alert/switch`
  return axios.put(uri, params)
}

export const setLBA = params => {
  const uri = `/cash/v1/balance/alert`
  return axios.put(uri, params)
}

export const getBalanceInfo = () => {
  const uri = `/cash/v1/balance`
  return axios.get(uri)
}

export const getPaymentInfo = payload => {
  const uri = `/cash/v1/payment`
  return axios.get(uri)
}

export const createPayment = paymentInfo => {
  const uri = `/cash/v1/payment`
  return axios.post(uri, paymentInfo)
}

export const deletePayment = data => {
  const uri = `/cash/v1/payment`
  return axios.delete(uri, { data })
}

export const chargeBalance = chargeParams => {
  const uri = `/cash/v1/balance`
  return axios.post(uri, chargeParams)
}

export const requestPayment = payload => {
  const uri = `/cash/v1/balance`
  return axios.post(uri, payload)
}

export const setAutoRecharge = params => {
  const uri = `/cash/v1/payment`
  return axios.put(uri, params)
}

export const switchAutoRecharge = payload => {
  const uri = `/cash/v1/recharge`
  return axios.put(uri, payload)
}

export const getBankAccount = () => {
  const uri = `/cash/v1/bank/accounts`
  return axios.get(uri)
}
// 계좌 등록
export const authBankAccount = accountParams => {
  const uri = `/cash/v1/bank/accounts/authentication`
  return axios.post(uri, accountParams)
}

export const deleteBankAccount = () => {
  const uri = `/cash/v1/bank/accounts`
  return axios.delete(uri)
}

export const withdraw = params => {
  const uri = `/cash/v1/withdraw`
  return axios.post(uri, params)
}

export const transfer = params => {
  const uri = `/cash/v1/transfer`
  return axios.post(uri, params)
}

export const receipt = historyId => {
  const uri = `/cash/v1/receipt?historyId=${historyId}`
  return axios.get(uri, {
    responseType: 'blob'
  })
}

export const getBalanceHistory = params => {
  const query = qs.stringify(params)
  const uri = `/cash/v1/balance/history?${query}`
  return axios.get(uri)
}

// pricing
export const getUnitPriceInfo = () => {
  const uri = `/pricing/v1/messaging/countries?limit=300`
  return axios.get(uri)
}

export const issueTaxinvoice = payload => {
  const uri = `/cash/v1/taxinvoice`
  return axios.post(uri, payload)
}
