import { Map } from 'immutable'
import _ from 'lodash'
import { requestHandler } from 'lib'
import * as UploadAPI from 'lib/api/upload'
import { isString } from 'lib/detectType'

const { createRedux, onSuccess } = requestHandler
const initialState = Map({
  files: {},
  fileData: {},
  fileItems: {},
  filePondProps: {},
  ref: {},
  options: {},
  status: {
    INIT: 1,
    IDLE: 2,
    PROCESSING_QUEUED: 9,
    PROCESSING: 3,
    PROCESSING_COMPLETE: 5,
    PROCESSING_ERROR: 6,
    PROCESSING_REVERT_ERROR: 10,
    LOADING: 7,
    LOAD_ERROR: 8
  },
  defaultProps: {
    labelIdle:
      '첨부할 파일을 이곳에 드래그 하거나 <span class="filepond--label-action">찾아보기</span>'
  }
})

const handler = {
  INIT: {
    reducer: (state, action) => {
      const { key, options = {}, filePondProps = {} } = action.payload
      const originOptions = state.get('options')
      const originReader = state.get('reader')
      const originFiles = state.get('files')
      const originFileData = state.get('fileData')
      const originRawData = state.get('rawData')
      const originFileItems = state.get('fileItems')
      const originProps = state.get('filePondProps')
      const defaultProps = _.cloneDeep(state.get('defaultProps') || {})
      return state
        .set('reader', Object.assign({}, originReader, { [key]: [] }))
        .set('files', Object.assign({}, originFiles, { [key]: [] }))
        .set('fileData', Object.assign({}, originFileData, { [key]: [] }))
        .set('rawData', Object.assign({}, originRawData, { [key]: [] }))
        .set('fileItems', Object.assign({}, originFileItems, { [key]: [] }))
        .set('options', Object.assign({}, originOptions, { [key]: options }))
        .set(
          'filePondProps',
          Object.assign({}, originProps, {
            [key]: Object.assign(defaultProps, _.cloneDeep(filePondProps))
          })
        )
    }
  },
  CLEAR_FILES: {
    reducer: (state, action) => {
      const { key } = action.payload
      const originReader = _.cloneDeep(state.get('reader'))
      const originFiles = _.cloneDeep(state.get('files'))
      const originFileData = _.cloneDeep(state.get('fileData'))
      const originRawData = _.cloneDeep(state.get('rawData'))
      const originFileItems = _.cloneDeep(state.get('fileItems'))
      const reader = _.omit(originReader, [key])
      const files = _.omit(originFiles, [key])
      const fileData = _.omit(originFileData, [key])
      const rawData = _.omit(originRawData, [key])
      const fileItems = _.omit(originFileItems, [key])
      return state
        .set('reader', reader)
        .set('files', files)
        .set('fileData', fileData)
        .set('rawData', rawData)
        .set('fileItems', fileItems)
    }
  },
  REMOVE_FILE: {
    reducer: (state, action) => {
      const { key, index: removeIndex } = action.payload
      const originReader = _.cloneDeep(state.get('reader'))
      const originFiles = _.cloneDeep(state.get('files'))
      const originFileData = _.cloneDeep(state.get('fileData'))
      const originRawData = _.cloneDeep(state.get('rawData'))
      const originFileItems = _.cloneDeep(state.get('fileItems'))
      const reader = Object.assign({}, originReader, {
        [key]: (originReader[key] || []).filter((data, index) => {
          return index !== removeIndex && removeIndex !== -1
        })
      })
      const files = Object.assign({}, originFiles, {
        [key]: (originFiles[key] || []).filter((data, index) => {
          return index !== removeIndex && removeIndex !== -1
        })
      })
      const fileData = Object.assign({}, originFileData, {
        [key]: (originFileData[key] || []).filter((data, index) => {
          return index !== removeIndex && removeIndex !== -1
        })
      })
      const rawData = Object.assign({}, originRawData, {
        [key]: (originRawData[key] || []).filter((data, index) => {
          return index !== removeIndex && removeIndex !== -1
        })
      })
      const fileItems = Object.assign({}, originFileItems, {
        [key]: (originFileItems[key] || []).filter((data, index) => {
          return index !== removeIndex && removeIndex !== -1
        })
      })
      return state
        .set('reader', reader)
        .set('files', files)
        .set('fileData', fileData)
        .set('rawData', rawData)
        .set('fileItems', fileItems)
    }
  },
  ADD_FILE: {
    payloadCreator: UploadAPI.addFile,
    reducer: {
      onSuccess: (state, action, options) => {
        const { key, fileItem } = action.meta.payload
        const file = action.payload
        const originReader = state.get('reader')
        const originFiles = state.get('files')
        const originFileData = state.get('fileData')
        const originFileItems = state.get('fileItems')
        const originRawData = state.get('rawData')
        const stateData = {
          reader: Object.assign({}, originReader, {
            [key]: (originReader[key] || []).concat(file?.reader)
          }),
          files: Object.assign({}, originFiles, {
            [key]: (originFiles[key] || []).concat(fileItem.file)
          }),
          fileData: Object.assign({}, originFileData, {
            [key]: (originFileData[key] || []).concat(
              isString(file.result) && file.result.replace
                ? file.result.replace(/^.*base64,/, '')
                : file.result
            )
          }),
          rawData: Object.assign({}, originRawData, {
            [key]: (originRawData[key] || []).concat(file.result)
          }),
          fileItems: Object.assign({}, originFileItems, {
            [key]: (originFileItems[key] || []).concat(fileItem)
          })
        }
        return onSuccess(state, action, { stateData, ...options })
      }
    }
  },
  UPDATE_FILES: {
    payloadCreator: UploadAPI.updateFiles,
    reducer: {
      onSuccess: (state, action, options) => {
        const { key, fileItems } = action.meta.payload
        const files = action.payload
        const originReader = state.get('reader')
        const originFiles = state.get('files')
        const originFileData = state.get('fileData')
        const originFileItems = state.get('fileItems')
        const originRawData = state.get('rawData')
        const stateData = {
          reader: Object.assign({}, originReader, {
            [key]: files.map(file => file.reader)
          }),
          files: Object.assign({}, originFiles, {
            [key]: fileItems.map(item => item.file)
          }),
          fileData: Object.assign({}, originFileData, {
            [key]: files
              .map(file =>
                isString(file.result) && file.result.replace
                  ? file.result.replace(/^.*base64,/, '')
                  : file.result
              )
              .filter((file, index) => {
                return fileItems[index].status === 2
              })
          }),
          rawData: Object.assign({}, originRawData, {
            [key]: files
              .map(file => file.result)
              .filter((file, index) => {
                return fileItems[index].status === 2
              })
          }),
          fileItems: Object.assign({}, originFileItems, {
            [key]: fileItems
          })
        }
        return onSuccess(state, action, { stateData, ...options })
      }
    }
  }
}
const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
