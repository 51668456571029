import axios from 'axios'

export const login = params => {
  const uri = '/coolapi/v1/login'
  return axios.post(uri, params, { withCredentials: true })
}

export const getMyIP = params => {
  const uri = 'https://www.cloudflare.com/cdn-cgi/trace'
  return axios.get(uri)
}

export const getUser = params => {
  const uri = `/coolapi/v1/users`
  return axios.get(uri)
}

export const getAllowedIpAddrs = params => {
  const uri = `/allowed-ipaddrs/v1/ipaddrs`
  return axios.get(uri)
}

export const createAllowedIpAddr = params => {
  const uri = `/allowed-ipaddrs/v1/ipaddrs`
  return axios.post(uri, params)
}

export const deleteAllowedIpAddr = params => {
  const uri = `/allowed-ipaddrs/v1/ipaddrs`
  return axios.delete(uri, { data: params })
}

export const updateUser = params => {
  const uri = `/coolapi/v1/users`
  return axios.put(uri, params)
}

export const getPayInfo = payload => {
  const uri = `/coolapi/v1/pay-info`
  return axios.get(uri)
}
