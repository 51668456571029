import { Map } from 'immutable'
import * as webhookAPI from 'lib/api/webhook'
import { requestHandler } from 'lib'
const { createRedux } = requestHandler
const initialState = Map({})
const handler = {}

handler.getWebhooks = {
  payloadCreator: webhookAPI.getWebhooks
}

handler.createEvent = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: webhookAPI.createEvent
}

handler.createWebhook = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: webhookAPI.createWebhook
}

handler.getEvents = {
  payloadCreator: webhookAPI.getEvents
}

handler.updateWebhook = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: webhookAPI.updateWebhook
}

handler.deleteWebhook = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: webhookAPI.deleteWebhook
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
