import getAllProperties from 'lib/getAllProperties'

const proxyOverride = (self, options = {}) => {
  const { except = [] } = options
  if (!self.props.proxy) return
  getAllProperties(self, { include: [/.+/] }).forEach(key => {
    if (except.indexOf(key) === -1) {
      self.props.proxy[key] = self[key]
    }
  })
}

export default proxyOverride
