import { Map } from 'immutable'
import { accept as acceptAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  password: '',
  passwordConfirm: '',
  isValidPassword: false,
  isValidPasswordConfirm: false,
  errorCode: undefined,
  isCreateInviteMember: false,
  isAccept: false,
  invitationInfo: {
    owner: { name: '' },
    account: { name: '' }
  }
})
const PW_REGEX = /^(?=.*?[a-zA-Z])(?=.*?[0-9])(?=.*?[~!@#$%^&*()_+[\]\-={}|])[a-zA-Z0-9~!@#$%^&*()_+[\]\-={}|]{6,16}$/

const handler = {
  ON_CHANGE_PASSWORD: {
    reducer: (state, action) => {
      const password = action.payload
      return state
        .set('password', password)
        .set('isValidPassword', PW_REGEX.test(password))
    }
  },
  ON_CHANGE_PASSWORD_CONFIRM: {
    reducer: (state, action) => {
      const password = action.payload
      return state
        .set('passwordConfirm', password)
        .set('isValidPasswordConfirm', PW_REGEX.test(password))
    }
  },
  ACCEPT_INVITATION: {
    payloadCreator: acceptAPI.acceptInvitation,
    reducer: {
      onPending,
      onFailure: (state, action, options) => {
        const isCreateInviteMember = state.get('isCreateInviteMember')
        if (isCreateInviteMember) {
          // 이미 만들어진 멤버 처리가 되면 수락으로 처리
          const stateData = { isAccept: true }
          return onFailure(state, action, { ...options, stateData })
        } else {
          const { errorCode } = action.payload.response.data
          const stateData = { errorCode }
          if (errorCode) {
            return onFailure(state, action, { ...options, stateData })
          }
        }
      },
      onSuccess: (state, action, options) => {
        const stateData = { isAccept: true }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  CREATE_INVITE_MEMBER: {
    payloadCreator: acceptAPI.createInviteMember,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { isCreateInviteMember: true }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_INVITATION_INFO: {
    payloadCreator: acceptAPI.getInvitationInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { invitationInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
