import { Map } from 'immutable'
import * as prePaymentAPI from 'lib/api/prePayment'
import { requestHandler } from 'lib'
const { createRedux } = requestHandler
const initialState = Map({})
const handler = {}

handler.payByImport = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: prePaymentAPI.payByImport
}

handler.payByKcp = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: prePaymentAPI.payByKcp
}

handler.getHistories = {
  payloadCreator: prePaymentAPI.getHistories
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
