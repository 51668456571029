import axios from 'axios'
import _ from 'lodash'
import qs from 'qs'

export const getBlackList = (params = {}) => {
  const searchParams = _.omitBy(params, _.isUndefined)
  const query = qs.stringify(searchParams)
  const uri = `/iam/v1/black${query ? '?' + query : ''}`
  return axios.get(uri)
}
