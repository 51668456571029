import axios from 'axios'
import qs from 'qs'

export const getWebhooks = (params = {}) => {
  const { admin = false, ...reqParams } = params
  const queryString = qs.stringify(reqParams)
  const uri = `/webhook/v1/${admin ? 'admin/' : ''}outgoing${
    queryString ? '?' + queryString : ''
  }`
  return axios.get(uri)
}

export const createEvent = params => {
  const uri = `/webhook/v1/admin/events`
  return axios.post(uri, params)
}

export const createWebhook = params => {
  const { admin, ...requestParams } = params
  const uri = `/webhook/v1/${admin ? 'admin/' : ''}outgoing`
  return axios.post(uri, requestParams)
}

export const updateWebhook = params => {
  const { webhookId, admin, ...requestParams } = params
  const uri = `/webhook/v1/${admin ? 'admin/' : ''}outgoing/${webhookId}`
  return axios.put(uri, requestParams)
}

export const deleteWebhook = params => {
  const { admin, webhookId } = params
  const uri = `/webhook/v1/${admin ? 'admin/' : ''}outgoing/${webhookId}`
  return axios.delete(uri)
}

export const getEvents = params => {
  const uri = `/webhook/v1/events`
  return axios.get(uri)
}
