import { Map } from 'immutable'
import { accountManagementMemberInfo as accountManagementMemberInfoAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  memberInfo: {},
  snackBarInfo: {
    variant: 'success',
    open: false,
    message: ''
  }
})
const handler = {
  GET_MEMBER_INFO: {
    payloadCreator: accountManagementMemberInfoAPI.getMemberInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { memberInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  CHANGE_MEMBER_INFO: {
    reducer: (state, action) => {
      const { value, key } = action.payload
      const memberInfo = state.get('memberInfo')
      memberInfo[key] = value
      return state.set('memberInfo', Object.assign({}, memberInfo))
    }
  },
  UPDATE_MEMBER_INFO: {
    payloadCreator: accountManagementMemberInfoAPI.updateMemberInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const message = '성공적으로 회원 정보가 변경되었습니다.'
        const stateData = {
          snackBarInfo: {
            variant: 'success',
            open: true,
            message
          }
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  CLOSE_SNACK_BAR: {
    reducer: (state, action) => {
      const snackBarInfo = state.get('snackBarInfo')
      return state.set(
        'snackBarInfo',
        Object.assign({}, snackBarInfo, {
          variant: 'success',
          open: false
        })
      )
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
