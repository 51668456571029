export default (date) => {
  const currentDate = Date.now()
  const targetDate = new Date(date).getTime()
  const diff = currentDate - targetDate
  const prefix = diff < 0 ? '후' : '전'
  const diffSec = Math.abs(diff) / 1000
  if (diffSec < 60) {
    return {
      format: '방금',
      diff: diffSec
    }
  }
  if (diffSec < 3600) {
    return {
      format: `${Math.floor(diffSec / 60)}분 ${prefix}`,
      diff: diffSec
    }
  }
  if (diffSec < 86400) {
    return {
      format: `${Math.floor(diffSec / 60 / 60)}시간 ${prefix}`,
      diff: diffSec
    }
  }
  return {
    format: `${Math.floor(diffSec / 60 / 60 / 24)}일 ${prefix}`,
    diff: diffSec
  }
}
