import uniq from 'lodash/uniq'
import { isArray, isFunction } from 'lib/detectType'

const filter = (key, include, exclude) => {
  const match = pattern =>
    typeof pattern === 'string' ? key === pattern : pattern.test(key)
  let includeFlag = true
  let excludeFlag = true
  if (isArray(include) && include.length > 0) {
    includeFlag = include.some(match)
  }
  if (isArray(exclude) && exclude.length > 0) {
    excludeFlag = !exclude.some(match)
  }
  if (includeFlag === false || excludeFlag === false) {
    return false
  }
  return true
}

const getAllProperties = (object, options = {}) => {
  const {
    // 포함된 경우만 사용
    include = [
      // /^on[A-Z]+/, /^handle.+/
    ],
    // 하나라도 포함된 경우 사용하지 않음
    exclude = [
      /^onChangeFormValue/,
      /^onChangeFormData/,
      /^onChangeFormField/,
      /^onClickAway/,
      /^onChangeTextField/,
      /^onKeyUp/,
      /^onNearestX/,
      /^onCompleteTimer/,
      /^onClose/,
      /^onExited/,
      /^onComplete/,
      /^onWindowClose/,
      /^onChangePassword/,
      /^onMouseLeave/
    ]
  } = options
  let prototype = object.constructor.prototype

  const method = Object.keys(object)
    .map(key => {
      if (isFunction(object[key])) return key
      return null
    })
    .filter(d => d)
  const properties = new Set()
  do {
    for (const key of Reflect.ownKeys(prototype)) {
      properties.add([prototype, key])
    }
  } while (
    (prototype = Reflect.getPrototypeOf(prototype)) &&
    prototype !== Object.prototype
  )

  for (const [prototype, key] of properties) {
    const descriptor = Reflect.getOwnPropertyDescriptor(prototype, key)
    if (key === 'constructor' || filter(key, include, exclude) === false) {
      continue
    }
    if (descriptor && typeof descriptor.value === 'function') {
      method.push(key)
    }
  }

  return uniq(method).filter(key => {
    return (
      [
        'constructor',
        'render',
        'setState',
        'forceUpdate',
        'componentDidMount',
        'componentDidUpdate',
        'shouldComponentUpdate',
        'detectTokenUpdate',
        'componentWillUnmount',
        'checkPublicPage',
        'isHex',
        'checkMySite',
        'checkMarketerAccountId',
        'checkInvalidToken',
        'checkExpiredToken',
        'checkSelectedAccountId',
        'checkTrialAccount',
        'checkTempToken',
        'setSiteMonitoring',
        'getMuiThemeProvider',
        'getRouteData',
        'getToken',
        'getAhid',
        'setAuth',
        'tokenCheck',
        'setDocumentTitle',
        'setRouteParams',
        'gaPageView',
        'getDeepColor',
        'visitedMenu',
        'initChannelIO',
        'installLayout',
        'getCurrentExtraFields',
        'createAuthState',
        'checkPackageUpdate',
        'getMemberData',
        'isValidMember',
        'getMemberPerson',
        'setMemberData',
        'onChangeCookie'
      ].indexOf(key) === -1 && filter(key, include, exclude)
    )
  })
}

export default getAllProperties
