
/**
 * 주의! 이 코드는 사용하지 않는 코드 입니다.
 * write by Genie
 *
 * code author - Henry
 */
import { Map } from 'immutable'
import { company as companyAPI } from 'lib/api'
import { createRedux, onFailure, onPending } from 'lib/requestHandler'

const defaultCondition = {
  inputValue: '',
  selectValue: 'accountId'
}

const initialState = Map({
  conditions: [defaultCondition],
  count: 0,
  page: 0,
  rowsPerPage: 20,
  data: []
})

const handler = {
  ADD_CONDITION: {
    reducer: state => {
      const conditions = state.get('conditions')
      return state.set('conditions', [...conditions, defaultCondition])
    }
  },
  SET_CONDITION: {
    reducer: (state, action) => {
      const conditions = state
        .get('conditions')
        .slice()
        .map(condition => Object.assign({}, condition))
      const {
        index,
        inputValue = conditions[index].inputValue,
        selectValue = conditions[index].selectValue
      } = action.payload
      conditions[index].inputValue = inputValue
      conditions[index].selectValue = selectValue
      return clearData(state).set('conditions', conditions)
    }
  },
  REMOVE_CONDITION: {
    reducer: (state, action) => {
      const conditions = state.get('conditions')
      return clearData(state).set('conditions', [
        ...conditions.slice(0, action.payload),
        ...conditions.slice(action.payload + 1, conditions.length)
      ])
    }
  },
  CLEAR_CONDITIONS: {
    reducer: state => {
      return clearData(state).set('conditions', [defaultCondition])
    }
  },
  SET_STATE: {
    reducer: (state, action) => {
      Object.keys(action.payload).forEach(key => {
        state = state.set(key, action.payload[key])
      })
      return state
    }
  },
  GET_ROW_DATA: {
    payloadCreator: companyAPI.getCompanyList,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action) => {
        const count = state.get('count')
        let length = action.payload.length
        if (state.get('rowsPerPage') === length) length++
        return state.set('data', action.payload).set('count', count + length)
      }
    }
  },
  UPDATE_COMPANY_DATA: {
    payloadCreator: companyAPI.updateCompanyData,
    reducer: {
      onPending,
      onFailure,
      onSuccess: clearData
    }
  },
  CREATE_COMPANY_DATA: {
    payloadCreator: companyAPI.createCompanyData,
    reducer: {
      onPending,
      onFailure,
      onSuccess: clearData
    }
  }
}

function clearData(state) {
  return state
    .set('data', [])
    .set('count', 0)
    .set('page', 0)
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
