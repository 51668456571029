/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { messageLog as messageLogAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
const initialState = Map({
  alertMessage: '',
  loadProgress: true,
  messageData: {
    limit: 20,
    messageList: {}
  },
  messageGroupData: {
    limit: 20,
    groupList: {}
  }
})

const checkGroupListType = groupList => {
  const types = ['sms', 'lms', 'mms', 'ata', 'cta', 'cti']
  Object.keys(groupList).forEach(groupKey => {
    const { countForCharge, app } = groupList[groupKey]
    types.forEach(type => {
      if (!Object.keys(countForCharge).includes(type)) {
        countForCharge[type] = {}
      }
      if (!Object.keys(app.profit).includes(type)) {
        app.profit[type] = 0
      }
    })
  })
}

const handler = {
  GET_MESSAGE_GROUP_LIST: {
    payloadCreator: messageLogAPI.getMessageGroupList,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const groupData = action.payload.data
        checkGroupListType(groupData.groupList)
        const stateData = { messageGroupData: groupData }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  ADD_MESSAGE_GROUP_LIST: {
    payloadCreator: messageLogAPI.getMessageGroupList,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const data = action.payload.data
        checkGroupListType(data.groupList)
        const groupData = state.get('messageGroupData')
        const groupList = Object.assign({}, groupData.groupList, data.groupList)
        const stateData = {
          messageGroupData: Object.assign({}, groupData, {
            groupList,
            nextKey: data.nextKey
          })
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_MESSAGE_LIST: {
    payloadCreator: messageLogAPI.getMessageList,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const messageData = action.payload.data
        const stateData = { messageData }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  ADD_MESSAGE_LIST: {
    payloadCreator: messageLogAPI.getMessageList,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const data = action.payload.data
        const messageData = state.get('messageData')
        const messageList = Object.assign(
          {},
          messageData.messageList,
          data.messageList
        )
        const stateData = {
          messageData: Object.assign({}, messageData, {
            messageList,
            nextKey: data.nextKey
          })
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  RESEND_GROUP: {
    payloadCreator: messageLogAPI.resendGroup
  }
}

handler.getGroups = {
  payloadCreator: messageLogAPI.getGroups
}

handler.messageExcelDownload = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: messageLogAPI.messageExcelDownload
}

handler.getGroupInfo = {
  payloadCreator: messageLogAPI.getGroupInfo
}

handler.getMessages = {
  payloadCreator: messageLogAPI.getMessages
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
