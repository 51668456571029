import { Map } from 'immutable'
import { withdrawPopup as withdrawalPopupAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  balanceHistory: [],
  balanceInfo: {},
  paymentInfo: {},
  selectedChargePaymentIndex: 0,
  lowBalanceAlert: {},
  bankAcocuntInfo: {},
  remainingTryCount: null,
  popupFlag: {},
  popupMessageFlag: false,
  popupMessage: '',
  closePopup: false
})

const handler = {
  OPEN_POPUP: {
    reducer: state => {
      const popupFlag = state.get('popupFlag')
      return state.set(
        'popupFlag',
        Object.assign({}, popupFlag, { accountForm: true })
      )
    }
  },
  CONFIRM_SUBMIT: {
    reducer: (state, action) => {
      return state.set('confirm', action.payload)
    }
  },
  CLOSE_POPUP: {
    reducer: (state, action) => {
      const name = action.payload
      const popupFlag = Object.assign({}, state.get('popupFlag'))
      if (Object.prototype.toString.call(name) === '[object Array]') {
        Object.keys(popupFlag)
          .filter(d => name.indexOf(d) !== -1)
          .forEach(n => {
            popupFlag[n] = false
          })
      } else {
        popupFlag[name] = false
      }
      return state.set('popupFlag', popupFlag).set('alertMessage', '')
    }
  },
  SET_POPUP_MESSAGE: {
    reducer: (state, action) => {
      const { flag, message } = action.payload
      return state.set('popupMessageFlag', flag).set('popupMessage', message)
    }
  },
  AUTH_BANK_ACCOUNT: {
    payloadCreator: withdrawalPopupAPI.authBankAccount,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { accountNumber, bankCode, holderName } = action.payload.data
        return onSuccess(state, action, {
          ...options,
          stateData: {
            alertMessage: '',
            bankAccount: { accountNumber, bankCode, holderName }
          }
        })
      }
    }
  },
  GET_BANK_ACCOUNT: {
    payloadCreator: withdrawalPopupAPI.getBankAccount,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { bankAccount: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  DELETE_BANK_ACCOUNT: {
    payloadCreator: withdrawalPopupAPI.deleteBankAccount,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const {
          payload: { data }
        } = action
        const stateData = {
          bankAccount: data.n ? {} : state.get('bankAccount'),
          alertMessage: data.n
            ? '정상적으로 삭제 되었습니다.'
            : '계좌가 존재하지 않습니다.'
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  GET_BALANCE_INFO: {
    payloadCreator: withdrawalPopupAPI.getBalanceInfo,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const stateData = { balanceInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  },
  WITHDRAW: {
    payloadCreator: withdrawalPopupAPI.withdraw,
    reducer: {
      onPending,
      onFailure,
      onSuccess: (state, action, options) => {
        const { amount } = JSON.parse(action.payload.config.data)
        const balanceInfo = state.get('balanceInfo')
        const mfa = state.get('mfa')
        const { balance = 0 } = balanceInfo
        const popupFlag = state.get('popupFlag')
        const stateData = {
          balanceInfo: Object.assign({}, balanceInfo, {
            balance: balance - amount
          }),
          mfa: Object.assign({}, mfa, { withdraw: undefined }),
          popupFlag: Object.assign({}, popupFlag, { accountForm: false })
        }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
