import { Map } from 'immutable'
import Joi from 'joi-browser'
import deepEqual from 'fast-deep-equal/react'
import { mapValues, assign, cloneDeep, pick, omit } from 'lodash'
import { requestHandler } from 'lib'
const { createRedux, onFailure, onSuccess } = requestHandler
const initialState = Map({
  // form에 출력되는 값, 또는 사용자가 변경한 값
  data: {},
  error: {},
  // 서버 등에서 받아온 초기 값
  originData: {},
  schema: {},
  updateFlag: {}
})

const validate = (data = {}, schema = {}) => {
  const errors = Object.keys(schema).map(field => {
    const { error } = Joi.validate(data[field], schema[field])
    return { [field]: error ? error.message : error }
  })
  return Object.assign({}, ...errors)
}

const set = (payload, state) => {
  const { key, pre = false, data = {}, schema = {} } = payload
  const stateData = state.get('data') || {}
  const stateError = state.get('error') || {}
  const originData = state.get('originData') || {}
  const stateSchema = state.get('schema') || {}
  const stateUpdateFlag = state.get('updateFlag') || {}
  const newData = assign(stateData[key] || {}, data)
  const newSchema = assign(stateSchema[key] || {}, schema)
  const updateKeys = Object.keys(newSchema).concat(Object.keys(newData))
  const newError = pre
    ? {}
    : assign(
        stateError[key],
        validate(pick(newData, updateKeys), pick(newSchema, updateKeys))
      )
  const updateFlag = assign(
    ...Object.keys(originData[key] || {})
      .concat(Object.keys(newData))
      .map(field => {
        const origin = originData[key] || {}
        return { [field]: !deepEqual(origin[field], newData[field]) }
      })
  )
  return cloneDeep({
    data: assign(stateData, { [key]: newData }),
    error: assign(stateError, { [key]: newError }),
    updateFlag: assign(stateUpdateFlag, { [key]: updateFlag }),
    schema: assign(stateSchema, { [key]: newSchema })
  })
}

const handler = {
  INIT: {
    reducer: (state, action) => {
      const {
        key,
        data: initData = {},
        schema = {},
        error = {},
        pre = false
      } = action.payload
      const data = state.get('data')
      const originInitFlag = state.get('initFlag')
      const originData = state.get('originData')
      const originError = state.get('error')
      const originSchema = state.get('schema')
      const originUpdateFlag = state.get('updateFlag')
      const newError = pre ? validate(initData, schema) : {}
      return state
        .set('initFlag', Object.assign({}, originInitFlag, { [key]: true }))
        .set('data', Object.assign({}, data, { [key]: cloneDeep(initData) }))
        .set(
          'error',
          Object.assign({}, originError, {
            [key]: { ...cloneDeep(newError), ...error }
          })
        )
        .set(
          'originData',
          Object.assign({}, originData, { [key]: cloneDeep(initData) })
        )
        .set(
          'schema',
          Object.assign({}, originSchema, { [key]: cloneDeep(schema) })
        )
        .set('updateFlag', Object.assign({}, originUpdateFlag, { [key]: {} }))
    }
  },
  CLEAR_ALL: {
    reducer: (state, action) => {
      const except = action?.payload?.except || []
      const initFlag = pick(state.get('initFlag'), except)
      const data = pick(state.get('data'), except)
      const originData = pick(state.get('originData'), except)
      const error = pick(state.get('error'), except)
      const schema = pick(state.get('schema'), except)
      const updateFlag = pick(state.get('updateFlag'), except)
      return state
        .set('initFlag', initFlag)
        .set('data', data)
        .set('originData', originData)
        .set('error', error)
        .set('schema', schema)
        .set('updateFlag', updateFlag)
    }
  },
  CLEAR: {
    reducer: (state, action) => {
      const { key } = action.payload
      const originInitFlag = state.get('initFlag') || {}
      const data = state.get('data') || {}
      const originData = state.get('originData') || {}
      const originError = state.get('error') || {}
      const schema = state.get('schema') || {}
      const originUpdateFlag = state.get('updateFlag') || {}
      return state
        .set(
          'initFlag',
          Object.assign({}, originInitFlag, { [key]: undefined })
        )
        .set('data', Object.assign({}, data, { [key]: {} }))
        .set('originData', Object.assign({}, originData, { [key]: {} }))
        .set('error', Object.assign({}, originError, { [key]: {} }))
        .set('updateFlag', Object.assign({}, originUpdateFlag, { [key]: {} }))
        .set('schema', Object.assign({}, schema, { [key]: {} }))
    }
  },
  SET: {
    reducer: (state, action) => {
      const data = state.get('data') || {}
      const originData = state.get('originData') || {}
      const originError = state.get('error') || {}
      const schema = state.get('schema') || {}
      const originUpdateFlag = state.get('updateFlag') || {}
      const {
        key,
        data: argsData = {},
        schema: argsSchema = {}
      } = action.payload
      const newSchema = Object.assign(
        {},
        schema[key] || {},
        cloneDeep(argsSchema)
      )
      const newData = Object.assign({}, data[key] || {}, cloneDeep(argsData))
      const updateKeys = Object.keys(argsSchema).concat(Object.keys(argsData))
      const error = Object.assign(
        {},
        originError[key],
        validate(pick(newData, updateKeys), pick(newSchema, updateKeys))
      )
      const updateFlag = Object.assign(
        {},
        ...Object.keys(originData[key] || {})
          .concat(Object.keys(newData))
          .map(field => {
            const origin = originData[key] || {}
            return { [field]: !deepEqual(origin[field], newData[field]) }
          })
      )
      return state
        .set('data', Object.assign({}, data, { [key]: cloneDeep(newData) }))
        .set(
          'error',
          Object.assign({}, originError, { [key]: cloneDeep(error) })
        )
        .set(
          'updateFlag',
          Object.assign({}, originUpdateFlag, { [key]: updateFlag })
        )
        .set(
          'schema',
          Object.assign({}, schema, { [key]: cloneDeep(newSchema) })
        )
    }
  },
  VALIDATE: {
    reducer: (state, action) => {
      const { key, schema: argsSchema = {} } = action.payload
      const data = state.get('data')
      const schema = state.get('schema')
      const originError = state.get('error') || {}
      const newSchema = Object.assign({}, schema[key] || {}, argsSchema)
      const error = validate(data[key], newSchema)
      return state.set(
        'error',
        Object.assign({}, originError, { [key]: error })
      )
    }
  },
  SET_ERROR: {
    reducer: (state, action) => {
      const { key, error } = action.payload
      const originError = state.get('error') || {}
      const newError = Object.assign({}, originError, {
        [key]: Object.assign({}, originError[key], error)
      })
      return state.set('error', newError)
    }
  }
}

handler.initError = {
  reducer: (state, action) => {
    const { key, error = {} } = action.payload
    const originError = state.get('error') || {}
    const newError = Object.assign({}, originError, {
      [key]: error
    })
    return state.set('error', newError)
  }
}

handler.submit = {
  payloadCreator: params => {
    const { data, schema } = params
    return new Promise((resolve, reject) => {
      const error = validate(data, schema)
      const errorMessages = Object.values(error).filter(d => d)
      if (errorMessages.length > 0) {
        const errorMessage = errorMessages.filter(d => d).join(' / ')
        return reject({
          response: { data: { errorMessage } }
        })
      }
      return resolve(data)
    })
  },
  reducer: {
    onFailure: (state, action, options) => {
      const { key, failure = {} } = action.meta.payload
      const stateData = set(action.meta.payload, state)
      return onFailure(state, action, {
        stateData: {
          ...stateData,
          data: assign(stateData.data, {
            [key]: assign(stateData.data[key], failure.data)
          })
        },
        ...options
      })
    },
    onSuccess: (state, action, options) => {
      const {
        key,
        reflect = true,
        data = {},
        success = {}
      } = action.meta.payload
      const originData = state.get('originData') || {}
      const payload = {
        key,
        data: mapValues(data, (value, fieldKey) => {
          return originData[key][fieldKey]
        })
      }
      const stateData = set(payload, state)
      return onSuccess(state, action, {
        stateData:
          reflect === true
            ? {
                ...stateData,
                data: assign(stateData.data, {
                  [key]: assign(stateData.data[key], success.data)
                }),
                error: omit(stateData.error[key], Object.keys(data))
              }
            : {},
        ...options
      })
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
