import jwt from 'jsonwebtoken'
import cookies from 'js-cookie'
import ReactGA from 'lib/ga'

export const saveTiming = ({ category, variable, value, label }) => {
  try {
    const token = jwt.decode(cookies.get('CSAK')) || {}
    ReactGA.timing({
      category,
      variable,
      value,
      label: label || token.accountId
    })
  } catch (error) {
    saveExceptionLog({ error, fatal: true })
  }
}

export const saveActionLog = ({ actionName, event = {}, options = {} }) => {
  try {
    const token = jwt.decode(cookies.get('CSAK')) || {}
    ReactGA.event({
      category: 'ActionLog',
      action: event.action || actionName,
      label: event.label || token.accountId,
      value: event.value
    })
  } catch (error) {
    saveExceptionLog({ error, fatal: true })
  }
}

export const saveExceptionLog = ({ event = {}, error, fatal = false }) => {
  try {
    const token = jwt.decode(cookies.get('CSAK')) || {}
    ReactGA.event({
      category: event.category || 'Exception',
      action: event.action || 'Error',
      label: event.label || token.accountId + '|' + error.message,
      value: event.value
    })
    ReactGA.exception({ description: error.message, fatal })
  } catch (error) {
    ReactGA.exception({ description: error.message, fatal: true })
  }
}

export default { saveActionLog, saveExceptionLog, saveTiming }
