import axios from 'axios'
import qs from 'qs'
import _ from 'lodash'

export const getStorageList = search => {
  return axios.get(
    `/storage/v1/files?${decodeURIComponent(qs.stringify(search))}`
  )
}

export const upload = (params = {}) => {
  const { file, name, type, link } = params
  const reqParams = _.omitBy({ file, name, type, link }, _.isUndefined)
  const uri = `/storage/v1/files`
  return axios.post(uri, reqParams)
}

export const batchUpload = (fileObject = {}) => {
  const uri = `/storage/v1/files`
  return Promise.all(
    Object.keys(fileObject).map(name => {
      const file = fileObject[name]
      return axios.post(uri, { name, file })
    })
  )
}

export const getFileInfo = (params = {}) => {
  const { fileId } = params
  const uri = `/storage/v1/files/${fileId}`
  return axios.get(uri)
}

export const deleteFile = (params = {}) => {
  const { fileId } = params
  const uri = `/storage/v1/files/${fileId}`
  return axios.delete(uri)
}
