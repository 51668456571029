import axios from 'axios'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'

export const getTxn = (params = {}) => {
  const { transactionId } = params
  const uri = `/2fa/v1/transaction/${transactionId}`
  return axios.get(uri)
}

export const verifyHash = params => {
  const { hash } = params
  const uri = `/2fa/v1/verify/${hash}`
  return axios.get(uri)
}

export const createPassword = params => {
  const { password } = params
  const uri = `/2fa/v1/password`
  return axios.post(uri, { password })
}

export const updatePassword = params => {
  const { password, initialRetryCount } = params
  const uri = `/2fa/v1/password`
  return axios.put(uri, omitBy({ password, initialRetryCount }, isUndefined))
}

export const checkForPasswordUsage = () => {
  const uri = `/2fa/v1/password/status`
  return axios.post(uri, {})
}

export const deletePassword = () => {
  const uri = `/2fa/v1/password`
  return axios.delete(uri)
}

export const getMemberHash = () => {
  const uri = `/2fa/v1/member-hash`
  return axios.get(uri)
}

export const tempCert = () => {
  const uri = `/2fa/v1/temp-cert`
  return axios.post(uri)
}
