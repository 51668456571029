import qs from 'qs'
import axios from 'axios'

export const getNoticeHistory = params => {
  const uri = `/notification/v1/history?${qs.stringify(params)}`
  return axios.get(uri)
}

export const setNotificationBlock = blockFlag => {
  const uri = `/notification/v1/denial`
  if (blockFlag) {
    return axios.delete(uri)
  }
  return axios.post(uri, {})
}

export const setNotificationDenial = ({ denial, key }) => {
  const uri = `/notification/v1/denial`
  if (denial) {
    return axios.post(uri, { key })
  }
  return axios.delete(uri + `?key=${key}`)
}

export const getNotifications = (payload, options) => {
  const params = qs.stringify(payload)
  const uri = `/notification/v1/history${params ? '?' + params : ''}`
  return axios.get(uri, {
    cache: options?.cache === undefined ? true : options?.cache
  })
}

export const confirmNotification = payload => {
  const uri = `/notification/v1/confirm`
  return axios.put(uri, payload)
}

export const getNoticeDenial = () => {
  const uri = `/notification/v1/denial`
  return axios.get(uri)
}

export const setNoticeDenial = payload => {
  const { block } = payload
  const uri = `/notification/v1/denial`
  return axios.put(uri, { block })
}
