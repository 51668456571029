/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
import classNames from 'classnames'
import createTheme from '@material-ui/core/styles/createTheme'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
// import omitBy from 'lodash/omitBy'

// material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
// import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Badge from '@material-ui/core/Badge'
import CircularProgress from '@material-ui/core/CircularProgress'
import Tooltip from '@material-ui/core/Tooltip'

// material ui icon
// import GetAppIcon from '@material-ui/icons/GetApp'
import { RxOpenInNewWindow as RxOpenInNewWindowIcon } from 'react-icons/rx'

// my components
// import withCompWidth from 'commonHoc/withCompWidth'
// import UserComponentName from 'components/molecules/UserComponentName'

// my containers
// import UserContainerName from 'containers/UserContainerName'

// libraries
// import config from 'config'
import { isNumber, isFunction, isEmptyString } from 'lib/detectType'
import isEqualProps from 'lib/isEqualProps'

const styles = theme => {
  return {
    root: {},
    buttonRoot: {
      minWidth: 50
    },
    shakeAni: {
      animation: `$shake 0.82s cubic-bezier(.36, .07, .19, .97) both`
    },
    errorButton: {
      transform: 'translate3d(0, 0, 0)',
      backfaceVisibility: 'hidden',
      perspective: 1000,
      color: '#f00',
      // backgroundColor: theme.palette.background.paper,
      backgroundColor: `${theme.palette.error.main}0d`,
      '&:hover': {
        backgroundColor: `${theme.palette.error.main}26`
      }
    },
    errorButtonOutliend: {
      border: `1px solid ${theme.palette.error.main}`
    },
    '@keyframes shake': {
      '10%, 90%': {
        transform: 'translate3d(-1px, 0, 0)'
      },
      '20%, 80%': {
        transform: 'translate3d(2px, 0, 0)'
      },
      '30%, 50%, 70%': {
        transform: 'translate3d(-4px, 0, 0)'
      },
      '40%, 60%': {
        transform: 'translate3d(4px, 0, 0)'
      }
    },
    loadingBox: {
      position: 'absolute',
      left: '50%',
      transform: 'translateX(-50%)'
    },
    loadingButtonName: {
      display: 'contents',
      color: 'rgba(0, 0, 0, 0.1)'
    },
    disabledButton: {},
    clickableDisabledButtonOutliend: {
      cursor: 'pointer',
      boxShadow: 'none',
      color: 'rgba(0, 0, 0, 0.26)',
      border: '1px solid rgba(0, 0, 0, 0.12)'
    },
    clickableDisabledButton: {
      color: 'rgba(0, 0, 0, 0.26)',
      border: 'none',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      cursor: 'pointer',
      '&:hover': {
        border: 'none',
        color: 'rgba(0, 0, 0, 0.26)',
        boxShadow: 'none',
        backgroundColor: 'rgba(0, 0, 0, 0.12)'
      }
    },
    loadingButtonLabel: {},
    buttonLabelRoot: {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textTransform: 'none'
    },
    progress: {
      verticalAlign: 'sub'
      // marginRight: 8
    },
    tooltipBox: {
      display: 'inline-block'
    },
    tooltipBoxFullWidth: {
      width: '100%'
    },
    badgeRoot: {},
    badgeRootFullWidth: {
      width: '100%'
    },
    badge: {
      top: -8,
      right: -5,
      backgroundColor: '#f00',
      color: theme.palette.background.paper,
      transform: 'scale(1)',
      // border: `3px solid ${theme.palette.background.paper}`,
      padding: '0 8px'
    },
    newTabIcon: {
      fontSize: '1.2em',
      marginLeft: 4
    }
  }
}

const StyledTooltip = withStyles(theme => ({
  tooltip: {
    fontSize: '0.8em'
    // backgroundColor: theme.palette.common.white,
    // color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
    // fontSize: 11
  }
}))(Tooltip)
export const useProps = {
  children: { default: '확인', type: 'any' }
}
/**
 * 공용 버튼
 */
const CommonButtonCore = React.memo(
  ({ classes, proxy, ...props }) => {
    const {
      children,
      buttonShake = false,
      buttonError = false,
      buttonProps = {},
      className = '',
      progressValue,
      loading = false,
      newTabIcon,
      onClick,
      onClickDisabled,
      onMouseEnter
    } = props
    const child = (
      <>
        {children}
        {newTabIcon === true && (
          <RxOpenInNewWindowIcon className={classes.newTabIcon} />
        )}
      </>
    )
    if (!isEmptyString(buttonProps?.href)) {
      return (
        <Button
          {...buttonProps}
          {...(isEmptyString(className)
            ? {}
            : { className: classNames(className) })}
          onClick={onClick}
          classes={{
            root: classes.buttonRoot,
            label: classNames(classes.buttonLabelRoot)
          }}
        >
          {child}
        </Button>
      )
    }
    const buttonSize = buttonProps?.size
    if (loading === true) {
      const size =
        buttonSize === 'large' ? 20 : buttonProps?.size === 'small' ? 15 : 18
      return (
        <Button
          {...buttonProps}
          {...(isEmptyString(className)
            ? {}
            : { className: classNames(className) })}
          disabled
          classes={{
            root: classes.buttonRoot,
            label: classNames(
              classes.buttonLabelRoot,
              classes.loadingButtonLabel
            )
          }}
        >
          <Box className={classes.loadingBox}>
            <Grid
              container
              wrap="nowrap"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Grid item>
                <CircularProgress
                  size={size}
                  className={classes.progress}
                  {...(isNumber(progressValue)
                    ? { variant: 'determinate' }
                    : {})}
                  value={progressValue}
                />
              </Grid>
              {isNumber(progressValue) && (
                <Grid item>
                  <Typography variant="body2" color="primary">
                    <strong>{progressValue}%</strong>
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Box>
          <span className={classes.loadingButtonName}>{child}</span>
        </Button>
      )
    } else if (buttonError === true) {
      return (
        <Button
          {...buttonProps}
          className={classNames(
            { [className]: !isEmptyString(className) },
            classes.errorButton,
            {
              [classes.shakeAni]: buttonShake,
              [classes.errorButtonOutliend]: buttonProps?.variant === 'outlined'
            }
          )}
          disabled={false}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabelRoot
          }}
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          {child}
        </Button>
      )
    } else if (buttonProps?.disabled) {
      return (
        <Button
          {...buttonProps}
          className={classNames(
            { [className]: !isEmptyString(className) },
            isFunction(onClickDisabled)
              ? buttonProps?.variant === 'outlined'
                ? classes.clickableDisabledButtonOutliend
                : classes.clickableDisabledButton
              : classes.disabledButton
          )}
          disabled={!isFunction(onClickDisabled)}
          onClick={onClickDisabled}
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabelRoot
          }}
          disableElevation
          disableFocusRipple
          disableRipple
          disableTouchRipple
        >
          {child}
        </Button>
      )
    } else {
      return (
        <Button
          {...buttonProps}
          {...(isEmptyString(className)
            ? {}
            : { className: classNames(className) })}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          classes={{
            root: classes.buttonRoot,
            label: classes.buttonLabelRoot
          }}
        >
          {child}
        </Button>
      )
    }
  },
  (prevProps, nextProps) => {
    return isEqualProps(nextProps, prevProps)
  }
)

const CommonButton = props => {
  const { classes, buttonProps, badgeContent, tooltipTitle } = props
  return (
    <StyledTooltip
      open={isEmptyString(tooltipTitle) ? false : undefined}
      title={tooltipTitle}
      arrow
    >
      <Box
        className={classNames(classes.tooltipBox, {
          [classes.tooltipBoxFullWidth]: buttonProps?.fullWidth
        })}
      >
        <Badge
          invisible={isEmptyString(badgeContent)}
          badgeContent={badgeContent}
          classes={{
            root: classNames(classes.badgeRoot, {
              [classes.badgeRootFullWidth]: buttonProps?.fullWidth
            }),
            badge: classes.badge
          }}
        >
          <CommonButtonCore {...props} />
        </Badge>
      </Box>
    </StyledTooltip>
  )
}

const StyledButton = props => {
  const { primaryColor, contrastText } = props
  if (isEmptyString(primaryColor)) {
    return <CommonButton {...props} />
  }
  const newTheme = createTheme(
    !isEmptyString(primaryColor)
      ? {
          palette: {
            primary: {
              main: primaryColor,
              contrastText: contrastText ?? '#fff'
            }
          }
        }
      : {}
  )
  return (
    <MuiThemeProvider theme={newTheme}>
      <CommonButton {...props} />
    </MuiThemeProvider>
  )
}

CommonButton.defaultProps = setDefaultProps(useProps)
CommonButton.propTypes = setPropTypes(useProps)
export default withStyles(styles)(StyledButton)
