const axios = require('axios')
const qs = require('querystring')

export const getWithdrawalHistory = async searchParams => {
  const { refresh } = searchParams
  delete searchParams.refresh
  const uri = `/cash/v1/withdraw?${decodeURIComponent(
    qs.stringify(searchParams)
  )}`
  const { data } = await axios.get(uri)
  return { result: data, refresh }
}
