import axios from 'axios'

export const getUnsubscribeList = async ({ phoneNumber }) => {
  const uri = `/iam/v1/black/optouts/${phoneNumber}?limit=1000`
  const data = await axios.get(uri)
  return data
}

export const deleteUnsubscribe = async ({ handleKeys, phoneNumber }) => {
  const uri = `/iam/v1/black/optouts/${phoneNumber}`
  const data = await axios.delete(uri, {
    data: { handleKeys }
  })
  return data
}
