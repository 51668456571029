const axios = require('axios')
const config = require('config')
const qs = require('qs')

export const getAppInfo = appId =>
  axios.get(`/appstore/v2/me/apps?${qs.stringify({ appId })}`)

export const getAppList = payload =>
  axios.get(`/appstore/v2/me/apps?${qs.stringify(payload)}`)

export const createNewApp = payload =>
  axios.post(`/appstore/v2/me/apps`, payload)

export const updateStage = appId =>
  axios.put(`/appstore/v2/me/apps/${appId}/stage`, null)

export const updateFormData = ({
  appId,
  dateCreated,
  dateUpdated,
  accountId,
  clientId,
  clientSecret,
  stage,
  status,
  reasonBlocked,
  log,
  ...payload
}) => {
  if (config.isCoolsms) delete payload.profit
  return axios.put(`/appstore/v2/me/apps/${appId}`, payload)
}

export const uploadImage = ({ appId, file, target, name }) => {
  return new Promise(async (resolve, reject) => {
    const res = await axios.post(
      `/appstore/v2/me/apps/${appId}/images/${target}`,
      { image: file, name }
    )
    if (res.status !== 200) return reject(res)
    resolve({ ...res, target })
  })
}

export const savePermission = scopes => {
  return new Promise((resolve, reject) => {
    resolve()
  })
}
