/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { createRedux } from 'lib/requestHandler'
const handler = {}
const initialState = Map({
  viewDocument: {}
})

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
