/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import findLastKey from 'lodash/findLastKey'
import { useResizeDetector } from 'react-resize-detector'

import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'

import getBreakPoints from 'lib/getBreakPoints'

/**
 * 컴포넌트 너비
 */
const withCompWidth = (
  WrappedComponent,
  { withWidthParams = {}, enabled = false } = {}
) => {
  if (enabled !== true) return WrappedComponent
  const calcBreakpoint = width => {
    const { values = {} } = getBreakPoints
    return findLastKey(values, value => value <= width)
  }
  const Component = props => {
    const {
      width: compWidth,
      height: compHeight,
      ref
    } = useResizeDetector({
      refreshMode: 'debounce',
      refreshRate: 1000,
      refreshOptions: {
        leading: true
      }
    })
    const compBreakpoint = calcBreakpoint(compWidth)
    const isCompWidthUp = bp => isWidthUp(bp, compBreakpoint)
    const isCompWidthDown = bp => isWidthDown(bp, compBreakpoint)
    const isWindowWidthUp = bp => isWidthUp(bp, props.width)
    const isWindowWidthDown = bp => isWidthDown(bp, props.width)
    return (
      <div ref={ref}>
        <WrappedComponent
          {...props}
          compWidth={compWidth}
          compHeight={compHeight}
          compBreakpoint={compBreakpoint}
          isCompWidthUp={isCompWidthUp}
          isCompWidthDown={isCompWidthDown}
          isWindowWidthUp={isWindowWidthUp}
          isWindowWidthDown={isWindowWidthDown}
          isWidthUp={isWidthUp}
          isWidthDown={isWidthDown}
        />
      </div>
    )
  }
  return withWidth(withWidthParams)(Component)
}
export default withCompWidth
