export default {
  // 앱 이름
  appName: {
    presence: {
      allowEmpty: false,
      message: '앱 이름이 비어있습니다.'
    },
    format: {
      // eslint-disable-next-line
      pattern: '^[가-힣a-zA-Z0-9\\s]+$',
      flags: 'i',
      message: '앱 이름은 한글, 영어, 숫자만 가능합니다.'
    },
    length: {
      minimum: 3,
      maximum: 32,
      tooShort: '앱 이름이 너무 짧습니다. 최소 3글자 이상으로 적어주세요',
      tooLong: '앱 이름이 너무 깁니다. 최소 32글자 이하로 적어주세요'
    }
  },
  // 앱 버전
  appVersion: {
    length: {
      maximum: 30,
      tooLong: '앱 버전이 너무 깁니다. 최소 30글자 이하로 적어주세요'
    }
  },
  // 앱 인트로
  intro: {
    validateIntro: true,
    length: {
      maximum: 128,
      tooLong: '앱 소개가 너무 깁니다. 최소 128글자 이하로 적어주세요'
    }
  },
  // 앱 설명
  description: {
    length: {
      maximum: 8192,
      tooLong: '앱 설명이 너무 깁니다. 최소 8192글자 이하로 적어주세요'
    }
  },
  // 앱 수익금
  profit: {
  },
  // 앱 홈페이지
  homepage: {
    format: {
      // eslint-disable-next-line
      pattern: '^(https?):\/\/([a-z0-9-]+\.)+[a-z0-9]{2,4}.*$',
      flags: 'i',
      message: '앱 홈페이지는 url 포맷만 가능합니다.'
    },
    length: {
      maximum: 128,
      tooLong: '앱 홈페이지가 너무 깁니다. 최소 128글자 이하로 적어주세요'
    }
  },
  // 이메일
  email: {
    email: {
      message: '이메일값이 잘못되었습니다.'
    }
  },
  // 앱 썸네일
  thumbnail: {
  },
  // 앱 스크린샷
  screenshots: {
    length: {
      maximum: 6,
      message: '스크린샷은 최소 1개부터 최대 6개까지만 가능합니다.'
    }
  },
  // 앱 카테고리
  categories: {
  },
  // 앱 단계
  stage: {
    inclusion: {
      within: { 'default': '기본', 'DEVELOP': '개발중', 'LIVE': '라이브' },
      message: '잘못된 앱 단계를 선택하였습니다.'
    }
  },
  // 앱 상태
  status: {
    inclusion: {
      within: { 'default': '기본', 'ACTIVE': '활성화', 'BLOCKED': '차단' },
      message: '잘못된 앱 상태값을 선택하였습니다.'
    }
  },
  // 앱 차단사유
  reasonBlocked: {
    length: {
      maximum: 128,
      tooLong: '앱 차단사유가 너무 깁니다. 최소 128글자 이하로 적어주세요'
    }
  },
  // 앱 clientId
  clientId: {
    length: {
      minimum: 1,
      maximum: 50,
      tooShort: '앱 clientId가 너무 짧습니다. 최소 1글자 이상으로 적어주세요',
      tooLong: '앱 clientId가 너무 깁니다. 최소 50글자 이하로 적어주세요'
    }
  },
  // 앱 appId
  appId: {
    length: {
      minimum: 1,
      maximum: 50,
      tooShort: '앱 appId가 너무 짧습니다. 최소 1글자 이상으로 적어주세요',
      tooLong: '앱 appId가 너무 깁니다. 최소 50글자 이하로 적어주세요'
    }
  },
  // 앱 scope
  scope: {
    length: {
      minimum: 1,
      maximum: 1000,
      tooShort: '앱 scope는 필수 입니다.',
      tooLong: '앱 scope가 너무 깁니다.'
    }
  }
}

export const liveSchema = {
  // 앱 이름
  appName: {
    presence: {
      allowEmpty: false,
      message: '앱 이름이 비어있습니다.'
    },
    format: {
      // eslint-disable-next-line
      pattern: '^[가-힣a-zA-Z0-9\\s]+$',
      flags: 'i',
      message: '앱 이름은 한글, 영어, 숫자만 가능합니다.'
    },
    length: {
      minimum: 3,
      maximum: 32,
      tooShort: '앱 이름이 너무 짧습니다. 최소 3글자 이상으로 적어주세요',
      tooLong: '앱 이름이 너무 깁니다. 최소 32글자 이하로 적어주세요'
    }
  },
  // 앱 버전
  appVersion: {
    presence: {
      allowEmpty: false,
      message: '앱 버전이 비어있습니다.'
    },
    length: {
      minimum: 1,
      maximum: 30,
      tooShort: '앱 버전이 너무 짧습니다. 최소 1글자 이상으로 적어주세요',
      tooLong: '앱 버전이 너무 깁니다. 최소 30글자 이하로 적어주세요'
    }
  },
  // 앱 인트로
  intro: {
    presence: {
      allowEmpty: false,
      message: '앱 소개가 비어있습니다.'
    },
    format: {
      // eslint-disable-next-line
      pattern: '^[가-힣a-zA-Z0-9\\s]+$',
      flags: 'i',
      message: '앱 소개는 한글, 영어, 숫자만 가능합니다.'
    },
    length: {
      minimum: 1,
      maximum: 128,
      tooShort: '앱 소개가 너무 짧습니다. 최소 1글자 이상으로 적어주세요',
      tooLong: '앱 소개가 너무 깁니다. 최대 128글자 이하로 적어주세요'
    }
  },
  // 앱 설명
  description: {
    presence: {
      allowEmpty: false,
      message: '앱 설명이 비어있습니다.'
    },
    length: {
      minimum: 1,
      maximum: 8192,
      tooShort: '앱 설명이 너무 짧습니다. 최소 1글자 이상으로 적어주세요',
      tooLong: '앱 설명이 너무 깁니다. 최소 8192글자 이하로 적어주세요'
    }
  },
  // 앱 수익금
  profit: {
  },
  // 앱 홈페이지
  homepage: {
    presence: {
      allowEmpty: false,
      message: '홈페이지가 비어있습니다.'
    },
    format: {
      // eslint-disable-next-line
      pattern: '^(https?):\/\/([a-z0-9-]+\.)+[a-z0-9]{2,4}.*$',
      flags: 'i',
      message: '앱 홈페이지는 url 포맷만 가능합니다.'
    },
    length: {
      maximum: 128,
      tooLong: '앱 홈페이지가 너무 깁니다. 최소 128글자 이하로 적어주세요'
    }
  },
  // 이메일
  email: {
    email: {
      message: '이메일값이 잘못되었습니다.'
    }
  },
  // 앱 썸네일
  thumbnail: {
    presence: {
      allowEmpty: false,
      message: '썸네일이 비었습니다.'
    }
  },
  // 앱 스크린샷
  screenshots: {
    presence: {
      allowEmpty: false,
      message: '스크린샷이 비었습니다.'
    },
    length: {
      minimum: 1,
      maximum: 6,
      message: '스크린샷은 최소 1개부터 최대 6개까지만 가능합니다.'
    }
  },
  // 앱 카테고리
  categories: {
    presence: {
      allowEmpty: false,
      message: '카테고리가 비어있습니다.'
    }
  },
  // 앱 단계
  stage: {
    presence: {
      allowEmpty: false,
      message: '앱 스테이지가 비어있습니다.'
    },
    inclusion: {
      within: { 'default': '기본', 'DEVELOP': '개발중', 'LIVE': '라이브' },
      message: '잘못된 앱 단계를 선택하였습니다.'
    }
  },
  // 앱 상태
  status: {
    presence: {
      allowEmpty: false,
      message: '앱 상태가 비어있습니다.'
    },
    inclusion: {
      within: { 'default': '기본', 'ACTIVE': '활성화', 'BLOCKED': '차단' },
      message: '잘못된 앱 상태값을 선택하였습니다.'
    }
  },
  // 앱 차단사유
  reasonBlocked: {
    length: {
      minimum: 1,
      maximum: 128,
      tooShort: '앱 차단사유가 너무 짧습니다. 최소 1글자 이상으로 적어주세요',
      tooLong: '앱 차단사유가 너무 깁니다. 최소 128글자 이하로 적어주세요'
    }
  },
  // 앱 clientId
  clientId: {
    length: {
      minimum: 1,
      maximum: 50,
      tooShort: '앱 clientId가 너무 짧습니다. 최소 1글자 이상으로 적어주세요',
      tooLong: '앱 clientId가 너무 깁니다. 최소 50글자 이하로 적어주세요'
    }
  },
  // 앱 appId
  appId: {
    length: {
      minimum: 1,
      maximum: 50,
      tooShort: '앱 appId가 너무 짧습니다. 최소 1글자 이상으로 적어주세요',
      tooLong: '앱 appId가 너무 깁니다. 최소 50글자 이하로 적어주세요'
    }
  },
  // 앱 scope
  scope: {
    length: {
      minimum: 1,
      maximum: 1000,
      tooShort: '앱 scope는 필수 입니다.',
      tooLong: '앱 scope가 너무 깁니다.'
    }
  }
}
