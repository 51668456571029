/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { credentials as credentialsAPI } from 'lib/api'
import { createRedux } from 'lib/requestHandler'

const initialState = Map({
  errorMessage: '',
  mfaKeys: ['mfaCreateCredentialContainer'],
  popupKeys: ['popupCreateCredentialContainer']
})
const handler = {}

handler.getCredentials = {
  payloadCreator: credentialsAPI.getCredentials
}
handler.getCredentialsSecret = {
  payloadCreator: credentialsAPI.getCredentialsSecret
}
handler.getSdkList = {
  payloadCreator: credentialsAPI.getSdkList
}
handler.new = {
  options: { debounce: { wait: 500 } },
  payloadCreator: credentialsAPI.createCredential
}
handler.findCredentials = {
  payloadCreator: credentialsAPI.findCredentials
}
handler.updateCredential = {
  options: { debounce: { wait: 500 } },
  payloadCreator: credentialsAPI.updateCredential
}
handler.getCredentialList = {
  payloadCreator: credentialsAPI.getCredentialList
}
handler.switchCredentialStatus = {
  options: { debounce: { wait: 500 } },
  payloadCreator: credentialsAPI.switchCredentialStatus
}
handler.resetCredentialSecret = {
  options: { debounce: { wait: 500 } },
  payloadCreator: credentialsAPI.resetCredentialSecret
}
handler.deleteCredential = {
  options: { debounce: { wait: 500 } },
  payloadCreator: credentialsAPI.deleteCredential
}
handler.updateIPFilter = {
  options: { debounce: { wait: 500 } },
  payloadCreator: credentialsAPI.updateIPFilter
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
