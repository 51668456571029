/**
 * @author EdenCha <eden@nurigo.net>
 */
import { Map } from 'immutable'
import { start as StartAPI } from 'lib/api'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'

const initialState = Map({
  responseData: {},
  roles: ['MEMBER', 'OWNER', 'DEVELOPER'],
  role: 'MEMBER',
  activeStep: 0,
  signUpFlag: false
})

const handler = {
  INVITATION: {
    payloadCreator: StartAPI.invitation,
    reducer: {
      onFailure,
      onPending,
      onSuccess
    }
  },
  GET_SENDER_ID_INFO: {
    options: {
      debounce: {
        wait: 500
      }
    },
    payloadCreator: StartAPI.getSenderIdInfo,
    reducer: {
      onFailure,
      onPending,
      onSuccess: (state, action, options) => {
        const stateData = { senderIdInfo: action.payload.data }
        return onSuccess(state, action, { ...options, stateData })
      }
    }
  }
}

handler.getCredentials = {
  payloadCreator: StartAPI.getCredentials
}

handler.getCredentialsSecret = {
  options: {
    debounce: {
      wait: 500
    }
  },
  payloadCreator: StartAPI.getCredentialsSecret,
  reducer: {
    onFailure,
    onPending,
    onSuccess: (state, action, options) => {
      const { apiKey } = action.meta.payload
      const { apiSecret } = action.payload.data
      const stateData = { apiKey, apiSecret }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState,
  { moduleName: 'start' }
)
export const actions = reduxActions
export default reduxReducers
