import axios from 'axios'

export const getMemberInfo = params => {
  const uri = `/users/v1/member`
  return axios.get(uri)
}

export const authenticateEmail = (params = {}) => {
  const uri = `/users/v1/member/verify`
  return axios.put(uri, params)
}
