const qs = require('query-string')
const axios = require('axios')

export const requestFeedback = params => {
  const uri = `/notification/v1/report`
  return axios.post(uri, params)
}

export const getNotice = params => {
  const uri = `/notification/v1/history?${qs.stringify(params)}`
  return axios.get(uri)
}

export const confirmNotice = params => {
  const uri = `/notification/v1/confirm`
  return axios.put(uri)
}
