import { Map, fromJS } from 'immutable'
import {
  createRedux,
  onFailure,
  onPending,
  onSuccess
} from 'lib/requestHandler'
import { pricing as pricingAPI } from 'lib/api'
import _ from 'lodash'
import { isArray } from 'lib/detectType'

const initialForm = Map({
  userId: '',
  accountId: '',
  countryId: '82',
  sms: 20,
  lms: 50,
  mms: 200,
  ata: 13,
  cta: 19,
  price: {},
  unitPriceInfo: {}
})
const initialState = Map({
  modal: Map({
    isOpen: false,
    data: Map({
      ...initialForm
    })
  }),
  message: '',
  messageVariant: '',
  result: '',
  page: 0,
  count: 0,
  rowsPerPage: 20,
  list: [],
  selected: [],
  conditionList: [
    {
      type: 'userId',
      operator: 'eq',
      value: ''
    }
  ],
  categoryList: [
    {
      text: '사용자 아이디 (userId)',
      type: 'userId',
      allowedOperators: ['eq']
    },
    {
      text: '계정 번호 (accountId)',
      type: 'accountId',
      allowedOperators: ['eq']
    },
    {
      text: '국가 코드 (countryId)',
      type: 'countryId',
      allowedOperators: ['eq']
    }
  ],
  operatorList: {
    na: { text: '해당없음' },
    eq: { text: '== 와 같음' },
    ne: { text: '!== 와 다름' },
    gte: { text: '>= 같거나 큰' },
    gt: { text: '> 큰' },
    lte: { text: '<= 같거나 작은' },
    lt: { text: '< 작은' }
  }
})

const handler = {
  ADD_SELECT: {
    reducer: (state, action) => {
      const selected = state.get('selected')
      selected.push(action.payload)
      return state.set('selected', selected)
    }
  },
  SELECT_ALL: {
    reducer: state => {
      const list = state.get('list')
      let selected = state.get('selected')
      if (selected.length === list.length) return state.set('selected', [])
      selected = list.map(item => `${item.countryId}-${item.accountId}`)
      return state.set('selected', selected)
    }
  },
  TOGGLE_MODAL: {
    reducer: state => {
      return state.setIn(['modal', 'isOpen'], !state.getIn(['modal', 'isOpen']))
    }
  },
  RESET_MODAL: {
    reducer: state => {
      return state.setIn(['modal', 'data'], initialForm)
    }
  },
  SET_MODAL_DATA: {
    reducer: (state, action) => {
      const list = state.get('list')
      const id = action.payload
      const data = _.find(list, o => {
        return id === `${o.countryId}-${o.accountId}`
      })
      return state.setIn(['modal', 'data'], fromJS(data))
    }
  },
  UPDATE_SELECTED: {
    reducer: (state, action) => {
      const selected = state.get('selected')
      const id = action.payload
      const selectedIndex = selected.indexOf(id)
      let newSelected = []
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, id)
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1))
      } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1))
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1)
        )
      }
      return state.set('selected', newSelected)
    }
  },
  UPDATE_FORM: {
    reducer: (state, action) => {
      const { target, value } = action.payload
      return state.setIn(['modal', 'data', target], value)
    }
  },
  UPDATE_ROWS_PER_PAGE: {
    reducer: (state, action) => {
      const rowsPerPage = state.get('rowsPerPage')
      return state
        .set('rowsPerPage', action.payload)
        .set('message', `테이블이 ${rowsPerPage} 씩 보여줍니다.`)
        .set('messageVariant', 'info')
    }
  },
  UPDATE_PAGE: {
    reducer: (state, action) => {
      return state.set('page', action.payload)
    }
  },
  CLOSE_MESSAGE: {
    reducer: state => {
      return state.set('message', '')
    }
  },
  SET_CONDITION: {
    reducer: (state, action) => {
      return state.set('conditionList', action.payload)
    }
  },
  REMOVE_CONDITION: {
    reducer: (state, action) => {
      const index = action.payload
      const conditionList = state.get('conditionList')
      if (conditionList.length === 1) {
        conditionList[0].value = ''
        return state.set('conditionList', conditionList)
      }

      const newCondList = []
      _.filter(conditionList, (o, i) => {
        if (index !== i) newCondList.push(o)
      })
      return state.set('conditionList', newCondList)
    }
  },
  ADD_CONDITION: {
    reducer: state => {
      const conditionList = state.get('conditionList')
      const categoryList = state.get('categoryList')
      let newCond
      if (_.isEmpty(conditionList)) {
        const v = categoryList[0]
        return state.set('conditionList', {
          type: v.type,
          operator: v.allowedOperators[0],
          value: ''
        })
      }

      _.forOwn(categoryList, (v, k) => {
        if (newCond) return
        const newObj = _.find(conditionList, o => {
          return o.type === v.type
        })
        if (_.isUndefined(newObj)) {
          newCond = { type: v.type, operator: v.allowedOperators[0], value: '' }
        }
      })
      if (!newCond) return state.set('conditionList', conditionList)

      conditionList.push(newCond)
      return state.set('conditionList', conditionList)
    }
  }
}

// 단가 조회
handler.getUnitPriceInfo = {
  payloadCreator: pricingAPI.getUnitPriceInfo,
  reducer: {
    onFailure,
    onPending,
    onSuccess: (state, action, options) => {
      const priceList = action.payload.data
      // south korea
      const unitPriceInfo = isArray(priceList)
        ? priceList.filter(price => price.countryId === '82')
        : [priceList]
      const stateData = {
        allPriceList: priceList,
        unitPriceInfo: unitPriceInfo.shift()
      }
      return onSuccess(state, action, { ...options, stateData })
    }
  }
}

handler.getMessagePrice = {
  payloadCreator: pricingAPI.getMessagePrice
}

handler.getDefaultMessagePriceList = {
  payloadCreator: pricingAPI.getDefaultMessagePriceList
}

const { actions: reduxActions, reducers: reduxReducers } = createRedux(
  handler,
  initialState
)
export const actions = reduxActions
export default reduxReducers
