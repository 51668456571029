import PropTypes from 'prop-types'
import mapValues from 'lodash/mapValues'
import omitBy from 'lodash/omitBy'
import isUndefined from 'lodash/isUndefined'
import { isObject, isString } from 'lib/detectType'

export const setPropTypes = useProps => {
  if (!isObject(useProps)) return {}
  return omitBy(
    mapValues(useProps, (value = {}) => {
      if (!value.type && !value.types) return undefined
      const type = value.type || value.types
      return isString(type) ? PropTypes[type] : type
    }),
    isUndefined
  )
}

export const setDefaultProps = useProps => {
  if (!isObject(useProps)) return {}
  return omitBy(
    mapValues(useProps, (value = {}) => {
      if (!value.default && !value.defaultValue) return undefined
      return value.default || value.defaultValue
    }),
    isUndefined
  )
}

export default {
  setPropTypes,
  setDefaultProps
}
