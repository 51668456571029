const get = require('lodash/get')
const { isString, isEmptyString, isArray } = require('lib/detectType')
const { getExtraFieldFromText } = require('lib/contacts')
const description = {
  general: {
    content: '내용',
    emphasizeTitle: '강조표기 제목',
    buttons: '버튼',
    quickReplies: '바로연결버튼',
    header: '헤더',
    'highlight.title': '하이라이트 제목',
    'item.list': '아이템 리스트',
    'item.summary.description': '합계 값'
  },
  buttons: {
    linkMo: '모바일 웹링크',
    linkPc: 'PC 웹링크',
    linkAnd: '안드로이드 URL스킴',
    linkIos: 'IOS URL스킴'
  },
  quickReplies: {
    linkMo: '모바일 웹링크',
    linkPc: 'PC 웹링크',
    linkAnd: '안드로이드 URL스킴',
    linkIos: 'IOS URL스킴'
  },
  'item.list': {
    description: '아이템 리스트 설명'
  }
}

export default function (templateInfo = {}, { withFieldKeys = false } = {}) {
  const variables = []
  const hasVarFieldKeys = []
  Object.keys(description?.general).forEach(generalKey => {
    const title = description?.general?.[generalKey]
    const value = get(templateInfo, generalKey)
    if (isString(value) && !isEmptyString(value)) {
      const vars = getExtraFieldFromText(value, title, '', {
        checkInvalid: true
      })
      if (vars?.length > 0 && withFieldKeys === true) {
        hasVarFieldKeys.push({
          name: vars?.[0]?.key,
          fieldKey: generalKey
        })
      }
      variables.push(...vars)
    } else if (isArray(value) && value.length > 0) {
      value.forEach((item = {}, itemIndex) => {
        Object.keys(item).forEach(itemKey => {
          const itemTitle = `${itemIndex + 1}번째 ${
            description?.[generalKey]?.[itemKey]
          }`
          const itemValue = item?.[itemKey]
          if (!isEmptyString(itemValue)) {
            const vars = getExtraFieldFromText(itemValue, title, itemTitle, {
              checkInvalid: true
            })
            if (vars?.length > 0 && withFieldKeys === true) {
              hasVarFieldKeys.push({
                name: vars?.[0]?.key,
                fieldKey: `${generalKey}[${itemIndex}].${itemKey}`
              })
            }
            variables.push(...vars)
          }
        })
      })
    }
  })
  const result = variables.map(data => {
    if (data?.invalid === true) return data
    if (/\./.test(data?.extraFieldName)) return { ...data, invalid: true }
    return data
  })
  if (withFieldKeys === true) {
    return { variables: result, hasVarFieldKeys }
  }
  return result
}
