import axios from 'axios'
import qs from 'qs'

export const getAffiliate = (params = {}) => {
  const { admin = false, ...reqParams } = params
  const queryString = qs.stringify(reqParams)
  const uri = `/affiliate/v1/${admin ? 'admin/' : ''}consumers/accounts${
    queryString ? '?' + queryString : ''
  }`
  return axios.get(uri)
}

export const getRecommend = (params = {}) => {
  const { admin = false, ...reqParams } = params
  const queryString = qs.stringify(reqParams)
  const uri = `/affiliate/v1/${admin ? 'admin/' : ''}consumers/members${
    queryString ? '?' + queryString : ''
  }`
  return axios.get(uri)
}

export const insertAffiliate = (params = {}) => {
  const { admin = false, ...reqParams } = params
  const uri = `/affiliate/v1/${admin ? 'admin/' : ''}consumers/accounts`
  return axios.post(uri, reqParams)
}

export const insertRecommend = (params = {}) => {
  const { admin = false, ...reqParams } = params
  const uri = `/affiliate/v1/${admin ? 'admin/' : ''}consumers/members`
  return axios.post(uri, reqParams)
}

export const deleteAffiliate = (params = {}) => {
  const { admin = false, consumerAccountId } = params
  const uri = `/affiliate/v1/${
    admin ? 'admin/' : ''
  }consumers/accounts/${consumerAccountId}`
  return axios.delete(uri)
}

export const deleteRecommend = (params = {}) => {
  const { admin = false, consumerMemberId } = params
  const uri = `/affiliate/v1/${
    admin ? 'admin/' : ''
  }consumers/members/${consumerMemberId}`
  return axios.delete(uri)
}

export const getProfit = payload => {
  const queryString = qs.stringify(payload)
  const uri = `/affiliate/v1/settlement${queryString ? '?' + queryString : ''}`
  return axios.get(uri)
}
