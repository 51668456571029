import ReactGA from 'react-ga'
import config from 'config'

const isProduction =
  process.env.NODE_ENV === 'production' && process.env.REACT_APP_SANDBOX !== '1'

if (!window.gaLoaded && isProduction) {
  ReactGA.initialize(config.gaTrackingID)
  window.gaLoaded = true
}

export default !isProduction
  ? {
      initialize: () => {},
      set: () => {},
      event: () => {},
      exception: () => {},
      ga: () => {},
      outboundLink: () => {},
      timing: () => {},
      pageview: () => {},
      modalview: () => {}
    }
  : ReactGA
