import last from 'lodash/last'
import flatten from 'lodash/flatten'
import { isString, isNumber, isArray, isEmptyString } from 'lib/detectType'

// 특정 요소가 element인지 확인
export const isComponentElement = value => {
  return isNumber(value) || isNumber(value?.elementIndex)
}

// 특정 요소의 elementIndex를 반환합니다.
export const getComponentElementIndex = value => {
  if (isComponentElement(value)) {
    return isNumber(value) ? value : value?.elementIndex
  }
  return -1
}

/**
 * value값 유효성을 위한 reduce
 * 1. 라인의 시작과 끝에는 항상 text
 * 2. element 타입은 연속될 수 없음
 */
export const reduceValue = value => {
  if (!isArray(value)) return [['']]
  const newValue = value.map(line => {
    const newLine = line.reduce((result, currentData) => {
      if (!isComponentElement(currentData) && !isString(currentData)) {
        return result
      }
      const prevData = last(result)
      if (prevData === undefined) {
        return [currentData]
      }
      const isElement = isComponentElement(currentData)
      const isNextDiff =
        (isString(currentData) && !isString(prevData)) ||
        (isComponentElement(currentData) && !isComponentElement(prevData))
      if (isNextDiff) {
        result.push(currentData)
      } else {
        if (isElement) {
          result.push('', currentData)
        } else {
          result.pop()
          result.push(`${prevData}${currentData}`)
        }
      }
      return result
    }, [])
    const firstData = newLine?.[0]
    const lastData = last(newLine)
    if (isComponentElement(firstData)) {
      newLine.unshift('')
    }
    if (isComponentElement(lastData)) {
      newLine.push('')
    }
    return newLine
  })
  if (newValue.length === 0) return [['']]
  return newValue
}

// Object를 기준으로하여, 특정한 단어를 element또는 새로운 단어로 변경
// replacementWords = [
//   {
//     regexpString: '#{[^\\.]+?}',
//     targetElementIndex: 1
//   },
//   {
//     regexpString: 'test',
//     targetElementIndex: 2,
//     elementProps: {}
//   }
// ]}
export const replaceWordForLine = (line, replacementWords) => {
  if (
    !isArray(line) ||
    line?.length < 1 ||
    replacementWords === undefined ||
    !isArray(replacementWords) ||
    replacementWords?.length < 1
  ) {
    return line
  }
  // 치환 대상 문구
  const targetWords = replacementWords.map(info => {
    return info?.regexpString
  })
  const regexpStr = targetWords.map(key => `(${key})`).join('|')
  const regexp = new RegExp(regexpStr, 'g')
  const replacedLineArr = line.map(value => {
    if (!regexp.test(value) || !isString(value) || isEmptyString(value)) {
      return value
    }
    const matchedInfo = []
    const chunk = value
      .replace(regexp, function (matched) {
        const [, ...args] = arguments
        const replacementWordIndex = args.indexOf(matched)
        matchedInfo.push({
          replacementWordIndex,
          matchedText: matched
        })
        return `%%__SPLIT__INDEX__${replacementWordIndex}__%%`
      })
      .split('%%__SPLIT__')
    let matchedIndex = -1
    const res = [
      ...chunk.map(d => {
        const matched = d.match(/^INDEX__([0-9]*)__%%(.*)/) || []
        if (matched.length < 1) return [d]
        matchedIndex++
        const [, index, text] = matched
        const replacementWordIndex = Number(index)
        const info = replacementWords[replacementWordIndex] || {}
        const { targetElementIndex = 0, elementProps = {} } = info
        return [
          {
            elementIndex: targetElementIndex,
            elementProps: {
              ...elementProps,
              matchedInfo: matchedInfo[matchedIndex]
            }
          },
          text
        ]
      })
    ]
    return flatten(res)
  })
  return flatten(replacedLineArr)
}

export default { reduceValue, replaceWordForLine }
