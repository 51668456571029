const axios = require('axios')

// 계좌 등록
export const authBankAccount = accountParams => {
  const uri = `/cash/v1/bank/accounts`
  return axios.post(uri, accountParams)
}
// 계좌 정보
export const getBankAccount = () => {
  const uri = `/cash/v1/bank/accounts`
  return axios.get(uri)
}
// 계좌 삭제
export const deleteBankAccount = data => {
  const uri = `/cash/v1/bank/accounts`
  return axios.delete(uri, { data })
}
// 금액 정보
export const getBalanceInfo = () => {
  const uri = `/cash/v1/balance`
  return axios.get(uri)
}
// 출금 신청
export const withdraw = params => {
  const uri = `/cash/v1/withdraw`
  return axios.post(uri, params)
}
