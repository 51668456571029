import React, { Component } from 'react'
import uniqid from 'uniqid'
import { connect } from 'react-redux'
import deepEqual from 'fast-deep-equal/react'
import findIndex from 'lodash/findIndex'

import bindStateToProps from 'lib/bindStateToProps'
import bindActionCreators from 'lib/bindActionCreators'
// import isEqualProps from 'lib/isEqualProps'

import { actions as consoleLayoutActions } from 'store/modules/consoleLayout'
import { actions as snackbarActions } from 'store/modules/snackbar'

import Skeleton from '@material-ui/lab/Skeleton'
import Box from '@material-ui/core/Box'

import config from 'config'
import Error from 'components/molecules/Error'
import ComponentLoadError from 'components/molecules/ComponentLoadError'
import AccessDenied from 'components/molecules/AccessDenied'
import { saveExceptionLog } from 'lib/requestLog'
import { isObject } from 'lib/detectType'
import getComponentId from 'lib/getComponentId'

// import HighlightContainer from 'containers/HighlightContainer'

const withSplitting = (getComponent, options = {}) => {
  class WithSplitting extends Component {
    constructor(props) {
      super(props)
      const stateObj = {
        loadCount: 0,
        loading: true,
        Splitted: null,
        hasError: false,
        error: null,
        errorInfo: null,
        hash: uniqid()
      }
      this.myRef = React.createRef()
      this.componentId = getComponentId(options.componentName)
      const { ConsoleLayoutActions } = props
      ConsoleLayoutActions.setLoadComponent(options?.componentName)
      if (
        (options.coolsmsOnly === true && !config.isCoolsms) ||
        (options.solapiOnly === true && config.isCoolsms)
      ) {
        this.state = Object.assign({}, stateObj, { loading: false })
        ConsoleLayoutActions.loadedComplete(options?.componentName)
      } else {
        this.state = stateObj
        this.loadComponents(this.state.loadCount)
      }
    }

    componentDidUpdate = prevProps => {
      const { auth: prevAuth = {} } = prevProps
      const { auth = {} } = this.props
      // const { Splitted, loading } = this.state
      if (!deepEqual(prevAuth, auth)) {
        this.loadComponents(this.state.loadCount)
      }
      // if (Splitted && loading === false) {
      //   setTimeout(() => {
      //     const offsetWidth = this.myRef?.current?.offsetWidth
      //     const offsetHeight = this.myRef?.current?.offsetHeight
      //     if (offsetWidth && offsetHeight) {
      //       const loadingBoxSize =
      //         JSON.parse(window.sessionStorage.getItem('loadingBoxSize')) || {}
      //       if (!loadingBoxSize[options?.componentName]) {
      //         const newSize = {
      //           ...loadingBoxSize,
      //           [options?.componentName]: {
      //             offsetWidth,
      //             offsetHeight
      //           }
      //         }
      //         window.sessionStorage.setItem(
      //           'loadingBoxSize',
      //           JSON.stringify(newSize)
      //         )
      //       }
      //     }
      //   }, 2500)
      // }
    }

    componentDidCatch(error, errorInfo) {
      console.log('default error', error)
      this.setState({ hasError: true, error, errorInfo })
      this.errorReport(error, errorInfo)
    }

    // 멤버 권한에 따라 컴포넌트를 불러오지 않습니다.
    checkPermissions = componentPermission => {
      const { auth } = this.props
      const componentPermissionLv = findIndex(config?.role, role => {
        return role?.key === componentPermission
      })
      if (componentPermissionLv === -1) return true
      const myRole = auth?.role
      if (!myRole) return false
      const myLv = findIndex(config?.role, role => {
        return role?.key === myRole
      })
      if (myLv === -1) return false
      return componentPermissionLv >= myLv
    }

    checkAuthorization = () => {
      const { auth } = this.props
      const { authOnly, adminOnly } = options
      const isAuthorized = authOnly ? isObject(auth) : true
      const isAdmin = adminOnly
        ? isObject(auth) && auth?.isAdmin === true
        : true
      return isAuthorized && isAdmin
    }

    errorReport = (error, errorInfo) => {
      saveExceptionLog({
        error,
        fatal: true,
        event: {
          category: 'ComponentImportException',
          action: options.componentName
        }
      })
    }

    loadComponents = loadCount => {
      const {
        ConsoleLayoutActions,
        auth = {},
        failedToLoadComponent
      } = this.props
      const permission = options?.role
        ? this.checkPermissions(options?.role)
        : true
      const authorized =
        options?.authOnly || options?.adminOnly
          ? this.checkAuthorization()
          : true
      if (!permission || !authorized) {
        setTimeout(() => {
          this.setState({
            loadCount: loadCount + 1,
            memberRole: auth?.role,
            loading: false,
            permission,
            authorized
          })
        })
        return ConsoleLayoutActions.loadedComplete(options?.componentName)
      }
      getComponent()
        .then(({ default: Splitted, useProps }) => {
          const componentRole = options?.role
          const state = {
            loadCount: loadCount + 1,
            loading: false,
            memberRole: auth?.role,
            componentRole,
            permission,
            authorized,
            Splitted
          }
          this.setState(state)
          ConsoleLayoutActions.loadedComplete(options?.componentName)
        })
        .catch(error => {
          const state = {
            loadCount: loadCount + 1,
            loading: false,
            hasError: true,
            failedToLoadComponent: true,
            errorInfo: error.stack
          }
          this.setState(state)
          if (failedToLoadComponent !== true) {
            ConsoleLayoutActions.setState({
              failedToLoadComponent: state?.failedToLoadComponent
            })
          }
          ConsoleLayoutActions.loadedComplete(options?.componentName)
        })
    }

    onClickSupportAccessDenied = (role, componentRole = '없음') => {
      const { ChannelIO } = this.props
      ChannelIO(
        'openChat',
        undefined,
        [
          `권한이 없는 컴포넌트에 관하여 문의가 있습니다.`,
          `현재 권한 : ${role}`,
          `필요 권한 : ${componentRole}`,
          `--------------`,
          ``
        ].join('\n')
      )
    }

    render() {
      const { auth, authLoading } = this.props
      const {
        Splitted,
        failedToLoadComponent = false,
        hasError = false,
        loading = true,
        permission,
        authorized,
        componentRole,
        memberRole
      } = this.state || {}
      if (
        loading === true ||
        (permission === false && (authLoading === true || auth === undefined))
      ) {
        // const loadingBoxSize =
        //   JSON.parse(window.sessionStorage.getItem('loadingBoxSize')) || {}
        // const size = loadingBoxSize[options?.componentName]
        // const isOrganisms = /organisms/.test(options?.componentName)
        return (
          <Box p={1}>
            <Skeleton
              variant="rect"
              animation="wave"
              height={options.skeletonHeight}
              style={{ borderRadius: 5 }}
            />
          </Box>
        )
      }
      if (hasError) {
        if (failedToLoadComponent === true) {
          return <ComponentLoadError />
        } else {
          return <Error />
        }
      }
      if (authorized === false) {
        return null
      }
      if (permission === false) {
        if (!memberRole) return null
        return (
          <AccessDenied
            role={memberRole}
            componentRole={componentRole}
            onClickSupport={this.onClickSupportAccessDenied}
          />
        )
      }
      if (!Splitted) {
        return null
      }
      // const { highlightKeyword } = this.props
      // if (
      //   isArray(highlightKeyword) &&
      //   highlightKeyword.length > 0 &&
      //   highlightKeyword.indexOf(options.componentName) !== -1
      // ) {
      //   return (
      //     <HighlightContainer componentName={options.componentName}>
      //       <Splitted {...this.props} componentName={options.componentName} />
      //     </HighlightContainer>
      //   )
      // }
      return (
        <div ref={this.myRef} id={this.componentId}>
          <Splitted {...this.props} componentName={options.componentName} />
        </div>
      )
    }
  }

  return connect(
    bindStateToProps({
      consoleLayout: ['auth', 'authLoading']
    }),
    bindActionCreators({ consoleLayoutActions, snackbarActions })
  )(WithSplitting)
}

export default withSplitting
