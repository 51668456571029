/**
 * @author EdenCha <eden@nurigo.net>
 */
import React from 'react'
import { setPropTypes, setDefaultProps } from 'lib/setPropTypes'
import withStyles from '@material-ui/core/styles/withStyles'
// import omitBy from 'lodash/omitBy'
import { TransitionGroup } from 'react-transition-group'
import classNames from 'classnames'
import omit from 'lodash/omit'

// material UI
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import IconButton from '@material-ui/core/IconButton'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import Slide from '@material-ui/core/Slide'

// material ui icon
import CloseIcon from '@material-ui/icons/Close'

// my components
// import UserComponentName from 'components/molecules/UserComponentName'
import ProgressContainer from 'containers/ProgressContainer'

// libraries
// import config from 'config'
import { isArray } from 'lib/detectType'

const styles = theme => {
  return {
    root: {
      position: 'fixed',
      zIndex: 1500,
      bottom: 15,
      left: 10,
      maxWidth: 'calc(100% - 20px)'
    },
    paper: {
      position: 'relative',
      width: 420,
      maxWidth: '100%',
      borderRadius: 4,
      backgroundColor: '#454545',
      boxSizing: 'border-box',
      boxShadow:
        '0px 1px 7px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 5%), 0px 2px 10px -1px rgb(0 0 0 / 10%)',
      color: theme.palette.background.paper
    },
    errorPaper: {
      backgroundColor: 'rgb(211, 47, 47)',
      color: theme.palette.background.paper
    },
    successPaper: {
      borderLeft: '4px solid #4ca450'
    },
    infoPaper: {
      borderLeft: '4px solid #2196f3'
    },
    warningPaper: {
      borderLeft: '4px solid #ffc107'
    },
    iconArea: {
      textAlign: 'center'
    },
    icon: {
      maxWidth: 22
    },
    iconButton: {
      zIndex: 1501
    },
    progressWrap: {
      position: 'absolute',
      zIndex: 1500,
      top: 10,
      right: 10
    },
    button: {
      color: theme.palette.background.paper,
      borderColor: theme.palette.background.paper
    },
    errorButton: {
      color: theme.palette.background.paper,
      borderColor: theme.palette.background.paper
    },
    closeIcon: {
      color: theme.palette.background.paper
    },
    errorCloseIcon: {
      color: theme.palette.background.paper
    }
  }
}
export const useProps = {}

/**
 * (작성된 설명이 없습니다.)
 */
const Icons = props => {
  const { classes, severity } = props
  if (severity === 'error') {
    return (
      <img
        className={classNames(classes.icon, classes.errorIcon)}
        alt="error"
        title="error"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAADpUlEQVRoge2ZzWtcVRjGf+fec+/QYkqNjUJxUFEUpDAGF10PgSgtVE3SUKQiTUzsqrTNwghC6sfCCuPEnTRp3SRUOjWWYtpaDP0LRJFsBrspRrGtdTRunLkfx0VmpneSmbkTzkkHZJ7Vvfe89zzPc857zns/BFuAzPlLSTsMsijRX770Hcp+9/gbr+RNcwnTHWbOX0raQfgj0L2u6U8LlTp2eHDFJJ9lsjMAOwyyQLeAxTDwH7dQSeAq0B0iPjXNZ9xAJW0E6ujJN4d/PXZ4cCWwrbfLrS+ZppOtBPUfOpLEsrIhol9AV+X6/n37Gt7z+90/UsAKwO3bd3p379oFsGN6bkGtj128cqV6rOAfEEvSCiavzZ2LXTOxa6D/0JGksux6Od3UgOd7pULhrwFfCLtn586cI6XbKDZqIIKCCIPU9S+/+KWZvvgZsKwsdcTHwZGO+2hPzzebvS+Ch5UlM8Bws6BYA+W0AWB6apLnn3262vbZ/Nca+u7j+vxM9Xg5f5OTH5yunMaumdhFHM35qHiAh7Zva1VjQ3St62PPc89EztSOuPu1dqG+vb1aJrq2b6Nvb6+OhNZ2oUZ4cvdjjL72spYAXZivAw8YHQPtRsdAu9ExUIH30QLB4g+xcf7MEt5UzhStXh2owDt1Ee/9i+BKErkT2AderBvnn71B6egshAr+9XBOv67NrT0DVfEAJZ/i8HTdmfBnliiNnVkTD3ifXMb7cEGXXt+ASD4CVuSpvOhRHMgQXP6+eqk68iryKuBKrNQTuvT6KSRH0wCUxu+PLiWf4sEsidwJ1N3V2jaITbVN8Wv3QBMTgxkI1IaRNyUeDBmABib8sDbIsHgwXAfkaBr3zHj9F1VpGxcPW1bIjH9uagijBvyzN9ZSSG348AB+QPFgtmZ3MgFjBqrio7uNtEBEZqO8O5k0YcRAXfGuJPHVBO7seG2dMGxC20BD8eUFK0fSuDNbZ0LbgFq5Vys+4ZBYmKjZbeRIGvfztzakU/jTLV16fQPO1BDOx+WHMleSuHAce//GLw1yrK8mnZxTQzjvDejSmylkzjsHwPOxep+qK75KNpKGIET9VsCZGjJBba4StzqacqzPFCXQeSNrPzoG2o2OgXYj1sDaP6s1LOdvbq0aYDn/c/T077j4FuqAWAL1KhD9c/JgIPg2LiR2BqQVTAIFI4I2h3sSJuKCYg1cmzuXF2GQApEDsWpGWzOIVQQXpOCFq/OzRv/qd9DB/xH/AUh+Pw1LFBQeAAAAAElFTkSuQmCC"
      />
    )
  }
  if (severity === 'success') {
    return (
      <img
        className={classNames(classes.icon, classes.successIcon)}
        alt="success"
        title="success"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAD8klEQVRoge2ZXUwcVRTHf3dmFsrKR7vhwxBWsTRtpBiKGEmMiikUm9akXwkhhqbBmvhdtaQR+pm0lcYmW6hpfKnWh0JKqNE+AK0ISY3GGG1NTfABirGJbKUqUpam25TdHR9gtzuE3YHeWTZp9vc099xz7zn/3Hvm3skIYoDrzDmnGvA3o4uqaVMvutr43tYNA1bHElZP6Dpzzqn6A1cAx4yu/xT04h21W4atjKdYORmAGvA3Aw4BXQG/L09BdwLnAUcAcczqeJYLCG4bgf76zm3V7h21W4b9qvLadO+LVofT5uJUVVPnRFGaA4gqAWlB+/p16yKOGfnn32JgGODGjb9LcjMzAdJbWr/UZ/p2dXeHnnWYANGnKf6GC62nTGvGtAaqauqcuqLOtqejCpj0Td4dG7u52SeEmrV48VmbpiVF8g0XEMaYCPiLe9o//zNafuYroCjNzJK8GTbNlpSdldU533FhLNEVzQVUR3MyFTC9bQBoOdBA4fKCUN/xtq8k8rtHT9vJ0HP/wBA7D34UbJrWjGkRh+/58OQBUu0pc80xImkz5ihasSyspaebjZd6C1WUlUiJSLOnUFFWIpPC3N5CkcjPzWH7prVSCchi/TmwwCQExJuEgHiTEBBv4iLgJ/fPdA7OeoGbN1IH2f0wODrIoYtNeH1eRiZG2F5ah5D4MFzQFXB73DT27sPr8wLQ3t/Bxz+ekJpzwQSM3xlnd99+xu+MG+yPZjwiNa+0AB2do9+7aO/viOhze9JLQ+8e3B63wV5b/DIbH98gFV+6Bk5faaPn928A+GtihHfK3kRT7k3rC/g4ePEwV0eHDOMql65m26qtsuHlVuDba99x+te2ULtrsJs9ffu5dfcWMLU6x344zqXrlw3jyvKeZtez9VLFG0RKwENJduxJdoPt8vVfeKvrXdweNycvfRZanSArMpezr3w3qlBlQoeQEvBUbikta13kpGYb7G6Pmzc636bjty8MdmdGHk2Vh1mkLZIJa0C6iB9bks8nL53giZwig/32pNfQdqQ4OFL5IRnJpl+J88KS12hGcjpH1xyhcunqWfvtNjtNlYd4ODXHinAGLDsHbKqND57bxaulrxiKU1M0Drywl2WOgiij7x9LDzKBoKaomr3ljSSrSQgE9c+8T2nuk1aGMRCTu1B5/vNk2jP54+Y11hRUxCJEiJhd5lZmF7IyuzBW04dIfA/Em4SAeJMQEG8efAFT/6ym6B8YiuZqCf0DV8Ob45H8gszhIBN9oG8Ewv+cLAyCr81cTFdAU/wNwJglCc2PUQ3qzZxMBVxoPTUgAv5iEGdBeKzJLRrCg6BDE6w63/appX/1EyR4EPkffHoGCAfd2usAAAAASUVORK5CYII="
      />
    )
  }
  if (severity === 'info') {
    return (
      <img
        className={classNames(classes.icon, classes.infoIcon)}
        alt="info"
        title="info"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAD6UlEQVRoge2ZT2wTRxTGv7e7NuB4g4DSFhwUCIFEqgqH0PYM4gIneqhSFVBaCckYJ4h7VSmRUO+lsVNfKiEFiYQeqNRyzIUeUBRLSFxK1BoLTEIc4kNsjOxk93EgQsn+ye7srldI5HfzmzfzvqcZ78y8AbbY4sOGAhllkuXOSvVLScdJBvVJQC8D+wDE1zxqDMwBeEzgPIOniuX2aQyT7je0rwQOZ+oHmPQ0wBcYSAhGLrFO44omZf67Git51eApge4b1b2agusAvgcQ9Rp8jSYBvysy/zSbbH8p2lk4ga5s7TsG/wpgt2hfB5aYMFhMqbdFOrlOoC/HkYpWywK4JCxNAGLkdinxoXySVlz5u3Han+PYdq36B4PO+JPnDma+11TUb+aSVHfylZwc+nIcCVM8ABDR2W1a7c/PJtnx/+WYQEWrZcMUv47Tr1/WfnFy2nQJdY3VzjPzeHCaxGHGt8W0OmHXbptAYnR5T1SifwF81BJl7qlEZO6x+8TaLqGoRD/Dh/gfjkXwYKANDwbaMPB5xOswALB7RacRu0bLGei+Ue/QFO1/eNykvtgnY+Lcjne/GUD/3deYmde8DAcATZKVI4XkjqfGBssZ0BV9ED522OMfbxyWLGyCRPVVLWXVYB51mCUmPu8n2sPyxjMaA3i44O/cJhFfxCTLJrvR0Plp9SswOvwEm5nXMPJPAwuvGAuvGCP3G8i/8Lx8AAAMJA4tLfcZ7YrRIOk46SvSGjcfreDmI1enAdcQ6BSA6fU20wwQ04lAowYIg0wzYEpAJxwNR44neowG0xKitzcp3xRS8Q2/u8Zq/gdlszarb1vcwva+oBoNvj7O7wNWCQQw1y2jajSYEmBgPhwtHiCzNlMCEmM2HDWeeGw0mGeAeCYcLeIwm7VZLCGeCkeOOBLLJm2mBIrl9mkAz0JRJMbTwmIsbzRanEZJZ6ZboUgSgEG3rEqRlvuAokkZAM2Wq3JPY5WkjFWD7Z24K1sdY+By6zQJwBh9klaHrJpsd+JmVP8RgHCtsgUsRRS2vRPbJlC6tLPCBMusQya1WdF307NQMaXeJkYueE2uyTy5ot7ZzMHxMFfYG08TcDc4TW6hvzvL8WuOXm6G2p/jWHS1eoeIzvoX5gb6qyG39QdS3AWAuSTV9yjqOQJ+8y/OkUxnue1rN+IBDw8cBzPVfiKMIviS4yKAtNOaNyJ8oSmm1Ymmzr0gZAE0RPtb0ABjdCWq94qKB3w+8nWM1ROKrg/S20LYAcHuzxg0vkpSppSKPfeqIZhn1mGWDn2yfIJAp9ZKHz1gJLDumRVACYRZZp6RWJ4qLMbyQTyzbrHFh84bhqRC3BxZlk0AAAAASUVORK5CYII="
      />
    )
  }
  if (severity === 'warning') {
    return (
      <img
        className={classNames(classes.icon, classes.warningIcon)}
        alt="warning"
        title="warning"
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAABmJLR0QA/wD/AP+gvaeTAAAChUlEQVRoge2YT2sTQRjGf9Nmd6NFIlHx4EUQPbR48AOIVtFDUD9Bj6LFPwj2JngRBMFiBC9+Bc+CJ8GmQu9SAoIEL7UgDShEoWnavF7aZLvN7szszlbB/Z2WzOy8z5tn5mESKCgoKCj4n1F5LSwLwRUUrwHoq9tqev19HnVyaUCWqNIrfwGpbn/0k75/Wk132q5rjbleEIBe8DQkHuAw4xtP8ijl3AFZ9CcR9QkoRYa2QM6pCxvLLuu5d0BUnb3iAcZBvXRdzmkD0ghuAFcTplySheC6y5rOtpA08WkHy8AZTcUWv7pTqkbXRV13DrSD++jEAwinOBjcdVXWiQMjYlOHs1h148De2NThLFYzOxAXmw/mp/jR8QA4UulRf9iMvuokVrM7EBObXqk/ePa9fnQYHMVqpgaSYtP3ZPAcbiZC5lhN3YA08YHncePhbz3czAgFdXlHkFZHegc0semVhqL9eAcyx2qqBmSJKqhHSXN2O5DQAIDisXw4dDSNlnQOGMRmWLSnayBDrFo3IIv+JHBTNy+8bfxSwhkYLMwtafhnbfXYOxB/29yF8RkYkipWrRowuG0OsDoDQ6xj1bgBXWxGsTwDYUVWsWrugOltc5uwaM/kDOxgGatGd6EUt82sGN9WzRywv21mxThWtQ4k/EhP5Ntambcfj6OAa+e/c+LYus3rYHhb1YsyjM0or96cZHWtDMDX1QM8u/fZdomdWL2cNClxC9nEZpTOb2/ksyXaWI1twDY2o8zUVqhMbFKZ2GSmtpJ2GW2sxp4BaQRzwHz6yg4R5tTF7otRQ0lb6E5OcuwZi9eSz3+j+0h8A8IsitY+ahmNosWWmv3bMgoKCgoK/k3+AILkzValVvu5AAAAAElFTkSuQmCC"
      />
    )
  }
  return null
}

const SnackbarQueue = ({ classes, proxy, ...props }) => {
  const {
    onCompleteTimer,
    onClickCloseButton,
    onClickAway,
    onMouseEnter,
    onMouseLeave,
    onClickButton
  } = proxy
  const { messageQueue = [] } = props
  return (
    <div
      className={classes.root}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <TransitionGroup>
          {messageQueue.map(data => (
            <Slide key={data?.hash} direction="right">
              <Box
                p={1}
                mb={1}
                className={classNames(classes.paper, {
                  [classes.errorPaper]: data?.severity === 'error',
                  [classes.infoPaper]: data?.severity === 'info',
                  [classes.warningPaper]: data?.severity === 'warning',
                  [classes.successPaper]: data?.severity === 'success'
                })}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Grid
                      container
                      // alignItems="center"
                      alignItems="flex-start"
                      justifyContent="flex-end"
                      spacing={1}
                    >
                      <Grid item xs="auto">
                        <div className={classes.iconArea}>
                          <div className={classes.imgWrap}>
                            <Icons
                              classes={classes}
                              severity={data?.severity}
                            />
                          </div>
                        </div>
                      </Grid>
                      <Grid item xs>
                        <Typography
                          variant="body2"
                          style={{
                            wordBreak: 'break-all',
                            whiteSpace: 'pre-wrap'
                          }}
                        >
                          {data?.message}
                        </Typography>
                      </Grid>
                      {data?.showCloseButton !== false && (
                        <Grid item xs="auto">
                          <IconButton
                            size="small"
                            className={classes.iconButton}
                            onClick={() => onClickCloseButton(data?.hash)}
                          >
                            <CloseIcon
                              className={classNames(classes.closeIcon, {
                                [classes.errorCloseIcon]:
                                  data?.severity === 'error'
                              })}
                            />
                          </IconButton>
                          {data?.autoHide !== false && (
                            <div className={classes.progressWrap}>
                              <ProgressContainer
                                type="circular"
                                // 해당 플래그 변경 시 시간 초기화
                                resetFlag={data?.message}
                                color={data?.progressColor || '#ccc'}
                                pause={data?.hidePause}
                                duration={data?.duration}
                                onComplete={() => {
                                  onCompleteTimer(data?.hash)
                                }}
                                progressProps={{ size: 26 }}
                              />
                            </div>
                          )}
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                  {isArray(data?.buttons) && data.buttons.length > 0 && (
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        {data.buttons.map((buttonData, index) => {
                          return (
                            <Grid item xs="auto">
                              <Button
                                className={classNames(classes.button, {
                                  [classes.errorButton]:
                                    data?.severity === 'error'
                                })}
                                size="small"
                                variant="outlined"
                                fullWidth
                                {...omit(buttonData, ['name'])}
                                onClick={e => onClickButton(e, index, data)}
                              >
                                {buttonData?.name}
                              </Button>
                            </Grid>
                          )
                        })}
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Box>
            </Slide>
          ))}
        </TransitionGroup>
      </ClickAwayListener>
    </div>
  )
}

SnackbarQueue.defaultProps = setDefaultProps(useProps)
SnackbarQueue.propTypes = setPropTypes(useProps)
export default withStyles(styles)(SnackbarQueue)
