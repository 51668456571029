import { createStore, applyMiddleware, compose, combineReducers } from 'redux'
import penderMiddleware from 'redux-pender'
import modules from 'store/modules'

if (process.env.NODE_ENV === 'test' || process.env.REACT_APP_ADMIN === '1') {
  const adminReducers = require('admin/store/modules')
  Object.assign(modules, adminReducers)
}

const isDev = process.env.NODE_ENV === 'development'
const devtools =
  isDev && window.devToolsExtension ? window.devToolsExtension : () => fn => fn

const configureStore = initialState => {
  const enhancers = [applyMiddleware(penderMiddleware()), devtools()]

  const store = createStore(
    combineReducers({
      ...modules
    }),
    initialState,
    compose(...enhancers)
  )

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('./modules', () => store.replaceReducer(modules))
  }
  return store
}

export default configureStore
